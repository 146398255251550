import i18n from "i18next";
import { initReactI18next, } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import commonFr from './fr/common.json'
import commonEn from './en/common.json'
import berthFr from './fr/berth.json'
import berthEn from './en/berth.json'
import authFr from './fr/auth.json'
import authEn from './en/auth.json'
import appEn from './en/app.json'
import appFr from './fr/app.json'
import ElectricityEn from './en/electricity.json'
import ElectricityFr from './fr/electricity.json'
import vigieEn from './en/vigie.json'
import vigieFr from './fr/vigie.json'
import ticketFr from './fr/ticket.json'
import ticketEn from './en/ticket.json'
import usersEn from './en/users.json'
import usersFr from './fr/users.json'
import newsEn from './en/news.json'
import newsFr from './fr/news.json'
import craneEn from './en/crane.json'
import craneFr from './fr/crane.json'
import portCMSEN from './en/portCMS.json'
import portCMSFR from './fr/portCMS.json'

import technicalAreaEN from './en/technicalArea.json'
import technicalAreaFR from './fr/technicalArea.json'

import * as dayjs from "dayjs";
import "dayjs/locale/fr";
var advancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(advancedFormat);
export const client_lang = window.location.hostname.match(process.env.REACT_APP_EN_DOMAIN!) !== null ? "en" : "fr";

dayjs.locale(client_lang);
i18n
  // .use(LanguageDetector) // not used for the moment as we get the language from the ENV domain name
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: client_lang, // lng from language
    ns: ['common', 'berth', 'vigie', 'electricity', 'news', "auth"],
    defaultNS: 'common',
    // the translations
    supportedLngs: ['fr', 'en'],
    fallbackLng: "fr",
    resources: {
      en: {
        common: commonEn,
        berth: berthEn,
        electricity: ElectricityEn,
        auth: authEn,
        app: appEn,
        vigie: vigieEn,
        ticket: ticketEn,
        users: usersEn,
        news: newsEn,
        crane: craneEn,
        portCMS: portCMSEN,
        technicalArea: technicalAreaEN
      },
      fr: {
        common: commonFr,
        berth: berthFr,
        electricity: ElectricityFr,
        vigie: vigieFr,
        ticket: ticketFr,
        auth: authFr,
        app: appFr,
        users: usersFr,
        news: newsFr,
        crane: craneFr,
        portCMS: portCMSFR,
        technicalArea: technicalAreaFR
      },
    },
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

export default i18n;