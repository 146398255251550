import dayjs from "dayjs"
import i18n from "../../../translations/initI18n"
import { createSelector } from "@reduxjs/toolkit"
import { RootState } from "../store"
import { getDateObjectFromTime } from "../../../_helpers/dateTime.helper"
import { compareString, stringDescendingComparator } from "../../../_helpers/sort.helpers"
import { TABooking } from "../../models/technicalArea.models"

export const getFilteredTABookingList = createSelector(
  (state: RootState) => state.technicalArea.bookingList,
  (state: RootState) => state.technicalArea.filters,
  (list, filters) => {
    return list?.filter(l => {
      const date = dayjs(l.start_date).format(i18n.t('dates.day_month', { ns: "common" })) +
        getDateObjectFromTime(l.start_time, l.start_date).format(i18n.t('dates.hour', { ns: "common" })) +
        " " +
        getDateObjectFromTime(l.end_time, l.end_date).format(i18n.t('dates.hour', { ns: "common" }))
      const agents = l.agents.map(a => "" + a.first_name + " " + a.last_name).join(",")
      const services = l.services.map(s => s.name).join(",")
      const str = "" + agents || "" +
        services || "" +
        (l.boater?.first_name || "") +
        (l.boater?.last_name || "") +
        (l.boat?.name || "") +
        (l.boat?.registration_number || "") +
        date

      return compareString(str, filters.text) && (Object.keys(filters.status).length > 0
        ? filters.status[l.status.replaceAll("-", '_').toUpperCase()] : 1)
    }).sort((a: TABooking, b: TABooking) => {
      const timeA = getDateObjectFromTime(a.start_time, a.start_date)
      const timeB = getDateObjectFromTime(b.start_time, b.start_date)
      return -stringDescendingComparator(timeA, timeB)
    })
  }
)

export const findSelectedInList = createSelector(
  (state: RootState) => state.technicalArea.bookingList,
  (_: any, id?: string) => id,
  (list, id) => {
    return list?.find(item => item.id === id)
  }
) 