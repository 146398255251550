import { Box } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { FC, useContext, useEffect, useState } from "react";
import { TableSection } from "./Table/Table.section";
import { AreaSection } from "./Area/Area.section";
import { useAppDispatch, useAppSelector } from "../../../../../core/store/hooks";
import { moveSection } from "../../../../../core/store/actions/Port.actions";
import { AccordionLayout, AreaLayout, BaseLayout, ImageLayout, ImagesTableLayout, LAYOUT_NAME, Layout, TableLayout } from "../../../../../core/models/portCMS.models";
import { PortSectionContainer } from "./Section.container";
import { ImageSection } from "./Image.section";
import { ImageTableSection } from "./ImageTable.section";
import { AccordionSection } from "./Accordion.section";
import classNames from "classnames";
import { PortCMSPreviewContext } from "./Preview/PreviewContext";

export const SectionNameI18n = {
  [LAYOUT_NAME.ACCORDION]: "sections.accordeon.title",
  [LAYOUT_NAME.AREA]: "sections.area.title",
  [LAYOUT_NAME.IMAGE]: "sections.image.title",
  [LAYOUT_NAME.IMAGE_TABLE]: "sections.imageTable.title",
  // [LAYOUT_NAME.LABEL_VALUE]: "sections.imageTable.title",
  [LAYOUT_NAME.TABLE]: "sections.table.title"
}

type SectionsListPros = {
}
export const SectionsList: FC<SectionsListPros> = () => {
  const { current: port, sections } = useAppSelector(state => state.ports)
  const dispatch = useAppDispatch()
  const {
    canDrag, setCanDrag,
    isDraggable, setIsDraggable } = useContext(PortCMSPreviewContext)

  const [items, setItems] = useState<Layout[]>(sections)

  useEffect(() => {
    setItems(sections)
  }, [sections])

  const onDragEnd = (result: any) => {
    if (!result.destination || !port) {
      return;
    }
    const sectionToMove = items[result.source.index]
    dispatch(moveSection({ port: port.id, section: sectionToMove, position: result.destination.index }))
    const tmpItems: Layout[] = reorder(
      items as Layout[],
      result.source.index,
      result.destination.index
    );
    setItems(tmpItems);
  }

  const reorder = (list: Layout[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: "none",
    ...draggableStyle
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable isDropDisabled={!canDrag || !isDraggable} droppableId="droppable">
        {(provided, snapshotList) => (
          <Box
            className={classNames("flex flex-col gap-8", {
              "": snapshotList.isDraggingOver
            })}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {items.map((item, index) => (
              <Draggable
                isDragDisabled={!canDrag || !isDraggable}
                key={item.layout + item.id + index}
                draggableId={item.layout + item.id}
                index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <SwitchSection isDND={snapshotList.isDraggingOver} section={item} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )
}
type SiwthSectionProps = {
  section: Layout,
  isDND: boolean
}

const SwitchSection: FC<SiwthSectionProps> = ({ section, isDND = false }) => {
  switch (section.layout) {
    case LAYOUT_NAME.AREA:
      return <AreaSection isDND={isDND} key={section.id} section={section as AreaLayout} />
    case LAYOUT_NAME.TABLE:
      return <TableSection section={section as TableLayout} key={section.id} />
    case LAYOUT_NAME.ACCORDION:
      return <AccordionSection section={section as AccordionLayout} key={section.id} />
    case LAYOUT_NAME.IMAGE:
      return <ImageSection section={section as ImageLayout} key={section.id} />
    case LAYOUT_NAME.IMAGE_TABLE:
      return <ImageTableSection section={section as ImagesTableLayout} key={section.id} />
    default:
      return <PortSectionContainer
        section={section}
        isDND={isDND}
        key={(section as BaseLayout).id}
        title={(section as BaseLayout).title}
        subtitle={(section as BaseLayout).layout}></PortSectionContainer>
  }
}