import { Alert, Box, Button, Tab, Tabs, Typography } from "@mui/material"
import { FC, useContext } from "react"
import { PortCMSPreviewContext } from "./PreviewContext"
import { useAppSelector } from "../../../../../../core/store/hooks"
import portImgFallback from "../../../../../../assets/img/bg-login.jpg"
import { SwitchSectionPreview } from "./SwitchSectionPreview"
import { useTranslation } from "react-i18next"

export const PreviewContainer: FC = () => {
  const { previewSection } = useContext(PortCMSPreviewContext)
  const { t } = useTranslation(["portCMS"]);

  const port = useAppSelector(state => state.ports.current)

  return (
    <Box className="bg-[#E1E9EC] w-1/3 p-8 self-stretch fixed top-0 h-full right-0 ">
      <Box className='pt-16'>
        {/* <Typography><b>Pré-visualisation simplifée </b>: cliquer sur la zone que vous vous souhaitez modifier sur votre droite</Typography> */}
        <Alert severity="info" >{t("preview.info")}</Alert>

        <Box className="relative mx-auto mt-3 rounded-2xl max-h-[68vh] aspect-[9/16] border-4 border-black bg-fcogrey">
          {
            port && <Box className="relative">
              <Box className="max-h-xs z-0 relative h-64 overflow-hidden">
                <img
                  className="rounded-t-xl "
                  alt={port.name}
                  onError={(e) => {
                    (e.target as HTMLImageElement).onerror = null; // avoid looping
                    (e.target as HTMLImageElement).src = portImgFallback
                  }}
                  src={(port as any).header} />
                <p className="px-4 py-2 text-white font-bold absolute bottom-6 text-2xl">{port.name}</p>
              </Box>
              <Box className=" grid grid-cols-2 justify-around pl-8 py-t-2 px-4 bg-white -mt-6 rounded-t-3xl z-20 relative">
                <Box className="text-center py-2  border-b-4  border-fcomain">
                  {t("preview.marina")}
                </Box>
                <Box className="text-center leading-3 border-b border-fcomain text-fcotextgrey py-2 ">
                  {t("preview.commodities")}
                </Box>

              </Box>
            </Box>
          }
          <Box className="px-4 py-4 flex flex-col gap-6">
            <SwitchSectionPreview />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}