import { useAppDispatch } from "../store/hooks";
import { updateTicket } from "../store/actions/Ticket.actions";
import { ITicket } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import FDK from "@wattsonelements/front-fdk";
import { dispatchToPromise } from "../../_helpers/dispatchPromiseHelper";

export const useTicket = () => {
  // const categories = useAppSelector((state) => state.tickets.categories);
  // const [iconCategories, setIconCategories] = useState();
  const dispatch = useAppDispatch();

  // const getAssignees = (ticket: ITicket) => {
  //   const assignees: Partial<IAssignee>[] =
  //     ticket.ticket_intervention.length > 0
  //       ? ticket.ticket_intervention[0].intervention_assignee
  //       : [];
  //   return assignees;
  // }

  const updateAssignee = (id: string, assignees: string[]) =>
    dispatchToPromise(dispatch(
      updateTicket({
        id: id,
        field: { assignees }
      })
    ))

  // TODO replace any with Partial<ITicket>
  const updateFields = (id: string, field: any) => {
    return dispatchToPromise(dispatch(updateTicket({ id, field })))
  }

  const updateDescription = (id: string, description: string) =>
    dispatchToPromise(
      dispatch(
        updateTicket({
          id: id,
          field: { description }
        })
      ))

  const updateTitle = (id: string, title: string) =>
    dispatchToPromise(
      dispatch(
        updateTicket({
          id: id,
          field: { title }
        })
      ))

  const generateTicketGeoJson = (datas: ITicket[]) => {
    let tmpGeoJson: any = {};
    tmpGeoJson = { type: "FeatureCollection", features: [] };
    datas.map((ticket, i) => {
      if (
        ticket.localization &&
        (ticket.status === FDK.Constants.TicketConstants.TicketStatus.ONGOING ||
          ticket.status === FDK.Constants.TicketConstants.TicketStatus.OPEN)
      ) {
        const iconName =
          ticket.priority === FDK.Constants.TicketConstants.TicketPriority.URGENT ? "emergency" : "incident";
        tmpGeoJson.features.push({
          type: "Feature",
          id: ticket.id,
          properties: {
            width: 20,
            icon: `${iconName}`,
            selectedIcon: `selected_ticket`,
            anchor: { x: 10, y: 10 },
            clickable: true,
            id: ticket.id,
          },
          geometry: {
            type: "Point",
            coordinates: ticket.localization?.coordinates,
          },
        });
      }
    });
    // console.log("geojson", tmpGeoJson);

    return tmpGeoJson;
  };

  return {
    updateAssignee,
    updateDescription,
    generateTicketGeoJson,
    updateTitle,
    updateFields
  };
};
