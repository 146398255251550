import { useTranslation } from "react-i18next";
import { PortSectionContainer } from "../Section.container"
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, TextField, Typography } from "@mui/material";
import { ContactListForm } from "./Contacts.form";
import { Area, AreaLayout } from "../../../../../../core/models/portCMS.models";
import { FC, useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ExpandMore, ModeEdit, Save } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../../../../core/store/hooks";
import { getArea, updateArea, updateSection } from "../../../../../../core/store/actions/Port.actions";
import { CalendarsList } from "./Calendars.list";
import { PortCMSPreviewContext } from "../Preview/PreviewContext";
import { AlertType, useAppContext } from "../../../../../contexts/AppContext";
import { SectionNameI18n } from "../Sections.list";
import { AreaOpeningAlert } from "./AreaOpeningAlert";


type AreaSectionProps = {
  section: AreaLayout
  isDND: boolean
}
export const AreaSection: FC<AreaSectionProps> = ({ section, isDND }) => {
  const { t } = useTranslation("portCMS");
  const dispatch = useAppDispatch()
  const { setPreviewSection, formsDirty, setFormsDirty, previewSection } = useContext(PortCMSPreviewContext)
  const port = useAppSelector(state => state.ports.current)
  const { setAlertMessage, setAppLoading } = useAppContext()
  const methods = useForm<AreaLayout>()
  const { register, handleSubmit, reset, getValues, formState: { errors, isDirty } } = methods;

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!section || !port) return
    dispatch(getArea({ port: port.id, area: section.area })).then(res => {
      reset({ ...section, area: (res.payload as Area) })
    }).finally(() => {
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    setFormsDirty({
      ...formsDirty,
      [section.layout + '-' + section.id]: isDirty
    })
  }, [isDirty, section])

  const submit = async (data: AreaLayout) => {
    if (!port) return
    setAppLoading!(true)
    setPreviewSection(data)

    Promise.all([
      dispatch(updateArea({ port: port.id, area: data.area })),
      dispatch(updateSection({
        port: port.id,
        section: {
          id: data.id,
          title: data.title,
          button: data.area.contacts.length > 0 ? data.button : undefined,
          layout: data.layout
        }
      }))
    ]).then(res => {
      const areaError = res.filter(r => r.type.includes('sections/area') && r.type.includes('rejected'))
      const sectionError = res.filter(r => r.type.includes("sections/update") && r.type.includes('rejected'))

      if (areaError.length > 0 && sectionError.length > 0) {
        setAlertMessage!({
          title: t(SectionNameI18n[section.layout]) + ' - ' + section.title,
          message: t('sections.form.saved.error'),
          // sub_message: res[0].payload.data?.sub_message + ' ' + res[1].payload.data?.sub_message || "",
          type: AlertType.ERROR
        })
      } else if (areaError.length > 0) {
        setAlertMessage!({
          title: t(SectionNameI18n[section.layout]) + ' - ' + section.title,
          message: areaError[0].payload.data?.message || t('sections.form.saved.error'),
          sub_message: areaError[0].payload.data?.sub_message || "",
          type: AlertType.ERROR
        })
      } else if (sectionError.length > 0) {
        setAlertMessage!({
          title: t(SectionNameI18n[section.layout]) + ' - ' + section.title,
          message: sectionError[0].payload.data?.message || t('sections.form.saved.error'),
          sub_message: sectionError[0].payload.data?.sub_message || "",
          type: AlertType.ERROR
        })
      } else {
        setAlertMessage!({
          title: t(SectionNameI18n[section.layout]) + ' - ' + section.title,
          message: t('sections.form.saved.success'),
          type: AlertType.SUCCESS
        })
        const newValue: Partial<AreaLayout> = {
          ...res[1].payload,
          area: res[0].payload,
        }
        reset(newValue)
        setFormsDirty({
          ...formsDirty,
          [data.layout + '-' + data.id]: false
        })
      }
    }, err => {
      console.log(err);

    }).finally(() => {
      setAppLoading!(false)

    })
  }

  return (
    <PortSectionContainer
      section={section}

      // isDND={isDND}
      rightItems={<Box className="flex gap-2 items-center">
        {
          section.area.opening_dates_warning && previewSection?.id !== section.id &&
          <AreaOpeningAlert warning={section.area.opening_dates_warning} />
        }

        {
          isDirty ? <Button onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            handleSubmit(submit)(e)
          }}
            size="small"
            variant="outlined"
            color="secondary"><Save /></Button> : (previewSection?.id === section.id ? <> </> : <ModeEdit color="secondary" />)
        }
      </Box>}
      title={`${t('sections.area.title')} - ${section?.title}`}
      subtitle={t('sections.area.subtitle')}
    >
      {
        isDND || isLoading ? <></> :
          <FormProvider {...methods}>
            <form
              className="flex flex-col"
              onSubmit={handleSubmit(submit)}>
              <TextField {...register("title", { required: false, maxLength: 30, })} className="self-start" label={t("sections.form.title")} />

              <Accordion defaultExpanded elevation={0}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Box className="flex flex-col gap-2 w-full">
                    <Typography
                      color="primary"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.2em"
                      }}>{t("sections.area.openingForm.title")}</Typography>
                    {/* {
                      !hasCurrentOpeningPeriod(getValues("area.opening_dates.dates")) &&
                      <Alert className="block w-full" severity="warning">
                        {t("sections.area.openingForm.noCurrentPeriod")}
                      </Alert>
                    } */}
                    {
                      section.area.opening_dates_warning &&
                      <AreaOpeningAlert warning={section.area.opening_dates_warning} />
                    }

                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <CalendarsList />
                </AccordionDetails>
              </Accordion>
              <Accordion elevation={0}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography
                    color="primary"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.2em"
                    }}>{t("sections.area.contactTitle")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className=" flex flex-col gap-3">
                    <Typography color="primary"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.1em"
                      }}>{t("sections.area.contactForm.title")}</Typography>
                    <Box className=" flex gap-3">
                      <TextField error={!!errors.area?.email} {...register('area.email', { required: true, maxLength: 50, })} label={t("sections.area.contactForm.email")} />
                      <TextField error={!!errors.area?.phone}  {...register('area.phone', { required: true, maxLength: 20, })} label={t("sections.area.contactForm.phone")} />
                      <TextField type="text" error={!!errors.area?.vhf_channel}  {...register('area.vhf_channel', { required: true, maxLength: 10, })} label={t("sections.area.contactForm.canal")} />
                    </Box>
                    <Typography color="primary"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.1em"
                      }}>{t("sections.area.contactListForm.title")}</Typography>
                    <ContactListForm />
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Button type="submit" color="secondary" variant="contained">{t("sections.form.save")}</Button>
            </form>
          </FormProvider>
      }
    </PortSectionContainer>
  )
}
