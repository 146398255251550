import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import FDK from '@wattsonelements/front-fdk'
import { UUID } from '@wattsonelements/front-fdk/dist/models/type.models'

export enum TICKET_FIELDS {
  BOAT = "boat",
  SPOT = "spot",
  AREA = "area",
  EVENT_DATE = "event_date",
  DEADLINE = "deadline",
  TIME_SPENT = "time_spent",
  KIND = "kind"
}

export type TicketConfig = {
  port_id: UUID,
  ticket_displayed_fields: string | TICKET_FIELDS
  ticket_kinds: {
    id: UUID,
    name: string
  }[]
}

export const ticketApi = createApi({
  reducerPath: 'ticketApi',
  baseQuery: fetchBaseQuery({
    baseUrl: "v1/ticket",
  }),
  endpoints: (builder) => {
    return {
      getConfig: builder.query<TicketConfig | null, string>({
        queryFn: async (portId, { getState }, extraOptions, baseQuery) => {
          if (portId) {
            const res = await FDK.MainAPI.API.request<TicketConfig[]>({
              url: `/v1/ticket/configs?port_id=${portId}`
            })
            if (res.data && res.data.length > 0) {
              return { data: res.data[0] }
            }
            return { data: null }
          }
          return { data: null }
        },
      },
      )
    }
  },
}
)