import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { usePeriodic } from "../../../../core/services/Periodic.service";
import {
  getBerthsList,
  getBerthStats,
} from "../../../../core/store/actions/Berth.actions";
import { setFirstLoading, setSelected as setSelectedBerth } from "../../../../core/store/reducers/Berth.reducer";
import { useAppDispatch, useAppSelector } from "../../../../core/store/hooks";
import { getFilteredBerthList } from "../../../../core/store/selectors/Module.selector";
import { useBerthService } from "../../../../core/services/Berth.service";
import { DashboardLayout } from "../../layouts/Dashboard.layout";
import { MapBoxLayer } from "../../elements/MapBoxLayer.element";
import { MapType } from "../../../../_helpers/map.helper";
import { BerthChart } from "../../elements/dashboard/Berth/BerthChart.element";
import { BerthLegend } from "../../elements/dashboard/Berth/BerthLegend.element";
import { Geojson } from "../../../../core/models/map.models";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";
import { formatAreaId, generateAreaUrl } from "../../../../_helpers/misc.helpers";
import { usePortService } from "../../../../core/services/Port.service";

export const BerthModulePage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("berth");
  const navigate = useNavigate();
  const { berthId, areaId } = useParams();
  const port = useAppSelector((state) => state.ports.current);
  const config = useAppSelector((state) => state.berth.config);
  const listLoading = useAppSelector((state) => state.berth.firstLoading);
  const { startPeriodic, stopPeriodic } = usePeriodic();
  const delay = config.config.refresh_delay;
  const datas = useAppSelector(getFilteredBerthList);
  const selectedBerthId = useAppSelector((state) => state.berth.selected?.id);
  const { loading, filters: { status } } = useAppSelector((state) => state.berth);
  const { generateBerthGeoJson } = useBerthService();
  const { generateAreasStatsGeoJson } = usePortService();
  const [geojson, setGeoJson] = useState<Geojson>(generateBerthGeoJson([], status));
  const mapRef = useRef<any>();

  useEffect(() => {
    if (!port) return;
    if (datas) {
      // setMarkers(datas.map((b) => generateBerthMarker(b)));
      (formatAreaId(areaId) === "" && port.areas.length > 1) ?
        setGeoJson(generateAreasStatsGeoJson(port.areas, MapType.BERTH))
        : setGeoJson(generateBerthGeoJson(datas, status))
    }
  }, [datas, port]);

  useEffect(() => {
    if (loading || listLoading || !mapRef.current) return
    if (!berthId)
      setTimeout(() => mapRef.current?.fitTheMap(), 500);
    else
      setTimeout(() => mapRef.current.flyToSelectedMarker(berthId), 500);
  }, [berthId, loading, listLoading, mapRef.current]);

  useEffect(() => {
    dispatch(setFirstLoading(true))
    startPeriodic("berth-list", delay, () => {
      return dispatch(getBerthsList({ port_area: formatAreaId(areaId) }));
    });
    startPeriodic("berth-stats", delay, () => {
      return dispatch(getBerthStats({ port_area: formatAreaId(areaId) }));
    });

    return () => {
      dispatch(setSelectedBerth(null));
      stopPeriodic("berth-list");
      stopPeriodic("berth-stats");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaId]);

  const clickOnMarker = (berthId: UUID) => {
    navigate(`/admin/area/${areaId || "all"}/modules/berths/${berthId}`);
    // if (!loading && !listLoading && mapRef.current)
    mapRef.current.flyToSelectedMarker(berthId);
    // if (port && port.areas.length > 1)
    //   navigate(`/admin/area/${datas!.find((berth) => berth.id === berthId)?.port_area}/modules/berths/${berthId}`);
    // else
    //   navigate(`/admin/area/all/modules/berths/${berthId}`);
  };
  const clickOnArea = (id: UUID) => {
    navigate(generateAreaUrl(id))
  };

  return (
    <DashboardLayout
      top={
        <Box className="flex justify-center items-center">
          <BerthChart />
          <BerthLegend />
        </Box>
      }
    >
      <Box className="berth-content flex-col flex flex-1 flex-grow">
        <Outlet />
      </Box>
      <MapBoxLayer
        ref={mapRef}
        mapType={MapType.BERTH}
        loading={loading || listLoading}
        selectedMarkerId={selectedBerthId ? selectedBerthId : null}
        geojson={geojson}
        clickOnMarker={
          !port ?
            () => { }
            : (formatAreaId(areaId) === "" && port.areas.length > 1) ?
              clickOnArea
              : clickOnMarker}
      />
    </DashboardLayout>
  );
};
