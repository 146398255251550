import { FC } from "react"
import { TABooking, TAService, TA_BOOKING_STATUS } from "../../../../../../core/models/technicalArea.models"
import { Box, Button } from "@mui/material"
import { getDateObjectFromTime } from "../../../../../../_helpers/dateTime.helper"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { CheckCircle, CloseRounded } from "@mui/icons-material"

type ServicesListProps = {
  booking: TABooking
}
export const ServicesList: FC<ServicesListProps> = ({ booking }) => {
  const { t } = useTranslation("technicalArea");

  return <Box className="pl-3 mb-4">
    <div className="pb-2 flex items-center gap-3">
      <div className="rounded-full w-[10px] min-h-[10px] max-h-[10px] bg-black "></div>
      {t('arrival')} <span className="font-bold">
        {dayjs(booking.start_date).format(t("dates.full", { ns: "common" }))}
        {
          booking.start_time && <>
            &nbsp;{t("dates.at", { ns: "common" })}&nbsp;
            {booking.start_time && getDateObjectFromTime(booking.start_time).format(t("dates.hour", { ns: "common" }))}
          </>
        }

      </span>
    </div>
    <div className="flex items-center gap-3">
      <div className="relative pl-5
        before:block before:bg-black before:w-[2px] before:absolute 
        before:-top-4  before:-bottom-4  before:left-[4px] 
      ">
        <div className="font-bold">{t("services")}</div>
        <ul
        >
          {
            booking.booking_services && booking?.booking_services.map(s => <li key={s.id} className="flex items-center gap-2">
              {s.name}
              {
                [TA_BOOKING_STATUS.ONGOING, TA_BOOKING_STATUS.ON_HOLD, TA_BOOKING_STATUS.BOAT_IN_AREA, TA_BOOKING_STATUS.DONE].includes(booking.status) &&
                s.is_done &&
                <CheckCircle color="secondary" />
              }
              {
                [TA_BOOKING_STATUS.BOAT_IN_AREA, TA_BOOKING_STATUS.DONE].includes(booking.status) && !s.is_done &&
                <CloseRounded color="error" />
              }
            </li>)
          }
        </ul>
      </div >
    </div>
    <div className="pt-2 flex items-center gap-3">
      <div className="rounded-full w-[10px] min-h-[10px] max-h-[10px] bg-black "></div>

      {t('leaving')} <span className="font-bold">
        {dayjs(booking.end_date).format(t("dates.full", { ns: "common" }))}
        {
          booking.end_time && <> &nbsp;{t("dates.at", { ns: "common" })}&nbsp;
            {booking.end_time && getDateObjectFromTime(booking.end_time).format(t("dates.hour", { ns: "common" }))}
          </>
        }


      </span>
    </div>
  </Box>
}