import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";

// TODO move to FDK
export type Layout = AccordionLayout | AreaLayout | TableLayout | ImagesTableLayout | ImageLayout

export type LayoutButton = {
  label: string;
  action: {
    type: "link" | "modal";
    data: any
  }
}
export enum LAYOUT_NAME {
  IMAGE = "image",
  IMAGE_TABLE = "image-table",
  ACCORDION = "accordion",
  AREA = "area",
  TABLE = "label-value-table",
  LABEL_VALUE = "label-value"
}

export interface BaseLayout {
  id: string | number
  layout: LAYOUT_NAME;
  title: string;
}

export interface TableLayout extends BaseLayout {
  layout: LAYOUT_NAME.TABLE,
  table?: { label: string; value: string, layout: LAYOUT_NAME.LABEL_VALUE }[]
}

export interface AccordionLayout extends BaseLayout {
  layout: LAYOUT_NAME.ACCORDION,
  text_more: string,
  text_preview: string
}

export interface ImageLayout extends BaseLayout {
  id: number
  layout: LAYOUT_NAME.IMAGE,
  image: string,
  title: string,
  link?: string,
}

export interface ImagesTableLayout extends BaseLayout {
  layout: LAYOUT_NAME.IMAGE_TABLE,
  variant?: 'gallery' | 'link'
  table?: ImageLayout[]
}

export interface AreaContact {
  label: string,
  email: string,
  phone: string,
  tab_name: string
}

export interface AreaLayout extends BaseLayout {
  layout: LAYOUT_NAME.AREA,
  area: Area
  button?: LayoutButton | null | undefined;
}

export enum AREA_DATE_ERROR {
  CRITICAL_NO_DATE_RANGE = "CRITICAL_NO_DATE_RANGE",
  WARNING_NO_FUTURE_DATE_RANGE = "WARNING_NO_FUTURE_DATE_RANGE",
  RANGE_DISCONTINUITY = "RANGE_DISCONTINUITY"
}
export type AreaOpeningDateWarning = {
  error: AREA_DATE_ERROR
  start: string
  end: string
}
export interface Area {
  id: UUID
  contacts: AreaContact[]
  email: string
  name: string
  phone: string
  vhf_channel: string
  opening_dates: { dates: OpeningDate[] }// TODO
  current_schedule: Schedule
  opening_dates_warning?: AreaOpeningDateWarning
}



export type Schedule = {
  [key: number]: ScheduleDay[] //key from 1 to 7
}
export type ScheduleDay = {
  open: string // format "00:00"
  close: string // format "00:00"
}
export interface OpeningDate {
  start_date: string;
  end_date: string;
  schedule: Schedule;
}
