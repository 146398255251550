import { Button } from "@mui/material";
import { AxiosResponse } from "axios";
import { FC, MouseEvent } from "react";
import { AlertType, useAppContext } from "../../contexts/AppContext";
import { useTranslation } from "react-i18next";

interface DlFileButtonProps {
  label?: string
  disabled?: boolean;
  children?: React.ReactNode;
  request: () => Promise<AxiosResponse<any, any>>;
}
/**
 * ⚠️ **IMPORTANT** : request must have `responseType: 'blob'`
 * @param props 
 * @returns ReactElement
 */
export const DlFileButton: FC<DlFileButtonProps> = (
  props: DlFileButtonProps
) => {
  const { disabled = false, children, request } = props;
  const { t } = useTranslation()
  const { setAlertMessage } = useAppContext()

  const download = (res: AxiosResponse<any, any>) => {
    const contentDisposition = res.headers["content-disposition"];
    //.get("content-disposition");
    const excelFile = res.data;
    console.log("file", res);

    const url = window.URL.createObjectURL(excelFile);
    const a = document.createElement("a");
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    let filename = `export.xlsx`;
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  };
  return (
    <Button
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        request().then((res) => download(res), err => {
          setAlertMessage!({ type: AlertType.ERROR, message: t('form.error', { ns: 'common' }) })
        });
      }}
      disabled={disabled}
      variant="contained"
      color="secondary"
    >
      {children}
    </Button>
  );
};
