import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../core/store/hooks";
import { getFirstElement } from "../../../../../_helpers/array.helpers";
import { getFilteredBerthList } from "../../../../../core/store/selectors/Module.selector";
import { useBerthService } from "../../../../../core/services/Berth.service";
import { GoToTicketBtn } from "../../../elements/dashboard/GoToTicketBtn.element";
import { DlFileButton } from "../../../UX/DlFile.button";
import { changeSort, updateFilter, updateFilterWithoutSaving } from "../../../../../core/store/reducers/Berth.reducer";
import { Box, Button, CircularProgress } from "@mui/material";
import { ModuleTable } from "../Table";
import { TableTop } from "../TableTop";
import { useNavigate, useParams } from "react-router-dom";
import { AlertType, useAppContext } from "../../../../contexts/AppContext";
import { createTicket } from "../../../../../core/store/actions/Ticket.actions";
import { usePeriodic } from "../../../../../core/services/Periodic.service";
import { IMarinaBerth } from "@wattsonelements/front-fdk/dist/models/Berth.models";
import FDK from "@wattsonelements/front-fdk";

export const BerthTable: FC = () => {
  const { t } = useTranslation("berth");
  const navigate = useNavigate();
  const datas = useAppSelector(getFilteredBerthList);
  const dispatch = useAppDispatch();
  const port = useAppSelector((state) => state.ports.current);
  const { getBerthIconName } = useBerthService();
  const [creatingTicket, setCreatingTicket] = useState(false);
  const listLoading = useAppSelector((state) => state.berth.firstLoading);
  const berthSelected = useAppSelector((state) => state.berth.selected);
  const order = useAppSelector((state) => state.berth.sort?.order);
  const orderBy = useAppSelector((state) => state.berth.sort?.orderBy);
  const { restartPeriodic } = usePeriodic();
  const berthCateg = useAppSelector((state) => state.tickets.categories).find(
    (cat: any) => cat.slug === "berth"
  );
  const mooringToConfirmCateg = useAppSelector((state) => state.tickets.categories).find(
    (cat: any) => cat.slug === "mooring-to-confirm"
  );
  const tableRef = useRef<any>();
  const { setAlertMessage } = useAppContext();

  const [selected, setSelected] = useState<IMarinaBerth[]>([]);

  useEffect(() => {
    return () => {
      dispatch(updateFilterWithoutSaving({ text: "" }));
    }
  }, [])

  /**
   * used to determine button activation
   * @returns boolean
   */
  // const canCreateTicket = () => {
  //   const result = selected.every(
  //     (berth) =>
  //       berth.status === BERTH_STATUS.MOORING_TO_CONFIRM &&
  //       berth.tickets.length === 0
  //   );
  //   return result && selected.length > 0;
  // };
  const canCreateTicket = () => {
    // let tmp = selected.filter(sel => sel.tickets.length === 0 || sel.tickets.find((tic: any) => tic.status === TicketStatus.CLOSED))
    return selected.length > 0;
    let tmp = selected.filter(sel => sel.tickets.length === 0 || sel.tickets.find((tic: any) => tic.status === FDK.Constants.TicketConstants.TicketStatus.CLOSED))
    return tmp.length > 0;

    // const result = selected.every(
    //   (plug) =>
    //     !plug.ticket &&
    //     (plug.global_consumption.high_consumption_12_hours ||
    //       plug.global_consumption.high_consumption_now)
    // );
    // return result && selected.length > 0;
  };

  const createTickets = async (e: any) => {
    setCreatingTicket(true);
    let errors = 0;
    for (let i in selected) {
      try {
        const defaultValues = {
          category: selected[i].status === FDK.Constants.BerthConstants.BERTH_STATUS.MOORING_TO_CONFIRM ? mooringToConfirmCateg!.id : berthCateg!.id,
          spot_id: selected[i].id,
          plug: undefined,
          boat: undefined,
          priority: FDK.Constants.TicketConstants.TicketPriority.NORMAL,
          description: "",
        }
        let res = await dispatch(createTicket(defaultValues));
        if (res.type === "ticket/create/rejected") {
          errors++;
          setAlertMessage!({
            type: AlertType.ERROR,
            message: t("ticketCreationError", { ns: "ticket" }),
          });
        }
      } catch (e) {
        errors++;
        setAlertMessage!({
          type: AlertType.ERROR,
          message: t("ticketCreationError", { ns: "ticket" }),
        });

      }
    }
    if (selected.length > errors) {
      restartPeriodic("berth-list");
      if (tableRef.current)
        tableRef.current.resetSelected();
      setAlertMessage!({
        type: AlertType.SUCCESS,
        message: t("successfullyCreated", { ns: "ticket" }),
      });
    }
    setCreatingTicket(false);
  }

  const sortHandler = (key: string) => {
    let tmpOrder;
    if (order === "asc")
      tmpOrder = "desc";
    else
      tmpOrder = "asc";
    // if (order === undefined) {
    //   tmpOrder = "asc";
    // } else if (order === "asc") {
    //   tmpOrder = "desc";
    // } else {
    //   tmpOrder = undefined;
    // }
    dispatch(changeSort({ order: tmpOrder, orderBy: key }));
  };

  const headers = [
    {
      label: t("table.headers.berth"),
      key: "name",
      format: (data: IMarinaBerth) => {
        return data.name;
      },
      sort: true,
      compareValue: (data: IMarinaBerth) => data.name,
    },
    {
      label: t("table.headers.boat"),
      key: "boat_expected_name",
      format: (data: IMarinaBerth) => {
        return data.boat_expected?.name || "- -";
      },
      sort: true,
      compareValue: (data: IMarinaBerth) => data.boat_expected?.name,
    },
    {
      label: t("table.headers.status"),
      key: "status",
      format: (data: IMarinaBerth) => {
        return (
          <img
            alt={data.status}
            className={"mx-auto " + (getBerthIconName(data).match("buoy") !== null ? "h-7 w-7" : "h-10 rotate-90")}
            src={require(`../../../../../assets/img/icons/berth/map/v2/${getBerthIconName(
              data
            )}.svg`)}
          />
        );
      },
      sort: true,
      compareValue: (data: IMarinaBerth) => data.status,
    },
    {
      label: t("table.headers.contract"),
      key: "contracts",
      format: (data: IMarinaBerth) => {
        return data.contract_name
          ? data.contract_name
          : "- -"; // TODO TRANSLATION
      },
      compareValue: (data: IMarinaBerth) =>
        data.contract_name ? data.contract_name : "- -",
      sort: true,
    },
    {
      label: t("table.headers.tickets"),
      key: "tickets",
      format: (data: IMarinaBerth) => {
        const ticket = getFirstElement(data.tickets) || "";
        return ticket ? <GoToTicketBtn nbTickets={data.tickets.length} ticketId={ticket.id} /> : "";
      },
      sort: true,
      compareValue: (data: IMarinaBerth) => data.tickets.length,
    },
  ];

  return (
    <>
      <TableTop
        datas={datas}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          event.preventDefault();
          dispatch(updateFilterWithoutSaving({ text: event.target.value }));
        }}
        buttons={
          <>
            {creatingTicket ?
              <CircularProgress color={"info"} />
              :
              <Button
                disabled={!canCreateTicket()}
                variant="contained"
                color="secondary"
                onClick={createTickets}
              >
                {selected.filter(sel => sel.tickets.length === 0 || sel.tickets.find((tic: any) => tic.status === FDK.Constants.TicketConstants.TicketStatus.CLOSED)).length > 1 ? t("table.createMultiple") : t("table.create")}
                {/* {selected.length > 0 && <Box className=" ml-2 flex px-1 rounded-full justify-center items-center text-xs bg-white text-fcosecondary">{selected.length}</Box>} */}
              </Button>
            }
            <DlFileButton
              request={() =>
                FDK.Marina.BerthModule.getExport(selected.map((s) => s.id)) as any
              }
              disabled={selected.length === 0}
            >
              {t("table.export")} {selected.length > 0 && <Box className=" ml-2 flex px-1 rounded-full justify-center items-center text-xs bg-white text-fcosecondary">{selected.length}</Box>}
            </DlFileButton>
          </>
        }
      />
      <Box className="cursor-pointer">
        <ModuleTable
          ref={tableRef}
          onClickRow={(event: React.MouseEvent, berth: IMarinaBerth) => {
            // navigate to page detail
            if (port && port.areas.length > 1)
              navigate(`/admin/area/${berth.port_area}/modules/berths/${berth.id}`);
            else
              navigate(`/admin/area/all/modules/berths/${berth.id}`);
          }}
          selectedKey={berthSelected! ? berthSelected.id : undefined}
          onChangeSelection={setSelected}
          datas={datas}
          order={order}
          orderBy={orderBy}
          onSort={sortHandler}
          headers={headers}
          isLoading={listLoading}
        />
      </Box>
    </>
  );
};
