
/**
 * dispatch don't return an error if rejected, but a component might need it
 * @param dispatch redux dispatch
 * @returns 
 */
export const dispatchToPromise = async (dispatch: Promise<any>) => {
  return new Promise((resolve, reject) => {
    dispatch.then((e) => e.type.includes('rejected') ? reject() : resolve(e.payload), (err) => reject(err))
  })
}