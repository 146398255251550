import { FC, useState } from "react"
import { Amendment, TABooking, UPDATABLE_FIELD } from "../../../../../../core/models/technicalArea.models"
import { useTranslation } from "react-i18next"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import dayjs from "dayjs"
import { getDateObjectFromTime } from "../../../../../../_helpers/dateTime.helper"
import { ReadOnlyField } from "../../../../UX/inputs/ReadOnlyField"
import { EndDateTime } from "./EndDatetime"
import { ServicesSelect } from "./Services.select"
import { Box, Button, CircularProgress } from "@mui/material"
import { updateTABookingDetail } from "../../../../../../core/store/actions/TechnicalArea.actions"
import { useAppDispatch } from "../../../../../../core/store/hooks"
import { CGUField } from "./CGU.field"
import { PrintSignatureBox } from "./SignatureBox.print"
import { updateItemInList, updateSelectedBooking } from "../../../../../../core/store/reducers/TechnicalArea.reducer"
import { TADuration } from "./Duration"
import { StartDateTime } from "./StartDatetime"

type AmendmentFormEditProps = {
  amendment: Amendment,
  booking: TABooking,
  onClose?: () => any
}
export const AmendmentFormEdit: FC<AmendmentFormEditProps> = ({ amendment, booking, onClose = () => { } }) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation("technicalArea")
  const [isSending, setIsSending] = useState(false)

  const form = useForm({
    defaultValues: {
      start_date: booking.start_date,
      start_time: booking.start_time ? getDateObjectFromTime(booking.start_time) : null,
      end_date: dayjs(amendment.end_date),
      end_time: getDateObjectFromTime(amendment.end_time),
      services: [...booking.services, ...amendment.services_to_add]
        .filter(s => !amendment.services_to_remove.map(sr => sr.id).includes(s.id)),
      update_request_validated: false,
      update_request_terms_and_conditions_upload: null
    },
  })
  const { handleSubmit, watch, formState: { isValid } } = form
  const end_date = watch("end_date")
  const end_time = watch("end_time")

  const submit: SubmitHandler<any> = (data) => {
    setIsSending(true)
    const dataToSend = {
      end_date: data.end_date.format("YYYY-MM-DD"),
      end_time: data.end_time.format("HH:mm"),
      services: data.services.map((s: TABooking["services"][0]) => s.id),
      update_request_validated: data.update_request_validated,
      update_request_terms_and_conditions_upload: data.update_request_terms_and_conditions_upload
    }
    if (!dataToSend.update_request_terms_and_conditions_upload) {
      delete dataToSend.update_request_terms_and_conditions_upload
    }
    const getFormData = (object: any) => Object.keys(object).reduce((formData, key: string) => {
      // if (!booking.updatable_fields?.includes(key as UPDATABLE_FIELD)) return formData
      if (Array.isArray(object[key])) {
        for (var i = 0; i < object[key].length; i++) {
          formData.append(`${key}`, object[key][i]);
        }
        return formData;
      }
      formData.append(key, object[key]);
      return formData;
    }, new FormData());
    const formData = getFormData(dataToSend)
    updateTABookingDetail(booking.id, formData).then((res) => {
      dispatch(updateItemInList(res.data))
      dispatch(updateSelectedBooking({
        ...res.data
      }))
      onClose()
    }).finally(() => {
      setIsSending(false)
    })
  }

  return <FormProvider {...form}>
    <form id="technical-area-form-creation"
      onSubmit={handleSubmit(submit)}
      className="grid grid-cols-2 gap-6">
      <ReadOnlyField className="col-start-1  " label={t("form.boater", { ns: "ticket" })} value={booking.boater.first_name + " " + booking.boater.last_name} />
      <ReadOnlyField label={t("form.boat", { ns: "ticket" })} value={booking.boat.name + (booking.boat.registration_number ? (" - " + booking.boat.registration_number) : "")} />
      {/* <ReadOnlyField className="col-start-1" label={t("form.dateStart")} value={dayjs(booking.start_date).format(t('dates.full', { ns: "common" }))} />
      <ReadOnlyField label={t("form.hourStart")} value={getDateObjectFromTime(booking.start_time).format(t('dates.hour', { ns: "common" }))} /> */}
      <Box className="col-span-2 grid grid-cols-3 gap-x-6 gap-y-4">
        <StartDateTime readonly />
        <EndDateTime updatableFields={[UPDATABLE_FIELD.END_DATE, UPDATABLE_FIELD.END_TIME]} />
      </Box>
      <TADuration
        className="col-span-2 text-sm -mt-1 py-3"
        dateEnd={end_date}
        timeEnd={end_time}
        dateStart={booking.start_date}
        timeStart={booking.start_time} />

      <ServicesSelect servicesStates={booking.booking_services} />
      <CGUField
        cguAcceptedFieldName="update_request_validated"
        cguUploadFieldName="update_request_terms_and_conditions_upload"
      />
      <PrintSignatureBox />
      <Button type="submit" disabled={!isValid || isSending} variant="contained" className="col-span-2 print:hidden" color="secondary">
        {!isSending && t('amendments.form.btn')}
        {
          isSending && <div className="flex gap-2 items-center">
            {t('form.actions.saving')}
            <CircularProgress size={20} />
          </div>
        }
      </Button>
    </form>
  </FormProvider>
}