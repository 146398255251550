import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useTranslation } from "react-i18next";
import imageCompression from "browser-image-compression";
import { useDropzone } from 'react-dropzone'
import { UseFormReturn } from 'react-hook-form';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { cropToBlob, loadAsBase64 } from '../../../_helpers/image.helpers';
import ReactCrop, {
  type Crop, centerCrop,
  makeAspectCrop
} from 'react-image-crop'
import classNames from 'classnames';

type ImageUploaderProps = {
  thumb: boolean;
  form?: UseFormReturn | any;
  name: string;
  simpleButton?: boolean;
  multiple?: boolean;
  label?: string | null;
  displayLabel?: boolean;
  addBtn?: string | JSX.Element | null;
  delBtn?: string | null;
  deleteButton?: boolean;
  currentImg?: string | undefined | null;
  onChange?: (files: File[]) => void;
  preview: boolean;
  hasCrop?: boolean;
  cropOptions?: {
    crop: Crop
    aspect?: number | null | undefined
  }
}
export const ImageUploader = forwardRef((props: ImageUploaderProps, ref: any) => {
  const {
    thumb,
    simpleButton = false,
    form = null,
    name,
    multiple = false,
    displayLabel = true,
    label = null,
    addBtn = null,
    delBtn = null,
    deleteButton = false,
    currentImg,
    onChange = null,
    preview,
    hasCrop = false,
    cropOptions = {
      crop: {
        unit: 'px',
        height: 1080 / 2,
        width: 1920 / 2,
        x: 0,
        y: 0
      },
      aspect: 16 / 9
    }
  } = props;
  const { t } = useTranslation("common")
  const [loading, setLoading] = useState(false);
  const [localImgs, setLocalImgs] = useState((currentImg && currentImg !== "") ? [currentImg] : []);
  const [crop, setCrop] = useState<Crop | undefined>(cropOptions?.crop)
  const [imgForCrop, setImgForCrop] = useState(0)
  const [compressed, setCompressed] = useState<File[]>([])
  const notCompressed = useRef<File[]>([])
  const [cropDone, setCropDone] = useState(false)
  const options = { // image compression
    maxSizeMB: 5,
    maxWidthOrHeight: 1500,
    useWebWorker: true,
    initialQuality: 0.8
  };

  const imageCropRef = useRef<HTMLImageElement>()

  useImperativeHandle(ref, () => ({
    reset() {
      handleDelete(null);
      setCropDone(false)
      setCompressed([])
      notCompressed.current = []
      setLocalImgs([])
      setImgForCrop(0)
    },
  }))

  useEffect(() => {
    if (typeof currentImg === "string" && currentImg !== "")
      setLocalImgs([currentImg])
  }, [currentImg])

  const handleDelete = (e: any) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (typeof onChange === "function")
      onChange([]);
    setLocalImgs([]);
    if (hasCrop) {
      setCropDone(false)
      setCompressed([])
      notCompressed.current = []
      setLocalImgs([])
      setImgForCrop(0)
    }
    if (form)
      form.setValue(name, multiple ? [] : "");
    return false;
  }

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    setLoading(true);
    if (hasCrop) {
      setCropDone(false)
      setCompressed([])
      notCompressed.current = []
      setLocalImgs([])
      setImgForCrop(0)
    }


    let compressedFiles: File[] = [];
    let base64Files: string[] = [];
    notCompressed.current = acceptedFiles
    for (let i = 0; i < acceptedFiles.length; i++) {
      try {
        let compressedFile = await imageCompression(acceptedFiles[i], options);
        compressedFiles.push(new File([compressedFile], compressedFile.name))
        base64Files.push(await loadAsBase64(acceptedFiles[i]));
      } catch (error) {
        if (form)
          form.setError(name, { type: 'custom', message: t("form.imageInput.imageError2") });
        console.log(error);
      }
    }
    if (form)
      form.setValue(name, multiple ? compressedFiles : compressedFiles[0]);
    setLocalImgs(base64Files);

    if (typeof onChange === "function")
      onChange(compressedFiles);
    setCompressed(compressedFiles)
    setLoading(false);
  }, [])


  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget
    setCrop(centerAspectCrop(width, height, cropOptions?.aspect || 16 / 9))
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/jpg, image/jpeg, image/png', multiple })
  return (
    <Box >
      <Box
        {...getRootProps({
          className: simpleButton ?
            ""
            :
            'p-3 hover:border-fcosecondaryhover border-dashed border-gray-300 border-2 flex flex-col w-full items-center gap-y-2 justify-center'
            + (isDragActive && " border-fcosecondaryhover")
        })}>
        {!simpleButton && displayLabel && <p className="text-fcomain text-opacity-40">{label ? label : multiple ? t('form.imageInput.labelMultiple') : t('form.imageInput.label')}</p>}
        <Box className="flex items-center justif-center gap-x-2">
          {loading ?
            <CircularProgress size={20} color="secondary" /> :
            addBtn && typeof addBtn !== "string" ?
              <IconButton className="shadow-md flex-auto">
                {addBtn}
              </IconButton>
              :
              <Button
                component="span"
                variant="contained">
                {addBtn ? addBtn : t('form.imageInput.addBtn')}
                <AddPhotoAlternateIcon />
              </Button>
          }
          {deleteButton && localImgs.length > 0 && localImgs[0] !== "" &&
            <Button
              onClick={handleDelete}
              variant="outlined"
              color="error">
              {delBtn ? delBtn : t('form.imageInput.delBtn')}
            </Button>}
        </Box>

        {!simpleButton &&
          <Box className="flex gap-2 flex-wrap">
            {preview && localImgs && localImgs.length > 0 &&
              localImgs.map((img, i) => img != "" && <Box key={"img-" + i}
                // className={thumb || localImgs.length > 1 ? localImgs.length > 1 ? "w-auto max-h-20" : "w-auto max-h-24" : "w-auto max-h-92"}
                className={classNames(
                  {
                    "w-auto max-h-20": hasCrop || thumb || localImgs.length > 1,
                    "w-auto max-h-24": !hasCrop && !thumb && localImgs.length > 1,
                    "w-auto max-h-92": !(thumb || localImgs.length > 1) && !hasCrop
                  }
                )}
                onClick={(e) => {
                  if (hasCrop) {
                    e.stopPropagation()
                    e.preventDefault()
                    setImgForCrop(i)
                  }
                }}
                component="img"
                alt="user picture"
                src={img}
              />)
            }


          </Box>}
        {form ?
          <input
            multiple={multiple}
            className="hidden"
            accept="image/*"
            {...form.register(name, {
            })}
            id={name} type="file" />
          :
          <input
            multiple={multiple}
            className="hidden"
            accept="image/*"
            id={name} type="file" />
        }
        <input
          {...getInputProps()} />
      </Box>
      {
        !cropDone && !simpleButton
        // && compressed && compressed.length > 0
        && crop && hasCrop && preview && localImgs
        && localImgs.length > imgForCrop && localImgs[imgForCrop] &&
        <Box className="my-2">
          <Box className="flex justify-between items-center mb-1">
            <p className='font-bold'>{t("form.imageInput.cropAndResize")}</p>
            <Box>
              {/* <Button color='error'>
                Reset
              </Button> */}
              <Button variant='contained' color='secondary' onClick={() => {
                cropToBlob(crop, imageCropRef.current, async (file: File, base64Image: string) => {
                  const tmp = [...compressed]
                  let compressedFile = await imageCompression(file, options);

                  tmp[imgForCrop] = compressedFile
                  setCompressed(tmp)

                  if (typeof onChange === "function") {
                    onChange(tmp);
                  }

                  if (form) {
                    form.setValue(name, multiple ? tmp : tmp[0]);
                  }
                  setLocalImgs([base64Image])
                  setCropDone(true)
                })

              }}>
                {t('form.imageInput.saveCrop')}
              </Button>
            </Box>
          </Box>
          <Box className="bg-slate-400 text-center">
            <ReactCrop
              minHeight={200}
              minWidth={200}
              onChange={(crop, percentCrop) => {
                setCrop(crop)
              }}
              onComplete={(crop, percentCrop) => {
                setCrop(crop)
              }}
              aspect={cropOptions?.aspect || undefined}
              crop={crop}
              className='rounded '
            >
              <img ref={imageCropRef as any} className='rounded' onLoad={onImageLoad} src={localImgs[imgForCrop]} alt="news cover to crop" />
            </ReactCrop>

          </Box>
          {/* <div id="test-canvas"></div> */}
          {/* {JSON.stringify(crop)} */}
        </Box>
      }
    </Box>
  )
})


function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: 'px',
        width: 500,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}