import { Box, CircularProgress } from "@mui/material";
import { createContext, FC, useContext, useState, useEffect } from "react";
import useVersion from "../../_helpers/useVersion.helpers";
import { Narwhal } from "../components/elements/debug/Narwhal.element";
import { NarwhalModal } from "../components/elements/debug/Narwhal.modal";


interface IQAContext {
  APP_VERSION: string;
  APP_ENV: string;
  API_URL: string;
  QAModalOpened: boolean;
  toggleQAModal?: () => void;
  setAPIURL?: (val: string) => void;
  setAPPENV?: (val: string) => void;
  setAPPVERSION?: (val: string) => void;
}

const defaultState = {
  APP_VERSION: "",
  APP_ENV: process.env.REACT_APP_ENV || "",
  API_URL: process.env.REACT_APP_API_URL || "",
  QAModalOpened: false
};

export const QAContext = createContext<IQAContext>(defaultState);

export const QAProvider: FC = ({ children }) => {
  const { checkVersion } = useVersion();
  const [APP_VERSION, setAPP_VERSION] = useState(defaultState.APP_VERSION);
  const [APP_ENV, setAPP_ENV] = useState(defaultState.APP_ENV);
  const [API_URL, setAPI_URL] = useState(defaultState.API_URL);
  const [QAModalOpened, setQAModalOpened] = useState(defaultState.QAModalOpened);

  const toggleQAModal = () => {
    setQAModalOpened(!QAModalOpened);
  };

  const setAPIURL = (val: string) => {
    setAPI_URL(val);
  };
  const setAPPENV = (val: string) => {
    setAPP_ENV(val);
  };

  // VERSION
  const setAPPVERSION = (val: string) => {
    setAPP_VERSION("MA." + val);
  };
  const getVersion = async () => {
    setAPPVERSION(await checkVersion());
  }
  useEffect(() => {
    getVersion();
  }, [])
  // EN VERSION STUFF

  return (
    <QAContext.Provider
      value={{
        APP_VERSION,
        APP_ENV,
        API_URL,
        QAModalOpened,
        toggleQAModal,
        setAPIURL,
        setAPPENV,
        setAPPVERSION
      }}
    >
      {APP_VERSION === "" ?
        <Box className="absolute w-full h-full flex items-center justify-center">
          <CircularProgress />
        </Box>
        : children}
    </QAContext.Provider>
  );
};
export const useQAContext = () => useContext(QAContext);
