import {
  configureStore,
  combineReducers
} from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import Auth from "./reducers/Auth.reducer";
import Berth from "./reducers/Berth.reducer";
import Port from "./reducers/Port.reducer";
import Users from "./reducers/User.reducer";
import Electricity from './reducers/Electricity.reducer';
import Vigie from "./reducers/Vigie.reducer";
import Tickets from "./reducers/Ticket.reducer";
import News from "./reducers/News.reducer";
import Attachments from "./reducers/Attachments.reducer";
import Crane from "./reducers/Crane.reducer";
import { ticketApi } from "../API/Ticket";
import TechnicalArea from "./reducers/TechnicalArea.reducer";

const reducers = combineReducers({
  tickets: Tickets,
  users: Users,
  berth: Berth,
  electricity: Electricity,
  vigie: Vigie,
  // categories: Categories,
  auth: Auth,
  ports: Port,
  news: News,
  attachments: Attachments,
  crane: Crane,
  technicalArea: TechnicalArea,
  [ticketApi.reducerPath]: ticketApi.reducer,
  // config: Config
});
const loggerMiddleware = createLogger();

export const store = configureStore(
  {
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
      // removing redux logger in production and when testing with jest (to prevent spam in console)
      // process.env.REACT_APP_ENV !== "PRODUCTION" && process.env.TS_JEST !== "1" ?
      //   getDefaultMiddleware().concat(loggerMiddleware)
      //   : getDefaultMiddleware()
      getDefaultMiddleware().concat(ticketApi.middleware as any)
  }
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
