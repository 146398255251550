import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"

export const PrintSignatureBox = () => {

  const { t } = useTranslation("technicalArea")

  return (<Box className="hidden print:block col-span-2">
    <h3 className="font-bold text-lg">{t("form.sign")}</h3>
    <Box className="border-dashed border-gray-300 border-2 h-16 w-full">
    </Box>
  </Box>)
}