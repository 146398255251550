import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useAppSelector } from "../../../../core/store/hooks";
import { setDefaultResultOrder } from "dns/promises";
import { Box } from "@mui/material";
import FDK from "@wattsonelements/front-fdk";
import axios from "axios";
import { client_lang } from "../../../../translations/initI18n";

enum REPORTING {
  berths = "berth",
  tickets = "ticket",
  electricity = "electricity",
  "waiting-requests" = "waiting-requests",
  "handling-task" = "handling-task",
  "contract-renewal" = "contract-renewal"
}

enum METABASE_LANG_CODE {
  'fr' = 'fr-fr',
  'en' = 'en-en'
}

export const ReportingPage = () => {
  const { slug } = useParams<{ slug: string }>();
  const [iFrameURL, setIFrameURL] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!!slug && !!REPORTING[slug as keyof typeof REPORTING]) {
      setLoading(true);
      FDK.Marina.CommonModule.getReporting(
        REPORTING[slug as keyof typeof REPORTING]
      ).then(
        async ({ data }) => {
          const lang = METABASE_LANG_CODE[client_lang]
          setIFrameURL(
            `https://metabase.falco.app/embed/dashboard/${data}?locale=${lang}#bordered=true&titled=true`
          );
        },
        (err) => {
          setLoading(false);
        }
      );
    }
  }, [slug]);

  const onLoadCB = () => {
    setLoading(false);
  };

  return (
    <Box className="w-full h-full relative">
      <iframe
        onLoad={onLoadCB}
        title="reporting iframe"
        src={iFrameURL}
        className="w-full h-full bg-white"
        width="100%"
        height="100%"
      // allowtransparency
      ></iframe>
      {loading && (
        <Box className="absolute bg-white top-0 left-0 flex justify-center items-center w-full h-full">
          <CircularProgress size={50} color="secondary" />
        </Box>
      )}
    </Box>
  );
};
