import { Box, Button, CircularProgress } from "@mui/material";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";
import mapboxgl, { GeoJSONSource, MapboxGeoJSONFeature } from "mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Geojson } from "../../../core/models/map.models";
import { useAppSelector } from "../../../core/store/hooks";
import { getCurrentMapCenter } from "../../../core/store/selectors/Port.selector";
import { getMapIcons, MapType } from "../../../_helpers/map.helper";
import { usePrevious } from "../../../_helpers/usePrevious.helpers";
import { AreasMapButtons } from "./dashboard/AreasMapButtons.element";

// TODO put in env
mapboxgl.accessToken =
  "pk.eyJ1IjoiZmFsY28tYWRtaW4iLCJhIjoiY2p4MzJjczZoMDAzZzQzbGJxamwwZnV2biJ9.WkebB_L3cK2sruq7Hih-Yw";
//ID_MAP: 'ck553uje100561cplwwm7pe0k',

/**
 * Marker must have :
 *
 * List of markers working the same
 * Popup capabilities per marker (data will vary)
 * Change icon on selection
 * Icon rotation
 * Refresh marker list after data update
 * Marker onclick action
 * Zoom size change
 */

export interface MarkerDatas {
  id: UUID;
  longitude: number;
  latitude: number;
  icon: {
    icon: any;
    selectedIcon: any;
    rotation: number;
    anchor: { x: number; y: number };
    width: number;
  };
}

interface MapProps {
  loading?: boolean;
  geojson?: Geojson;
  mapType: MapType;
  mapId?: string;
  zoom?: {
    default: number,
    min: number,
    max: number
  },
  displayAreasButtons?: boolean;
  selectedMarkerId?: UUID | null;
  multiMarkerSelection?: UUID[]
  clickOnMarker?: (id: UUID) => void;
}

export const MapBoxLayer = forwardRef((props: MapProps, ref) => {
  const { areaId } = useParams()
  const port = useAppSelector((state) => state.ports.current);
  const center = useAppSelector(getCurrentMapCenter(areaId));
  const zoomTo = (typeof areaId !== 'undefined' && areaId !== "" && areaId !== "all")
    ? 17 : 15;
  const { displayAreasButtons } = props;
  const {
    loading = false,
    geojson,
    selectedMarkerId,
    multiMarkerSelection = [],
    zoom = { default: 17, min: 17, max: 19 },
    clickOnMarker = (id: string, type?: string) => { },
  } = props;
  const cbRef = useRef(clickOnMarker);
  const mapContainer = useRef<any>(null);
  const map = useRef<any>(null);
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  const [mapInit, setMapInit] = useState<boolean>(false);
  const [sourceInit, setSourceInit] = useState<boolean>(false);
  const [statusImg, setStatusImg] = useState<any[]>([]);
  const currentGeoJson = useRef<any>(null);
  const currentFitMap = useRef("");

  const previousSelectedMarkerId = usePrevious<UUID | null | undefined>(
    selectedMarkerId
  );
  // console.log("selectedMarkerId", selectedMarkerId);

  const fitTheMap = (gjson: Geojson) => {
    if (currentFitMap.current === 'global') return;
    currentFitMap.current = 'global';

    if (!gjson) return;
    let bounds = new mapboxgl.LngLatBounds();
    gjson.features.forEach(function (feature) {
      bounds.extend(feature.geometry.coordinates as [number, number]);
    });
    try {
      // if array bounds missing one coordinate, we catch errors
      if (gjson.features.length > 0)
        map!.current.fitBounds(bounds, { padding: 100 });
      else
        map!.current.flyTo({
          center: center as [number, number],
          zoom: zoomTo
        });
    } catch (e) { }
  }
  const flyToSelectedMarker = async (id: string) => {

    // an ID of a ticket could be the same of a berth id, but very improbable :D
    if (currentFitMap.current === id) return;
    currentFitMap.current = id;

    let markerSelected = geojson?.features.find(
      (marker) => marker.id === id
    );
    if (!markerSelected) {
      return;
    }
    map!.current.flyTo({
      center: markerSelected.geometry.coordinates,
      zoom: zoom.max,
    });
  };

  const getImgStatus = async () => {
    let imgs = await getMapIcons(props.mapType, port!);
    setStatusImg(imgs);
  };

  useImperativeHandle(ref, () => ({
    fitTheMap() {
      fitTheMap(currentGeoJson.current)
    },
    flyToSelectedMarker(id: string) {
      flyToSelectedMarker(id)
    }
  }));

  useEffect(() => {
    cbRef.current = clickOnMarker;
  }, [clickOnMarker])

  useEffect(() => {
    const currentMap = map.current;
    return () => {
      currentMap && currentMap.remove();
    };
  }, []);

  useEffect(() => {
    console.log("geojson", geojson);

    if (
      currentGeoJson.current &&
      JSON.stringify(currentGeoJson.current) !== JSON.stringify(geojson) &&
      // currentGeoJson.current !== geojson &&
      geojson
    ) {
      map!.current.getSource("markers").setData(geojson);
      currentGeoJson.current = geojson;
      currentFitMap.current = "new"
      fitTheMap(currentGeoJson.current);
    }

  }, [geojson]);

  // effect to download status images (mooring, boats, buoy)
  useEffect(() => {
    if (!port) return
    if (statusImg.length < 1) {
      getImgStatus();
    }
  }, [port]);

  // effect to init the map if port is connected
  useEffect(() => {
    if (map.current || statusImg.length < 1) return; // initialize map only once
    if (!!port) {
      map!.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/falco-admin/ck553uje100561cplwwm7pe0k",
        center: center as [number, number],
        zoom: zoomTo,
        // NO MIN ZOOM because of a bug with the flyTo method
        // minZoom: zoom.min,
        maxZoom: zoom.max,
        // zoom: 17,
        attributionControl: false,
        antialias: true,
      });
      map!.current.on("load", (MAP: any) => {
        setMapLoaded(true);
      });
    }
  }, [port, map.current, statusImg]);

  // effect to select the marker selected
  useEffect(() => {
    // olt way to fly
    // const flyToSelectedMarker = async () => {
    //   let markerSelected = geojson?.features.find(
    //     (marker) => marker.id === selectedMarkerId
    //   );
    //   if (previousSelectedMarkerId === selectedMarkerId) {
    //     return;
    //   } // avoid yoyo with zoom, especially with periodics
    //   // console.log(
    //   //   "ZUT",
    //   //   geojson?.features,
    //   //   selectedMarkerId,
    //   //   markerSelected,
    //   //   previousSelectedMarkerId === selectedMarkerId
    //   // );

    //   if (!markerSelected) {
    //     return;
    //   }
    //   map!.current.flyTo({
    //     center: markerSelected.geometry.coordinates,
    //     zoom: zoom.max
    //   });
    // };


    if ((selectedMarkerId !== null || multiMarkerSelection.length > 0) && mapInit && sourceInit && geojson) {
      // old way to fly
      // flyToSelectedMarker();
      let matchQuery: any[] = []
      if (multiMarkerSelection && multiMarkerSelection.length > 0) {
        multiMarkerSelection.map(markerID => {
          matchQuery = [...matchQuery,
          ["get", "id"],
            markerID,
          ["get", "selectedIcon"], //image when id is the clicked feature id
          ["get", "icon"], // default
          ]
        })
      } else {
        matchQuery = [
          ["get", "id"],
          selectedMarkerId,
          ["get", "selectedIcon"], //image when id is the clicked feature id
          ["get", "icon"], // default
        ]
      }

      setTimeout(function () {
        map!.current.setLayoutProperty("markers", "icon-image", [
          "match",
          ...matchQuery
        ]);
      }, 200);
    }
    if (selectedMarkerId === null && multiMarkerSelection.length === 0 && mapInit && sourceInit && geojson) {
      setTimeout(function () {
        map!.current.setLayoutProperty("markers", "icon-image", [
          "match",
          ["get", "selectedIcon"],
          ["get", "icon-image"], // compare selectedIcon to icon-image and reset if same
          ["get", "icon"], //image when id is the clicked feature id
          ["get", "icon"], // default
        ]);
      }, 200);
    }
  }, [selectedMarkerId, mapInit, sourceInit, geojson, multiMarkerSelection]);

  // effect to setup the layer and the source of the layer
  useEffect(() => {
    if (mapLoaded && !mapInit && !sourceInit) {
      currentGeoJson.current = geojson;
      (Object.keys(statusImg) as Array<keyof typeof statusImg>).map((key) => {
        map!.current.addImage(key, statusImg[key]);
      });
      map!.current.addSource("markers", {
        generateId: true,
        type: "geojson",
        data: geojson,
      });
      map!.current.on("sourcedata", (e: any) => {
        if (
          e.dataType === "source" &&
          e.sourceId === "markers" &&
          e.isSourceLoaded === true
        ) {
          setSourceInit(true);
        }
      });
      map!.current.addLayer({
        id: "markers",
        type: "symbol",
        source: "markers",
        layout: {
          "icon-image": { type: "identity", property: "icon" }, // reference the image
          "icon-rotate": { type: "identity", property: "rotation" },
          "icon-rotation-alignment": "map",
          "icon-allow-overlap": true,
          // "text-allow-overlap": true,
          "symbol-z-order": "source",
          "symbol-sort-key": ["to-number", ["get", "priority"]],
          "icon-size": [
            "interpolate",
            ["exponential", 1],
            ["zoom"],
            10,
            0.005,
            14,
            0.01,
            16,
            0.02,
            17,
            0.05,
            17.5,
            0.06,
            18,
            0.1,
            19,
            0.15,
            22,
            0.25,
          ],
        },
      });

      map!.current.on("mouseenter", "markers", () => {
        map!.current.getCanvas().style.cursor = "pointer";
      });
      map!.current.on("mouseleave", "markers", () => {
        map!.current.getCanvas().style.cursor = "";
      });

      map!.current.on("click", "markers", (e: any) => {
        // Copy coordinates array.
        // const coordinates = e.features[0].geometry.coordinates.slice();
        // const description = e.features[0].properties.rotation;
        // while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        //   coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        // }
        if (e.features[0].properties.clickable) {
          if (e.features[0].properties?.group) {
            cbRef.current(e.features[0].properties.id, e.features[0].properties?.group);
          } else {
            cbRef.current(e.features[0].properties.id);
          }

          // clickOnMarker(e.features[0].properties.id);
        }

        // new mapboxgl.Popup()
        //   .setLngLat(coordinates)
        //   .setHTML(description)
        //   .addTo(map!.current);
      });
      setMapInit(true);
    }
  }, [mapLoaded, mapInit, sourceInit]);

  return (
    <div className="flex flex-col flex-1 w-full flex-grow justify-center items-center">
      <div className="relative flex flex-1 w-full flex-grow justify-center items-center">

        <div ref={mapContainer} className="map-container w-full h-full" />
        {(typeof displayAreasButtons === "undefined" || displayAreasButtons === true) && <AreasMapButtons />}
        {(loading || !mapInit) && (
          <Box
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            className="pointer-events-none bg-fcomain bg-opacity-40 absolute top-0 left-0 w-full h-full z-10 flex items-center justify-center"
            component="div"
          >
            <CircularProgress />
          </Box>
        )}
      </div>
    </div>
  );
});
// const modelOrigin: any = [port!.center_longitude, port!.center_latitude];
//       const modelAltitude = 0;
//       const modelRotate = [Math.PI / 2, 0, 0];

//       const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
//         modelOrigin,
//         modelAltitude
//       );
//       const modelTransform = {
//         translateX: modelAsMercatorCoordinate.x,
//         translateY: modelAsMercatorCoordinate.y,
//         translateZ: modelAsMercatorCoordinate.z,
//         rotateX: modelRotate[0],
//         rotateY: modelRotate[1],
//         rotateZ: modelRotate[2],
//         /* Since the 3D model is in real world meters, a scale transform needs to be
//         * applied since the CustomLayerInterface expects units in MercatorCoordinates.
//         */
//         // scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits()
//         scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits() * 50
//       };
//       const customLayer = {
//         id: '3d-model',
//         type: 'custom',
//         renderingMode: '3d',
//         onAdd: function (map: any, gl: any) {
//           this.camera = new THREE.Camera();
//           this.scene = new THREE.Scene();

//           // create two three.js lights to illuminate the model
//           const directionalLight = new THREE.DirectionalLight(0xffffff);
//           directionalLight.position.set(0, -70, 100).normalize();
//           this.scene.add(directionalLight);

//           const directionalLight2 = new THREE.DirectionalLight(0xffffff);
//           directionalLight2.position.set(0, 70, 100).normalize();
//           this.scene.add(directionalLight2);

//           // use the three.js GLTF loader to add the 3D model to the three.js scene
//           const loader = new THREE.GLTFLoader();
//           loader.load(
//             // 'https://docs.mapbox.com/mapbox-gl-js/assets/34M_17/34M_17.gltf',
//             'http://localhost:3000/boat.glb',
//             (gltf: any) => {
//               this.scene.add(gltf.scene);
//             }
//           );
//           this.map = map;

//           // use the Mapbox GL JS map canvas for three.js
//           this.renderer = new THREE.WebGLRenderer({
//             canvas: map.getCanvas(),
//             context: gl,
//             antialias: true
//           });

//           this.renderer.autoClear = false;
//         },
//         render: function (gl, matrix) {
//           const rotationX = new THREE.Matrix4().makeRotationAxis(
//             new THREE.Vector3(1, 0, 0),
//             modelTransform.rotateX
//           );
//           const rotationY = new THREE.Matrix4().makeRotationAxis(
//             new THREE.Vector3(0, 1, 0),
//             modelTransform.rotateY
//           );
//           const rotationZ = new THREE.Matrix4().makeRotationAxis(
//             new THREE.Vector3(0, 0, 1),
//             modelTransform.rotateZ
//           );

//           const m = new THREE.Matrix4().fromArray(matrix);
//           const l = new THREE.Matrix4()
//             .makeTranslation(
//               modelTransform.translateX,
//               modelTransform.translateY,
//               modelTransform.translateZ
//             )
//             .scale(
//               new THREE.Vector3(
//                 modelTransform.scale,
//                 -modelTransform.scale,
//                 modelTransform.scale
//               )
//             )
//             .multiply(rotationX)
//             .multiply(rotationY)
//             .multiply(rotationZ);

//           this.camera.projectionMatrix = m.multiply(l);
//           this.renderer.resetState();
//           this.renderer.render(this.scene, this.camera);
//           this.map.triggerRepaint();
//         }
//       };

//       map!.current.addLayer(customLayer, 'waterway-label');
