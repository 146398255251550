import { IBoat } from "@wattsonelements/front-fdk/dist/models/Boats.models"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { displayNumber } from "../../../../../../_helpers/misc.helpers"

type BoatHintType = {
  boat: Partial<IBoat>
}
export const BoatHint: FC<BoatHintType> = ({ boat }) => {
  const { t } = useTranslation("technicalArea")
  if (!boat.draft && !boat.length && !boat.width) return <>???</>
  return <span className="flex gap-1">
    <span>{t("boat.width")} {displayNumber(boat.width, "")} /&nbsp;</span>
    <span>{t("boat.heigth")} {displayNumber(boat.length, "")} /&nbsp;</span>
    <span> {t("boat.draft")} {displayNumber(boat.draft, "")} /</span>
  </span>
}