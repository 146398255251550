import FDK from "@wattsonelements/front-fdk";
import { IShortUser, ITicketBoat } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AsyncAutocomplete } from "../AsyncAutocomplete";
import { IBoat } from "@wattsonelements/front-fdk/dist/models/Boats.models";
import { AxiosResponse } from "axios";

interface TicketBoatInputProps {
  onChange: (boat: ITicketBoat | null) => void;
  initialValue?: Partial<IBoat> | null;
  beforeLabel?: string,
  owner?: IShortUser | null
  request?: (search: { name?: string, owner?: string }) => Promise<AxiosResponse<IBoat[], any>>
  [key: string]: any;
}

export const BoatSearchInput: FC<TicketBoatInputProps> = ({
  onChange,
  initialValue,
  beforeLabel = "",
  owner,
  request = (data) => FDK.Marina.BoatModule.searchBoat(data),
  ...props
}) => {
  const { t } = useTranslation("ticket");
  const [options, setOptions] = useState<IBoat[] | ITicketBoat[]>([]);
  const user = useRef<IShortUser>()

  const onSearch = (name: string) => {
    if (!name && !owner) {
      setOptions([])
      return
    }
    if (initialValue && name === initialValue) return
    if (user.current?.id === owner?.id)
      request({ name, owner: owner?.id || '' }).then((res) => {
        setOptions(res.data);
        if (res.data.length === 0) {
          onChange(null)
        }
      });
  };

  useEffect(() => {
    if (!owner) {
      user.current = undefined
      return
    }
    if (user?.current?.id === owner?.id) return

    user.current = owner
    request({ owner: owner?.id || '' }).then((res) => {
      console.log(res);
      setOptions(res.data);
      if (res.data.length === 1) {
        onChange(res.data[0])
      }
      if (res.data.length === 0) {
        onChange(null)
      }
    });
  }, [owner, onChange, request])

  return (

    <AsyncAutocomplete
      {...props}
      keepOptionsWhenTextIsEmpty={!!owner}
      label={beforeLabel + t("form.boat")}
      value={initialValue || ""}
      onSearch={onSearch}
      onChange={onChange}
      options={options}
      optionsLabel={(option: ITicketBoat) => option.name || ""}
    />

  );
};
