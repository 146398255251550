import FDK from "@wattsonelements/front-fdk";
import { IMarinaBerth } from "@wattsonelements/front-fdk/dist/models/Berth.models";
import { Geojson } from "../models/map.models";
import { BERTH_STATUS } from "@wattsonelements/front-fdk/dist/constants/berth.constants";

export const useBerthService = () => {
  const getBerthIconName = (berth: IMarinaBerth) => {
    const type = berth.type !== FDK.Constants.BerthConstants.BERTH_TYPE.DEFAULT ? berth.type + "-" : "";
    if (
      [
        FDK.Constants.BerthConstants.BERTH_STATUS.AVAILABLE,
        FDK.Constants.BerthConstants.BERTH_STATUS.AVAILABILITY_TO_CONFIRM,
        FDK.Constants.BerthConstants.BERTH_STATUS.UNAVAILABLE,
        FDK.Constants.BerthConstants.BERTH_STATUS.ERROR,
      ].includes(berth.status)
    ) {
      return type + berth.status;
    }
    return berth.status;
  };

  const generateBerthGeoJson = (datas: IMarinaBerth[], status: { [key in BERTH_STATUS]: boolean }) => {
    let tmpGeoJson: Geojson = { type: "FeatureCollection", features: [] };
    datas.forEach((berth, i) => {
      const iconName = getBerthIconName(berth);
      const anchor =
        berth.type === FDK.Constants.BerthConstants.BERTH_TYPE.BUOY ? { x: 10, y: 10 } : { x: 20, y: 10 };
      if (berth.alerts.failure.active && status[BERTH_STATUS.ERROR]) {
        const type = berth.type !== FDK.Constants.BerthConstants.BERTH_TYPE.DEFAULT ? berth.type + "-" : "";
        const iconFailure = type + FDK.Constants.BerthConstants.BERTH_STATUS.ERROR;
        tmpGeoJson.features.push({
          type: "Feature",
          id: berth.id,
          properties: {
            rotation: iconName.includes(FDK.Constants.BerthConstants.BERTH_TYPE.BUOY)
              ? 0
              : berth.angle_to_north,
            width: iconName.includes(FDK.Constants.BerthConstants.BERTH_TYPE.BUOY) ? 20 : 12,
            icon: `${iconFailure}`,
            selectedIcon: `${iconName}-select`,
            anchor: anchor,
            id: berth.id,
            clickable: true,
          },
          geometry: {
            type: "Point",
            coordinates: [berth.longitude, berth.latitude],
          },
        });
      }
      if (status[berth.status]) {
        tmpGeoJson.features.push({
          type: "Feature",
          id: berth.id,
          properties: {
            rotation: iconName.includes(FDK.Constants.BerthConstants.BERTH_TYPE.BUOY)
              ? 0
              : berth.angle_to_north,
            width: iconName.includes(FDK.Constants.BerthConstants.BERTH_TYPE.BUOY) ? 20 : 12,
            icon: `${iconName}`,
            selectedIcon: `${iconName}-select`,
            anchor: anchor,
            id: berth.id,
            clickable: true,
          },
          geometry: {
            type: "Point",
            coordinates: [berth.longitude, berth.latitude],
          },
        });
      }
    });
    return tmpGeoJson;
  };

  // OBSOLETE
  // NOT USED
  // const generateBerthMarker = (berth: MarinaBerth) => {
  //   const iconName = getBerthIconName(berth);
  //   const anchor =
  //     berth.type === BERTH_TYPE.BUOY ? { x: 10, y: 10 } : { x: 20, y: 10 };

  //   return {
  //     id: berth.id,
  //     longitude: berth.longitude,
  //     latitude: berth.latitude,
  //     icon: {
  //       icon: require(`../../assets/img/icons/berth/map/${iconName}.png`),
  //       selectedIcon: require(`../../assets/img/icons/berth/map/${iconName}-select.png`),
  //       rotation: iconName.includes(BERTH_TYPE.BUOY) ? 0 : berth.angle_to_north,
  //       width: iconName.includes(BERTH_TYPE.BUOY) ? 20 : 12,
  //       height: iconName.includes(BERTH_TYPE.BUOY) ? 15 : 20,
  //       anchor,
  //     },
  //   } as MarkerDatas;
  // };

  return { getBerthIconName, generateBerthGeoJson };
};
