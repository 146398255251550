import { Box, Button, Checkbox, Chip, FormControl, InputLabel, LinearProgress, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { TableTop } from "../TableTop";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useAppDispatch, useAppSelector } from "../../../../../core/store/hooks";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ModuleTable } from "../Table";
import { setSelectedBooking, updateFilterWithoutSavingTA, updateItemInList } from "../../../../../core/store/reducers/TechnicalArea.reducer";
import { TABooking, TA_BOOKING_STATUS } from "../../../../../core/models/technicalArea.models";
import { AgentSelector } from "../Crane/reservation/Agent.elelement";
import { TAStatusNoEdit } from "./detail/TAStatus.select";
import { TACreationForm } from "./TACreation.Form";
import { BaseSingleInputFieldProps, CalendarIcon, DatePicker, DateValidationError, FieldSection, LocalizationProvider, PickersActionBarProps, UseDateFieldProps } from "@mui/x-date-pickers";
import i18n from "../../../../../translations/initI18n";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { addBookingDateParam, exportBookings, getStatusListParam, getTABookingList, updateTABookingDetail } from "../../../../../core/store/actions/TechnicalArea.actions";
import { getFilteredTABookingList } from "../../../../../core/store/selectors/TechanicalArea.selector";
import { TParams } from "@wattsonelements/front-fdk/dist/models/Misc.models";
import { durationDisplay, getDateObjectFromTime } from "../../../../../_helpers/dateTime.helper";
import classNames from "classnames";
import { FormModal } from "./form/form.modal";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";
import { DlFileButton } from "../../../UX/DlFile.button";
import { DateSelectionWithActions, DateSelectionWithActionsChangeValue, DateType } from "../../../UX/inputs/DateSelectionWithActions";

interface ButtonFieldProps extends UseDateFieldProps<dayjs.Dayjs>,
  BaseSingleInputFieldProps<
    dayjs.Dayjs | null,
    dayjs.Dayjs,
    FieldSection,
    DateValidationError
  > {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}


export const TAListTable = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation("technicalArea")
  const { reservationId } = useParams<{ reservationId: UUID }>();
  const navigate = useNavigate();

  const { bookingListLoading, selectedBooking, filters } = useAppSelector(state => state.technicalArea)
  const bookingList = useAppSelector(getFilteredTABookingList)
  const [showLoader, setShowLoader] = useState(false)

  const [openCreate, setOpenCreate] = useState(false)

  const [openDatePicker, setOpenDatePicker] = useState(false);



  const onChangeStatusFilter = (e: any) => {
    let tmpStatus: string[] = e.target.value;
    const newFilters = { ...filters, status: { ...filters.status } };

    newFilters.status = {} as any;
    for (let i in tmpStatus) {
      newFilters.status[`${tmpStatus[i]}`] = true;
    }
    newFilters.status = { ...newFilters.status }
    dispatch(updateFilterWithoutSavingTA(newFilters))

    // 💡 THIS COULD BE A HELPER
    const getStatusListParam = (statusFilters: { [key: string]: boolean }, params: TParams = {}) => {
      const statusList = Object.keys(TA_BOOKING_STATUS).filter(status => statusFilters[status as TA_BOOKING_STATUS] === true).map(
        s => TA_BOOKING_STATUS[s as keyof typeof TA_BOOKING_STATUS]
      )
      if (statusList.length > 1) {
        params.status__in = statusList.join(',')
      } else if (statusList.length === 1) {
        params.status = statusList[0]
      }
      return params
    }
    dispatch(getTABookingList(getStatusListParam(newFilters.status))).finally(() => {
      setShowLoader(false)
    })
  }
  const onClickRow = (e: React.MouseEvent, row: any) => {
    if (selectedBooking?.id === row.id) return
    dispatch(setSelectedBooking(null))
    navigate(`${row.id}`);

  }

  const onChangeDateFilter = (type: DateType, value: DateSelectionWithActionsChangeValue) => {
    setShowLoader(true)
    dispatch(updateFilterWithoutSavingTA({ date: type, ...value }))
    dispatch(getTABookingList()).finally(() => {
      setShowLoader(false)
    })
  }

  const headers = [
    {
      label: "Infos",
      key: "name",
      format: (data: TABooking) => {
        return <Box>
          <Box className="mb-1 flex gap-1">
            <Chip
              variant="squared"
              color="primary"
              size="small"
              className="uppercase"
              label={dayjs(data.start_date).format('ddd ' + t('dates.full', { ns: "common" }))} />
            {
              data.start_time && <Chip
                variant="squared"
                color="primary"
                size="small"
                className="uppercase"
                label={getDateObjectFromTime(data.start_time).format(t('dates.hour', { ns: "common" }))} />
            }

            <Chip
              variant="squared"
              size="small"
              color="primary"
              label={
                durationDisplay(
                  getDateObjectFromTime(data.start_time, data.start_date),
                  getDateObjectFromTime(data.end_time, data.end_date))
              }
            />
          </Box>
          <p className="font-bold">
            {data?.boat?.name}
            {
              data.boat.registration_number && "-" + data?.boat?.registration_number
            }
          </p>

          <p>{data?.boater?.first_name} {data?.boater?.last_name}</p>

        </Box>;
      },
      sort: true,
      compareValue: (data: TABooking) => data?.boat?.name || '',
    },
    {
      label: "Service",
      key: "service",
      format: (data: TABooking) => {

        return data.services.map(s => s.name).join(', ')
      },
      sort: true,
      compareValue: (data: TABooking) => data.services.map(s => s.name).join() || '',
    },
    {
      label: "Agents",
      key: "agents",
      format: (data: TABooking) => {

        return <AgentSelector canEdit={false} onChange={(values) => {
          updateTABookingDetail(data.id, {
            agents: values,
          } as any).then(
            res => {
              dispatch(updateItemInList(res.data))
            }, err => {

            }
          )
        }} multiple agents={data.agents} />
      },
      sort: true,
      compareValue: (data: TABooking) => '',
    },
    {
      label: "Status",
      key: "status",
      format: (data: TABooking) => {
        //TODO TRANSLATION + select + rules of status change ?
        // return <TAStatusSelect value={data.status} />
        return <TAStatusNoEdit value={data.status} />
      },
      sort: true,
      compareValue: (data: TABooking) => data?.status || '',
    },

  ]

  return (
    <>
      <TableTop
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          event.preventDefault();
          dispatch(updateFilterWithoutSavingTA({ text: event.target.value }));
        }}
        buttons={
          <>
            <Box sx={{ minWidth: 110, maxWidth: 200 }}>
              <DateSelectionWithActions
                type={filters.date}
                dateStart={filters.dateStart}
                dateEnd={filters.dateEnd}
                onChange={(type, value) => {
                  onChangeDateFilter(type, value)
                }} />
              {/* <FormControl size="small" fullWidth={true} className="h-full">
                <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dayjs(filters.dateStart)}
                    format={t('dates.full', { ns: "common" })}
                    open={openDatePicker}
                    onClose={() => setOpenDatePicker(false)}
                    onOpen={() => setOpenDatePicker(true)}
                    slotProps={{
                      textField: {
                        size: "small"
                      },
                      field: { setOpen: setOpenDatePicker } as any
                    }}
                    slots={{
                      field: (props: ButtonFieldProps) => {
                        const {
                          setOpen,
                          id,
                          disabled,
                          InputProps: { ref } = {},
                          inputProps: { 'aria-label': ariaLabel } = {},
                        } = props;
                        return (<Button
                          className="h-full"
                          variant="contained"
                          color="light"
                          id={id}
                          disabled={disabled}
                          ref={ref}
                          aria-label={ariaLabel}
                          onClick={() => setOpen?.((prev) => !prev)}
                          endIcon={<CalendarIcon />}
                        >
                          {
                            filters.date !== "date" && t(`filters.${filters.date}`)
                          }
                          {
                            (filters.date === "date" && filters.dateStart) && dayjs(filters.dateStart).format(t('dates.full', { ns: "common" }))
                          }
                        </Button>)
                      },
                      actionBar: (props: PickersActionBarProps) =>
                        <div className={classNames(props.className, "p-2 grid grid-cols-2 gap-1")}>
                          <Button
                            variant={filters.date === "date" && dayjs(filters.dateStart).isSame(dayjs(), "day") ? "contained" : "text"}
                            color="secondary"
                            onClick={() => props.onSetToday()}>
                            {t("filters.today")}
                          </Button>
                          <Button
                            color="secondary"
                            variant={filters.date === "date" && dayjs(filters.dateStart).isSame(dayjs().add(1, "day"), "day") ? "contained" : "text"}
                            onClick={() => {
                              props.onCancel()
                              onChangeDateFilter(dayjs().add(1, "day"))
                            }}>
                            {t("filters.tomorrow")}
                          </Button>
                          <Button
                            color="secondary"
                            variant={filters.date === "week" ? "contained" : "text"}
                            onClick={() => {
                              props.onCancel()
                              onChangeDateFilter("week")
                            }}> {t("filters.week")}</Button>
                          <Button
                            color="secondary"
                            variant={filters.date === "month" ? "contained" : "text"}
                            onClick={() => {
                              props.onCancel()
                              onChangeDateFilter("month")
                            }}>{t("filters.month")}</Button>
                          <Button
                            color="secondary"
                            variant={filters.date === "all" ? "contained" : "text"}
                            onClick={() => {
                              props.onCancel()
                              onChangeDateFilter("all")
                            }}>{t("filters.all")}</Button>

                        </div> as any
                    }}

                    onChange={(value: dayjs.Dayjs | null) => onChangeDateFilter(value)}
                    label={t("table.filters.date")} />
                </LocalizationProvider>
              </FormControl> */}
            </Box>

            <Box sx={{ minWidth: 110, maxWidth: 200 }}>
              <FormControl size="small" fullWidth={true}>
                {Object.keys(filters.status).length < 1 && (
                  <InputLabel id="state-checkbox-label">
                    {t("table.headers.status")}
                  </InputLabel>
                )}
                <Select
                  labelId="state-checkbox-label"
                  id="state-checkbox"
                  multiple
                  value={Object.keys(filters.status)}
                  onChange={onChangeStatusFilter}
                  input={
                    <OutlinedInput
                    // label={t("table.headers.status")}
                    />
                  }
                  renderValue={(selected) =>
                    selected.map(
                      (sel) => t(`status.${sel.toLocaleLowerCase()}`) + ", "
                    )
                  }
                  IconComponent={FilterListIcon}
                >
                  {/* Booking Status */}
                  {Object.keys(TA_BOOKING_STATUS).map(
                    (s) =>
                    (
                      <MenuItem dense={true} key={"status-" + s} value={s}>
                        <Checkbox
                          size={"small"}
                          checked={filters.status[s as TA_BOOKING_STATUS] === true}
                        />
                        <ListItemText
                          primary={t(`status.${s.toLocaleLowerCase()}`)}
                        />
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Box>
            <Button variant="contained" color="secondary" onClick={() => {
              setOpenCreate(true)
            }}>
              {t("create")}
            </Button>
            <DlFileButton
              request={() => {
                let params = {}
                const { date, dateStart, status } = filters
                params = addBookingDateParam(date, dateStart, params)
                params = getStatusListParam(status, params)
                return exportBookings(params) as any
              }
              }

            >
              {t("export")} {bookingList && bookingList.length > 0 && <Box className=" ml-2 flex px-1 rounded-full justify-center items-center text-xs bg-white text-fcosecondary">{bookingList.length}</Box>}
            </DlFileButton>

          </>
        }
      />
      {
        (bookingListLoading || showLoader) && <LinearProgress color="secondary" />
      }
      <ModuleTable
        rowPadding={true}
        headers={headers}
        displayHeader={false}
        datas={bookingList || []}
        selectedKey={reservationId}
        selectionKey='id'
        selectable={false}
        onClickRow={onClickRow}
        isLoading={bookingListLoading}
      />
      <FormModal open={openCreate} setOpen={setOpenCreate} title={t("form.title")} printTitle={t("form.titleBoater")} >
        <TACreationForm onClose={() => { setOpenCreate(false) }} />
      </FormModal>
    </>

  )
}