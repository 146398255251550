import { useEffect, useState } from "react"
import { useAppSelector } from "../../../../../core/store/hooks";
import FDK from "@wattsonelements/front-fdk";
import { Button } from "@mui/material";
import { FacebookRounded } from "@mui/icons-material";
import classNames from "classnames";
import { IUserMarina } from "@wattsonelements/front-fdk/dist/models/User.models";
import { useTranslation } from "react-i18next";

export const FacebookConnectorButton = () => {
  const { t } = useTranslation('news')
  const organization = useAppSelector((state) => state.users.profile?.organisation);
  const [org, setOrg] = useState<IUserMarina['organisation']>()

  const showButton = org?.ports.some(p => p.post_facebook_available)
  useEffect(() => {
    if (organization) {
      FDK.MainAPI.API.request({
        method: "GET",
        url: `/v2/marina/organizations/${organization.slug}`,
      }).then(res => {
        setOrg(res.data)
      })
    }
  }, [organization])

  const url = process.env.REACT_APP_API_URL + "social-auth/facebook/"
  if (!showButton) return <></>

  return <div className="flex gap-6">

    <div className="flex gap-2 flex-wrap items-center">
      {
        org?.ports.filter(p => p.post_facebook_available).map(p => <span className="border h-fit border-solid px-3 py-[2px] rounded-full text-sm text-fcomain border-fcomain flex gap-1 items-center">
          {p.name}
          <span className={classNames("block rounded-full aspect-square w-2", {
            "bg-fcosecondary": p.post_facebook_connected,
            "bg-fcowarn": !p.post_facebook_connected
          })}></span>
        </span>)
      }
    </div>
    <Button href={url} target="_blank" variant="contained">
      {t("fbConnect")}&nbsp;
      <FacebookRounded />
    </Button>
  </div>
}