import { useAppSelector } from "../store/hooks";
import { getFilteredPlugList } from "../store/selectors/Module.selector";
import { Geojson } from "../models/map.models";
import { IPlug, IPowerUnit } from "@wattsonelements/front-fdk/dist/models/Electricity.models";

export enum ElecMarkerGroup {
  powerunit = "powerunit",
  plug = "plug",
}

export const isInUse = (plug: IPlug) => {
  return plug.in_use === true;
};

export const noConso = (plug: IPlug) => {
  return !plug.in_use;
};

export const isOverConso = (plug: IPlug) => {
  // if (plug.global_consumption) {
  //   if (
  //     plug.global_consumption.high_consumption_12_hours ||
  //     plug.global_consumption.high_consumption_now
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // } else {
  //   return false;
  // }
  return false
};

export const useElectricityService = () => {
  const plugs = useAppSelector(getFilteredPlugList);

  const getPowerunitIconName = (plugsList: IPlug[]) => {
    const hasError = plugsList.some((p) => p?.alerts?.failure?.active);
    return hasError ? "pu-failure" : "pu-no-error";
  };

  const getPlugIconName = (plug: IPlug) => {

    if (plug?.alerts?.failure?.active) {
      return "plug-failure";
    }
    if (isInUse(plug)) {
      return "plug-pluged";
    } else if (noConso(plug)) {
      return "plug-unpluged";
    }
  };

  const getPlugIconNameForMap = (plug: IPlug) => {
    if (plug.tickets.length > 0) {
      return "plug-alert"
    }
    return getPlugIconName(plug)
  }

  const getLongLatPlug = (
    index: number,
    singlePowerUnit: IPowerUnit,
    nbOfElmts: number
  ) => {
    const listAngles4 = [-60, 240, 60, 120];
    const listAngles2 = [-90, 90];

    let listAngles = [];

    switch (nbOfElmts) {
      case 2:
        listAngles = listAngles2;
        break;
      case 4:
        listAngles = listAngles4;
        break;
      default:
        const d = 760 / nbOfElmts;
        for (let i = 0; i < nbOfElmts; i++) {
          listAngles.push(380 - d * i);
        }
        break;
    }

    const spacing = 0.000025
    // latitude
    const x =
      singlePowerUnit.latitude +
      spacing *
      Math.cos(
        (singlePowerUnit.angle_to_north + listAngles[index]) * (Math.PI / 180)
      );
    // longitude
    const y =
      singlePowerUnit.longitude +
      spacing *
      Math.sin(
        (singlePowerUnit.angle_to_north + listAngles[index]) * (Math.PI / 180)
      );

    return { latitude: x, longitude: y };
  };

  const generateElectricityGeoJson = (powerunits: IPowerUnit[]) => {
    let tmpGeoJson: Geojson = { type: "FeatureCollection", features: [] };
    // console.log(powerunits)
    let nbPriority = 1;
    powerunits.map((powerunit, i) => {
      const plugsList = powerunit.plugs;
      const iconName = getPowerunitIconName(plugsList);
      tmpGeoJson.features.push({
        type: "Feature",
        id: powerunit.id,
        properties: {
          priority: nbPriority++,
          group: ElecMarkerGroup.powerunit,
          width: 20,
          icon: `${iconName}`,
          selectedIcon: `pu-select`,
          anchor: { x: 10, y: 10 },
          clickable: true,
          id: powerunit.id,
        },
        geometry: {
          type: "Point",
          coordinates: [powerunit.longitude, powerunit.latitude],
        },
      });
    });
    powerunits.map((powerunit, i) => {
      const plugsList = powerunit.plugs;
      plugs.filter((plg: IPlug) => plugsList.find(p => p.id === plg.id)).map(
        (plug, i) => {
          const angle = getLongLatPlug(i, powerunit, plugsList.length);
          const iconName = getPlugIconNameForMap(plug);
          tmpGeoJson.features.push({
            type: "Feature",
            // plug id here to allow the flyto zoom
            id: plug.id,
            properties: {
              priority: nbPriority++,
              group: ElecMarkerGroup.plug,
              width: 20,
              icon: `${iconName}`,
              selectedIcon: `plug-select`,
              anchor: { x: 10, y: 10 },
              clickable: true,
              id: plug.id,
            },
            geometry: {
              type: "Point",
              coordinates: [angle.longitude, angle.latitude],
            },
          });
        }
      )
    });
    // console.log("tmpGeoJson", tmpGeoJson)
    return tmpGeoJson;
  };

  return { generateElectricityGeoJson, isOverConso, getPlugIconName };
};
