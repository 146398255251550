import { Check, Close } from "@mui/icons-material"
import { Box, Chip, CircularProgress, Typography } from "@mui/material"
import { REQUEST_STATUS } from "@wattsonelements/front-fdk/dist/api"
import { IPlanningCreation } from "@wattsonelements/front-fdk/dist/models/Booking.models"
import { FC } from "react"
import { useTranslation } from "react-i18next"

type RecapPlanningRowProps = {
  status?: REQUEST_STATUS,
  message?: string,
  startTime?: string
  endTime?: string
  weekday?: number,
  maximumTasksNumber: number,
  confirmation: boolean,
  showWeekDay?: boolean
}
export const RecapPlanningRow: FC<RecapPlanningRowProps> = ({ startTime, endTime, maximumTasksNumber, weekday, status, message, confirmation, showWeekDay = true }) => {
  const { t } = useTranslation('crane')
  return <>

    <Box className="flex justify-between gap-2 ">

      <Box className="flex gap-2">
        <span className="font-bold">
          {showWeekDay && t(`dates.days.${weekday}`, { ns: "common" })} {' '}

          {startTime} - {' '}
          {endTime} {' '}</span>
        <Chip variant="squared" size="small" label={`${maximumTasksNumber} ${t('planning.slots')}`} />
      </Box>
      {
        confirmation && status === REQUEST_STATUS.PENDING && <CircularProgress size={15} color="secondary" />
      }
      {
        status === REQUEST_STATUS.SUCCESS && <>
          <Check color="success" />
        </>
      }
      {
        status === REQUEST_STATUS.ERROR && <>
          <Close
            color="error" />
        </>
      }
    </Box>
    {
      status === REQUEST_STATUS.ERROR &&
      <>
        <Typography className="rounded bg-fcodanger/[0.09] px-4 py-2"
          sx={{ fontSize: "0.8em", fontWeight: "bold" }}
          color="error">
          {message || t('form.error', { ns: "common" })}
        </Typography>
      </>
    }
  </>
}