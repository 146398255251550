import { FC, useContext } from "react"
import { AreaLayout, LAYOUT_NAME, Layout, TableLayout } from "../../../../../../core/models/portCMS.models"
import { AreaPreview } from "../Area/Area.preview"
import { PortCMSPreviewContext } from "./PreviewContext"
import { TablePreview } from "../Table/Table.preview"
import { Alert, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { SectionNameI18n } from "../Sections.list"

type SwitchSectionPreviewProps = {
}

export const SwitchSectionPreview: FC<SwitchSectionPreviewProps> = () => {
  const { previewSection } = useContext(PortCMSPreviewContext)
  const { t } = useTranslation(["portCMS"]);

  if (!previewSection) {
    return <Alert severity="info">{t("preview.noSectionSelected")}</Alert>
  }

  switch (previewSection?.layout) {
    case LAYOUT_NAME.AREA:
      return <AreaPreview section={previewSection as AreaLayout} />
    case LAYOUT_NAME.TABLE:
      return <TablePreview section={previewSection as TableLayout} />
    // case LAYOUT_NAME.ACCORDION:
    //   return <AccordionSection section={section as AccordionLayout} />
    // case LAYOUT_NAME.IMAGE:
    //   return <ImageSection section={section as ImageLayout} />
    // case LAYOUT_NAME.IMAGE_TABLE:
    //   return <ImageTableSection section={section as ImagesTableLayout} />
    default:
      return <Alert severity="warning">{t('preview.notImplemented', { section: t(SectionNameI18n[previewSection.layout]) })}</Alert>
  }
}