import React, { useEffect } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "../../../../assets/img/menu/HomeIcon";
import ReportingIcon from "../../../../assets/img/menu/ReportingIcon";
import { List, Tooltip } from "@mui/material";
import BerthIcon from "../../../../assets/img/menu/BerthIcon";
import TicketIcon from "../../../../assets/img/menu/TicketIcon";
import MenuReportingIcon from "../../../../assets/img/menu/MenuReportingIcon";
import ElectricityIcon from "../../../../assets/img/menu/ElectricityIcon";
import UserIcon from "../../../../assets/img/menu/UserIcon";
import NewsIcon from "../../../../assets/img/menu/NewsIcon";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../../../contexts/AppContext";
import { useModule } from "../../../../core/services/Modules.service";
import { ArticleOutlined, PhonelinkSetup } from "@mui/icons-material";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DryPortIcon from "../../../../assets/img/menu/DryPortIcon";

export const HarborMenu: React.FC = () => {
  const { t } = useTranslation("app");
  const { drawerOpened, toggleDrawer } = useAppContext();
  const location = useLocation();
  const { isModuleActivated, findFirstActivatedModule } = useModule();

  const findDefaultReportingModule = () => {
    if (isModuleActivated("emplacement", "reporting") || isModuleActivated("berth", "reporting")) {
      return "/admin/reporting/berths"
    }
    if (isModuleActivated("electricity", "reporting")) {
      return "/admin/reporting/electricity"
    }
    if (isModuleActivated("ticket", "reporting")) {
      return "/admin/reporting/ticket"
    }
    if (isModuleActivated("waiting-request", "reporting")) {
      return "/admin/reporting/waiting-request"
    }
    if (isModuleActivated("handling-task", "reporting")) {
      return "/admin/reporting/handling-task"
    }
    if (isModuleActivated("contract-renewal", "reporting")) {
      return "/admin/reporting/contract-renewal"
    }
    return "/admin/cms/port"
  }

  useEffect(() => {
    if (drawerOpened) toggleDrawer!();
  }, [location]);

  const reportingPaddingLeft = drawerOpened ? 7 : 2
  const iconStyle = {
    pl: reportingPaddingLeft,
    transform: drawerOpened ? "" : 'scale(0.7)',
    marginRight: drawerOpened ? "0" : "20px",
    marginLeft: drawerOpened ? "0" : "4px",
    gap: drawerOpened ? "0" : "25px",
    opacity: drawerOpened ? "1" : "0.8"
  }

  return (
    <>
      <Tooltip placement="right" arrow title={t("menu.bloc1.overviewToolTip")}>
        {/*  */}
        <NavLink
          className={({ isActive }) => (isActive ? "drawer-menu-active" : "")}
          to={findFirstActivatedModule()}
        >
          <ListItemButton>
            <HomeIcon />
            <ListItemText
              disableTypography
              primary={
                <div className="ml-4 font-bold text-md">
                  {t("menu.bloc1.overview")}
                </div>
              }
            />
          </ListItemButton>
        </NavLink>
      </Tooltip>
      <Tooltip placement="right" arrow title={t("menu.bloc1.subtitleToolTip")}>
        <NavLink to={findDefaultReportingModule()}>
          <ListItemButton>
            <ReportingIcon />
            <ListItemText
              disableTypography
              primary={
                <div className="ml-4 font-bold text-md">
                  {t("menu.bloc1.subtitle")}
                </div>
              }
            />
          </ListItemButton>
        </NavLink>
      </Tooltip>
      <List component="div" disablePadding>
        {(isModuleActivated("emplacement", "reporting") || isModuleActivated("berth", "reporting")) &&
          // {isModuleActivated("berth", "reporting") &&
          <NavLink
            className={({ isActive }) => (isActive ? "drawer-menu-active" : "")}
            to="/admin/reporting/berths"
          >
            <ListItemButton sx={iconStyle}>
              <BerthIcon whiteSquare={true} />
              <ListItemText
                disableTypography
                primary={
                  <div className="ml-4 font-medium text-md">
                    {t("menu.modules.berth")}
                  </div>
                }
              />
            </ListItemButton>
          </NavLink>
        }
        {isModuleActivated("electricity", "reporting") &&
          <NavLink
            className={({ isActive }) => (isActive ? "drawer-menu-active" : "")}
            to="/admin/reporting/electricity"
          >
            <ListItemButton sx={iconStyle}>
              <ElectricityIcon whiteSquare={true} />
              <ListItemText
                disableTypography
                primary={
                  <div className="ml-4 font-medium text-md">
                    {t("menu.modules.electricity")}
                  </div>
                }
              />
            </ListItemButton>
          </NavLink>
        }
        {
          isModuleActivated("ticket") && <NavLink
            className={({ isActive }) => (isActive ? "drawer-menu-active" : "")}
            to="/admin/reporting/tickets"
          >
            <ListItemButton sx={iconStyle}>
              <TicketIcon whiteSquare={true} />
              <ListItemText
                disableTypography
                primary={
                  <div className="ml-4 font-medium text-md">
                    {t("menu.modules.ticket")}
                  </div>
                }
              />
            </ListItemButton>
          </NavLink>
        }

        {
          isModuleActivated("waiting-request", "reporting") &&
          <NavLink
            className={({ isActive }) => (isActive ? "drawer-menu-active" : "")}
            to="/admin/reporting/waiting-requests"
          >
            <ListItemButton sx={iconStyle}>
              <MenuReportingIcon whiteSquare={true}>
                <FormatListBulletedIcon sx={{
                  width: 17
                }} color="primary" />
              </MenuReportingIcon>

              <ListItemText
                disableTypography
                primary={
                  <div className="ml-4 font-medium text-md">
                    {t("menu.modules.wr")}
                  </div>
                }
              />
            </ListItemButton>
          </NavLink>
        }
        {
          isModuleActivated("handling-task", "reporting") &&
          <NavLink
            className={({ isActive }) => (isActive ? "drawer-menu-active" : "")}
            to="/admin/reporting/handling-task"
          >
            <ListItemButton sx={iconStyle}>
              <MenuReportingIcon whiteSquare={true}>
                <DryPortIcon />
              </MenuReportingIcon>

              <ListItemText
                disableTypography
                primary={
                  <div className="ml-4 font-medium text-md">
                    {t("menu.modules.dryport")}
                  </div>
                }
              />
            </ListItemButton>
          </NavLink>
        }
        {
          isModuleActivated("contract-renewal", "reporting") &&
          <NavLink
            className={({ isActive }) => (isActive ? "drawer-menu-active" : "")}
            to="/admin/reporting/contract-renewal"
          >
            <ListItemButton sx={iconStyle}>
              <MenuReportingIcon whiteSquare={true}>
                <ArticleOutlined color="primary" />
              </MenuReportingIcon>

              <ListItemText
                disableTypography
                primary={
                  <div className="ml-4 font-medium text-md whitespace-pre-line leading-4">
                    {drawerOpened && t("menu.modules.contractRenewal")}
                  </div>
                }
              />
            </ListItemButton>
          </NavLink>
        }
      </List>
    </>
  );
};

export const ManagementMenu = () => {
  const { isModuleActivated } = useModule();
  const { t } = useTranslation("app");
  return (
    <>
      <Tooltip placement="right" arrow title={t("menu.bloc2.portTooltip")}>
        <NavLink
          className={({ isActive }) => (isActive ? "drawer-menu-active" : "")}
          to="/admin/cms/port"
        >
          <ListItemButton>
            <PhonelinkSetup />
            <ListItemText
              disableTypography
              primary={
                <div className="ml-4 font-bold text-md">
                  {t("menu.bloc2.port")}
                </div>
              }
            />
          </ListItemButton>
        </NavLink>
      </Tooltip>
      {isModuleActivated("posts") &&
        <Tooltip placement="right" arrow title={t("menu.bloc2.newsToolTip")}>
          <NavLink
            className={({ isActive }) => (isActive ? "drawer-menu-active" : "")}
            to="/admin/cms/news"
          >
            <ListItemButton>
              <NewsIcon />
              <ListItemText
                disableTypography
                primary={
                  <div className="ml-4 font-bold text-md">{t("menu.bloc2.news")}</div>
                }
              />
            </ListItemButton>
          </NavLink>
        </Tooltip>
      }

      <Tooltip placement="right" arrow title={t("menu.bloc2.usersToolTip")}>
        <NavLink
          className={({ isActive }) => (isActive ? "drawer-menu-active" : "")}
          to="/admin/cms/users"
        >
          <ListItemButton>
            <UserIcon />
            <ListItemText
              disableTypography
              primary={
                <div className="ml-4 font-bold text-md">
                  {t("menu.bloc2.users")}
                </div>
              }
            />
          </ListItemButton>
        </NavLink>
      </Tooltip>

    </>
  );
};
