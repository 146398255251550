import { FC, useEffect, useRef, useState } from "react";
import { AsyncAutocomplete } from "../AsyncAutocomplete";
import { IShortUser } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import FDK from "@wattsonelements/front-fdk";
import { useTranslation } from "react-i18next";
import { IBoat } from "@wattsonelements/front-fdk/dist/models/Boats.models";

interface BoaterSearchInputProps {
  onChange: (boater: IShortUser | null) => void;
  boat?: Partial<IBoat> | null;
  beforeLabel?: string
  initialValue?: null | IShortUser;
  [key: string]: any;
}

export const BoaterSearchInput: FC<BoaterSearchInputProps> = (
  { onChange, initialValue, boat, beforeLabel = "", ...props }
) => {
  const [options, setOptions] = useState<IShortUser[]>([]);
  const { t } = useTranslation("ticket");
  // const boatId = useRef("")
  const [searchParams, setSearchParams] = useState<{ text: string, boat: Partial<IBoat> | null }>({
    boat: null,
    text: ""
  })


  const onSearch = async (name: string, currentBoat?: Partial<IBoat> | null) => {
    console.log("on search boater", initialValue, name, currentBoat, searchParams);

    if (!name && !currentBoat) {
      setOptions([])
      setSearchParams({
        text: "",
        boat: null
      })
      return
    }
    if (searchParams.text === name && searchParams.boat?.id === currentBoat?.id) return
    if (name === `${initialValue?.first_name || ""} ${initialValue?.last_name || ""}`) return
    setSearchParams({
      text: name,
      boat: currentBoat || null
    })

    await FDK.Marina.UserMarinaAPI.searchBoater({ q: name, boat: currentBoat?.id || "" }).then((res) => {
      setOptions([...res.data]);
      if (res.data.length === 1) {
        onChange(res.data[0])
      }
      if (res.data.length === 0) {
        onChange(null)
      }
    });

  };

  useEffect(() => {
    console.log("before on search", boat);
    onSearch("", boat)
  }, [boat?.id, onChange])

  return (
    <AsyncAutocomplete
      {...props}
      keepOptionsWhenTextIsEmpty={!!boat}
      label={beforeLabel + t("form.boater")}
      value={initialValue || ""}
      onSearch={onSearch}
      onChange={onChange}
      options={options}
      optionsLabel={(option) => `${option.first_name || ""} ${option.last_name || ""}`}
    />
  )
}