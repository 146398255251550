import {
  Alert, Avatar, Box, Button, Chip, Divider, FormControlLabel, MenuItem, Paper, Select, Stack, Switch, TextField, Tooltip, Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  getTicketDetail,
  updateTicket,
} from "../../../../../core/store/actions/Ticket.actions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../core/store/hooks";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import dayjs from "dayjs";
import { TicketAssignees } from "./TicketAssignees";
import { TicketBerthInput } from "./TicketBerth.element";
import { TicketBoatInput } from "./TicketBoat.element";

import { useTicket } from "../../../../../core/services/Ticket.service";
import { Ticketchat } from "./TicketChat";
import {
  setSelected,
  setSelectedID,
} from "../../../../../core/store/reducers/Ticket.reducer";
import useConfirm from "../../../../../_helpers/useConfirm";
import { TicketDetailLoading } from "./TicketDetailLoading";
import { usePeriodic } from "../../../../../core/services/Periodic.service";
import FDK from "@wattsonelements/front-fdk";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";
import { AlertType, useAppContext } from "../../../../contexts/AppContext";
import { IAssignee, IPlug, ITicketBoat } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import { IMarinaBerth } from "@wattsonelements/front-fdk/dist/models/Berth.models";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { AreaSelect } from "./AreaSelect.element";
import { IPortArea } from "@wattsonelements/front-fdk/dist/models/Port.models";
import { TimeSpent } from "./TimeSpent.field";
import { getCategoryById } from "../../../../../core/store/selectors/Ticket.selector";
import { Print } from "@mui/icons-material";
import { TicketKindField } from "./TicketKind.field";
import { DlFileButton } from "../../../UX/DlFile.button";

export const TicketDetail = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("ticket");
  const navigate = useNavigate();
  const { id } = useParams<{ id: UUID }>();

  const { updateAssignee, updateDescription, updateTitle, updateFields } = useTicket();
  const { confirm } = useConfirm();
  const { startPeriodic, stopPeriodic } = usePeriodic();

  const ticket = useAppSelector((state) => state.tickets.selected);
  const port = useAppSelector(state => state.ports.current)
  const categories = useAppSelector(state => state.tickets.categories)
  const profile = useAppSelector(state => state.users.profile)

  const [loading, setLoading] = useState(true);

  const [plugName, setPlugName] = useState("");
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const { setAlertMessage } = useAppContext();
  const [editDescription, setEditDescription] = useState(false);

  // Time fields
  const [deadline, setDeadline] = useState<dayjs.Dayjs | null>()
  const [eventDate, setEventDate] = useState<dayjs.Dayjs | null>()
  const [timePickerOpen, setTimePickerOpen] = useState(false)
  const [area, setArea] = useState<Partial<IPortArea> | null | undefined>()

  const disabled =
    ticket?.status === FDK.Constants.TicketConstants.TicketStatus.CLOSED ||
    ticket?.status === FDK.Constants.TicketConstants.TicketStatus.CANCELLED ||
    ticket?.status === FDK.Constants.TicketConstants.TicketStatus.ARCHIVED;

  useEffect(() => {
    if (id) {
      dispatch(setSelectedID(id));
      // dispatch(getTicketDetail({ id }))

      startPeriodic("ticket-detail", 5, () =>
        dispatch(getTicketDetail({ id }))
      );
    }

    return () => {
      stopPeriodic("ticket-detail");
      dispatch(setSelected(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id && ticket && id === ticket?.id) {
      setLoading(false);
      setDescription(ticket.description);
      setTitle(ticket?.title || "")
      if (typeof ticket.area === "string") {
        setArea({
          id: ticket.area
        })
      } else {
        setArea(ticket.area || null)
      }

      // this state if we had to get the power unit name + plug name
      if (ticket?.plug !== null) {
        // get power unit name
        //...
        setPlugName((ticket!.plug as IPlug)?.name)
      }
    }
  }, [id, ticket]);

  const cancelDescription = () => {
    setEditDescription(false);
    setDescription(ticket!.description);
  }

  const category = useAppSelector((state) =>
    getCategoryById(state, state.tickets.selected?.category || '')
  );

  const assigneesCB = async (ass: Partial<IAssignee>[]) => {
    let assignees = ass.map(as => as.id)
    try {
      await updateAssignee(ticket!.id, assignees as string[]);
    } catch (e) {
      setAlertMessage!({ type: AlertType.ERROR, message: t('ticketUpdateError') })
    }
  }

  const berthCB = async (berth: IMarinaBerth | null) => {
    dispatch(updateTicket({ id: ticket!.id, field: { spot: berth?.id || null } }))
      .then((e) => e.type === "ticket/update/rejected"
        ? setAlertMessage!({ type: AlertType.ERROR, message: t('ticketUpdateError') }) : null,
        (err) => setAlertMessage!({ type: AlertType.ERROR, message: t('ticketUpdateError') }))
  }

  const boatCB = async (boat: ITicketBoat | null) => {
    console.log("boatCB", boat);
    dispatch(updateTicket({ id: ticket!.id, field: { boat: boat?.id || null } }))
      .then((e) => e.type === "ticket/update/rejected"
        ? setAlertMessage!({ type: AlertType.ERROR, message: t('ticketUpdateError') }) : null,
        (err) => setAlertMessage!({ type: AlertType.ERROR, message: t('ticketUpdateError') }))
  }

  const checkUpdateTitle = async () => {
    if (title !== ticket!.title)
      try {
        await updateTitle(ticket!.id, title);
      } catch (e) {
        setAlertMessage!({ type: AlertType.ERROR, message: t('ticketUpdateError') })
      }
  }
  const checkUpdateDescription = async () => {
    if (description !== ticket!.description)
      try {
        await updateDescription(ticket!.id, description);
      } catch (e) {
        setAlertMessage!({ type: AlertType.ERROR, message: t('ticketUpdateError') })
      }
  }



  const handleStatusClose = async () => {
    if (!(await confirm(t("confirmCloseTicket.text")))) return;
    dispatch(updateTicket({ id: ticket!.id, field: { status: FDK.Constants.TicketConstants.TicketStatus.CLOSED } }))
      .then((e) => e.type === "ticket/update/rejected"
        ? setAlertMessage!({ type: AlertType.ERROR, message: t('ticketUpdateError') }) : null,
        (err) => setAlertMessage!({ type: AlertType.ERROR, message: t('ticketUpdateError') }))
  };
  const handleStatusReOpen = async () => {
    if (!(await confirm(t("confirmReopenTicket.text")))) return;
    dispatch(updateTicket({ id: ticket!.id, field: { status: FDK.Constants.TicketConstants.TicketStatus.OPEN } }))
      .then((e) => e.type === "ticket/update/rejected"
        ? setAlertMessage!({ type: AlertType.ERROR, message: t('ticketUpdateError') }) : null,
        (err) => setAlertMessage!({ type: AlertType.ERROR, message: t('ticketUpdateError') }))
  };


  // NEW FIELDS 

  const updateArea = async (area: string | null) => {
    try {
      await updateFields(ticket!.id, { area: area });
    } catch (e) {
      setAlertMessage!({ type: AlertType.ERROR, message: t('ticketUpdateError') })
    }
  }

  const updateTimeSpent = async (timeSpent: string | null) => {
    try {
      await updateFields(ticket!.id, { time_spent: timeSpent ? timeSpent : null });
    } catch (e) {
      setAlertMessage!({ type: AlertType.ERROR, message: t('ticketUpdateError') })
    }
  }

  const updateEventDate = async () => {
    if (eventDate && !eventDate?.isValid()) return
    const date = eventDate ? eventDate.format('YYYY-MM-DD') : eventDate || null

    try {
      await updateFields(ticket!.id, { event_date: date });
    } catch (e) {
      setAlertMessage!({ type: AlertType.ERROR, message: t('ticketUpdateError') })
    }
  }

  const updateDeadline = async () => {
    if (deadline && !deadline?.isValid()) return
    const date = deadline ? deadline.toISOString() : deadline || null

    try {
      await updateFields(ticket!.id, { deadline: date });
    } catch (e) {
      setAlertMessage!({ type: AlertType.ERROR, message: t('ticketUpdateError') })
    }
  }

  const updateKind = async (value?: string | null) => {
    try {
      await updateFields(ticket!.id, { kind: value });
    } catch (e) {
      setAlertMessage!({ type: AlertType.ERROR, message: t('ticketUpdateError') })
    }
  }

  return (
    <Paper className="p-4">
      {/** HEADER **/}
      <Box className="flex items-center justify-between mb-2">
        <Box className="flex items-center gap-5">
          <Button
            onClick={() => {
              navigate(-1);
              // dispatch(setSelected(null));
            }}
            variant="outlined"
            startIcon={<ArrowBackIosIcon />}
          >
            {t("buttons.back", { ns: "common" })}
          </Button>
          <Box>
            {ticket?.title &&
              <Box component="h1" className="font-extrabold">
                {ticket?.title}
              </Box>
            }

            {ticket?.reference &&
              <Chip className="mr-2 font-bold" label={ticket?.reference} />
            }

            {t("form.createdAt")}{" "}
            {dayjs(ticket?.date_created).format(t("dates.full", { ns: "common" }))}
          </Box>
        </Box>
        {/* PRIORITY TOGGLE */}
        {ticket?.priority !== FDK.Constants.TicketConstants.TicketPriority.URGENT && (
          <Tooltip placement="left" arrow title={t("form.urgentToolTip")}>
            <FormControlLabel
              control={<Switch color="error" />}
              label={t("form.intermediate")}
              checked={ticket?.priority === FDK.Constants.TicketConstants.TicketPriority.INTERMEDIATE}
              onChange={(
                event: React.SyntheticEvent<Element, Event>,
                checked: boolean
              ) => {
                dispatch(updateTicket({
                  id: ticket!.id, field: {
                    priority: checked
                      ? FDK.Constants.TicketConstants.TicketPriority.INTERMEDIATE
                      : FDK.Constants.TicketConstants.TicketPriority.NORMAL
                  }
                }))
                  .then((e) => e.type === "ticket/update/rejected"
                    ? setAlertMessage!({ type: AlertType.ERROR, message: t('ticketUpdateError') }) : null,
                    (err) => setAlertMessage!({ type: AlertType.ERROR, message: t('ticketUpdateError') }))
              }
              }
            />
          </Tooltip>
        )}
        <Box className="flex flex-col gap-3">
          {ticket?.priority === FDK.Constants.TicketConstants.TicketPriority.URGENT &&
            <Chip color="error" className="uppercase" label={t("form.urgent")} />
          }
        </Box>
        <DlFileButton
          disabled={!id}
          request={() =>
            FDK.MainAPI.API.request({
              method: "GET",
              url: `/v1/ticket/${id}/pdf`,
              responseType: 'blob',
            })
          }
        >
          <Print />
        </DlFileButton>
      </Box>
      <Divider />
      {(loading || !ticket) && <TicketDetailLoading />}

      {/** DETAIL **/}
      {!loading && ticket && (
        <>
          {/** CREATOR AREA **/}
          <Box className="flex items-center px-5 my-2">
            <Avatar src={ticket?.creator?.avatar} />
            <Box className="ml-3 flex justify-between items-center w-full">
              <Box>
                <Typography variant="subtitle2"> {t("createdBy")}</Typography>
                <Typography variant="h5">
                  {ticket?.creator?.first_name} {ticket?.creator?.last_name}
                </Typography>
                <Typography variant="subtitle1">
                  {(ticket?.boat as ITicketBoat)?.name || ""}
                </Typography>
              </Box>
              <Box>

                {
                  profile?.is_manager ?
                    <Select onChange={async (e) => {
                      console.log(e.target.value);
                      try {
                        await updateFields(ticket!.id, { category: e.target.value });
                      } catch (e) {
                        setAlertMessage!({ type: AlertType.ERROR, message: t('ticketUpdateError') })
                      }

                    }} disabled={!profile?.is_manager} value={ticket.category}>
                      {
                        categories.filter(c => c.allow_creation).map(c => <MenuItem key={c.id} value={c.id}>
                          <Box className=" rounded-t px-2 flex items-center gap-2 justify-center">
                            <Box className="bg-fcomain p-1 w-[20px] h-[20px] rounded">
                              <img className="w-full h-full " src={c?.icon_url} alt={c?.label} />
                            </Box>
                            {c?.label}
                          </Box>
                        </MenuItem>)
                      }

                    </Select> : <Box className=" rounded-t px-2 flex items-center gap-2 justify-center">
                      {category?.label}
                      <Box className="bg-fcomain p-1 w-[30px] h-[30px] rounded">
                        <img className="w-full h-full " src={category?.icon_url} alt={category?.label} />
                      </Box>

                    </Box>
                }
              </Box>
            </Box>
          </Box>
          <Box className="grid grid-cols-2">
            <Box className="p-5">
              {/** MAIN FORM **/}
              <Stack className="mt-2" spacing={2}>

                {/* {ticket.description && */}
                <Box>
                  {/* <p className="text-sm m-0 p-0 block font-bold">
                    {t("form.description")}
                  </p> */}
                  {/* <p>{ticket.description}</p> */}
                  <TextField
                    disabled={disabled}
                    onChange={(e) => {
                      setTitle(e.target.value)
                    }}
                    onBlur={checkUpdateTitle}
                    className="w-full"
                    sx={{ marginTop: "10px" }}
                    label={t("form.title")}
                    value={title}
                    inputProps={
                      { maxLength: 40 }
                    }

                  />
                  <TextField
                    disabled={disabled}
                    onChange={(e) => setDescription(e.target.value)}
                    // onBlur={() => setEditDescription(false)}
                    // onBlur={cancelDescription}
                    onBlur={checkUpdateDescription}
                    // onFocus={() => setEditDescription(true)}
                    className="w-full"
                    sx={{ marginTop: "10px" }}
                    label={t("form.description")}
                    multiline
                    value={description}
                    minRows={4}
                  />


                  {/* {editDescription && <Box className="flex items-center justify-end gap-2 mt-2">
                    <Button onMouseDown={() => updateDescription(ticket)(description)} variant="contained" color="primary" size={"small"}>{t('form.save')}</Button>
                    <Button onMouseDown={cancelDescription} size={"small"}>{t('form.cancel')}</Button>
                  </Box>} */}
                </Box>

                {/* } */}

                <Box className="flex justify-start items-center gap-2">
                  <p className="text-sm m-0 p-0 block font-bold">
                    {t("form.ref") + (ticket.status === FDK.Constants.TicketConstants.TicketStatus.CLOSED && ticket.assignees.length < 1 ? " : " + ticket.assignees.length : "")}
                  </p>
                  <TicketAssignees
                    onChange={assigneesCB}
                    canEdit={!disabled}
                    ticket={ticket}
                    displayLabel={true}
                  />
                </Box>

                {/* AREA SELECT ADDED HERE */}
                {
                  port?.config.ticket_displayed_fields.includes("area") &&
                  <AreaSelect value={area?.id} onChange={updateArea} />
                }

                {ticket?.plug !== null &&
                  <TextField aria-readonly value={plugName} label={t('form.plug')} disabled={true} />}
                {
                  port?.config.ticket_displayed_fields.includes("spot") &&
                  <TicketBerthInput
                    initialValue={ticket?.spot}
                    onChange={berthCB}
                    disabled={disabled}
                  />
                }
                {
                  port?.config.ticket_displayed_fields.includes("boat") &&
                  <TicketBoatInput
                    initialValue={ticket?.boat}
                    onChange={boatCB}
                    disabled={disabled}
                  />
                }
                <Divider />
                {/****************** NEW FIELDS HERE ******************/}
                {
                  port?.config.ticket_displayed_fields.includes("event_date") &&
                  <Box>
                    <DatePicker
                      defaultValue={eventDate}
                      className="w-full"
                      format={
                        t("dates.full", { ns: "common" })
                      }
                      label={t("form.eventDateTime")}
                      // slotProps={{ textField: { error: !!newsForm.formState.errors.date_published } }}
                      // value={dayjs(newsForm.watch("date_published", ""))}
                      onChange={(newValue: dayjs.Dayjs | null) => {
                        console.log("time event date", newValue);
                        setEventDate(newValue)
                      }}
                      onClose={() => {
                        updateEventDate()
                      }}
                      slotProps={{ field: { clearable: true } }}
                      disabled={disabled}
                    />
                  </Box>
                }
                {
                  port?.config.ticket_displayed_fields.includes("deadline") &&
                  <Box>
                    <DateTimePicker
                      defaultValue={ticket.deadline ? dayjs(ticket.deadline) : null}
                      className="w-full"
                      ampm={t('dates.ampm', { ns: "common" })}
                      format={
                        t("dates.full", { ns: "common" }) +
                        " " +
                        t("dates.hour", { ns: "common" })
                      }
                      label={t("form.deadline")}
                      // slotProps={{ textField: { error: !!newsForm.formState.errors.date_published } }}
                      // value={dayjs(newsForm.watch("date_published", ""))}
                      onChange={(newValue: dayjs.Dayjs | null) => {
                        console.log("time deadline", newValue);
                        setDeadline(newValue)
                        // updateDeadline()
                      }}
                      onClose={() => updateDeadline()}
                      disabled={disabled}
                      slotProps={{ field: { clearable: true } }}
                    />

                  </Box>
                }


                {
                  port?.config.ticket_displayed_fields.includes("time_spent") &&
                  <Box>
                    {/* <DesktopTimePicker
                      className="w-full"
                      ampm={false}
                      open={timePickerOpen}
                      defaultValue={getDateObjectFromTime(ticket.time_spent)}
                      label={t("form.timeSpent")}
                      onChange={(newValue: dayjs.Dayjs | null) => {
                        console.log("time time spent", newValue);
                        setTimeSpent(newValue)
                        if (newValue && newValue.hour() && newValue.minute()) {
                          updateTimeSpent()
                        }
                      }}
                      onClose={
                        () => {
                          updateTimeSpent()
                          setTimePickerOpen(false)
                        }
                      }
                      slotProps={{
                        textField: {
                          onClick: () => setTimePickerOpen(true),
                        },
                      }}
                      disabled={disabled}
                    /> */}
                    <TimeSpent
                      value={ticket.time_spent}
                      update={updateTimeSpent}
                      disabled={disabled}
                    />
                  </Box>

                }
                {
                  port?.config.ticket_displayed_fields.includes("kind") && <TicketKindField update={updateKind} value={ticket?.kind} />
                }

                {ticket.status !== FDK.Constants.TicketConstants.TicketStatus.CLOSED &&
                  ticket.status !== FDK.Constants.TicketConstants.TicketStatus.CANCELLED &&
                  ticket.status !== FDK.Constants.TicketConstants.TicketStatus.ARCHIVED && (
                    <Button
                      onClick={() => {
                        // TODO modal
                        handleStatusClose();
                      }}
                    >
                      {t("form.btnClose")}
                    </Button>
                  )}
              </Stack>
            </Box>
            <Box className="flex w-full flex-grow p-5 bg-fcogrey bg-opacity-40 rounded-xl">
              <Ticketchat />
            </Box>
          </Box>

          {
            (ticket.status === FDK.Constants.TicketConstants.TicketStatus.CLOSED ||
              ticket.status === FDK.Constants.TicketConstants.TicketStatus.CANCELLED ||
              ticket.status === FDK.Constants.TicketConstants.TicketStatus.ARCHIVED) && (
              <Alert
                className="mt-4"
                severity="info"
                action={
                  <Button
                    onClick={() => {
                      handleStatusReOpen();
                    }}
                    color="inherit"
                    size="small"
                  >
                    {t("form.reOpen")}
                  </Button>
                }
              >
                {t("form.closedAt")}
                {dayjs(ticket.date_closed).format(
                  t("dates.date_hour", { ns: "common" })
                )}
              </Alert>
            )
          }
        </>
      )}
    </Paper >
  );
};
