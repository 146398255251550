import { Button, FormControl } from "@mui/material";
import { BaseSingleInputFieldProps, CalendarIcon, DatePicker, DateValidationError, FieldSection, LocalizationProvider, PickersActionBarProps, UseDateFieldProps } from "@mui/x-date-pickers";
import i18n from "../../../../translations/initI18n";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { FC, useState } from "react";
import classNames from "classnames";

interface ButtonFieldProps extends UseDateFieldProps<dayjs.Dayjs>,
  BaseSingleInputFieldProps<
    dayjs.Dayjs | null,
    dayjs.Dayjs,
    FieldSection,
    DateValidationError
  > {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}
export type DateType = "date" | "all" | "week" | "month" | "range" | string;
export type DateSelectionWithActionsChangeValue = {
  dateStart: string
  dateEnd: string
}
type DateSelectionWithActionsProps = {
  type: DateType
  dateStart: string | dayjs.Dayjs
  dateEnd: string | dayjs.Dayjs
  onChange: (type: DateType, value: DateSelectionWithActionsChangeValue) => any
}

export const DateSelectionWithActions: FC<DateSelectionWithActionsProps> = ({ type, onChange, dateStart, dateEnd }) => {
  const { t } = useTranslation("technicalArea")
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const onChangeDateFilter = (type: DateType, dateStart?: dayjs.Dayjs | null, dateEnd?: dayjs.Dayjs | null) => {
    if (!type) return
    let newValue = {
      dateStart: "",
      dateEnd: ""
    }
    switch (type) {
      case "all":
        newValue = {
          ...newValue,
        }
        break;
      case "week":
        newValue = {
          ...newValue,
          dateStart: dayjs().startOf("week").toISOString(),
          dateEnd: dayjs().endOf("week").toISOString(),
        }
        break;
      case "month":
        newValue = {
          ...newValue,
          dateStart: dayjs().startOf("month").toISOString(),
          dateEnd: dayjs().endOf("month").toISOString(),
        }
        break
      case "range":
        newValue = {
          ...newValue,
          dateStart: dateStart?.toISOString() || dayjs().toISOString(),
          dateEnd: dateEnd?.toISOString() || dayjs().toISOString(),
        }
        break
      default:
        newValue = {
          ...newValue,
          dateStart: dateStart?.toISOString() || dayjs().toISOString(),
        }

        break;
    }

    onChange(type, newValue)

    // dispatch(updateFilterWithoutSavingTA({
    //   ...newValue
    // }))

    // dispatch(getTABookingList()).finally(() => {
    //   setShowLoader(false)
    // })
  }

  return <FormControl size="small" fullWidth={true} className="h-full">
    <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs}>
      <DatePicker
        value={dayjs(dateStart)}
        format={t('dates.full', { ns: "common" })}
        open={openDatePicker}
        onClose={() => setOpenDatePicker(false)}
        onOpen={() => setOpenDatePicker(true)}
        slotProps={{
          textField: {
            size: "small"
          },
          field: { setOpen: setOpenDatePicker } as any
        }}
        slots={{
          field: (props: ButtonFieldProps) => {
            const {
              setOpen,
              id,
              disabled,
              InputProps: { ref } = {},
              inputProps: { 'aria-label': ariaLabel } = {},
            } = props;
            return (<Button
              className="h-full"
              variant="contained"
              color="light"
              id={id}
              disabled={disabled}
              ref={ref}
              aria-label={ariaLabel}
              onClick={() => setOpen?.((prev) => !prev)}
              endIcon={<CalendarIcon />}
            >
              {
                type !== "date" && t(`filters.${type}`)
              }
              {
                (type === "date" && dateStart) && dayjs(dateStart).format(t('dates.full', { ns: "common" }))
              }
            </Button>)
          },
          actionBar: (props: PickersActionBarProps) =>
            <div className={classNames(props.className, "p-2 grid grid-cols-2 gap-1")}>
              <Button
                variant={type === "date" && dayjs(dateStart).isSame(dayjs(), "day") ? "contained" : "text"}
                color="secondary"
                onClick={() => props.onSetToday()}>
                {t("filters.today")}
              </Button>
              <Button
                color="secondary"
                variant={type === "date" && dayjs(dateStart).isSame(dayjs().add(1, "day"), "day") ? "contained" : "text"}
                onClick={() => {
                  props.onCancel()
                  onChangeDateFilter("date", dayjs().add(1, "day"))
                }}>
                {t("filters.tomorrow")}
              </Button>
              <Button
                color="secondary"
                variant={type === "week" ? "contained" : "text"}
                onClick={() => {
                  props.onCancel()
                  onChangeDateFilter("week")
                }}> {t("filters.week")}</Button>
              <Button
                color="secondary"
                variant={type === "month" ? "contained" : "text"}
                onClick={() => {
                  props.onCancel()
                  onChangeDateFilter("month")
                }}>{t("filters.month")}</Button>
              <Button
                color="secondary"
                variant={type === "all" ? "contained" : "text"}
                onClick={() => {
                  props.onCancel()
                  onChangeDateFilter("all")
                }}>{t("filters.all")}</Button>

            </div> as any
        }}

        onChange={(value: dayjs.Dayjs | null) => onChangeDateFilter("date", value)}
        label={t("table.filters.date")} />
    </LocalizationProvider>
  </FormControl>
}