import { createAsyncThunk } from "@reduxjs/toolkit";
import FDK from "@wattsonelements/front-fdk";
import { TParams } from "@wattsonelements/front-fdk/dist/models/Misc.models";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";
import { RootState } from "../store";
import dayjs from "dayjs";
import { BOOKING_STATUS } from "@wattsonelements/front-fdk/dist/models/Booking.models";
import { loadPaginatedDatas } from "../../../_helpers/loadPaginatedData.helpers";

export const getStatusListParam = (statusFilters: { [key: string]: boolean }, params: TParams = {}) => {
    const statusList = Object.keys(BOOKING_STATUS).filter(status => statusFilters[status as BOOKING_STATUS] === true).map(
        s => BOOKING_STATUS[s as keyof typeof BOOKING_STATUS]
    )
    if (statusList.length > 1) {
        params.status__in = statusList.join(',')
    } else if (statusList.length === 1) {
        params.status = statusList[0]
    }
    return params
}

export const addBookingDateParam = (type: string, dateStart: string, params: TParams) => {
    switch (type) {
        case "all":
            break;
        case "week":
            params.date__gte = dayjs().startOf("week").format("YYYY-MM-DD")
            params.date__lte = dayjs().endOf("week").format("YYYY-MM-DD")
            break;
        case "month":
            params.date__gte = dayjs().startOf("month").format("YYYY-MM-DD")
            params.date__lte = dayjs().endOf("month").format("YYYY-MM-DD")
            break
        default:
            params.date = dayjs(dateStart).format("YYYY-MM-DD")
            break;
    }
    return params
}

export const getBookingList = createAsyncThunk(
    'crane/booking',
    async (params: TParams | undefined = {}, { getState }) => {
        const { date, dateStart, statusBooking } = (getState() as RootState).crane.filters
        params = addBookingDateParam(date, dateStart, params)
        params = getStatusListParam(statusBooking, params)
        return loadPaginatedDatas((page: number = 1) => FDK.Marina.CraneModule.getBookings({ ...params, page }))
    }
)

export const getPlanningSlotList = createAsyncThunk(
    'crane/planning/list',
    async ({ port, overrideDate }: { port: UUID, overrideDate?: string }, { getState }) => {
        let { date, dateStart, statusPlanning } = (getState() as RootState).crane.filters
        if (date !== "all") {
            dateStart = dayjs(overrideDate ? overrideDate : dateStart).format("YYYY-MM-DD")
        }
        const params = getStatusListParam(statusPlanning)
        return FDK.Marina.CraneModule.getPlanning(dateStart, port, params).then(res => res.data)
    }
)

export const getBookingById = createAsyncThunk(
    'crane/booking/get',
    async (id: UUID) => {
        return FDK.Marina.CraneModule.getBooking(id).then(res => res.data)
    }
)