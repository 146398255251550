import { FC } from "react"
import { ImageLayout } from "../../../../../core/models/portCMS.models"
import { PortSectionContainer } from "./Section.container"
import { useTranslation } from "react-i18next"

type ImageSectionProps = {
  section: ImageLayout
}
export const ImageSection: FC<ImageSectionProps> = ({ section }) => {
  const { t } = useTranslation(["portCMS"]);

  return <PortSectionContainer
    section={section}
    title={t('sections.image.title')}
    editable={false}
  >
    <img className="max-w-[200px] border border-fcogreyvariant rounded" alt="img"
      onError={(e) => {
        // (e.target as HTMLImageElement).onerror = null; // avoid looping
        // (e.target as HTMLImageElement).src = 'https://picsum.photos/100/100'
      }}
      key={section.title + section.id}
      src={section.image} />
  </PortSectionContainer>
}