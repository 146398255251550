import { Skeleton } from "@mui/material";
import FDK from "@wattsonelements/front-fdk";
import { BERTH_STATUS } from "@wattsonelements/front-fdk/dist/constants/berth.constants";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface StatusChipProps {
  status: BERTH_STATUS | undefined;
  emptySince?: string,
  occupiedSince?: string
  isLoading?: boolean
}

export const StatusChip: FC<StatusChipProps> = ({
  status,
  emptySince,
  occupiedSince,
  isLoading = false
}: StatusChipProps) => {
  const { t } = useTranslation("berth");
  const [color, setColor] = useState({ bg: "#5f5f5f", text: "#fff" });
  const [text, setText] = useState<string>(status ? t(`status.${status}`) : "")

  const formatStatus = (date: string | null | undefined) => {
    if (!status) return
    if (!date) {
      setText(t(`status.${status}`))
      return
    }
    let dateDiff = dayjs().diff(dayjs(date), "hour")
    let dateFormat = t("dates.h", { ns: "common" })
    if (dateDiff > 24) {
      dateDiff = dayjs().diff(dayjs(date), "day")
      dateFormat = t("dates.d", { ns: "common" })
    }
    setText(`${t(`status.${status}`)} ${t(`status.since`)} ${dateDiff}${dateFormat} `)
  }

  useEffect(() => {
    setText(status ? t(`status.${status}`) : "")
    switch (status) {
      case FDK.Constants.BerthConstants.BERTH_STATUS.AVAILABILITY_TO_CONFIRM:
      case FDK.Constants.BerthConstants.BERTH_STATUS.MOORING_TO_CONFIRM:
        setColor({ bg: "#be3a2d", text: "#fff" });
        break;
      case FDK.Constants.BerthConstants.BERTH_STATUS.AVAILABLE:
        formatStatus(emptySince)
        setColor({ bg: "#5ab19b", text: "#fff" });
        break;
      case FDK.Constants.BerthConstants.BERTH_STATUS.UNAVAILABLE:
      case FDK.Constants.BerthConstants.BERTH_STATUS.ERROR:
        setColor({ bg: "#5f5f5f", text: "#fff" });
        break;
      case FDK.Constants.BerthConstants.BERTH_STATUS.MOORING_IDDLE:
        setColor({ bg: "#5f5f5f", text: "#fff" });
        formatStatus(occupiedSince)
        break;
      default:
        setText("")
        break;
    }
  }, [status, emptySince, occupiedSince]);

  if (!status) {
    return <></>
  }
  return (
    <BaseStatusChip isLoading={isLoading} text={text} color={color} />
  );
};

type MoteStatusChipProps = {
  emptySince?: string,
  occupiedSince?: string
}
export const MoteStatusChip: FC<MoteStatusChipProps> = ({ emptySince, occupiedSince }) => {
  const { t } = useTranslation("berth");
  const [text, setText] = useState<string>('')

  useEffect(() => {
    let status = ""
    let date = null
    if (emptySince && !occupiedSince) {
      status = "free"
      date = emptySince
    } if (!emptySince && occupiedSince) {
      status = "occupied"
      date = occupiedSince
    } else if (dayjs(emptySince).isAfter(dayjs(occupiedSince))) {
      status = "free"
      date = emptySince
    } else if (dayjs(emptySince).isBefore(dayjs(occupiedSince))) {
      status = "occupied"
      date = occupiedSince
    }
    let minutes = dayjs().diff(dayjs(date), "minutes")
    let dateFormat = t("dates.m", { ns: "common" })
    if (minutes < 60) {
      setText(`${t(`status.mote.${status}`)} ${minutes}${dateFormat} `)
      return
    }

    let hours = dayjs().diff(dayjs(date), "hour")
    dateFormat = hours + t("dates.h", { ns: "common" }) + minutes % 60

    if (hours > 24) {
      let dateDiff = dayjs().diff(dayjs(date), "day")
      dateFormat = dateDiff + t("dates.d", { ns: "common" })
    }
    setText(`${t(`status.mote.${status}`)} ${dateFormat} `)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emptySince, occupiedSince])

  return <BaseStatusChip text={text} />
}

type BaseStatusChipProps = {
  text: string | null | undefined
  color?: { bg: string, text: string }
  isLoading?: boolean
}

export const BaseStatusChip: FC<BaseStatusChipProps> = ({ text, isLoading = false, color = { bg: "#5f5f5f", text: "#fff" } }) => {
  return (
    <div
      style={{ backgroundColor: color.bg, color: color.text }}
      className="rounded-full py-2 px-4"
    >
      {text && !isLoading ? (
        text
      ) : (
        <Skeleton width={150} height={30} variant="text" />
      )}
    </div>
  );
}
