import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import i18n from "../../../../../../translations/initI18n"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { FC, useEffect, useState } from "react"
import { UPDATABLE_FIELD } from "../../../../../../core/models/technicalArea.models"
import { ReadOnlyField } from "../../../../UX/inputs/ReadOnlyField"
import { Tide, getTide } from "../../../../../../core/services/Tide.service"
import { useAppSelector } from "../../../../../../core/store/hooks"
import { TideHint } from "./Tide.hint"

type StartDateTimeProps = {
  readonly?: boolean
  updatableFields?: UPDATABLE_FIELD[]
}

export const StartDateTime: FC<StartDateTimeProps> = ({ readonly, updatableFields = [UPDATABLE_FIELD.START_DATE, UPDATABLE_FIELD.START_TIME] }) => {
  const { t } = useTranslation("technicalArea")
  const { control, getValues, watch } = useFormContext()
  const port = useAppSelector(state => state.ports.current)
  const start_date = watch("start_date")
  const start_time = watch("start_time")

  const getDateTime = () => {
    const time = start_time ? dayjs(start_time) : dayjs().startOf("day")
    const date = dayjs(start_date).hour(time.hour()).minute(time.minute())
    return date
  }

  const [list, setList] = useState<Tide[]>([])

  useEffect(() => {
    if (!port || !start_date) return
    const date = getDateTime()
    getTide(port.id, date).then(res => {
      setList(res.data.tide_forecast)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_date, port])
  if (readonly) {
    return <>
      <ReadOnlyField className="col-start-1" label={t("form.dateStart")} value={dayjs(getValues("start_date")).format(t('dates.full', { ns: "common" }))} />
      <ReadOnlyField label={t("form.hourStart")} value={dayjs(getValues("start_time")).format(t('dates.hour', { ns: "common" }))} />
      <TideHint date={getDateTime()} className="col-start-1 col-span-full" tides={list} />

    </>
  }
  return <>
    <Controller
      name="start_date"

      control={control}
      rules={{ required: true }}
      render={({ field, fieldState, formState }) => (
        <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs}>
          <DatePicker
            className="col-start-1"
            value={dayjs(field.value)}
            format={t('dates.full', { ns: "common" })}
            onChange={field.onChange}
            disabled={!updatableFields.includes(UPDATABLE_FIELD.START_DATE)}
            slotProps={
              {
                textField: {
                  ref: field.ref,
                  onBlur: field.onBlur,

                  error: !!fieldState.error
                }
              }
            }
            label={t("form.dateStart")} />
        </LocalizationProvider>
      )}
    />
    <Controller
      name="start_time"
      control={control}
      rules={{ required: true }}
      render={({ field, fieldState, formState }) => (
        <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs}>
          <TimePicker
            disabled={!updatableFields.includes(UPDATABLE_FIELD.START_TIME)}
            value={field.value ? dayjs(field.value) : null}
            ampm={t('dates.ampm', { ns: "common" })}
            format={t('dates.hour', { ns: "common" })}
            onChange={field.onChange}
            slotProps={
              {
                textField: {
                  ref: field.ref,
                  onBlur: field.onBlur,
                  error: !!fieldState.error
                }
              }
            }
            label={t("form.hourStart")} />
        </LocalizationProvider>
      )}
    />

    <TideHint date={getDateTime()} className="col-start-1 col-span-full" tides={list} />
  </>
}