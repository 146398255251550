import { createSlice } from "@reduxjs/toolkit"
import { getBookingById, getBookingList, getPlanningSlotList } from "../actions/Crane.actions"
import { BOOKING_STATUS, IBooking, IPlaningAvailability } from "@wattsonelements/front-fdk/dist/models/Booking.models"
import dayjs from "dayjs"
import { CraneState } from "../../models/module.models"

const initialState = {
  bookingList: null,
  bookingListLoading: true,
  planningListLoading: true,
  planning: null,
  planningDate: dayjs().hour(0).minute(0).toISOString(),
  selectedBooking: null,
  selectedPlanningSlot: null,
  filters: {
    text: "",
    statusPlanning: {
      AVAILABLE: true,
      UNAVAILABLE: true,
      BOOKED: true,
      CANCELLED: true,
      DONE: true,
      MISSED: true,
    },
    statusBooking: {
      BOOKED: true,
      CANCELLED: true,
      MISSED: true,
    },
    date: "date",
    dateStart: dayjs().toISOString(),
    dateEnd: "",

  },
} as CraneState

export const Crane = createSlice(
  {
    name: "Crane",
    initialState,
    reducers: {
      setSelectedBooking: (state, { payload }) => {
        state.selectedBooking = payload
      },
      updateFilterWithoutSaving: (state, { payload }) => {
        state.filters = { ...state.filters, ...payload };
      },
      cancelBooking: (state, { payload: id }) => {
        state.bookingList = state.bookingList?.map(b => {
          if (b.id === id) {
            b.status = BOOKING_STATUS.CANCELLED
          }
          return b
        }) || null
        if (state.selectedBooking && state.selectedBooking?.id === id) {
          state.selectedBooking.status = BOOKING_STATUS.CANCELLED
        }
      },
      doneBooking: (state, { payload: id }) => {
        state.bookingList = state.bookingList?.map(b => {
          if (b.id === id) {
            b.status = BOOKING_STATUS.DONE
          }
          return b
        }) || null
        if (state.selectedBooking && state.selectedBooking?.id === id) {
          state.selectedBooking.status = BOOKING_STATUS.DONE
        }
      },
      setPlanningListLoading: (state, { payload }) => {
        state.planningListLoading = payload
      },
      setBookingListLoading: (state, { payload }) => {
        state.bookingListLoading = payload
      },
      updateComment: (state, { payload }) => {
        state.bookingList = state.bookingList?.map(b => {
          if (b.id === payload.id) {
            b.comment = payload.comment
          }
          return b
        }) || null
        if (state.selectedBooking && state.selectedBooking?.id === payload.id) {
          state.selectedBooking.comment = payload.comment
        }
      }
    },
    extraReducers: (builder) => {
      builder.addCase(getBookingList.fulfilled, (state, { payload }) => {
        state.bookingListLoading = false
        state.bookingList = payload
      })
        .addCase(getPlanningSlotList.fulfilled, (state, { payload }) => {
          state.planningListLoading = false
          state.planning = payload
        })
        .addCase(getBookingById.fulfilled, (state, { payload }) => {
          state.selectedBooking = payload
        })
    }
  }
)

export default Crane.reducer;
export const {
  setSelectedBooking,
  setBookingListLoading,
  setPlanningListLoading,
  cancelBooking,
  doneBooking,
  updateComment,
  updateFilterWithoutSaving
} = Crane.actions;