import { FC } from "react"
import { ImagesTableLayout } from "../../../../../core/models/portCMS.models"
import { PortSectionContainer } from "./Section.container"
import { useTranslation } from "react-i18next"
import { Box } from "@mui/material"

type ImageTableSectionProps = {
  section: ImagesTableLayout
}
export const ImageTableSection: FC<ImageTableSectionProps> = ({ section }) => {
  const { t } = useTranslation(["portCMS"]);

  return <PortSectionContainer editable={false} section={section} title={t('sections.imageTable.title')}>
    <Box className="flex flex-wrap gap-2">
      {
        section?.table?.map(img => <img alt="img" onError={(e) => {
          // (e.target as HTMLImageElement).onerror = null; // avoid looping
          // (e.target as HTMLImageElement).src = 'https://picsum.photos/100/100'
        }}
          key={section.title + img.id}
          src={img.image} />)
      }
    </Box>
  </PortSectionContainer>
}