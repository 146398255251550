import { Close, LocalPrintshop } from "@mui/icons-material"
import { Box, Button, Modal } from "@mui/material"
import { FC, ReactNode, useState } from "react"
import { useTranslation } from "react-i18next"

type FormModalProps = {
  children: ReactNode,
  title: string,
  printTitle: string,
  open: boolean,
  setOpen: (open: boolean) => void
}
export const FormModal: FC<FormModalProps> = ({ children, title, printTitle, open, setOpen }) => {
  const { t } = useTranslation("technicalArea")

  return <Modal
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

    }} open={open}>
    <>

      <Box className="
     bg-white rounded-md
       shadow-md w-auto 
     lg:w-2/5 max-h-[80vh] md:w-2/3 
     overflow-hidden
     relative
     print:max-h-[1000px]
     print:shadow-none
     print:w-full 
     print:pt-0">
        <Box className="bg-white z-10 absolute w-full pt-7 px-14 top-0 right-0">
          <Box className="print:hidden flex justify-end ">
            <Button
              // disabled={!isValid || isSending}
              onClick={() => {
                window.print()
              }}>
              <LocalPrintshop />
            </Button>
            <Button
              onClick={() => setOpen(false)}>
              <Close />
            </Button>
          </Box>
          <h2 className="print:hidden font-bold text-xl mb-6 mt-1">{title}</h2>
          <h2 className="hidden print:block font-bold text-xl mb-6 mt-1">{printTitle}</h2>
        </Box>
        <Box className="print:table-row-group relative min-h-min pt-20">

          {/* <TACreationForm onClose={() => { setOpenCreate(false) }} /> */}
          <Box className="overflow-auto max-h-[75vh] p-14">
            {children}
          </Box>

        </Box>
      </Box>
    </>
  </Modal>
}