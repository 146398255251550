import { Button, Chip, CircularProgress, Box, Typography, Paper } from "@mui/material"
import { IPlugDetail, IPlugGraph } from "@wattsonelements/front-fdk/dist/models/Electricity.models"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import { FC, useEffect, useState } from "react"
import { Line } from "react-chartjs-2"
import { ChartOptions } from "chart.js";
import annotationPlugin from 'chartjs-plugin-annotation';
import { useAppSelector } from "../../../../../../core/store/hooks";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  annotationPlugin
);


type PlugConsographsProps = {
  stats: IPlugDetail["stats"],
  defaultSelectedSlug: string
}

export const PlugConsographs: FC<PlugConsographsProps> = ({ stats, defaultSelectedSlug }) => {
  const [selectedGraph, setSelectedGraph] = useState<IPlugDetail["stats"][0] | null>(null)
  const { t } = useTranslation("electricity");

  const [graphData, setGraphData] = useState<any>() // chart data
  const [graphOptions, setGraphOptions] = useState<ChartOptions<"line"> | null>()
  const plug = useAppSelector(state => state.electricity.selected)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const getGraph = async (stat: IPlugDetail["stats"][0]) => {
    setLoading(true)
    setSelectedGraph(stat)
    setError(false)
    setGraphData(null)
    setGraphOptions(null)
    // const start = dayjs()
    // getPlugGraph(stat.url).then(async res => {
    // setLoading(false)
    const graph = stat.graph
    if (graph.data && graph.data.length === 0) {
      setGraphData(null)
      setGraphOptions(null)
      setLoading(false)
      return
    }

    const data = {
      labels: graph.labels,
      datasets: [
        {
          label: graph.title,
          data: graph.data,
          backgroundColor: "#027db4",
          borderColor: "#027db4",
        }
      ]
    }
    const options: ChartOptions<"line"> = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          suggestedMax: graph.suggestedMax,
          title: {
            text: graph.yAxisLabel,
            display: true
          }
        },
        x: {
          title: {
            text: graph.xAxisLabel,
            display: true,
          }
        }
      },
      plugins: {
        legend: {
          position: "top",
          display: false,
        },
        // annotation: { // seuil plugin 
        //   annotations: {
        //     line1: {
        //       label: {
        //         content: "seuil",
        //         display: true
        //       },
        //       type: 'line',
        //       yMin: 5,
        //       yMax: 5,
        //       borderColor: 'rgb(255, 99, 132)',
        //       borderWidth: 2,
        //     }
        //   }
        // }
      },
    }

    setGraphData(data)
    setGraphOptions(options)
    setLoading(false)
    // }, err => {
    //   setError(true)
    // }).finally(async () => {
    //   const delta = dayjs().diff(start)
    //   if (delta) {
    //     await new Promise(resolve => setTimeout(resolve, 800 - delta))
    //   }
    //   setLoading(false)
    // })
  }

  useEffect(() => {
    if (!stats || stats.length === 0) return
    if (selectedGraph) return
    if (!defaultSelectedSlug) {
      getGraph(stats[0])
    }
    const tmpStat = stats.find(s => s.slug === defaultSelectedSlug)
    if (tmpStat) {
      getGraph(tmpStat)
    }
  }, [defaultSelectedSlug])


  return (
    <Box className="m-4 mb-10">
      <Typography className="flex gap-2" variant="h5">
        {t('detail.consumption')}{" "}
        {/* TODO ELEC */}
        {(!!plug?.global_consumption.now && plug?.in_use) &&
          <Chip variant="outlined" color="secondary" label={`${t("detail.ongoing")} ${plug?.global_consumption.now?.toFixed(2)} ${plug?.global_consumption.unit} `} />
        }
      </Typography>
      <Box className="flex flex-row-reverse gap-5 justify-center items-center">
        <Box className="flex flex-col my-3 min-w-[150px] self-start gap-3">
          {
            stats.map((stat: IPlugDetail["stats"][0]) => {
              return (
                <Button
                  key={stat.slug}
                  onClick={() => {
                    getGraph(stat)
                  }}
                  color={
                    selectedGraph?.slug === stat.slug ? "secondary" : "primary"
                  }
                  variant={
                    selectedGraph?.slug === stat.slug ? "contained" : "outlined"
                  }
                  className="flex flex-col flex-1 justify-center items-center">
                  {stat.title}

                  <p className="font-bold text-lg">
                    {stat.sum_value?.toFixed(2)}
                    {stat.unit}
                  </p>

                  {
                    stat.cost &&
                    <span>
                      {stat.cost}
                      {stat.currency}
                    </span>
                  }
                </Button>
              )
            })
          }
        </Box>
        <Box className="min-h-[100px] flex-auto">
          {
            graphOptions && graphData &&
            <Box className="min-h-[400px] max-h-[400px]">
              <Line options={graphOptions} data={graphData} />
            </Box>
          }

          {
            !error && loading &&
            <Box className="text-fcosecondary gap-2 flex flex-col items-center justify-center pt-5" >
              <CircularProgress color="secondary" />
              {t('detail.graph.loading')}
            </Box>
          }
          {
            !error && !!loading && !graphOptions || !graphData &&
            <Paper variant="outlined" className="text-center p-4 mt-2 text-fcogrey">
              {t("detail.graph.noData")}
            </Paper>
          }
          {
            error &&
            <Box className="p-3 flex flex-col justify-center items-center gap-3 rounded text-fcoerror border-[1px] border-fcoerror" >
              <>
                {t('detail.graph.error')}
                <Button onClick={() => selectedGraph && getGraph(selectedGraph)} variant="contained" color="error">
                  {
                    loading ? <CircularProgress /> : <>{t('detail.graph.retry')}</>
                  }

                </Button>
              </>
            </Box>
          }

        </Box>
      </Box>
    </Box>
  )
}