import dayjs from "dayjs"
import { FC, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Box, Button, CircularProgress } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../../../core/store/hooks"
import { acceptTABookingDetail, getTABookingDetail, updateTABookingDetail } from "../../../../../core/store/actions/TechnicalArea.actions"
import { TABooking, TABookingEdit, TA_BOOKING_STATUS, UPDATABLE_FIELD } from "../../../../../core/models/technicalArea.models"
import { getDateObjectFromTime } from "../../../../../_helpers/dateTime.helper"
import { BoatBoaterSelection } from "../../../UX/inputs/BoatBoater.select"
import { StartDateTime } from "./form/StartDatetime"
import { EndDateTime } from "./form/EndDatetime"
import { ServicesSelect } from "./form/Services.select"
import { ReadOnlyField } from "../../../UX/inputs/ReadOnlyField"
import { updateItemInList, updateSelectedBooking } from "../../../../../core/store/reducers/TechnicalArea.reducer"
import { CGUField } from "./form/CGU.field"
import { PrintSignatureBox } from "./form/SignatureBox.print"
import { TADuration } from "./form/Duration"
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models"
import MainAPI from "@wattsonelements/front-fdk/dist/api"

type TAEditFormProps = {
  onClose?: () => any
  booking: TABooking
}

export const TAEditForm: FC<TAEditFormProps> = ({ booking, onClose = () => { } }) => {
  const { t } = useTranslation("technicalArea")
  const dispatch = useAppDispatch()
  const port = useAppSelector(state => state.ports.current)

  const formMethods = useForm({
    defaultValues: {
      start_date: dayjs(booking.start_date).format(),
      start_time: booking.start_time ? getDateObjectFromTime(booking.start_time).toISOString() : null,
      end_date: dayjs(booking.end_date).format(),
      end_time: booking.end_time ? getDateObjectFromTime(booking.end_time).toISOString() : null,
      services: booking.services,
      boater: booking.boater,
      boat: booking.boat,
      update_request_validated: false,
      marina_comment: booking.marina_comment,
      update_request_terms_and_conditions_upload: null
    },
  })
  const { control, handleSubmit, watch, formState: { isValid, isDirty } } = formMethods

  const [isSending, setIsSending] = useState(false)

  const start_date = watch("start_date")
  const start_time = watch("start_time")
  const end_date = watch("end_date")
  const end_time = watch("end_time")

  const send = (id: UUID | string, data: Partial<TABooking> | FormData) => {
    if (booking.status === TA_BOOKING_STATUS.REQUESTED) {
      acceptTABookingDetail(id, data).then(res => {
        dispatch(getTABookingDetail(id)).then(res => {
          if (!res.type.includes("rejected")) {
            dispatch(updateItemInList(res.payload))
          }
          onClose()
        }).finally(() => {
          setIsSending(false)
        })
      })
    } else {
      updateTABookingDetail(id, data).then(res => {
        dispatch(updateItemInList(res.data))
        dispatch(updateSelectedBooking(res.data))
        onClose()
      }).finally(() => {
        setIsSending(false)
      })
    }
  }

  const submit = (data: any) => {
    const updatedBooking: TABookingEdit = {
      start_date: dayjs(data.start_date).format("YYYY-MM-DD"),
      start_time: dayjs(data.start_time).format("HH:mm"),
      end_date: dayjs(data.end_date).format("YYYY-MM-DD"),
      end_time: dayjs(data.end_time).format("HH:mm"),
      marina_comment: data.marina_comment,
      services: data.services.map((s: TABooking["services"][0]) => s.id),
      update_request_validated: data.update_request_validated,
      update_request_terms_and_conditions_upload: data.update_request_terms_and_conditions_upload
    }
    if (!updatedBooking.update_request_terms_and_conditions_upload) {
      delete updatedBooking.update_request_terms_and_conditions_upload
    }
    const getFormData = (object: any) => Object.keys(object).reduce((formData, key: string) => {
      if (!booking.updatable_fields?.includes(key as UPDATABLE_FIELD) &&
        !["update_request_validated", "update_request_terms_and_conditions_upload"].includes(key)) {
        return formData
      }
      if (Array.isArray(object[key])) {
        for (var i = 0; i < object[key].length; i++) {
          formData.append(`${key}`, object[key][i]);
        }
        return formData;
      }
      formData.append(key, object[key]);
      return formData;
    }, new FormData());
    const formData = getFormData(updatedBooking)

    setIsSending(true)

    send(booking.id, formData)
  }

  return (
    <Box className="print:table-row-group">
      <FormProvider {...formMethods}>
        <form
          id="technical-area-form-creation"
          onSubmit={handleSubmit(submit)}
          className="grid grid-cols-2 gap-6"
        >
          <BoatBoaterSelection
            boatRequest={(data) => MainAPI.API.request({
              method: "GET",
              url: "/v2/marina/technical-areas/boats",
              params: { ...data, port_id: port?.id }
            })}
            boat={booking.boat}
            boater={booking.boater}
            readonly={true} />
          <Box className="col-span-2 grid grid-cols-3 gap-x-6 gap-y-4">
            <StartDateTime
              readonly={!booking.updatable_fields?.includes(UPDATABLE_FIELD.START_DATE) && !booking.updatable_fields?.includes(UPDATABLE_FIELD.START_TIME)}
              updatableFields={booking.updatable_fields} />
            <EndDateTime
              readonly={!booking.updatable_fields?.includes(UPDATABLE_FIELD.END_DATE) && !booking.updatable_fields?.includes(UPDATABLE_FIELD.END_TIME)}
              updatableFields={booking.updatable_fields} />
          </Box>

          <TADuration
            className="col-span-2 text-sm -mt-6 py-3"
            dateEnd={end_date}
            timeEnd={end_time}
            dateStart={start_date}
            timeStart={start_time} />

          <ServicesSelect
            servicesStates={booking.booking_services}
            disabled={!(booking && booking.updatable_fields?.includes(UPDATABLE_FIELD.SERVICES))}
          />
          {
            booking.boater_comment && <ReadOnlyField className="col-span-2" label={t("form.boaterComment")} value={booking.boater_comment} />
          }{
            booking.marina_comment && <ReadOnlyField className="col-span-2" label={t("form.description")} value={booking.boater_comment} />
          }
          <CGUField
            disabled={isSending}
            cguAcceptedFieldName="update_request_validated"
            cguUploadFieldName="update_request_terms_and_conditions_upload"
          />
          <PrintSignatureBox />

          <Box className="print:hidden col-span-2 pt-3 flex flex-col gap-2">
            <Button disabled={!isValid || isSending} className="w-full" color="secondary" variant="contained" type="submit">
              {!isSending && t('form.actions.save')}
              {
                isSending && <div className="flex gap-2 items-center">
                  {t('form.actions.saving')}
                  <CircularProgress size={20} />
                </div>
              }

            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  )
}