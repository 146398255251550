import { Add, CloseRounded } from "@mui/icons-material";
import { Box, Button, TextField } from "@mui/material"
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TableLayout } from "../../../../../../core/models/portCMS.models";
import { Control, FieldValues, useFormState } from "react-hook-form";

type TableSectionRowProps = {
  onAdd?: () => any,
  onDelete?: () => any,
  disabledAdd?: boolean;
  row?: { label: string; value: string }
  index: number
  control: Control<TableLayout, any>
}

export const TableSectionRow: FC<TableSectionRowProps> = ({ onAdd, onDelete, index, row, control, disabledAdd = false }) => {
  const { t } = useTranslation(["portCMS"]);
  const { register, getFieldState } = control
  return (
    <Box className="grid grid-cols-3 gap-3 px-6 py-3 border-slate-300 border">
      <TextField
        {...register(`table.${index}.label`, { required: true, maxLength: 20 })}
        error={!!getFieldState(`table.${index}.label`).error}
        label={t("sections.table.form.label")} />
      <TextField
        {...register(`table.${index}.value`, { required: true, maxLength: 20 })}
        error={!!getFieldState(`table.${index}.value`).error}
        label={t("sections.table.form.value")} />
      <Box className="flex justify-between">
        {
          onDelete && (
            <Button onClick={() => onDelete()}>
              <CloseRounded />
            </Button>
          )
        }
        {
          onAdd && (
            <Button
              disabled={disabledAdd}
              onClick={onAdd}>
              <Add />
            </Button>
          )
        }

      </Box>
    </Box>
  )
}