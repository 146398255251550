import { createSlice } from "@reduxjs/toolkit";
import { IPort } from "@wattsonelements/front-fdk/dist/models/Port.models";
import { addSection, getArea, getPortSections, getSection, getUserPort, moveSection, updateArea, updateSection } from "../actions/Port.actions";
import { AreaLayout, LAYOUT_NAME, Layout } from "../../models/portCMS.models";

export interface PortState {
  current: IPort | null;
  sections: Layout[],
  sectionsStatus: 'loading' | 'error' | 'loaded'
  // areaSelected: string;
}
const initialState = {
  current: null,
  sections: [],
  sectionsStatus: 'loading'
  // areaSelected: "",
} as PortState;

export const Port = createSlice({
  name: "Port",
  initialState,
  reducers: {
    // changeArea: (state, { payload }) => {
    //   state.areaSelected = payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserPort.pending, (state, { payload }) => { })
      .addCase(getUserPort.fulfilled, (state, { payload }) => {
        state.current = payload;
      })
      .addCase(getUserPort.rejected, (state, { payload }) => { })
      .addCase(getPortSections.pending, (state, { payload }) => {
        state.sectionsStatus = "loading"
      })
      .addCase(getPortSections.fulfilled, (state, { payload }) => {
        state.sections = payload
        state.sectionsStatus = "loaded"
      })
      .addCase(getPortSections.rejected, (state, { payload }) => {
        state.sectionsStatus = "error"
      })
      .addCase(updateArea.pending, (state, { payload }) => { })
      .addCase(updateArea.fulfilled, (state, { payload }) => {
      })
      .addCase(updateArea.rejected, (state, { payload }) => { })
      .addCase(updateSection.pending, (state, { payload }) => { })
      .addCase(updateSection.fulfilled, (state, { payload }) => {
        state.sections = [...state.sections].map(s => {
          if (s.id === payload.id) return { ...s, ...payload }
          return s
        })
      })
      .addCase(updateSection.rejected, (state, { payload }) => { })
      .addCase(addSection.fulfilled, (state, { payload }) => {
        state.sections.push(payload)
      })
      .addCase(moveSection.pending, (state, { payload }) => { })
      .addCase(moveSection.fulfilled, (state, { payload }) => {
        state.sections = payload
      })
      .addCase(moveSection.rejected, (state, { payload }) => { })
      .addCase(getSection.pending, (state, { payload }) => { })
      .addCase(getSection.fulfilled, (state, { payload }) => {
      })
      .addCase(getSection.rejected, (state, { payload }) => { })
      .addCase(getArea.pending, (state, { payload }) => { })
      .addCase(getArea.fulfilled, (state, { payload }) => {
        const arealayout = state.sections.find(s => s.layout === LAYOUT_NAME.AREA && s.area.id === payload.id)
        if (arealayout) {
          (arealayout as AreaLayout).area = { ...(arealayout as AreaLayout).area, ...payload }
        }
      })
      .addCase(getArea.rejected, (state, { payload }) => { })
  },
});

export default Port.reducer;
// export const { changeArea } = Port.actions;
