import { Box } from "@mui/material"
import { PlanningTable } from "./PlaningTable.element"
import { DashboardLayout } from "../../../../layouts/Dashboard.layout"
import { Outlet, useParams } from "react-router-dom"
import { PlanningForm } from "./Planning.form"
import { UUID } from "crypto"

export const PlanningPage = () => {
  const { reservationId } = useParams<{ reservationId: UUID }>();

  return (<DashboardLayout>
    <Box className="planning-content flex-col flex flex-1 max-w-[50%]" >
      <PlanningTable />
    </Box>
    <Box className="planning-detail flex-col flex flex-1 max-w-[50%]" >
      {/* <Box className="flex items-center gap-2 justify-end w-full px-3 py-4 bg-fcomain MuiBox-root css-0">
                <Button variant="contained" color="secondary">Ajouter des horaires</Button>
            </Box> */}
      {!reservationId &&
        <Box >
          <PlanningForm />
        </Box>
      }

      <Outlet></Outlet>
    </Box>
  </DashboardLayout>)
}