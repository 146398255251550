import { createSlice } from "@reduxjs/toolkit";

import { applyFiltersFromStorage, applyFiltersToStorage, FilterType } from "../../../_helpers/sort.helpers";
import { BerthState } from "../../models/module.models";
import { getBerthDetail, getBerthsList, getBerthStats } from "../actions/Berth.actions";
import { BERTH_STATUS } from "@wattsonelements/front-fdk/dist/constants/berth.constants";

const initialState = applyFiltersFromStorage(
  {
    list: [],
    sort: {
      order: undefined,
      orderBy: undefined,
    },
    filters: {
      text: "",
      status: {
        [BERTH_STATUS.AVAILABLE]: true,
        [BERTH_STATUS.UNAVAILABLE]: true,
        [BERTH_STATUS.MOORING_IDDLE]: true,
        [BERTH_STATUS.MOORING_TO_CONFIRM]: true,
        [BERTH_STATUS.AVAILABILITY_TO_CONFIRM]: true,
        [BERTH_STATUS.ERROR]: true,
      },
    },
    selected: null,
    loading: false,
    listStatus: "",
    legend: [],
    selectedStatus: "",
    firstLoading: true,
    config: {
      status: {
        available: true,
        last_update: new Date().toISOString(),
        error: {
          message: "",
          code: 200000,
        },
      },
      config: {
        enable: true,
        refresh_delay: 60, // secondes
        reporting: true,
      },
    },
  },
  FilterType.berth
) as BerthState;

export const Berth = createSlice({
  name: "Berth",
  initialState,
  reducers: {
    setFirstLoading: (state, { payload }) => {
      state.firstLoading = payload;
    },
    changeSort: (state, { payload }) => {
      state.sort = { ...state.sort, ...payload };
      applyFiltersToStorage<BerthState>(state, FilterType.berth);
    },
    updateFilterWithoutSaving: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload };
      console.log("DATE", { ...state.filters, ...payload });
      
    },
    updateFilter: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload };
      applyFiltersToStorage<BerthState>(state, FilterType.berth);
    },
    setSelected: (state, { payload }) => {
      state.selected = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBerthsList.pending, (state, { payload }) => {})
      .addCase(getBerthsList.fulfilled, (state, { payload }) => {
        state.list = payload;
        state.firstLoading = false;
      })
      .addCase(getBerthsList.rejected, (state, { payload }) => {})
      .addCase(getBerthDetail.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getBerthDetail.fulfilled, (state, { payload }) => {
        state.selected = payload;
        state.loading = false;
      })
      .addCase(getBerthDetail.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(getBerthStats.pending, (state, { payload }) => {})
      .addCase(getBerthStats.fulfilled, (state, { payload }) => {
        state.stats = payload;
      })
      .addCase(getBerthStats.rejected, (state, { payload }) => {});
  },
});

export default Berth.reducer;
export const {
  changeSort,
  updateFilter,
  setSelected,
  setFirstLoading,
  updateFilterWithoutSaving,
} = Berth.actions;
