import { Edit } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
    Avatar,
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
} from "@mui/material";
import { IUser, IUserMarina } from "@wattsonelements/front-fdk/dist/models/User.models";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../../core/store/hooks";


interface AgentSelectorProps {
    agent?: Partial<IUser | IUserMarina> | null;
    agents?: Partial<IUser | IUserMarina>[] | null;
    displayAllAssignees?: boolean;
    canEdit?: boolean;
    multiple?: boolean
    displayLabel?: boolean;
    onChange?: (assignee: Partial<IUser | IUserMarina> | Partial<IUser | IUserMarina>[] | null) => void;
    onOpen?: (isOpen: boolean) => void;
}
// TODO : condensed avatar version 
export const AgentSelector: FC<AgentSelectorProps> = ({
    canEdit = true,
    displayLabel = false,
    agent = null,
    multiple = false,
    agents = null,
    onChange = () => { },
    onOpen = () => { },
}) => {
    const { t } = useTranslation("common");
    const users = useAppSelector(state => state.users.list);
    const [assigned, setAssigned] = useState<Partial<IUser | IUserMarina> | null>(agent);
    const [assignedList, setAssignedList] = useState<Partial<IUser | IUserMarina>[]>(agents || []);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    useEffect(() => {
        onOpen(open);
    }, [onOpen, open]);

    // useEffect(() => {
    //     setAssigned(agent)
    // }, [agent])

    useEffect(() => {
        setAssignedList(agents || [])
    }, [agents, agents?.length])

    return (
        <Box
            onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
            }}
            className="flex items-center justify-center min-w-[175px]"
        >
            {assigned && (
                <Tooltip
                    key={`user-tooltip-${assigned?.id}`}
                    placement="top"
                    title={assigned?.first_name + " " + assigned?.last_name}
                >
                    <Avatar key={assigned?.id} src={assigned?.picture} />
                </Tooltip>
            )}
            {
                assignedList?.map(user => <Tooltip
                    key={`user-tooltip-${user?.id}`}
                    placement="top"
                    title={user?.first_name + " " + user?.last_name}
                >
                    <Avatar key={user?.id} src={user?.picture} />
                </Tooltip>)
            }
            <Menu
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => {
                    setAnchorEl(null);
                    setOpen(false);
                }}
                PaperProps={{
                    elevation: 0,
                    style: {
                        maxHeight: 300,
                    },
                    sx: {
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
            >
                <Box className="bg-fcomain py-2 font-bold text-white pl-4 -mt-2">
                    {t("assignTo")}
                </Box>
                {users.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                        <FormControlLabel
                            className="w-full"
                            control={
                                <Checkbox
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {


                                        if (multiple) {
                                            const currentlyChecked =
                                                assignedList.findIndex((a) => a.id === user.id) > -1;
                                            const checked = e.target.checked;
                                            let tmpAssigned: Partial<IUser | IUserMarina>[] = [];
                                            if (!currentlyChecked && checked) {
                                                tmpAssigned = [...assignedList, user];
                                            } else if (!checked && currentlyChecked) {
                                                tmpAssigned = assignedList.filter((a) => a.id !== user.id);
                                            }
                                            // const assignee = tmpAssigned.map((u) => ({ agent: u.id }));
                                            setAssignedList(tmpAssigned);
                                            onChange(tmpAssigned);
                                        } else {
                                            const currentlyChecked = assigned?.id === user.id
                                            const checked = e.target.checked;
                                            setAssigned(checked ? user : null);
                                            onChange(checked ? user : null);
                                        }
                                    }}
                                    checked={multiple ? (assignedList.findIndex((a) => a.id === user.id) > -1) : assigned?.id === user.id}
                                />
                            }
                            label={
                                <Box className="flex gap-2 justify-center items-center">
                                    <Avatar src={user.picture} />
                                    <ListItemText primary={user?.first_name} />
                                </Box>
                            }
                        />
                    </MenuItem>
                ))}
            </Menu>
            {/* </Popper> */}
            {canEdit && (
                <IconButton
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.stopPropagation();
                        setOpen(!open);
                        setAnchorEl(e.currentTarget);
                    }}
                    color="secondary"
                    aria-label="update assignees list"
                >
                    {!assigned ? (
                        <AddIcon color="secondary" />
                    ) : (
                        <Edit color="secondary" />
                    )}
                </IconButton>
            )}
        </Box>
    );
};
