import { Alert, Box, Button, Divider, Modal, Paper, Step, StepButton, Stepper, Typography } from "@mui/material"
import { FC, useState } from "react"
import { LAYOUT_NAME } from "../../../../../core/models/portCMS.models";
import { SectionNameI18n } from "./Sections.list";
import { useTranslation } from "react-i18next";
import { TableForm } from "./Table/Table.form";
import { FormProvider, useForm } from "react-hook-form";
import { Add } from "@mui/icons-material";

type AddSectionModalProps = {
  open: boolean,
  setOpen: (open: boolean) => any
}

const steps = ['addModal.steps.0', 'addModal.steps.1'];
enum STEPS {
  LAYOUT_CHOICE,
  FORM
}
export const AddSectionModal: FC<AddSectionModalProps> = ({ open, setOpen }) => {
  const { t } = useTranslation(["portCMS"]);
  const methods = useForm()

  const [activeStep, setActiveStep] = useState(STEPS.LAYOUT_CHOICE);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});
  const [layout, setLayout] = useState<LAYOUT_NAME>()

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  }

  return (
    <Modal
      open={open}
      onClose={(event: object, reason: string) => {
        if (reason === "backdropClick") {
          setOpen(false)
        }
        setActiveStep(STEPS.LAYOUT_CHOICE)
      }}
    >
      <Paper
        className="absolute top-1/2 left-1/2 
        transform -translate-x-1/2 -translate-y-1/2 p-4 
        shadow-md w-auto md:w-1/2 min-h-[50vh]">
        <Typography className="pb-8 text-center" variant="h4">{t('addModal.title')}</Typography>

        <Box className="max-w-sm m-auto text-center">
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {t(label)}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box className="p-5">
          {
            activeStep === STEPS.LAYOUT_CHOICE &&
            <Box className="flex flex-col gap-3">
              <Button
                startIcon={<Add />}
                onClick={() => {
                  setLayout(LAYOUT_NAME.TABLE)
                  setActiveStep(STEPS.FORM)
                  methods.reset({
                    layout: LAYOUT_NAME.TABLE,
                    table: [
                      { label: "", value: "", layout: LAYOUT_NAME.LABEL_VALUE }
                    ]
                  })
                }}
                variant="outlined"
              >{t(SectionNameI18n[LAYOUT_NAME.TABLE])}</Button>
              <Alert severity="info">Seul les tableaux sont ajoutables pour le moment</Alert>
            </Box>
          }
          {
            activeStep === STEPS.FORM && layout &&
            <Box className='py-4'>
              <Paper elevation={5} className="p-3 mb-8">
                <Typography variant='h6'>{t(SectionNameI18n[layout as keyof typeof SectionNameI18n])}</Typography>
                <Typography variant='subtitle1'>{t('sections.table.subtitle')}</Typography>

              </Paper>
              {/* <Box className="my-4">
                <Divider />
              </Box> */}
              <FormProvider {...methods}>
                <TableForm
                  onSubmit={(type) => {
                    if (type === "success") {
                      setOpen(false)
                      methods.reset({
                        layout: LAYOUT_NAME.TABLE,
                        table: [
                          { label: "", value: "", layout: LAYOUT_NAME.LABEL_VALUE }
                        ]
                      })
                    }
                  }}
                  section={{
                    layout: LAYOUT_NAME.TABLE,
                    table: [
                      { label: "", value: "", layout: LAYOUT_NAME.LABEL_VALUE }
                    ]
                  }} />
              </FormProvider>
            </Box>
          }
        </Box>

      </Paper>
    </Modal>
  )
}