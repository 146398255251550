import { Box, Skeleton, Tooltip } from "@mui/material"
import { FC, forwardRef, useEffect, useState } from "react"
import { Tide, getTideForDateTime } from "../../../../../../core/services/Tide.service"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import HelpIcon from '@mui/icons-material/Help';

type TideHintProps = {
  date?: string | dayjs.Dayjs
  tides?: Tide[],
  className?: string
}
export const TideHint: FC<TideHintProps> = ({ tides, date, className }) => {
  const { t } = useTranslation("common")

  const [tide, setTide] = useState<Tide | null>()
  useEffect(() => {
    if (!tides || !date) return
    setTide(getTideForDateTime(dayjs(date), tides))

  }, [date, tides])

  return <Tooltip arrow
    title={
      <Box className=" p-2">
        <h4 className="font-bold text-sm pb-2">{t("tides.todaytitle")}</h4>
        <Box className={classNames("flex flex-wrap gap-2 text-sm  ", className)}>
          {tides?.map(t => <TideItem tide={t} />)}
        </Box>
      </Box>}
    placement="bottom">
    {
      tide ? <Box className="flex gap-1 items-center ">
        <TideItem tide={tide} />
        <HelpIcon fontSize="small" className="m-auto text-fcotextgrey" />
      </Box> : <Skeleton variant="rounded" height={15} width={100} className="self-center" />
    }

  </Tooltip>

}
type TideItemProps = {
  tide: Tide
  selected?: boolean
}
export const TideItem: FC<TideItemProps> = forwardRef(({ tide, selected = false, ...props }, ref) => {
  const { t } = useTranslation("common")
  return <Box {...props} ref={ref} className={classNames("min-w-[100px] text-sm leading-4 flex flex-col self-center", {
    "font-bold": selected
  })}>
    <span>
      {
        tide.tide_type === "BM" && t("tides.low")
      }
      {
        tide.tide_type === "PM" && t("tides.hight")
      }
      {dayjs(tide.tideDateTime).format(t('dates.hour'))}
    </span>
    {
      tide.coef && <span>
        {t("tides.coef")} {tide.coef}
      </span>
    }

  </Box>
})