import { FC } from "react"
import { AccordionLayout } from "../../../../../core/models/portCMS.models"
import { PortSectionContainer } from "./Section.container"
import { useTranslation } from "react-i18next"
import { Box, Typography } from "@mui/material"

type AccordionSectionProps = {
  section: AccordionLayout
}
export const AccordionSection: FC<AccordionSectionProps> = ({ section }) => {
  const { t } = useTranslation(["portCMS"]);

  return <PortSectionContainer
    editable={false}
    section={section}
    title={t('sections.accordeon.title')}>
    <Box className="flex flex-col gap-3">
      <Typography color="primary"
        sx={{
          fontWeight: "bold",
          fontSize: "1.1em"
        }}>{t("sections.accordeon.preview")}</Typography>
      <Typography dangerouslySetInnerHTML={{ __html: section?.text_preview }}></Typography>
      <Typography color="primary"
        sx={{
          fontWeight: "bold",
          fontSize: "1.1em"
        }}>{t("sections.accordeon.full")}</Typography>
      <Typography dangerouslySetInnerHTML={{ __html: section?.text_more }}></Typography>
    </Box>
  </PortSectionContainer>
}