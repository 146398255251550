import { Box } from "@mui/material"
import { DashboardLayout } from "../../../layouts/Dashboard.layout"
import { TAListTable } from "./TAList.table"
import { Outlet } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../../../core/store/hooks"
import { useEffect } from "react"
import { getTABookingList, getTAConfig, getTAServiceslist, getTAStats } from "../../../../../core/store/actions/TechnicalArea.actions"
import { TAChart } from "./stats/TAChart"
import { TALegend } from "./stats/TALegend"

export const TAReservationPage = () => {
  const dispatch = useAppDispatch()
  const port = useAppSelector(state => state.ports.current)

  useEffect(() => {
    dispatch(getTABookingList())

  }, [])

  useEffect(() => {
    if (!port) return
    dispatch(getTAConfig(port.id))
    dispatch(getTAServiceslist(port.id))
    dispatch(getTAStats(port.id))
  }, [port])

  return (
    <DashboardLayout top={
      <Box className="flex justify-center items-center">
        <TAChart />
        <TALegend />
      </Box>
    }>
      <Box className="reservation-content flex-col flex flex-1 max-w-[50%]" >
        <TAListTable />
      </Box>
      <Box className="reservation-detail flex-col flex flex-1 max-w-[50%]" >
        <Outlet></Outlet>
      </Box>
    </DashboardLayout>
  )
}