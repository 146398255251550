import { useParams } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import { useRef } from "react";

// enum MODULE_LIST_V1TOV2 {
//   // emplacement = "emplacement",
//   berth = "emplacement",
//   electricity = "electricity",
//   vigie = "vigie",
//   posts = "posts",
//   'handling-task' = 'handling-task'
// }

export const useModule = () => {
  const moduleConfig = useAppSelector(
    ({ ports }) => ports.current?.config.modules
  );
  const currentModule = useRef("")
  const { areaId } = useParams();

  const isModuleActivated = (moduleName: string, type: "overview" | "reporting" = "overview") => {
    if (moduleName === "tickets") {
      moduleName = "ticket"
    }
    if (!moduleConfig) return false; // config not loaded
    if (!(moduleName in moduleConfig)) return false // module name not found

    const config = moduleConfig[moduleName as keyof typeof moduleConfig]
    if (!(type in config)) return false
    return config[type as keyof typeof config];
  };


  // TODO refactor with v2, ugly version for now
  const findFirstActivatedModule = () => {
    if (!moduleConfig) return ''
    if (moduleConfig?.berth.overview === true || moduleConfig?.emplacement.overview === true) {
      return `/admin/area/${areaId || "all"}/modules/berths`
    }
    if (moduleConfig?.electricity.overview === true) {
      return `/admin/area/${areaId || "all"}/modules/electricity`;
    }
    if (moduleConfig?.vigie.overview === true) {
      return `/admin/area/${areaId || "all"}/modules/vigie`;
    }
    if (("handling-task" in moduleConfig) && moduleConfig["handling-task"].overview === true) {
      return `/admin/area/${areaId || "all"}/modules/handling-task`;
    }
    if (("technical-areas" in moduleConfig) && moduleConfig["technical-areas"].overview === true) {
      return `/admin/area/${areaId || "all"}/modules/technical-areas`;
    }
    if (("ticket" in moduleConfig) && moduleConfig["ticket"].overview === true) {
      return `/admin/area/${areaId || "all"}/modules/tickets`;
    }
    if (("posts" in moduleConfig) && moduleConfig["posts"].overview === true) {
      return "/admin/cms/news";
    }
    return "/admin/cms/port"
    // return `/admin/modules/notfound`;
  };

  const setCurrentModule = (module: string) => {
    currentModule.current = module
  }

  return {
    isModuleActivated,
    findFirstActivatedModule,
    setCurrentModule,
    currentModule: currentModule.current
  };
};
