import { DragHandle } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material"
import { FC, ReactNode, useContext, useState } from "react"
import { PortCMSPreviewContext } from "./Preview/PreviewContext"
import { AccordionLayout, AreaLayout, ImageLayout, ImagesTableLayout, Layout, TableLayout } from "../../../../../core/models/portCMS.models"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

export type PortSectionContainerProps = {
  title: string
  subtitle?: string
  children?: React.ReactNode,
  isDND?: boolean,
  rightItems?: ReactNode,
  open?: boolean,
  editable?: boolean,
  section: Layout
}
export const PortSectionContainer: FC<PortSectionContainerProps> = ({ title, subtitle, children, section, rightItems, isDND = false, editable = true }) => {
  const { t } = useTranslation("portCMS")

  const {
    previewSection,
    setPreviewSection,
    canDrag,
    isDraggable } = useContext(PortCMSPreviewContext)

  const sectionIdentifier = (section: Layout | null) => section ? section.layout + section.id : ""
  return (
    <Accordion
      disabled={(canDrag && isDraggable)}
      elevation={0}
      className={classNames("px-12 py-6", { "opacity-80": !editable })}
      sx={{
        borderRadius: '30px !important'
      }}
      expanded={sectionIdentifier(previewSection) === sectionIdentifier(section)}
      onChange={() => {
        if (sectionIdentifier(previewSection) === sectionIdentifier(section)) {
          setPreviewSection(null)
        } else {
          setPreviewSection(section)
        }

      }}>
      <AccordionSummary>
        <Box className="w-full flex gap-3 items-center">
          {
            isDraggable && canDrag && <DragHandle className="-translate-x-6" />
          }

          <Box className="w-full">
            <Box className="w-full flex gap-3 items-center justify-between mb-3">
              <Typography color="primary" sx={{
                fontWeight: "bold",
                fontSize: "1.5em"
              }}>{title}</Typography>
              {
                rightItems && rightItems
              }
              {
                !editable &&
                <Box className="italic text-fcotextgrey text-sm">
                  {t("noEdit")}
                </Box>
              }
            </Box>
            <Typography>{subtitle}</Typography>
          </Box>

        </Box>
      </AccordionSummary>
      <AccordionDetails > {(!canDrag || !isDraggable) && children} </AccordionDetails>
    </Accordion >
  )
}