import { useAppContext } from "../../app/contexts/AppContext";

export const usePeriodic = () => {
  const { periodics, setPeriodics } = useAppContext();
  /**
   * Initiate a periodic pull
   * @param name unique name of the periodic
   * @param delay in seconds
   * @param request the request - must be an observable that END.
   * @param isActive is ok to send or not
   * @param requireLogin is the request only when logged or not
   */
  const startPeriodic = (
    name: string,
    delay: number,
    request: () => Promise<any>,
    isActive: boolean = true,
    requireLogin: boolean = true
  ) => {
    if (name in periodics && periodics[name].timeoutId) {
      clearInterval(periodics[name].timeoutId!);
      // console.log("periodics START Already exist", name, periodics);
    }

    periodics[name] = {
      delay: delay * 1000, // ms
      isActive,
      request,
      timeoutId: null,
      requireLogin,
      retry: 0,
    };
    setPeriodics!(periodics);
    // console.log("periodics START", name, periodics);

    sendPeriodicPull(name);
  };

  const restartPeriodic = (name: string) => {
    if (name in periodics) {
      periodics[name].isActive = true;
      sendPeriodicPull(name);
    }
  };

  const clearAll = () => {
    // console.log("periodics CLEAR ALL", periodics);
    Object.keys(periodics).map((name) => {
      periodics[name].isActive = false;
      if (periodics[name].timeoutId) {
        clearInterval(periodics[name].timeoutId!);
      }
    });
    setPeriodics!({});
  };

  const clearLogged = () => {
    Object.keys(periodics).map((name) => {
      const item = periodics[name];
      if (item.requireLogin) {
        item.isActive = false;
        clearInterval(item.timeoutId!);
        delete periodics[name];
      }
    });
    setPeriodics!(periodics);
  };

  const stopPeriodic = (name: string) => {
    // console.log("periodics STOP", name, periodics);
    if (name in periodics) {
      periodics[name].isActive = false;
      if (periodics[name].timeoutId) {
        clearInterval(periodics[name].timeoutId!);
      }
    }
  };

  const sendPeriodicPull = (name: string) => {
    // console.log("PERIODIC", name, "startime", new Date());
    if (!periodics[name].isActive) {
      return;
    }
    periodics[name].request().then(
      () => {
        // console.log("PERIODIC", name, "received", new Date());
        periodics[name].timeoutId = setTimeout(() => {
          sendPeriodicPull(name);
        }, periodics[name].delay);
      },
      (err) => {
        console.log("PERIODIC", name, "ERROR", new Date(), err);
        if (err.status === 404) {
          return;
        }
        periodics[name].retry = periodics[name].retry + 1;
        periodics[name].timeoutId = setTimeout(() => {
          sendPeriodicPull(name);
        }, periodics[name].delay * periodics[name].retry);
      }
    );
  };

  return {
    startPeriodic,
    restartPeriodic,
    stopPeriodic,
    clearAll,
    clearLogged,
  };
};
