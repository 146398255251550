import { Box, Typography } from "@mui/material"
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import { FC } from "react";
import { useTranslation } from "react-i18next";

type BoaterInfoProps = {
  title: string,
  boater: {
    email?: string,
    phone?: string,
    first_name?: string,
    last_name?: string
  }
}
export const BoaterInfo: FC<BoaterInfoProps> = ({ boater, title }) => {
  return (
    <Box className="flex flex-col gap-2">
      <Typography variant="h6"> {title}</Typography>
      {boater.first_name}  {boater.last_name}
      <Box className="flex  gap-2 justify-center">
        <Box className="flex flex-1 flex-col gap-2 items-center">
          <Box className="rounded-full bg-fcogrey p-2">
            <EmailIcon />
          </Box>
          <Typography className="text-fcotextgrey" variant="subtitle2">{boater?.email}</Typography>
        </Box>
        <Box className="flex flex-1 flex-col gap-2 items-center">
          <Box className="rounded-full bg-fcogrey p-2">
            <LocalPhoneIcon />
          </Box>
          <Typography className="text-fcotextgrey" variant="subtitle2">{boater?.phone}</Typography>
        </Box>
      </Box>
    </Box>
  )
}