import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../../core/store/hooks"
import { useMemo } from "react";
import { ChartData, ChartOptions } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Box, CircularProgress } from "@mui/material";

export const TAChart = () => {
  const chart = useAppSelector(state => state.technicalArea.stats?.chart)
  const { t } = useTranslation("technicalArea");
  const datas = useMemo<ChartData<"doughnut", number[], string>>(() => ({
    labels: chart?.labels || [],
    datasets: [
      {
        data: chart?.data || [],
        backgroundColor: chart?.backgroundColor || [],
      },
    ],
  }), [chart]);
  const options: ChartOptions<"doughnut"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
    },
    // rotation: 1 * Math.PI,
    // circumference: 1 * Math.PI,
    rotation: 86 * Math.PI,
    circumference: 57 * Math.PI,
  };
  return <Box
    className="flex items-center justify-center"
    sx={{
      backgroundColor: "#fff",
      width: "300px",
      height: "150px",
      position: "relative",
      padding: "10px",
    }}
  >
    {chart && datas ? (
      <>
        <Doughnut options={options} data={datas} />
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            lineHeight: "1em",
            fontWeight: "bold",
            width: "100%",
            transform: "translateY(-75%)",
          }}
          className="flex flex-col items-center justify-center align-center"
        >
          <span className="block">{chart.percentage}%</span>{" "}
          {t("legend.graphLegend")}
        </Box>
      </>
    ) : (
      <CircularProgress />
    )}
  </Box>
}