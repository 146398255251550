import { FC } from "react"
import { TableLayout } from "../../../../../../core/models/portCMS.models"
import { Box } from "@mui/material"

type TablePreviewProps = {
  section: TableLayout
}
export const TablePreview: FC<TablePreviewProps> = ({ section }) => {

  return (<Box>
    <p className="text-fcomain text-xl font-bold text-center mb-2">{section.title}</p>
    <div className="text-fcomain p-2 drop-shadow-md bg-white rounded-xl gap-2 grid grid-cols-3">
      {
        section.table?.map((t, index) => <div key={(section?.id || 'new') + t.layout + index} className="flex flex-col text-sm px-2 border-r border-fcogrey">
          <span>{t.label}</span>
          <span className="font-bold">{t.value}</span>

        </div>)
      }
    </div>
  </Box>
  )
}