import {
  Box,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { FC, MouseEvent } from "react";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { useAppContext } from "../../../contexts/AppContext";
import logoSVG from "../../../../assets/img/logo/logo-blue.svg";
import { useAppSelector } from "../../../../core/store/hooks";
import { useTranslation } from "react-i18next";
import { useQAContext } from "../../../contexts/QAContext";
import { UserMenu } from "../users/UserMenu.element";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: useAppContext().drawerWidth,
    width: `calc(100% - ${useAppContext().drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const AppBarElement: FC = ({ children }) => {
  const { t } = useTranslation("app");
  const port = useAppSelector((state) => state.ports.current);
  const { drawerOpened, toggleDrawer } = useAppContext();
  const { APP_VERSION, APP_ENV } = useQAContext();
  const toggleDrawerCB = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    toggleDrawer!();
  };

  return (
    <AppBar className="print:hidden" position="fixed" open={drawerOpened}>
      <Toolbar
        sx={{
          justifyContent: "space-between",
          pr: "24px", // keep right padding when drawer closed
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawerCB}
          sx={{
            marginRight: "36px",
            ...(drawerOpened && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography component="h1" variant="h6" color="inherit" noWrap>
          <Box
            className="h-5"
            component="img"
            alt="Logo Falco"
            src={logoSVG}
          />
          <Box className="text-fcomain text-opacity-60 font-normal text-xs">{APP_VERSION}
            {APP_ENV !== "PRODUCTION" ? <Box className="ml-2 inline text-fcoerror font-extrabold text-xs">{APP_ENV}</Box> : ""}
          </Box>
        </Typography>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            margin: "auto",
            padding: "4px",
            textAlign: "center",
            flexDirection: "column",
          }}
        >
          <Typography component="h1" variant="h4" color="inherit" noWrap>
            {port?.name}
          </Typography>
          <div className="font-bold">{t("yourHarbor")}</div>
        </Box>
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
};
