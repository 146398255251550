import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getBerthDetail } from "../../../../../core/store/actions/Berth.actions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../core/store/hooks";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { MoteStatusChip, StatusChip } from "./StatusChip";
import { BerthContract } from "./BerthContract.element";
import { useTranslation } from "react-i18next";
import { createTicket } from "../../../../../core/store/actions/Ticket.actions";
import { AlertType, useAppContext } from "../../../../contexts/AppContext";
// import { IIntervention } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import FDK from "@wattsonelements/front-fdk";
import { GoToTicketBtn } from "../GoToTicketBtn.element";
import { IMarinaBerth, IBerthDetail } from "@wattsonelements/front-fdk/dist/models/Berth.models";
import { getFirstElement } from "../../../../../_helpers/array.helpers";

export const BerthDetail: FC = () => {
  const { berthId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("berth");
  const berthCateg = useAppSelector((state) => state.tickets.categories).find(
    (cat: any) => cat.slug === "berth"
  );
  const mooringToConfirmCateg = useAppSelector((state) => state.tickets.categories).find(
    (cat: any) => cat.slug === "mooring-to-confirm"
  );
  const { setAlertMessage } = useAppContext();
  const [creatingTicket, setCreatingTicket] = useState(false);

  const berth = useAppSelector((state) => state.berth.selected);
  const isLoading = !berth || berth?.id !== berthId

  const canCreateTicket = () => {
    if (berth)
      return true;
    // return berth!.tickets!.length === 0 || berth!.tickets!.find((tic: any) => tic.status === TicketStatus.CLOSED);
  };

  const TicketOpenBtn = ({ data }: { data: IMarinaBerth | IBerthDetail }) => {
    if (!data.tickets) return <></>;
    const el = getFirstElement(data.tickets) || <></>;
    return el.id ? (
      <GoToTicketBtn nbTickets={data.tickets.length} ticketId={el.id} />
    ) : (
      <></>
    );
  }

  const createTickets = async (e: any) => {
    setCreatingTicket(true);
    try {
      // let ticketIntervention: IIntervention = {
      //   intervention_assignee: [],
      //   intervention_step: [
      //     {
      //       intervention_step_attachment: [],
      //       intervention_step_note: [],
      //     },
      //   ],
      // };
      const defaultValues = {
        // ticket_intervention: [ticketIntervention],
        category: berth!.status === FDK.Constants.BerthConstants.BERTH_STATUS.MOORING_TO_CONFIRM ? mooringToConfirmCateg!.id : berthCateg!.id,
        spot_id: berth!.id,
        plug: undefined,
        boat: undefined,
        priority: FDK.Constants.TicketConstants.TicketPriority.NORMAL,
        description: "",
      }
      let res: any = await dispatch(createTicket(defaultValues));
      console.log(res);
      if (res.type === "ticket/create/rejected") {
        setAlertMessage!({
          type: AlertType.ERROR,
          message: t("ticketCreationError", { ns: "ticket" }),
        });
      } else {
        // navigate(`/admin/area/${areaId || "all"}/modules/tickets/${res.payload.id}`);
        // no port areas for the moment
        navigate(`/admin/area/all/modules/tickets/${res.payload.id}`);
        setAlertMessage!({
          type: AlertType.SUCCESS,
          message: t("successfullyCreated", { ns: "ticket" }),
        });
      }
    } catch (e) {
      setAlertMessage!({
        type: AlertType.ERROR,
        message: t("ticketCreationError", { ns: "ticket" }),
      });
    }
    setCreatingTicket(false);
  }

  useEffect(() => {
    if (berthId) {
      dispatch(getBerthDetail(berthId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [berthId]);

  return (
    <Box className="w-full flex flex-grow flex-col">
      <Paper className="flex items-center p-3">
        <Button
          onClick={() => {
            navigate("../");
          }}
        >
          <ArrowBackIosIcon fontSize="large" />
        </Button>
        <Typography component="h2" variant="h5">
          {berth?.name}
        </Typography>
        <Divider
          sx={{
            marginX: "30px",
          }}
          orientation="vertical"
          flexItem
        />
        <Box className="flex items-center justify-between w-full">
          <Box className="flex items-center gap-3">
            <StatusChip isLoading={isLoading} status={berth?.status} />
            {!isLoading && (berth?.occupied_since || berth?.empty_since) &&
              <MoteStatusChip occupiedSince={berth?.occupied_since} emptySince={berth?.empty_since} />
            }
          </Box>
          <Box className="flex gap-3 items-center justify-end">
            {berth &&
              <TicketOpenBtn data={berth} />
            }

            {creatingTicket ?
              <CircularProgress />
              :
              <Button
                disabled={!canCreateTicket()}
                onClick={createTickets}
                variant="contained"
                color="secondary"
              >
                {t("table.create")}
              </Button>
            }
          </Box>
        </Box>
      </Paper>
      {berth?.contracts.length === 0 && (
        <Chip className="my-5 mx-10" label={t("detail.noClient")} />
      )}
      <Box className={"flex-grow w-full p-2 grid gap-2" + (berth?.contracts && berth?.contracts?.length > 1 && " grid-cols-2")}>
        {berth?.contracts?.map((contract) => (
          <BerthContract key={`contract-${contract.id}`} contract={contract} />
        ))}
      </Box>
    </Box>
  );
};
