import { createAsyncThunk } from "@reduxjs/toolkit";
import FDK from "@wattsonelements/front-fdk";
import { IPort } from "@wattsonelements/front-fdk/dist/models/Port.models";
import { Area, AreaLayout, LAYOUT_NAME, Layout } from "../../models/portCMS.models";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";

export const getUserPort = createAsyncThunk("port/current", async () => {
  return FDK.Marina.PortApi.getUserPort().then((res) => {
    if (res && res.data && res.data.length > 0) {
      return res.data[0];
    }
    return null;
  });
});


export const getPortSections = createAsyncThunk('port/section', async (port: UUID, { dispatch }) => {
  return FDK.MainAPI.API.get<Layout[]>(`/v2/marina/ports/${port}/sections`).then(
    res => {
      res.data.forEach(section => {
        if (section.layout === LAYOUT_NAME.AREA) {
          dispatch(getArea({ area: section.area, port }))
        }
      })
      return res.data
    }
  )
})

export const getArea = createAsyncThunk('port/area',
  async ({ port, area }: { port: UUID, area: Area }) => {
    return FDK.MainAPI.API.get<Area>(`/v2/marina/ports/${port}/areas/${area.id}`).then(
      res => {
        return res.data
      }
    )
  })

export const updateArea = createAsyncThunk('port/sections/area/update',
  async ({ port, area }: { port: UUID, area: Area }, { rejectWithValue }) => {
    return FDK.MainAPI.API.patch(`/v2/marina/ports/${port}/areas/${area.id}`, area).then(
      res => {
        return res.data
      },
      err => {
        return rejectWithValue(err)
      }
    )
  })

export const updateSection = createAsyncThunk('port/sections/update',
  async ({ port, section }: { port: UUID, section: Partial<Layout> }, { rejectWithValue }) => {
    return FDK.MainAPI.API.request({
      method: "patch",
      url: `/v2/marina/ports/${port}/sections/${section.layout}-${section.id}`,
      data: section
    }).then(
      res => {
        return res.data
      }, err => {
        return rejectWithValue(err)
      }
    )
  })

export const addSection = createAsyncThunk('port/sections/add',
  async ({ port, section }: { port: UUID, section: Partial<Layout> }, { rejectWithValue }) => {
    return FDK.MainAPI.API.request({
      method: "post",
      url: `/v2/marina/ports/${port}/sections`,
      data: section
    }).then(
      res => {
        return res.data
      }, err => {
        return rejectWithValue(err)
      }
    )
  })

export const moveSection = createAsyncThunk('port/sections/move',
  async ({ port, section, position }: { port: UUID, position: number, section: Layout }) => {
    return FDK.MainAPI.API.patch<Layout[]>(`/v2/marina/ports/${port}/sections/${section.layout}-${section.id}/move`, {
      new_position: position
    }).then(
      res => {
        return res.data
      }
    )
  })

export const getSection = createAsyncThunk('port/sections/get',
  async ({ port, section }: { port: UUID, section: Partial<Layout> }) => {
    return FDK.MainAPI.API.get(`/v2/marina/ports/${port}/sections/${section.layout}-${section.id}`).then(
      res => {
        return res.data
      }
    )
  })