import { useTranslation } from "react-i18next";
import { PortSectionContainer } from "../Section.container"
import { TableLayout } from "../../../../../../core/models/portCMS.models";
import { FC, useContext, useLayoutEffect, useRef } from "react";
import { Button } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { PortCMSPreviewContext } from "../Preview/PreviewContext";
import { TableForm } from "./Table.form";
import { ModeEdit, Save } from "@mui/icons-material";

type TableSectionProps = {
  section: TableLayout
}

export const TableSection: FC<TableSectionProps> = ({ section }) => {
  const { t } = useTranslation(["portCMS"]);
  const methods = useForm({ defaultValues: section })
  const { formState: { isDirty }, handleSubmit } = methods
  const { formsDirty, setFormsDirty, previewSection } = useContext(PortCMSPreviewContext)
  const ref = useRef<{ submit: (data: TableLayout) => void }>(null);

  useLayoutEffect(() => {
    setFormsDirty({
      ...formsDirty,
      [section.layout + '-' + section.id]: isDirty
    })
  }, [isDirty, section])

  return (
    <PortSectionContainer
      section={section}
      title={`${t('sections.table.title')}  - ${section?.title || ""}`}
      subtitle={t('sections.table.subtitle')}
      rightItems={isDirty ? <Button disabled={!ref.current} onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        if (!ref.current) return
        handleSubmit(ref.current.submit)(e)
      }}
        size="small"
        variant="outlined"
        color="secondary"><Save /></Button> : (previewSection?.id === section.id ? <> </> : <ModeEdit color="secondary" />)}
    >
      <FormProvider {...methods}>
        <TableForm ref={ref} section={section} />
      </FormProvider>
    </PortSectionContainer>
  )
}