import { Autocomplete, Chip, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useAppSelector } from "../../../../../../core/store/hooks";
import { useTranslation } from "react-i18next";
import { TABookingServices, TAService } from "../../../../../../core/models/technicalArea.models";
import { FC } from "react";
import { Check, Close } from "@mui/icons-material";

type ServicesSelectProps = {
  disabled?: boolean
  servicesStates?: TABookingServices[]
  getOptionLabel?: (item: any) => string
}
export const ServicesSelect: FC<ServicesSelectProps> = ({ disabled, servicesStates, getOptionLabel = (item) => item.name }) => {
  const { t } = useTranslation("technicalArea")
  const { control, formState } = useFormContext()
  const { services } = useAppSelector(state => state.technicalArea)

  return (
    <>
      {
        services &&
        <Controller
          control={control}
          name="services"
          rules={{ required: true }}
          render={({ field, fieldState, formState }) => (
            <Autocomplete
              className="col-span-2"
              disablePortal
              disabled={disabled}
              isOptionEqualToValue={(option, value) => {
                return value.id === option.id
              }}
              id="services-selection"
              options={services}
              multiple
              onChange={((_, values) => {
                console.log("services", values);
                field.onChange(values)
              })}
              value={field.value}
              onBlur={field.onBlur}
              getOptionLabel={getOptionLabel}
              getOptionDisabled={(option) => {
                if (!servicesStates) return false
                const isDone = !!servicesStates.find(s => s.service_id === option.id && s.is_done)
                return isDone
              }}
              renderInput={(params) => <TextField
                {...params}
                label={t("form.service")} />}
              renderTags={(values: TAService[], getTagProps, ownerState) => {
                console.log(values, ownerState);

                return <div className="flex flex-wrap gap-1">
                  {
                    values.map((value, index) => {
                      const isDone = servicesStates ? !!servicesStates.find(s => s.service_id === value.id && s.is_done) : false
                      return <Chip
                        // icon={isDone ? <IonIcon className="text-lg" color="success" icon={checkmarkCircle} /> : <></>}
                        {...getTagProps({ index })}
                        deleteIcon={isDone ? <Check /> : <Close />}
                        disabled={isDone}
                        label={value.name} />
                    })
                  }
                </div>
              }}
            />

          )}
        />
      }

    </>
  )

}