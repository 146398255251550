import { Box, Typography } from "@mui/material"
import classNames from "classnames"
import { FC, ReactNode, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { TBookingReportItem } from "../../../../../core/models/technicalArea.models"

// TODO : ADD images 
type AutomaticReportProps = {
  logs: TBookingReportItem[]
}
export const AutomaticReport: FC<AutomaticReportProps> = ({ logs }) => {
  const { t } = useTranslation("common")
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom()
  }, [logs])
  return (<>
    {
      logs && logs?.map((item, index) => {
        return <Box key={index + item.text} className={classNames("leading-4", { "text-fcodanger font-bold": item.type === "alert" })}>
          {item.text}
        </Box>
      })
    }
    <div ref={messagesEndRef} />
  </>
  )
}

type ReportLayoutProps = {
  children: ReactNode,
  header: ReactNode
}
export const ReportLayout: FC<ReportLayoutProps> = ({ children, header }) => {
  return (
    <Box className="bg-fcogrey rounded-xl p-5 flex flex-col  ">
      {header}
      <Box className="overflow-auto max-h-[550px]">
        <Box className="bg-white overflow-auto  rounded p-3 flex flex-col justify-end gap-4">

          {children}
        </Box>
      </Box>
    </Box>
  )
}