import {
  Box,
  Checkbox,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { FC, forwardRef, ReactElement, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { Order } from "../../../../core/models/module.models";
import { stringDescendingComparator } from "../../../../_helpers/sort.helpers";

// type Order = "asc" | "desc" | undefined;

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
  customOrder?: (datas: T[]) => T[]
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  if (customOrder) {
    let tmp = [...stabilizedThis.map((el) => el[0])] as T[];
    return customOrder(tmp);
  }
  return stabilizedThis.map((el) => el[0]);
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy?: Property
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  if (!!!orderBy || !!!order) {
    return (a, b) => 0;
  }

  return order === "desc"
    ? (a, b) =>
      stringDescendingComparator(
        orderBy.compareValue(a),
        orderBy.compareValue(b)
      )
    : (a, b) =>
      -stringDescendingComparator(
        orderBy.compareValue(a),
        orderBy.compareValue(b)
      );
}

interface Property {
  label: string;
  key: string;
  format: (data: any) => string | FC | ReactElement;
  sort: boolean;
  compareValue: (data: any) => string | number; // allow control over sorting
  cellClassName?: string | ((data: any) => string);
}
interface TableProps {
  rowPadding?: boolean;
  selectable?: boolean;
  selectWithRowIndex?: boolean,
  selectedKey?: string;
  headers?: Property[];
  displayHeader?: boolean;
  selectionKey?: string;
  isLoading?: boolean;
  order?: Order;
  customOrder?: (datas: any) => any | undefined;
  orderBy?: string | undefined;
  onSort?: (key: string) => void;
  datas: { [key: string]: any }[];
  onClickRow?: (event: React.MouseEvent, row: any) => void;
  labelRowsPerPage?: string; //TODO s'affiche pas
  onChangeSelection?: (datas: any) => void;
  disableSelection?: (datas: any) => boolean;
}

export const ModuleTable = forwardRef((props: TableProps, ref) => {
  const { t } = useTranslation("common");
  const {
    rowPadding = false,
    selectedKey, // like an id if selectionKey is an id
    selectable = true, // Checkbox
    selectWithRowIndex = false,
    headers,
    datas = [],
    isLoading = false,
    order = undefined,
    customOrder = undefined,
    orderBy = undefined,
    selectionKey = "id",
    onClickRow = (event: React.MouseEvent, row: any) => { },
    onSort = (key: string) => { },
    labelRowsPerPage = t("table.labelRowsPerPage"),
    onChangeSelection = () => { },
    disableSelection = () => false,
    displayHeader = true,
  } = props;

  const rowsPerPage = 10;
  // const [order, setOrder] = useState<Order>(undefined);
  // const [orderBy, setOrderBy] = useState<Property>();
  const [selected, setSelected] = useState<any[]>([]);

  useImperativeHandle(ref, () => ({
    resetSelected() {
      resetSelected();
    },
  }))
  const resetSelected = () => {
    setSelected([]);
    onChangeSelection([]);
  }

  const getItemPagePosition = (key: string | undefined) => {
    if (key) {
      const tmpPage = Math.floor(
        stableSort(datas, getComparator(order, headers?.find(h => h.key === orderBy)), customOrder).findIndex(
          (d) => d[selectionKey] === selectedKey
        ) / rowsPerPage
      )
      return tmpPage >= 0 ? tmpPage : 0
    } else {
      return 0
    }
  }


  const [page, setPage] = useState(0);
  const [maxSelectable, setMaxSelectable] = useState(1);

  const isSelected = (row: any, index: number) => {
    if (selectWithRowIndex) {
      return selected.findIndex((d) => d[selectionKey] === index) !== -1;
    }
    return selected.findIndex((d) => d[selectionKey] === row[selectionKey]) !== -1;
  }

  useEffect(() => {
    if (selectedKey) {
      setPage(getItemPagePosition(selectedKey));
    }
  }, [selectedKey, headers])

  useEffect(() => {
    setPage(getItemPagePosition(selectedKey))
    return () => {
      setPage(0);
    }
  }, [])

  const selectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = datas.filter((d) => !disableSelection(d));
      setSelected(newSelecteds);
      onChangeSelection(newSelecteds);
      return;
    }
    resetSelected();
  };

  const selectRow = (event: React.MouseEvent, data: any, index: number) => {
    if (selectWithRowIndex) {
      data = { ...data, [selectionKey]: index }
    }

    const selectedIndex = selected.findIndex(
      (s) => s[selectionKey] === data[selectionKey]
    );


    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, data);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    onChangeSelection(newSelected);
  };

  /**
   * Handle header
   */
  // const createSortHandler =
  //   (header: Property) => (event: React.MouseEvent<unknown>) => {
  //     if (order === undefined) {
  //       dispatch
  //       setOrder("asc");
  //     } else if (order === "asc") {
  //       setOrder("desc");
  //     } else {
  //       setOrder(undefined);
  //     }
  //     setOrderBy(header);
  //   };

  /**
   * HANDLE PAGINATION
   */

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (datas.length <= rowsPerPage && (page > 0 || page < 0)) {
      setPage(getItemPagePosition(selectedKey))
    }
    if (selectWithRowIndex) {
      setSelected([])
    }
  }, [datas]);

  useEffect(() => {
    const total = datas.reduce((acc, d) => {
      return acc + (!disableSelection(d) ? 1 : 0);
    }, 0);
    setMaxSelectable(total);
  }, [datas, disableSelection]);

  return (
    <Paper className="w-full">
      <TableContainer>
        <Table size="small">
          {/* TABLE HEADER */}
          {
            displayHeader &&

            <TableHead>
              <TableRow>
                {selectable === true && (
                  <TableCell
                    padding={rowPadding ? "normal" : "none"}
                    align="left"
                  >
                    <div className="flex items-center mr-1">
                      <Checkbox
                        color="primary"
                        indeterminate={
                          selected.length > 0 && selected.length < maxSelectable
                        }
                        checked={
                          datas.length > 0 && selected.length === maxSelectable
                        }
                        onChange={selectAll}
                        inputProps={{
                          "aria-label": "select all berth", // TODO ADD TRANSLATION
                        }}
                      />
                      {selected.length}
                    </div>
                  </TableCell>
                )}
                {headers?.map((h) => (
                  <TableCell
                    padding={rowPadding ? "normal" : "none"}
                    key={h.label}
                    align="left"
                  >
                    {h.sort ? (
                      <TableSortLabel
                        active={orderBy === h.key && !!order}
                        direction={orderBy === h.key ? order : undefined}
                        onClick={() => onSort(h.key)}
                      >
                        <Box className="mx-1">{h.label}</Box>
                      </TableSortLabel>
                    ) : (
                      <Box className="mx-1">{h.label}</Box>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          }
          {/* TABLE  */}
          {!isLoading && datas && datas.length > 0 && <TableBody>
            {stableSort(datas, getComparator(order, headers?.find(h => h.key === orderBy)), customOrder)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row, index + page * rowsPerPage);
                return (
                  <TableRow
                    selected={
                      selectedKey ? selectedKey === row[selectionKey] : false
                    }
                    hover
                    key={`${row[selectionKey]}-${index}`}
                    onClick={(event: React.MouseEvent) => {
                      onClickRow(event, row);
                    }}
                    role="checkbox"
                  >
                    {(typeof selectable === "undefined" ||
                      selectable === true) && (
                        <TableCell
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          padding={rowPadding ? "normal" : "none"}
                          align="left"
                        >
                          <Checkbox
                            disabled={disableSelection(row)}
                            onClick={(e: React.MouseEvent) => {
                              e.stopPropagation();
                              selectRow(e, row, index);
                            }}
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": "",
                            }}
                          />
                        </TableCell>
                      )}
                    {headers?.map((h, i) => {
                      const value = h.format(row);
                      return (
                        <TableCell
                          className={
                            "relative " + ((h.cellClassName && typeof h.cellClassName === 'function') ? h.cellClassName(row) : h.cellClassName)
                          }
                          padding={rowPadding ? "normal" : "none"}
                          key={`${i}-row`}
                          align="left"
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>}
        </Table>
      </TableContainer>
      {/* TABLE PAGINATION */}
      {!isLoading && (
        <TablePagination
          component="div"
          count={datas.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelDisplayedRows={({ from, to, count }) => t('table.labelDisplayedRows', { from, to, count: (count !== -1 ? count : t('table.moreThan', { to })) })}
          onPageChange={handleChangePage}
          labelRowsPerPage={labelRowsPerPage}
          rowsPerPageOptions={[rowsPerPage]}
        />
      )}

      {isLoading && (
        <Box className="flex w-full justify-center items-center p-3">
          <CircularProgress color="secondary" size={20} />
          <Typography className="pl-2">{t("loading")}</Typography>
        </Box>
      )}
      {!isLoading && datas && datas.length === 0 &&
        <Box className="m-3 p-3 flex flex-col justify-center items-center gap-3 rounded text-fcomain border-[1px] border-fcomain" >
          {t('table.noData')}
        </Box>

      }
    </Paper>
  );
});
