import { useTranslation } from "react-i18next";
import { ModuleTable } from "../../Table"
import { Box, Button, Checkbox, Chip, FormControl, InputLabel, LinearProgress, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../core/store/hooks";
import { BOOKING_STATUS, IBooking } from "@wattsonelements/front-fdk/dist/models/Booking.models";
import dayjs from "dayjs";
import { AgentSelector } from "./Agent.elelement";
import { useNavigate } from "react-router-dom";
import { setBookingListLoading, setSelectedBooking } from "../../../../../../core/store/reducers/Crane.reducer";
import { BookingStatusChip } from "../StatusChip";
import { getDateObjectFromTime } from "../../../../../../_helpers/dateTime.helper";
import { IUserMarina } from "@wattsonelements/front-fdk/dist/models/User.models";
import FDK from "@wattsonelements/front-fdk";
import { TableTop } from "../../TableTop";
import { useEffect, useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useAppContext } from "../../../../../contexts/AppContext";
import { updateFilterWithoutSaving } from "../../../../../../core/store/reducers/Crane.reducer";
import { getFilteredBookingList } from "../../../../../../core/store/selectors/Manutention.selector";
import { addBookingDateParam, getBookingList, getStatusListParam } from "../../../../../../core/store/actions/Crane.actions";
import { DlFileButton } from "../../../../UX/DlFile.button";
import { DateSelectionWithActions, DateSelectionWithActionsChangeValue, DateType } from "../../../../UX/inputs/DateSelectionWithActions";

export const ReservationTable = () => {
  const dispatch = useAppDispatch()
  const { bookingListLoading, selectedBooking, filters } = useAppSelector(state => state.crane)
  const bookingList = useAppSelector(getFilteredBookingList)
  const { t } = useTranslation("crane");
  const navigate = useNavigate();
  const { setAlertMessage } = useAppContext()
  const [showLoader, setShowLoader] = useState(false)
  const boatLine = (data: IBooking) => {
    const text = []
    if (data?.contract?.spot?.name) {
      text.push(data?.contract?.spot?.name)
    }
    if (data?.boat?.name) {
      text.push(data?.boat?.name)
    }
    if (data?.boat?.registration_number) {
      text.push(data?.boat?.registration_number)
    }

    return text.join(" - ")
  }

  const headers = [
    {
      label: "Infos",
      key: "name",
      format: (data: IBooking) => {
        return <Box>
          <Chip
            variant="squared"
            color="primary"
            size="small"
            className="mb-1"
            label={<>
              {
                dayjs(data.date).format(t('dates.day_month', { ns: "common" }))
              }&nbsp;
              {getDateObjectFromTime(data.related_planning.start_time, data.date).format(t('dates.hour', { ns: "common" }))}
              &nbsp;{t('dates.to', { ns: "common" })}&nbsp;
              {getDateObjectFromTime(data.related_planning.end_time, data.date).format(t('dates.hour', { ns: "common" }))}

            </>} />

          <p className="font-bold">{boatLine(data)}</p>

          <p>{data?.boater?.first_name} {data?.boater?.last_name}</p>

        </Box>;
      },
      sort: true,
      compareValue: (data: IBooking) => data?.boat?.name || '',
    },
    {
      label: "Service",
      key: "service",
      format: (data: IBooking) => {

        return data?.service ? t(`service.${data?.service}`) : "-"
      },
      sort: true,
      compareValue: (data: IBooking) => data?.service || '',
    },
    {
      label: "Motif",
      key: "motive",
      format: (data: IBooking) => {
        return <Box className="flex flex-col items-start">
          {data.hardware &&
            <Chip
              variant="outlined"
              color="primary"
              size="small"
              className="mb-1"
              label={data.hardware.name} />
          }
          {data?.motive?.label || "-"}
        </Box>
      },
      sort: true,
      compareValue: (data: IBooking) => data?.motive?.label || '',
    },
    {
      label: "Agent",
      key: "service",
      format: (data: IBooking) => {
        const { agent } = data
        const change = (agent: Partial<IUserMarina> | null | any) => {
          FDK.Marina.CraneModule.udpateBooking(data.id, { agent: agent?.id });
        }
        return <Box className='flex justify-center items-center'>
          <AgentSelector onChange={change} agent={agent || null} />
        </Box>
      },
      sort: true,
      compareValue: (data: IBooking) => data?.agent?.last_name || '',
    },
    {
      label: "Statut",
      key: "status",
      format: (data: IBooking) => {
        return <BookingStatusChip className="p-3" status={data?.status} />
      },
      sort: true,
      compareValue: (data: IBooking) => '' + data.status || '',
    }
  ]

  useEffect(() => {
    if (!selectedBooking && bookingList && bookingList.length > 0) {
      const row = bookingList[0]
      navigate(`${row.id}`);
      dispatch(setSelectedBooking(row))
    }
  }, [bookingList, selectedBooking])

  useEffect(() => {
    dispatch(updateFilterWithoutSaving({ date: "date", dateStart: dayjs().toISOString(), dateEnd: null }))
    setBookingListLoading(true)
  }, [])

  const onClickRow = (e: React.MouseEvent, row: IBooking) => {
    // if (selectedBooking && selectedBooking.id === row.id) {
    //     navigate(`../`);
    //     dispatch(setSelectedBooking(null))
    //     return
    // }
    navigate(`${row.id}`);
    dispatch(setSelectedBooking(row))
  }

  const onChangeDateFilter = (type: DateType, value: DateSelectionWithActionsChangeValue) => {
    setShowLoader(true)
    dispatch(updateFilterWithoutSaving({ date: type, ...value }))
    dispatch(getBookingList()).finally(() => {
      setShowLoader(false)
    })
  }

  const onChangeStatusFilter = (e: any) => {
    let tmpStatus: string[] = e.target.value;
    const newFilters = { ...filters, statusBooking: { ...filters.statusBooking } };

    newFilters.statusBooking = {} as any;
    for (let i in tmpStatus) {
      newFilters.statusBooking[`${tmpStatus[i]}`] = true;
    }
    newFilters.statusBooking = { ...newFilters.statusBooking }
    dispatch(updateFilterWithoutSaving(newFilters))
    dispatch(getBookingList()).finally(() => {
      setShowLoader(false)
    })
  }

  return (
    <>
      <TableTop
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          event.preventDefault();
          dispatch(updateFilterWithoutSaving({ text: event.target.value }));
        }}
        buttons={
          <>
            <Box sx={{ minWidth: 110, maxWidth: 200 }}>
              <DateSelectionWithActions
                type={filters.date}
                dateStart={filters.dateStart}
                dateEnd={filters.dateEnd}
                onChange={(type, value) => {
                  onChangeDateFilter(type, value)
                }} />
            </Box>
            <Box sx={{ minWidth: 110, maxWidth: 200 }}>
              <FormControl size="small" fullWidth={true}>
                {Object.keys(filters.statusBooking).length < 1 && (
                  <InputLabel id="state-checkbox-label">
                    {t("table.headers.status")}
                  </InputLabel>
                )}
                <Select
                  labelId="state-checkbox-label"
                  id="state-checkbox"
                  multiple
                  value={Object.keys(filters.statusBooking)}
                  onChange={onChangeStatusFilter}
                  input={
                    <OutlinedInput
                    // label={t("table.headers.status")}
                    />
                  }
                  renderValue={(selected) =>
                    selected.map(
                      (sel) => t(`booking.status.${sel.toLocaleLowerCase()}`) + ", "
                    )
                  }
                  IconComponent={FilterListIcon}
                >
                  {/* Booking Status */}
                  {Object.keys(BOOKING_STATUS).filter(s => {
                    if (BOOKING_STATUS[s as keyof typeof BOOKING_STATUS] === BOOKING_STATUS.AVAILABLE) return false
                    return true
                  }).map(
                    (s) =>
                    (
                      <MenuItem dense={true} key={"status-" + s} value={s}>
                        <Checkbox
                          size={"small"}
                          checked={filters.statusBooking[s as BOOKING_STATUS] === true}
                        />
                        <ListItemText
                          primary={t(`booking.status.${s.toLocaleLowerCase()}`)}
                        />
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Box>
            <DlFileButton
              request={() => {
                let params = {}
                params = addBookingDateParam(filters.date, filters.dateStart, params)
                params = getStatusListParam(filters.statusBooking, params)
                return FDK.MainAPI.API.request({
                  method: "get",
                  url: `/v2/marina/handling-tasks/export`,
                  responseType: 'blob',
                  params
                })
                // .then(res => { }, err => {
                //   setAlertMessage!({ type: AlertType.ERROR, message: t('form.error', { ns: 'common' }) })
                // }) 
              }}
            >
              {t("booking.export")}
            </DlFileButton>
            <Button onClick={(e) => {
              navigate("/admin/area/all/modules/handling-task/planning");
            }} color="secondary" variant="contained">{t("nav.admin")}</Button>
          </>
        }
      />
      {
        (bookingListLoading || showLoader) && <LinearProgress color="secondary" />
      }
      <ModuleTable
        rowPadding={true}
        headers={headers}
        displayHeader={false}
        datas={bookingList || []}
        selectedKey={selectedBooking?.id}
        selectionKey='id'
        selectable={false}
        onClickRow={onClickRow}
        isLoading={bookingListLoading}
      />
    </>
  )
}