import { Box } from "@mui/material"
import { BOOKING_STATUS } from "@wattsonelements/front-fdk/dist/models/Booking.models"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import classNames from 'classnames'

type BookingStatusChipProps = {
    status: BOOKING_STATUS,
    className?: string
}
export const BookingStatusChip:FC<BookingStatusChipProps> = ({status, className}) => {
    const { t } = useTranslation("crane");
   
    return (
        <Box className={classNames(
            "text-center rounded font-bold",
            className,
            {
                "bg-fcosecondary text-white":BOOKING_STATUS.AVAILABLE === status, 
                "bg-fcogreyvariant":BOOKING_STATUS.BOOKED === status || BOOKING_STATUS.DONE === status , 
                "bg-fcodanger text-white":BOOKING_STATUS.CANCELLED === status || BOOKING_STATUS.UNAVAILABLE === status, 
                "bg-fcowarn text-white":BOOKING_STATUS.MISSED === status, 
            }
            )}>
            {t(`booking.status.${status}`)}
        </Box>
    )

}