import { Box, Button, CircularProgress, Skeleton } from "@mui/material"
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { getDateObjectFromTime } from "../../../../../../_helpers/dateTime.helper";
import { FC, useEffect, useState } from "react";
import { Amendment as TAAmendment, TABooking, TA_AMENTDMENT_STATUS } from "../../../../../../core/models/technicalArea.models";
import { Edit } from "@mui/icons-material";
import { FormModal } from "../form/form.modal";
import { AmendmentFormEdit } from "../form/EditAmendment.form";
import { getAmendments } from "../../../../../../core/store/actions/TechnicalArea.actions";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";

type AmendmentProps = {
  booking: TABooking
  amendment: UUID | string
}
export const Amendment: FC<AmendmentProps> = ({ booking, amendment: id }) => {
  const { t } = useTranslation("technicalArea");
  const [openEditModal, setOpenEditModal] = useState(false)
  const [amendment, setAmendment] = useState<TAAmendment | null>()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (id) {
      setIsLoading(true)
      getAmendments(booking.id, id).then(res => {
        setAmendment(res.data)
      }).finally(() => {
        setIsLoading(false)
      })
    }
    return () => {
      setAmendment(null)
    }
  }, [booking, id])

  if (
    isLoading && !amendment
  ) {
    return <>
      <Box className="flex flex-col gap-3 w-full">
        <Skeleton variant="rectangular" width={"80%"} height={20} />
        <Skeleton variant="rectangular" width={"40%"} height={15} />
        <Skeleton variant="rectangular" width={"30%"} height={15} />
        <Skeleton variant="rectangular" width={"50%"} height={15} />
      </Box>
    </>
  }

  return (<Box className="border-2 border-fcowarn rounded-md p-5 bg-fcowarnligth flex gap-2 items-start justify-between">

    {
      amendment &&
      <>
        <Box >
          <h2 className="text-lg font-bold">{t("amendments.title")} </h2>
          <p>{t("amendments.endDate")} : <span className="font-bold">
            {dayjs(amendment.end_date).format(t("dates.full", { ns: "common" }))}

          </span></p>
          {amendment.end_time &&
            <p>{t("amendments.endTime")} : <span className="font-bold">
              {amendment.end_time && getDateObjectFromTime(amendment.end_time).format(t("dates.hour", { ns: "common" }))}
            </span></p>
          }

          {amendment?.services_to_add && amendment?.services_to_add.length > 0 &&
            <p>
              {t("amendments.servicesAdded")} : <span className="font-bold">{amendment?.services_to_add.map(s => s.name).join(', ')}</span>
            </p>
          }
          {amendment?.services_to_remove && amendment?.services_to_remove.length > 0 &&
            <p>
              {t("amendments.servicesRemoved")} : <span className="font-bold">{amendment?.services_to_remove.map(s => s.name).join(', ')}</span>
            </p>
          }
        </Box>
        <Button
          disabled={isLoading}
          onClick={() => { setOpenEditModal(true) }}
          color="secondary">
          {
            isLoading ? <CircularProgress size={20} color="secondary" /> : <Edit />
          }

        </Button>
        <FormModal open={openEditModal} setOpen={setOpenEditModal}
          title={""}
          printTitle={t("form.titleBoater")} >
          <AmendmentFormEdit
            onClose={() => {
              // getAmendments(booking.id, amendment.id).then(res => {
              //   setAmendment(res.data)
              // })
              setOpenEditModal(false)
            }}
            booking={booking} amendment={amendment} />
        </FormModal></>
    }

  </Box>)

}