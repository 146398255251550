import dayjs from "dayjs"
import i18n from "../translations/initI18n";

/**
 * 
 * @param time format 00:00
 * @param date format lisible par dayjs
 */
export const getDateObjectFromTime = (time: string = "", date: string | dayjs.Dayjs = dayjs()) => {

    if (!time || typeof time !== "string") {
        return dayjs(date).hour(0).minute(0).second(0)
    }
    const [hour, minute] = time.split(":")

    return dayjs(date).hour(parseInt(hour)).minute(parseInt(minute)).second(0)
}

/**
 * 
 * @param time string 00:00
 * @param duration millisecond to add
 */
export const addMsToTime = (time: string, duration: number) => {
    const dateTime = getDateObjectFromTime(time).add(duration)
    return { dateTime, formattedDatetime: dateTime.format("HH:mm") }
}

export const durationFieldToHoursMinutes = (duration?: string | null) => {
    if (!duration) return ""
    let days = '0', time = duration
    if (duration.includes(" ")) {
        [days, time] = duration.split(' ')
    }
    let [h, m, s] = (time).split(':')
    h = parseInt(h) + (parseInt(days) * 24) + ''
    return `${h}:${m}`
}

export const readableDuration = (duration?: string | null) => {
    if (!duration) return ""
    if (duration.includes(' ')) {
        let [days, time] = duration.split(' ')
        let [h, m, s] = time.split(':')

        return `${days}${i18n.t('dates.d', { ns: "common" })} ${h}:${m}`
    } else {
        let [h, m, s] = duration.split(':')
        if (parseInt(h) > 24) {
            const days = Math.floor(parseInt(h) / 24)

            h = (parseInt(h) - days * 24) + ''
            return `${days}${i18n.t('dates.d', { ns: "common" })} ${h}:${m}`
        } else {
            return `${h.padStart(2, '0')}:${m}`
        }
    }
}

export const durationDisplay = (min: string | dayjs.Dayjs, max: string | dayjs.Dayjs = dayjs(), withPrecision = false) => {
    let minutes = dayjs(max).diff(dayjs(min), "minutes")

    let dateFormat = i18n.t("dates.m", { ns: "common" })
    let days = 0
    if (minutes >= 0 && minutes < 60) {
        return `${String(minutes).padStart(2, '0')}${dateFormat} `
    }

    let hours = dayjs(max).diff(dayjs(min), "hour")
    dateFormat = hours + i18n.t("dates.h", { ns: "common" }) + (minutes % 60 > 0 ? String(minutes % 60).padStart(2, '0') : '')

    if (hours > 24) {
        days = dayjs(max).diff(dayjs(min), "day")
        dateFormat = days + i18n.t("dates.d", { ns: "common" }) + ((withPrecision && hours % 24 > 0) ? " & " + hours % 24 + i18n.t("dates.h", { ns: "common" }) : '')
    }
    if (days >= 30) {
        let months = dayjs(max).diff(dayjs(min), "month")
        dateFormat = months + " " + i18n.t("dates.month", { ns: "common" }) +
            ((days % 30 > 0) ? " & " + days % 30 + i18n.t("dates.d", { ns: "common" }) : '')
    }
    return dateFormat
}

/**
 * merge a date and time string
 * @param date date from api like YYYY-MM-DD
 * @param time hh:mm:ss
 * @returns 
 */
export const addTimeToDate = (date: string | dayjs.Dayjs, time: string) => {
    if (!time) return dayjs(date)
    const [h, m, s] = time.split(":")
    return dayjs(date).hour(parseInt(h)).minute(parseInt(m))
}