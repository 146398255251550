import { Box, Button, Chip, CircularProgress, Divider, Paper, Typography } from "@mui/material";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";
import { getFirstElement } from "../../../../../_helpers/array.helpers";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useElectricityService } from "../../../../../core/services/Electricity.service"
import { useAppDispatch, useAppSelector } from "../../../../../core/store/hooks";
import { getPlugDetail, getPowerunitById } from "../../../../../core/store/actions/Electricity.action";
import { setSelected, setSelectedMarkerId, setSelectedPowerUnit } from "../../../../../core/store/reducers/Electricity.reducer";
import { PlugContractsList } from "./detail/PlugContractsList.element";
import { PlugConsographs } from "./detail/PlugConsoGraphs.element";
import { useTranslation } from "react-i18next";
import FDK from "@wattsonelements/front-fdk";
import { AlertType, useAppContext } from "../../../../contexts/AppContext";
import { createTicket } from "../../../../../core/store/actions/Ticket.actions";
import { GoToTicketBtn } from "../GoToTicketBtn.element";
import { IPlug, IPlugDetail } from "@wattsonelements/front-fdk/dist/models/Electricity.models";

export const ElecDetail: FC = () => {
  const { id } = useParams<{ id: UUID }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { t } = useTranslation("electricity");
  const { setAlertMessage } = useAppContext();
  const { getPlugIconName } = useElectricityService()


  const plug = useAppSelector(state => state.electricity.selected)
  const currentPort = useAppSelector((state) => state.ports.current);
  const powerunit = useAppSelector(state => state.electricity.selectedPowerUnit)
  const [loading, setLoading] = useState(true)
  const childCateg = useAppSelector((state) => state.tickets.categories).find(
    (cat: any) => cat.slug === "electricity"
  );

  const TicketOpenBtn = ({ data }: { data: IPlug | IPlugDetail }) => {
    if (!data.tickets) return <></>;
    const el = getFirstElement(data.tickets) || <></>;
    return el.id ? (
      <GoToTicketBtn nbTickets={data.tickets.length} ticketId={el.id} />
    ) : (
      <></>
    );
  }

  const [creatingTicket, setCreatingTicket] = useState(false);
  const [error, setError] = useState("")

  useEffect(() => {
    if (id && plug?.id !== id) {
      setSelected(null)
      setLoading(true)
      dispatch(getPlugDetail(id)).unwrap().then(() => setError("")).catch(err => {
        switch (err?.status) {
          case 500:
            setError(t('detail.error.plug500'))
            break;
          case 404:
            setError(t('detail.error.plug404'))
            break;
          default:
            setError(t('detail.error.plugDefault'))
            break;
        }
      }).finally(() => {
        setLoading(false)
      })
      // dispatch(setSelectedMarkerId(id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  useEffect(() => {
    return () => {
      // dispatch(setSelectedPowerUnit(null))
    }
  }, [])
  useEffect(() => {
    if (!plug) return
    if (plug.powerunit.id === powerunit?.id) return
    dispatch(getPowerunitById(plug.powerunit.id))
  }, [plug, powerunit, dispatch])


  const createTickets = async (e: any) => {
    if (!plug) return
    setCreatingTicket(true);
    try {
      const defaultValues = {
        // category: isOverConso(selected[i]) ? childCateg!.id : parentCateg!.id,
        category: childCateg!.id,
        spot_id: plug.spot_id ? plug.spot_id : undefined,
        plug_id: plug.id,
        // plug: { id: selected[i].id, name: selected[i].name },
        boat: undefined,
        port: currentPort?.id,
        priority: FDK.Constants.TicketConstants.TicketPriority.NORMAL,
        description: "",
      };
      let res = await dispatch(createTicket(defaultValues));
      if (res.type === "ticket/create/rejected") {
        setAlertMessage!({
          type: AlertType.ERROR,
          message: t("ticketCreationError", { ns: "ticket" }),
        });
      }
    } catch (e) {
      setAlertMessage!({
        type: AlertType.ERROR,
        message: t("ticketCreationError", { ns: "ticket" }),
      });
    }
    setCreatingTicket(false);
  }

  return (
    <Box className="w-full flex flex-grow flex-col max-w-full">
      <Paper className="flex items-center p-3">

        <Button
          onClick={() => {
            navigate("../");
            dispatch(setSelected(null))
          }}
        >
          <ArrowBackIosIcon fontSize="large" />
        </Button>


        <Typography className="whitespace-nowrap flex pr-5" component="h2" variant="h5">
          {t('powerunit')} {plug?.powerunit.name || powerunit?.name}
        </Typography>

        <Divider
          sx={{
            marginX: "30px",
          }}
          orientation="vertical"
          flexItem
        />
        <Box className="flex items-center justify-end w-full gap-3">
          {
            plug && <TicketOpenBtn data={plug} />
          }
          {creatingTicket ?
            <CircularProgress />
            :
            <Button
              disabled={!plug}
              onClick={createTickets}
              variant="contained"
              color="secondary"
            >
              {t("btns.create")}
            </Button>
          }
        </Box>
      </Paper>
      <Paper>
        {powerunit && powerunit.plugs.find(p => p.id === id) &&
          <Box className="flex items-center justify-center w-full my-2 gap-2 mb-12">
            {
              powerunit && powerunit.plugs.map(p => <Button
                color="primary"
                key={p.id}
                variant={
                  p.id === id ? "outlined" : "text"
                }
                className={"gap-2 " +
                  (p.id === id ? "font-bold" : "")
                }
                onClick={() => {
                  setError("")
                  setLoading(true)
                  dispatch(setSelectedMarkerId(p.id))
                  navigate('../' + p.id)
                }}
              >
                <img className="w-6" alt='plug-status' src={require(`../../../../../assets/img/icons/electricity/legend/${getPlugIconName(p)}.svg`)} />
                {t('plug')} {p.name}
              </Button>)
            }
          </Box>
        }

        {loading &&
          <Box className="text-fcosecondary gap-2 flex flex-col items-center justify-center pt-5" >
            <CircularProgress color="secondary" />
            {t('detail.graph.loading')}
          </Box>
        }

        {!loading &&
          <>

            {
              error &&
              <Box className="m-3 p-3 flex flex-col justify-center items-center gap-3 rounded text-fcoerror border-[1px] border-fcoerror" >
                {error}
              </Box>
            }

            {
              plug?.contracts && <PlugContractsList contracts={plug.contracts} />
            }

            {
              plug?.stats &&
              <PlugConsographs defaultSelectedSlug={plug?.default_stat_slug} stats={plug?.stats} />
            }</>}
      </Paper>
    </Box >
  )

}