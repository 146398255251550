import { Box } from "@mui/material"
import { DashboardLayout } from "../../../../layouts/Dashboard.layout"
import { ReservationTable } from "./ReservationTable.element"
import { useEffect } from "react"
import { useAppDispatch } from "../../../../../../core/store/hooks"
import { getBookingList } from "../../../../../../core/store/actions/Crane.actions"
import { Outlet } from "react-router-dom"
import { usePeriodic } from "../../../../../../core/services/Periodic.service"

export const ReservationPage = () => {
    const dispatch = useAppDispatch()
    const { startPeriodic, stopPeriodic } = usePeriodic();

    // const { reservationId } = useParams<{ reservationId: UUID }>();

    useEffect(() => {
        startPeriodic("booking-list", 15, () => {
            return  dispatch(getBookingList());
          });
          return () => {
            stopPeriodic("booking-list");
          };
    }, [])
    return (
        <DashboardLayout>
            <Box className="reservation-content flex-col flex flex-1 max-w-[50%]" >
                <ReservationTable />
            </Box>
            <Box className="reservation-detail flex-col flex flex-1 max-w-[50%]" >
                <Outlet></Outlet>
            </Box>
        </DashboardLayout>
    )
}