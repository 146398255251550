import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../../core/store/hooks";
import { Legend, LegendGrouped } from "../../Legend";
import { Chip } from "@mui/material";
import { TA_BOOKING_STATUS } from "../../../../../../core/models/technicalArea.models";
import { updateFilterWithoutSavingTA } from "../../../../../../core/store/reducers/TechnicalArea.reducer";
import { getTABookingList } from "../../../../../../core/store/actions/TechnicalArea.actions";
import { TParams } from "@wattsonelements/front-fdk/dist/models/Misc.models";

export const TALegend = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation("technicalArea");

  const appfilters = useAppSelector(state => state.technicalArea.filters)
  const filters = useAppSelector(state => state.technicalArea.stats?.filters)
  const counters = useAppSelector(state => state.technicalArea.stats?.counters)
  const legends: LegendGrouped[] = [
    {
      actionGroup: "counter",
      legends: [
        {
          label: <Chip color="secondary" size="small" label={t("legend.currently_available_spots")} />,
          key: "currently_available_spots",
          isFilter: false,
          getStat: (stats) => stats.counters?.currently_available_spots
        },
        {
          label: <Chip color="secondary" size="small" label={t("legend.currently_occupied_spots")} />,
          key: "currently_occupied_spots",
          isFilter: false,
          getStat: (stats) => stats.counters?.currently_occupied_spots
        },
        // {
        //   label: <Chip size="small" label={t("legend.total_spot_available")} />,
        //   key: "",
        //   isFilter: false,
        //   getStat: (stats) => stats.counters?.total_spot_available || ""
        // },
      ]
    },
    {
      actionGroup: "filter",
      legends: [

        {
          label: <Chip size="small" color="secondary" label={t("legend.booking_state_requested")} />,
          key: TA_BOOKING_STATUS.REQUESTED.toUpperCase(),
          isFilter: true,
          getStat: (stats) => stats.filters?.booking_state_requested
        },
        {
          label: <Chip size="small" color="error" label={t("legend.booking_state_on_hold")} />,
          key: TA_BOOKING_STATUS.ON_HOLD.toUpperCase(),
          isFilter: true,
          getStat: (stats) => stats.filters?.booking_state_on_hold
        },
      ]
    }
  ]
  return <>
    <Legend
      legendStatus={appfilters.status}
      updateLegend={(checked: boolean, key: string) => {

        const newFilters = {
          ...appfilters,
          status: { ...appfilters.status, [key.toUpperCase()]: checked },
        };
        console.log("update legend");
        console.table(appfilters)
        console.table(newFilters)

        dispatch(updateFilterWithoutSavingTA(newFilters))
        const getStatusListParam = (statusFilters: { [key: string]: boolean }, params: TParams = {}) => {
          const statusList = Object.keys(TA_BOOKING_STATUS).filter(status => statusFilters[status as TA_BOOKING_STATUS] === true).map(
            s => TA_BOOKING_STATUS[s as keyof typeof TA_BOOKING_STATUS]
          )
          if (statusList.length > 1) {
            params.status__in = statusList.join(',')
          } else if (statusList.length === 1) {
            params.status = statusList[0]
          }
          return params
        }
        dispatch(getTABookingList(getStatusListParam(newFilters.status)))
      }}
      stats={{ filters, counters }}
      legends={legends}
    />
  </>
}