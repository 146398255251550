import { Box, Button, Checkbox, Chip, CircularProgress, FormControl, FormControlLabel, InputLabel, MenuItem, Modal, Paper, Select, Stack, Step, StepLabel, Stepper, TextField, Typography } from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { PlanningRow, TimePlanningRow } from "./TimePlanningRow"
import { IHardware, IPlanning, IPlanningCreation } from "@wattsonelements/front-fdk/dist/models/Booking.models"
import FDK from "@wattsonelements/front-fdk"
import { useAppSelector } from "../../../../../../core/store/hooks"
import { Check, Close } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { REQUEST_STATUS } from "@wattsonelements/front-fdk/dist/api"
import { addMsToTime, getDateObjectFromTime } from "../../../../../../_helpers/dateTime.helper"
import i18n from "../../../../../../translations/initI18n"
import { cp } from "fs"
import { RecapPlanningRow } from "./RecapRow.elemeent"

export const defaultRow: PlanningRow = {
  start_time: null,
  end_time: null,
  maximum_tasks_number: 1,
  isValid: false
}


export const PlanningForm = () => {
  const port = useAppSelector(state => state.ports.current)
  const { t } = useTranslation("crane");

  const [dateStartPeriod, setDateStartPeriod] = useState<dayjs.Dayjs | null>(dayjs())
  const [dateEndPeriod, setDateEndPeriod] = useState<dayjs.Dayjs | null>(dayjs().add(1, "week"))
  const [planningRows, setPlanningRows] = useState<PlanningRow[]>([defaultRow])

  const [days, setDays] = useState<number[]>([])

  const [hardwares, setHardwares] = useState<IHardware[]>()
  const [hardware, setHardware] = useState<string>('')
  const [sending, setSending] = useState<any[]>([])
  const [openModal, setOpenModal] = useState(false)

  const [confirmation, setConfirmation] = useState(false)


  const daysList = [
    { label: t('dates.days.1', { ns: "common" }), value: 1 },
    { label: t('dates.days.2', { ns: "common" }), value: 2 },
    { label: t('dates.days.3', { ns: "common" }), value: 3 },
    { label: t('dates.days.4', { ns: "common" }), value: 4 },
    { label: t('dates.days.5', { ns: "common" }), value: 5 },
    { label: t('dates.days.6', { ns: "common" }), value: 6 },
    { label: t('dates.days.0', { ns: "common" }), value: 7 },
  ]

  useEffect(() => {
    if (!port) return
    FDK.Marina.CraneModule.getHardwares(port.id).then(res => {
      setHardwares(res.data.results)
    })
  }, [port])

  const removePlanningRow = (index: number, row: PlanningRow) => {
    const tmp = [...planningRows];
    tmp.splice(index, 1)
    setPlanningRows(tmp);
  }

  const addPlanningRow = () => {
    const row: PlanningRow = { ...defaultRow }
    console.log("addPlanningRow", planningRows);

    if (planningRows.length > 0) {
      const previous = planningRows.slice(-1)[0]
      console.log('HERE', previous);
      if (previous.end_time) {
        row.start_time = previous.end_time
      }
      if (previous.start_time && previous.end_time) {
        const delta = getDateObjectFromTime(previous.end_time || "").diff(getDateObjectFromTime(previous.start_time || ""))
        row.end_time = addMsToTime(previous.end_time, delta).formattedDatetime
        row.isValid = true
      }
    }

    console.log("addPlanningRow", row);

    setPlanningRows([...planningRows, row])
  }

  const updatePlaningRow = (index: number, row: PlanningRow) => {
    const tmp = [...planningRows]
    tmp[index] = row
    setPlanningRows(tmp)
  }
  const beforeSend = () => {
    if (!isValid()) {
      return
    }
    setOpenModal(true)
    setConfirmation(false)
  }

  const send = () => {
    if (!isValid()) {
      return
    }
    setConfirmation(true)
    const data: IPlanningCreation[] = []
    const tempSending: any[] = []
    days.forEach(weekday => {
      planningRows.forEach(p => {
        const dataToAdd: IPlanningCreation = {
          weekday,
          hardware,
          start_time: p!.start_time as string,
          end_time: p!.end_time as string,
          maximum_tasks_number: p!.maximum_tasks_number,
          from_date: dateStartPeriod!.format("YYYY-MM-DD"),
          to_date: dateEndPeriod!.format("YYYY-MM-DD")
        }
        data.push(dataToAdd)
        tempSending.push({
          ...dataToAdd,
          status: REQUEST_STATUS.PENDING
        })
      })
    })
    setSending([...tempSending])
    console.log(data, tempSending);

    data.forEach((d, i) => {
      FDK.Marina.CraneModule.createAvailabilityPlanning(d).then((res: any) => {
        console.log("SUCCESS", d, res, i, sending);
        tempSending[i] = {
          ...tempSending[i],
          ...res.data,
          status: REQUEST_STATUS.SUCCESS
        }
        setSending([...tempSending])
      }, (err: any) => {
        console.log("ERROR", d, err, sending);
        tempSending[i] = {
          ...tempSending[i],
          ...err,
          status: REQUEST_STATUS.ERROR
        }
        setSending([...tempSending])
      })
    })
  }

  const isValid = () => {
    let valid = true
    if (!planningRows) {
      valid = false
    }
    if (planningRows.length === 0 || planningRows.some(r => !r.isValid)) {
      valid = false
    }
    if (!hardware) {
      valid = false
    }
    if (!dateStartPeriod || !dateEndPeriod) {
      valid = false
    }
    if (dateStartPeriod && dateEndPeriod && dateEndPeriod.isBefore(dateStartPeriod)) {
      valid = false
    }
    return valid
  }


  return <Box className="bg-white p-10" component="form">
    <h2 className="font-bold mb-4 text-xl">{
      t('planning.form.title')
    }</h2>
    <h3 className="font-bold my-4 text-lg">{t('planning.form.subtitle1')}</h3>
    <Box className="grid grid-cols-2 gap-3">
      {/* HARDWARE SELECTION */}
      <FormControl className="col-span-2" fullWidth>
        <InputLabel id="select-service-label">{t('planning.form.hardware')}</InputLabel>
        <Select
          labelId="select-service-label"
          id="select-service-label"
          label={t('planning.form.service')}
          onChange={(e) => {
            setHardware(e.target.value)
            // loadHardwareConfig(e.target.value)
          }}
          value={hardware}
          renderValue={(value) => {
            return hardwares?.find(h => h.id === value)?.name
          }}
        >
          {
            hardwares?.map(h => {
              return <MenuItem key={h.id} value={h.id}>{h.name}</MenuItem>
            })
          }

        </Select>
      </FormControl>
      <FormControl fullWidth>
        <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            format={t('dates.full', { ns: "common" })}
            value={dateStartPeriod}
            onChange={(value) => setDateStartPeriod(value)}
            label={t('planning.form.start')} />
        </LocalizationProvider>
      </FormControl>
      <FormControl fullWidth>
        <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            format={t('dates.full', { ns: "common" })}
            value={dateEndPeriod}
            onChange={(value) => setDateEndPeriod(value)}
            label={t('planning.form.end')} />
        </LocalizationProvider>
      </FormControl>
    </Box>
    <h3 className="font-bold my-4 text-lg">
      {t('planning.form.subtitle2')}
    </h3>
    <Box className="mb-3 flex flex-col gap-3">
      {
        planningRows.map((row, i) => <Box key={"planningrow" + i} className="flex w-full items-center gap-3">
          <TimePlanningRow value={row} onChange={(row) => updatePlaningRow(i, row)} />
          <Button onClick={() => removePlanningRow(i, row)} ><Close /></Button>
        </Box>)
      }

    </Box>
    <Button onClick={addPlanningRow} fullWidth color="secondary" variant="contained">
      {t('planning.form.actions.addhours')}

    </Button>
    <h3 className="font-bold my-4 text-lg">
      {t('planning.form.subtitle3')}

    </h3>

    <Box >
      <Box className="flex flex-wrap justify-center mb-3">
        {daysList.map((day) => {
          return (
            <Box
              key={day.value}
              className="flex-1 border border-fcogreyvariant flex justify-center items-center px-2 py-3">
              <FormControlLabel

                labelPlacement="bottom"
                label={day.label}
                control={<Checkbox
                  onChange={(e) => {
                    const checked = e.target.checked
                    const list = [...days]
                    if (checked) {
                      setDays([...list, day.value])
                    } else {
                      const tmp = [...list]
                      tmp.splice(days.indexOf(day.value), 1)
                      setDays(tmp)
                    }
                  }}
                  color="secondary"
                  checked={days.includes(day.value)}
                />} />
            </Box>
          )
        })}
      </Box>
      <Button disabled={!isValid()} onClick={beforeSend} fullWidth color="secondary" variant="contained">
        {t('planning.form.actions.save')}
      </Button>
    </Box>

    <Modal
      open={openModal}
    >
      <Paper
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 
        -translate-y-1/2 p-4 shadow-md w-auto md:w-2/5 max-h-[80vh]"  >
        <Typography sx={{ fontSize: "1.5em" }} component="h2" className="flex items-center justify-between flex-wrap">
          <span>
            {t('planning.form.saving.title')} {confirmation && sending.some(s => s.status === REQUEST_STATUS.PENDING) && <CircularProgress color="secondary" size={15} />}
          </span>
          <Chip className="block" label={hardwares?.find(h => h.id === hardware)?.name || ""} variant='outlined' />
        </Typography>
        <Box >
          {t('planning.form.saving.periodFrom')} {dateStartPeriod!.format(t(`dates.abbreviation_month_date`, { ns: "common" }))} {t('planning.form.saving.periodTo')} {dateEndPeriod!.format(t(`dates.abbreviation_month_date`, { ns: "common" }))}
        </Box>

        <Stepper className="my-4" activeStep={!confirmation ? 0 : 1} alternativeLabel>
          <Step >
            <StepLabel>{t('planning.form.saving.confirm')}</StepLabel>
          </Step>
          <Step >
            <StepLabel>{t('planning.form.saving.send')}</StepLabel>
          </Step>
        </Stepper>
        {
          !confirmation && <Box className="m-auto flex flex-col items-center gap-2">
            {t('planning.form.saving.confirmDay')}
            <div className="flex gap-1">
              {
                days.map(weekday => <Chip variant="squared" label={t(`dates.days.${weekday}`, { ns: "common" })} />)
              }
            </div>
            <Stack className="mb-4 overflow-auto max-h-[55vh] pb-20" spacing={2}>
              {
                planningRows.map(item =>
                  <RecapPlanningRow
                    showWeekDay={false}
                    confirmation={confirmation}
                    maximumTasksNumber={item.maximum_tasks_number}
                    startTime={item.start_time!}
                    endTime={item.end_time!}
                  />)
              }
              <Button onClick={() => {

                send()
              }} variant='contained' color="secondary">
                {t('planning.form.confirm')}
              </Button>
            </Stack>

          </Box>
        }
        {
          confirmation &&
          <Stack className="mb-4 overflow-auto max-h-[55vh] pb-20" spacing={2}>
            {
              sending.map((item, i) => <Box key={"itemsending" + i} className="flex flex-col gap-1 rounded-md p-2 border-b shadow border-l-fcogreyvariant">
                {
                  (i === 0 || item.weekday !== sending[i - 1].weekday) &&
                  <div className="text-center text-xl mt-3 font-bold">
                    {t(`dates.days.${item.weekday}`, { ns: "common" })}
                  </div>

                }
                <RecapPlanningRow
                  confirmation={confirmation}
                  maximumTasksNumber={item.maximum_tasks_number}
                  startTime={item.start_time}
                  endTime={item.end_time}
                  status={item.status}
                  message={item?.message}
                  weekday={item.weekday}
                />
              </Box>)
            }
          </Stack>
        }

        <Box className="flex justify-end absolute bottom-0 w-full -ml-4 py-3 px-4 bg-white rounded">
          {
            !confirmation &&
            <div className="flex gap-2">
              <Button onClick={() => setOpenModal(false)} variant="outlined" color="secondary">
                {t('planning.form.backToEdit')}
              </Button>

            </div>
          }
          {
            confirmation && <Button
              variant="outlined"
              disabled={sending.some(s => s.status === REQUEST_STATUS.PENDING)}
              onClick={() => {
                setOpenModal(false)
                setSending([])
                setPlanningRows([defaultRow])
                setDays([])
                setHardware("")
                setDateStartPeriod(dayjs())
                setDateEndPeriod(dayjs().add(1, "week"))
              }}>
              {t('planning.form.saving.close')}
            </Button>
          }

        </Box>
      </Paper>
    </Modal>
  </Box>

}