import { FC, useState } from "react"
import { ReadOnlyField } from "../../../UX/inputs/ReadOnlyField"
import { TABooking } from "../../../../../core/models/technicalArea.models"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { getDateObjectFromTime } from "../../../../../_helpers/dateTime.helper"
import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../../../core/store/hooks"
import { cancelTABooking, getTABookingDetail, rejectTABooking } from "../../../../../core/store/actions/TechnicalArea.actions"
import { updateItemInList } from "../../../../../core/store/reducers/TechnicalArea.reducer"
import { TADuration } from "./form/Duration"

type TARejectFormProps = {
  booking: TABooking,
  modalMode: "reject" | "cancel"
  onClose: () => any
}
export const TARejectForm: FC<TARejectFormProps> = ({ booking, modalMode, onClose = () => { } }) => {
  const { t } = useTranslation("technicalArea")
  const dispatch = useAppDispatch()

  const config = useAppSelector(state => state.technicalArea.config)
  const { handleSubmit, control, formState: { isValid } } = useForm({
    defaultValues: { motives: "", },
    mode: "all"
  })

  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState(false)

  const submit: SubmitHandler<{ motives: string }> = (data) => {
    console.log("submit", data);
    setError(false)
    setIsSaving(true)
    if (modalMode === "reject") {
      rejectTABooking(booking.id, {
        refusal_motive: data.motives
      }).then(res => {
        console.log(res.data);
        onClose()
        dispatch(getTABookingDetail(booking.id)).then(res => {
          if (!res.type.includes("rejected")) {
            dispatch(updateItemInList(res.payload))
          }
        })

      }, err => {
        // console.log("error", err);
        setError(true)
      }).finally(() => {
        setIsSaving(false)
      })
    } else {
      cancelTABooking(booking.id, {
        refusal_motive: data.motives
      }).then(res => {
        console.log(res.data);
        onClose()
        dispatch(getTABookingDetail(booking.id)).then(res => {
          if (!res.type.includes("rejected")) {
            dispatch(updateItemInList(res.payload))
          }
        })
      }, (err) => {
        // console.log("error", err);
        setError(true)
      }).finally(() => {
        setIsSaving(false)
      })
    }

  }

  return <form onSubmit={handleSubmit(submit)} className="grid grid-cols-4 gap-6">

    <FormControl fullWidth className="col-span-4">
      <InputLabel className="font-bold" id="select-rejection-motive-label">{t("form.reject.motive")}</InputLabel>
      <Controller
        name="motives"
        control={control}
        rules={{ required: true }}
        render={({ field }) => <Select
          value={field.value}
          label={t("form.reject.motive")}
          labelId="select-rejection-motive-label"
          onChange={(e) => {
            console.log(e.target.value);
            field.onChange(e.target.value)
            field.onBlur()
          }}
        >
          {
            config?.refusal_motives.map(motive => <MenuItem key={motive.id} value={motive.id}>{motive.name}</MenuItem>)
          }
        </Select>}
      />

    </FormControl>
    <ReadOnlyField className="col-start-1  col-span-2" label={t("form.boater", { ns: "ticket" })} value={booking.boater.first_name + " " + booking.boater.last_name} />
    <ReadOnlyField className="col-span-2" label={t("form.boat", { ns: "ticket" })} value={booking.boat.name + (booking.boat.registration_number ? (" - " + booking.boat.registration_number) : "")} />

    <ReadOnlyField className="col-start-1" label={t("form.dateStart")} value={dayjs(booking.start_date).format(t('dates.full', { ns: "common" }))} />
    <ReadOnlyField label={t("form.hourStart")} value={getDateObjectFromTime(booking.start_time).format(t('dates.hour', { ns: "common" }))} />

    <ReadOnlyField label={t("form.dateEnd")} value={dayjs(booking.end_date).format(t('dates.full', { ns: "common" }))} />
    <ReadOnlyField label={t("form.hourEnd")} value={booking.end_time ? getDateObjectFromTime(booking.end_time).format(t('dates.hour', { ns: "common" })) : "-"} />
    <TADuration
      className="col-span-4 text-sm -mt-6 py-3"
      dateStart={booking.start_date}
      timeStart={booking.start_time}
      dateEnd={booking.end_date}
      timeEnd={booking.end_time}
    />
    <ReadOnlyField className="col-span-4" label={t("form.service")} value={booking.services.map(s => s.name).join(', ')} />
    <ReadOnlyField className="col-span-4" label={t("form.description")} value={booking.boater_comment || "-"} />
    <Button
      disabled={!isValid || isSaving}
      type="submit"
      color="secondary"
      className="col-span-4" variant="contained">{isSaving && <CircularProgress size={20} />}
      {!isSaving && modalMode === "reject" &&
        t("form.actions.reject")
      }
      {!isSaving && modalMode === "cancel" &&
        t("form.actions.cancel")
      }
    </Button>
    {error && <Box className="col-span-4 text-fcoerror text-center text-sm border border-fcoerror rounded-md py-1">
      {
        modalMode === "reject" && t('form.reject.error')
      }
      {
        modalMode === "cancel" && t('form.cancel.error')
      }
    </Box>}

  </form>
}