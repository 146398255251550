import { LinearProgress } from "@mui/material";
import { useAppSelector } from "../../../../core/store/hooks";
import { useAppContext } from "../../../contexts/AppContext";

export const AppLoader = () => {
  const { appLoading } = useAppContext();
  const tickets = useAppSelector((state) => state.tickets);

  return (
    appLoading || tickets.updating.length > 0 ?
      <div className="bg-black bg-opacity-50 top-0 left-0 fixed w-full h-full z-[999] flex items-center justify-center">
        <div className="w-72"><LinearProgress color="primary" /></div>
      </div>
      : null
  )
}