import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../core/store/hooks";
import { Avatar, Box, Button, Chip, CircularProgress, Paper, Stack, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { BookingStatusChip } from "../StatusChip";
import { getDateObjectFromTime } from "../../../../../../_helpers/dateTime.helper";
import { BOOKING_STATUS, IBooking } from "@wattsonelements/front-fdk/dist/models/Booking.models";
import FDK from "@wattsonelements/front-fdk";
import { AlertType, useAppContext } from '../../../../../contexts/AppContext';
import { cancelBooking as cancelBookingAction, doneBooking as doneBookingAction, updateComment } from "../../../../../../core/store/reducers/Crane.reducer";
import { getBookingById } from "../../../../../../core/store/actions/Crane.actions";
import { BoaterInfo } from "../../Common/BoaterInfos";
import { AutomaticReport, ReportLayout } from "../../Common/AutomaticReport";
import { ReadOnlyField } from "../../../../UX/inputs/ReadOnlyField";


export const ReservationDetail = () => {
  const { reservationId } = useParams<{ reservationId: UUID }>();
  const dispatch = useAppDispatch()
  const { setAlertMessage } = useAppContext();
  const navigate = useNavigate()

  const { t } = useTranslation("crane");
  const disabled = false // use booking status ?
  const { selectedBooking } = useAppSelector(state => state.crane)
  const [comment, setComment] = useState(selectedBooking?.comment);
  const [actionLoader, setActionLoader] = useState(false)
  const [actionName, setActionName] = useState<"done" | "cancel" | string>("")

  useEffect(() => {
    if (selectedBooking) {
      setComment(selectedBooking.comment)
    }
  }, [reservationId, selectedBooking])
  useEffect(() => {
    if (!reservationId) return
    dispatch(getBookingById(reservationId))
  }, [reservationId])
  // useEffect(()=> {
  //     setAlertMessage!({ message:"Message", sub_message:"Sub message", title:"Titre de la modal", 
  //     type: AlertType.ERROR })
  // },[])

  const checkUpdateDescription = async () => {
    if (!reservationId || !selectedBooking) return
    if (comment !== selectedBooking!.comment) {
      try {
        await FDK.Marina.CraneModule.udpateBooking(reservationId, { comment });
        dispatch(updateComment({ id: reservationId, comment }))
      } catch (e) {
        setAlertMessage!({ type: AlertType.ERROR, message: t('') })
      }
    }
  }

  const cancelBooking = () => {
    if (!reservationId) return
    setActionLoader(true)
    setActionName("cancel")
    FDK.Marina.CraneModule.sendBookingAction(reservationId, "cancel").then(res => {
      console.log('canceled', res);
      const message = ""
      dispatch(cancelBookingAction(reservationId || ""))
      setAlertMessage!({ message, ...res.data, type: AlertType.SUCCESS })
    }, err => {
      const message = ""
      setAlertMessage!({ message, ...err, type: AlertType.ERROR })
    }).finally(() => {
      setActionLoader(false)
    })
  }

  const doneBooking = () => {
    if (!reservationId) return
    setActionLoader(true)
    setActionName("done")
    FDK.Marina.CraneModule.sendBookingAction(reservationId, "done", { comment }).then(res => {
      console.log('done', res);
      const message = ""
      dispatch(doneBookingAction(reservationId || ""))
      setAlertMessage!({ message, ...res.data, type: AlertType.SUCCESS })
    }, err => {
      const message = ""
      setAlertMessage!({ message, ...err, type: AlertType.ERROR })
    }).finally(() => {
      setActionLoader(false)
    })
  }

  const boatLine = (data: IBooking) => {
    const text = []
    if (data?.contract?.spot?.name) {
      text.push(data?.contract?.spot?.name)
    }
    if (data?.boat?.name) {
      text.push(data?.boat?.name)
    }
    if (data?.boat?.registration_number) {
      text.push(data?.boat?.registration_number)
    }

    return text.join(" - ")
  }

  return (
    <>
      {selectedBooking &&
        <Paper className="p-5 px-10 flex flex-col gap-3">
          <header className="flex justify-between mb-6">
            <Box>
              <Typography className="flex gap-2 items-center" component='h2' variant="h5">
                {selectedBooking?.service ? t(`service.${selectedBooking?.service}`) : t('booking.unavailable')}

              </Typography>
              <p className="font-bold">{boatLine(selectedBooking)}</p>
              {t('created_at', { ns: "common" })} {dayjs(selectedBooking.date_created).format(t('dates.full', { ns: "common" }))}
            </Box>
            <Box className="flex flex-col gap-2">
              <BookingStatusChip
                className="text-sm  py-1 px-3"
                status={selectedBooking?.status} />
              <Box className="flex gap-2">
                {
                  selectedBooking?.related_planning &&
                  <>
                    <Chip
                      variant="squared"
                      color="primary"
                      size="small"
                      className="mb-1"
                      label={<>
                        {getDateObjectFromTime(selectedBooking?.related_planning?.start_time, selectedBooking.date).format(t('dates.day_month', { ns: "common" }))}

                      </>} />

                    <Chip
                      variant="squared"
                      color="primary"
                      size="small"
                      className="mb-1"
                      label={<>
                        {getDateObjectFromTime(selectedBooking?.related_planning?.start_time, selectedBooking.date).format(t('dates.hour', { ns: "common" }))}
                        &nbsp;{t('dates.to', { ns: "common" })}&nbsp;
                        {getDateObjectFromTime(selectedBooking?.related_planning?.end_time, selectedBooking.date).format(t('dates.hour', { ns: "common" }))}
                      </>} />
                  </>
                }
              </Box>
            </Box>
          </header>
          <Box className="flex items-center">
            <Avatar src={selectedBooking?.created_by?.picture} />
            <Box className="ml-3">
              <Typography sx={{
                lineHeight: '1em'
              }} className="leading-4" variant="subtitle2"> {t("booking.createdBy")}</Typography>
              <Typography sx={{
                lineHeight: '1em'
              }} variant="h6">
                {selectedBooking?.created_by?.first_name} {selectedBooking?.created_by?.last_name}
              </Typography>
            </Box>
          </Box>
          <Box className="grid grid-cols-2 mt-2 gap-5">
            <Stack className="mt-2" spacing={2}>
              {
                selectedBooking?.boater_comment &&
                <ReadOnlyField className="opacity-90" label={t("booking.form.boaterComment")} value={selectedBooking.boater_comment} />
              }
              <TextField
                disabled={disabled}
                onChange={(e) => setComment(e.target.value)}
                // onBlur={() => setEditDescription(false)}
                // onBlur={cancelDescription}
                onBlur={checkUpdateDescription}
                // onFocus={() => setEditDescription(true)}
                className="w-full"
                sx={{ marginTop: "10px" }}
                label={t("booking.form.description")}
                multiline
                value={comment}
                minRows={4}
              />
              {
                selectedBooking?.boater &&
                <BoaterInfo boater={selectedBooking?.boater} title={t('booking.boater_infos')} />

              }

            </Stack>

            <ReportLayout
              header={<Typography sx={{ fontWeight: 'bold', fontSize: "0.9em" }} className="text-fcomain pb-1">
                {t('report')}</Typography>}
            >
              <AutomaticReport logs={selectedBooking.formatted_logs} />
            </ReportLayout>
          </Box>
          {
            selectedBooking.status === BOOKING_STATUS.BOOKED &&
            <Button className="flex gap-3" disabled={actionLoader} onClick={cancelBooking} fullWidth color="error" variant="outlined">
              {actionLoader && actionName === "cancel" &&
                <CircularProgress size={20} color="info" />
              }
              <span>{t('booking.cancel')}</span>
            </Button>
          }
          {
            selectedBooking.status === BOOKING_STATUS.BOOKED &&
            <Button className="flex gap-3" disabled={actionLoader} onClick={doneBooking} fullWidth color="secondary" variant="contained">
              {actionLoader && actionName === "done" &&
                <CircularProgress size={20} color="info" />
              }
              <span>{t('booking.done')}</span>
            </Button>
          }
        </Paper>
      }
    </>
  )
}