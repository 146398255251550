import FDK from "@wattsonelements/front-fdk";
import dayjs from "dayjs";

type TidesForecast = {
  for_date: string,
  tide_forecast: Tide[]
}

export type Tide = {
  coef?: number | string,
  tideTime: string;
  tideHeight_mt: number;
  tide_height: number;
  tide_unit: string
  tideDateTime: string;
  tide_type: 'HIGH' | 'LOW' | 'BM' | 'PM';
  tide_label: 'HIGH' | 'LOW'
}

export const getTide = (portId: string, date: dayjs.Dayjs) => {
  return FDK.MainAPI.API.get<TidesForecast>(
    `/v2/marina/tides/details?port_id=${portId}&for_date=${date.format("YYYY-MM-DD")}`
  )
}

export const getTideForDateTime = (date: dayjs.Dayjs, tides: Tide[]) => {
  let currentTide: Tide | null = null;
  tides.some(tide => {
    const tideTime = dayjs(tide.tideDateTime);
    if (dayjs(date).isBefore(tideTime)) {
      currentTide = tide;
      return true;
    }
    // if no tide for today
    return false;
  });
  if (!currentTide && tides.length > 0) {
    currentTide = tides.pop()!
  }
  return currentTide
}