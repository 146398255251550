import { Alert, Box, Button, CircularProgress, TextField } from "@mui/material";
import { TableSectionRow } from "./Table.row";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../../core/store/hooks";
import { FC, forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { FieldValues, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { PortCMSPreviewContext } from "../Preview/PreviewContext";
import { addSection, updateSection } from "../../../../../../core/store/actions/Port.actions";
import { LAYOUT_NAME, TableLayout } from "../../../../../../core/models/portCMS.models";
import { AlertType, useAppContext } from "../../../../../contexts/AppContext";
import { SectionNameI18n } from "../Sections.list";
import { Add } from "@mui/icons-material";


type TableFormProps = {
  section: Partial<TableLayout>,
  onSubmit?: (type: "success" | "error") => any
}
/**
 * Require a FormProvider above
 * @param props TableFormProps
 * @returns 
 */
export const TableForm = forwardRef<{ submit: (data: TableLayout) => void }, TableFormProps>(({ section, onSubmit = () => { } }, ref) => {
  const { t } = useTranslation(["portCMS"]);
  const port = useAppSelector(state => state.ports.current)
  const dispatch = useAppDispatch()
  const { setPreviewSection, formsDirty, setFormsDirty } = useContext(PortCMSPreviewContext)
  const { setAlertMessage, setAppLoading } = useAppContext()
  const [isSending, setIsSending] = useState(false)

  const { control, reset, register, handleSubmit, watch, formState } = useFormContext<TableLayout>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'table',
    rules: {
      required: true,
      validate: (val) => {
        return val.length > 3 ? false : true
      }
    }
  });

  useImperativeHandle(ref, () => {
    return {
      submit
    }
  }, [])

  const addOrUpdate = (data: TableLayout) => {
    if (data.id) {
      return dispatch(updateSection({
        port: port!.id,
        section: {
          id: data.id,
          title: data.title,
          layout: data.layout,
          table: data.table
        }
      }))
    } else {
      return dispatch(addSection({
        port: port!.id,
        section: {
          id: data.id,
          title: data.title,
          layout: data.layout,
          table: data.table
        }
      }))
    }
  }

  const submit = (data: TableLayout) => {
    if (!port) return
    setAppLoading!(true)
    setIsSending(true)
    addOrUpdate(data).then(res => {
      if (res.type.includes('rejected')) {
        setAlertMessage!({
          title: t(SectionNameI18n[section.layout!]) + ' - ' + data.title + ' ' + (res.payload.data?.message || ''),
          message: res.payload.data?.message || t('sections.form.saved.error'),
          sub_message: res.payload.data?.sub_messsage || "",
          type: AlertType.ERROR
        })
        onSubmit("error")
      } else {

        setAlertMessage!({
          title: t(SectionNameI18n[section.layout!]) + ' - ' + data.title + ' ' + (res.payload.data?.message || ''),
          message: t('sections.form.saved.success'),
          type: AlertType.SUCCESS
        })
        reset({}, { keepValues: true })
        onSubmit("success")
        setFormsDirty({
          ...formsDirty,
          [data.layout + '-' + data.id]: false
        })
      }
    }, err => {
      setAlertMessage!({
        message: "error",
        type: AlertType.ERROR
      })
      onSubmit("error")
    }).finally(
      () => {
        setAppLoading!(false)
        setIsSending(false)
      }
    )
    setPreviewSection(data)
  }
  return (
    <form
      className="flex flex-col gap-3"
      onSubmit={handleSubmit(submit)}>
      <TextField {...register("title", { maxLength: 30 })} className="self-start" label={t("sections.form.title")} />
      <Box className="mb-3 flex flex-col">
        {
          fields.map((field, index) =>
            <TableSectionRow
              disabledAdd={fields.length >= 3}
              key={field.id}
              control={control}
              index={index}
              row={field as { label: string; value: string }}
              onAdd={() => append({ value: "", label: "", layout: LAYOUT_NAME.LABEL_VALUE })}
              onDelete={() => { remove(index) }} />)
        }
        {
          fields.length === 0 && <Alert className="mb-3" severity="warning">
            {t('sections.table.form.minimum')}
          </Alert>
        }
        {
          fields.length === 0 && <Button onClick={() => {
            append({ value: "", label: "", layout: LAYOUT_NAME.LABEL_VALUE })
          }} className="self-end" variant="outlined" endIcon={<Add />}>
            {t('sections.table.form.add')}
          </Button>
        }
      </Box>
      <Button
        startIcon={isSending && <CircularProgress size={20} />}
        disabled={isSending}
        type="submit"
        color="secondary"
        variant="contained">
        {t("sections.form.save")}</Button>
    </form>
  )
})