import { FC, useEffect, useState } from "react";
import { AreaLayout, OpeningDate, Schedule, ScheduleDay } from "../../../../../../core/models/portCMS.models";
import { Alert, Box, Button, Typography } from "@mui/material";
import { AccessTime, Close, Mail, Phone, } from "@mui/icons-material";
import dayjs from "dayjs";
import { getDateObjectFromTime } from "../../../../../../_helpers/dateTime.helper";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { currentDayNumber, getContactsCategories, getCurrentOpeningPerdiod } from "./Area.helpers";

type AreaPreviewProps = {
  section: AreaLayout
}
export const AreaPreview: FC<AreaPreviewProps> = ({ section }) => {
  const { t } = useTranslation("portCMS")
  const [openContact, setOpenContact] = useState(false)
  const [openOpeninghour, setOpenOpeninghour] = useState(false)
  const [currentSchecule, setCurrentSchedule] = useState<OpeningDate | null>(null)
  const [currentDay, setCurrentDay] = useState<ScheduleDay[] | null>(null)
  const [contactTabs, setContactTabs] = useState<string[]>([])
  const [contactTab, setContactTab] = useState("")

  useEffect(() => {
    if (!!!section?.area?.opening_dates?.dates) return
    const schedule = getCurrentOpeningPerdiod(section.area.opening_dates.dates)
    if (schedule) {
      setCurrentSchedule(schedule)
      const dayNumber = currentDayNumber()
      setCurrentDay(schedule.schedule[dayNumber])
    }

    if (section.area.contacts) {
      const tabs = getContactsCategories(section.area.contacts)
      setContactTabs(tabs.reverse())
      if (tabs.length > 0) {
        setContactTab(tabs[0])
      }
    }
  }, [section])

  return (
    <Box >
      <p className="text-fcomain text-xl font-bold text-center mb-2">{section.title}</p>
      <Box className="text-[#0eb6cd] drop-shadow-md bg-white rounded-xl">
        <Box onClick={() => setOpenOpeninghour(true)} className="cursor-pointer py-2 px-4 text-center items-center flex flex-col gap-2 font-bold ">
          <AccessTime fontSize="large" />
          {
            currentDay?.map(h =>
              getDateObjectFromTime(h.open).format(t("dates.hour", { ns: "common" })) +
              "-" +
              getDateObjectFromTime(h.close).format(t("dates.hour", { ns: "common" }))).join(" / ")
          }
          {/* <Box className="w-60 h-40 overflow-auto">
            {
              JSON.stringify(sections)
            }
          </Box> */}
        </Box>
        <Box className="flex text-sm justify-between items-center border-fcogreyvariant border-t uppercase">
          {section.area.email &&
            <Box className="flex-1 py-3 text-center px-2 border-fcogreyvariant border-r" >
              <Mail /> {t('sections.area.preview.email')}
            </Box>
          }
          {section.area.phone &&
            <Box className="flex-1 py-3 text-center px-2 border-fcogreyvariant border-r">
              <Phone /> {t('sections.area.preview.phone')}
            </Box>
          }
          {section.area.vhf_channel &&
            <Box className="flex-1 py-3 text-center px-2 text-fcomain flex gap-1">
              <Mail /> {t('sections.area.preview.canal')}&nbsp;{section.area.vhf_channel}
            </Box>
          }
        </Box>
      </Box>
      {
        section.button && contactTabs.length > 0 && <button onClick={() => setOpenContact(true)} className="bg-[#0eb6cd] text-sm rounded-3xl text-white py-1 font-bold px-4 mt-4">
          {section.button.label}
        </button>
      }
      {!currentSchecule &&
        <Alert variant="filled" className="block w-full my-3" severity="error">
          {t("sections.area.openingForm.noCurrentPeriod")}
        </Alert>
      }
      {
        (openContact || openOpeninghour) && <Box className="bg-black/20 z-40  backdrop-opacity-20 absolute top-0 left-0 right-0 bottom-0 flex items-end">
          <Box className="bg-white w-full min-h-[60%] rounded-xl px-4 pt-4 ">
            <Box className="flex justify-end mb-2">
              <Button onClick={() => {
                setOpenContact(false)
                setOpenOpeninghour(false)
              }
              }><Close className="text-[#0eb6cd]" /></Button>
            </Box>
            <Box>
              {
                openContact && <>
                  <Box className="flex gap-3 bg-fcogrey p-2 rounded overflow-y-auto mb-3">
                    {
                      contactTabs.map(tab => <button className={classNames(
                        "py-2 px-5 rounded flex-1",
                        {
                          "bg-white shadow font-bold": tab === contactTab,
                          "text-fcotextgrey": tab !== contactTab
                        }
                      )} onClick={() => setContactTab(tab)}>{tab}</button>)
                    }
                  </Box>
                  <Box className="flex flex-col gap-3">
                    {
                      section.area.contacts.filter(c => c.tab_name === contactTab).map(contact => <Box
                        className="flex justify-between items-center">
                        <p className='font-bold text-fcomain'>
                          {contact.label}
                        </p>
                        <Box className="text-[#0eb6cd] flex gap-3 uppercase">
                          {
                            contact.email &&
                            <button className="shadow text-xs font-bold rounded-2xl uppercase px-4 py-1 drop-shadow-md block">
                              <Phone />
                              {t('sections.area.preview.phone')}
                            </button>

                          }
                          {
                            contact.phone &&
                            <button className="shadow text-xs font-bold rounded-2xl uppercase px-4 py-1 drop-shadow-md block">
                              <Mail />
                              {t('sections.area.preview.email')}
                            </button>
                          }
                        </Box>
                      </Box>)
                    }
                  </Box>
                </>
              }

              {
                openOpeninghour && currentSchecule && <div className="text-fcomain px-4">
                  <p className="text-fcomain text-xl font-bold text-center mb-2">{section.title}</p>
                  <DaysDisplay day={1} hours={currentSchecule.schedule[1]} />
                  <DaysDisplay day={2} hours={currentSchecule.schedule[2]} />
                  <DaysDisplay day={3} hours={currentSchecule.schedule[3]} />
                  <DaysDisplay day={4} hours={currentSchecule.schedule[4]} />
                  <DaysDisplay day={5} hours={currentSchecule.schedule[5]} />
                  <DaysDisplay day={6} hours={currentSchecule.schedule[6]} />
                  <DaysDisplay day={7} hours={currentSchecule.schedule[7]} />
                </div>
              }
            </Box>
          </Box>
        </Box>
      }
    </Box>
  )

}
type DaysDisplayProps = {
  day: number,
  hours: ScheduleDay[],
}
const DaysDisplay: FC<DaysDisplayProps> = ({ day, hours }) => {
  const { t } = useTranslation("common")

  return <Box className={classNames("grid grid-cols-3 gap-2", { "font-bold": (dayjs().day() === 0 ? 7 : dayjs().day()) === day })}>
    <div>{t(`dates.days.${day}`)}&nbsp;:</div>
    <div className="col-span-2">
      {hours.map(h => getDateObjectFromTime(h.open).format(t("dates.hour")) +
        "-" +
        getDateObjectFromTime(h.close).format(t("dates.hour"))).join(" / ")}
    </div>
  </Box>
}