import { Alert, Box, Button, LinearProgress, Skeleton, Tooltip, Typography } from "@mui/material"
import { SectionsList } from "../../elements/CMS/Port/Sections.list"
import { PageTitle } from "../../elements/UI/PageTitle.element"
import { useTranslation } from "react-i18next"
import { Add, DirectionsBoatOutlined, DragHandle, Edit, PhonelinkSetup } from "@mui/icons-material"
import { PortCMSPreviewContext, PortCMSPreviewProvider } from "../../elements/CMS/Port/Preview/PreviewContext"
import { PreviewContainer } from "../../elements/CMS/Port/Preview/Preview.container"
import { FC, useContext, useEffect, useState } from "react"
import { AddSectionModal } from "../../elements/CMS/Port/AddSection.modal"
import { useAppDispatch, useAppSelector } from "../../../../core/store/hooks"
import { getPortSections } from "../../../../core/store/actions/Port.actions"

export const PortCMSPage = () => {
  const { t } = useTranslation(["portCMS"]);
  const dispatch = useAppDispatch()

  const { current: port, sectionsStatus, sections } = useAppSelector(state => state.ports)
  useEffect(() => {
    if (!port) return
    dispatch(getPortSections(port.id))
  }, [port])

  return (
    <Box className="w-full h-full bg-white">
      <PortCMSPreviewProvider>
        <PageTitle
          title={t("page.title")}
          subtitle={t("page.subtitle")}
          icon={<PhonelinkSetup htmlColor="white" />}
        />

        <Box className="flex gap-3 p-5">
          <Box className="w-[60%] min-w-[750px] bg-fcolightblue/[.52] p-12 rounded-3xl ">
            <Box className="flex justify-between mb-4 items-center">
              <Typography
                className="pl-4 pb-3"
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.5em"
                }}>{t("page.tabs.marina")}
              </Typography>
              <SectionListAction />
            </Box>

            {
              (sectionsStatus === "loaded" && sections.length === 0) &&
              <Alert severity="info">{t("sections.noSection")}</Alert>
            }
            {
              (sectionsStatus === "error" && sections.length === 0) &&
              <Alert severity="error">{t("sections.error")}</Alert>
            }
            {
              (sectionsStatus === "loading" && sections.length === 0) &&
              <Box className="flex flex-col gap-6">
                <LinearProgress color="secondary" />
                <Box className="flex flex-col gap-3 bg-white rounded-lg p-6">
                  <Skeleton variant="rectangular" width={"30%"} height={40} />
                  <Skeleton variant="rectangular" width={"100%"} height={20} />
                  <Skeleton variant="rectangular" width={"80%"} height={20} />
                </Box>
                <Box className="flex flex-col gap-3 bg-white rounded-lg p-6">
                  <Skeleton variant="rectangular" width={"30%"} height={40} />
                  <Skeleton variant="rectangular" width={"100%"} height={20} />
                  <Skeleton variant="rectangular" width={"80%"} height={20} />
                </Box>
              </Box>
            }
            {
              (sectionsStatus === "loaded" || sections.length > 0) &&
              <SectionsList></SectionsList>
            }

          </Box>
          <PreviewContainer />
        </Box>
      </PortCMSPreviewProvider >

    </Box >
  )
}

const SectionListAction: FC = () => {
  const { t } = useTranslation(["portCMS"]);
  const { isDraggable, setIsDraggable, canDrag } = useContext(PortCMSPreviewContext)
  const [addModalOpen, setAddModalOpen] = useState(false)
  return <Box className="pr-5 flex gap-3 items-center">
    <Button onClick={() => {
      setAddModalOpen(true)
    }} startIcon={<Add />} color="secondary" variant="contained">{t('actions.add')}</Button>
    <Tooltip placement="top" arrow title={!canDrag && "Veuillez enregistrer vos modifications avant de déplacer vos sections !"}>
      <span>
        <Button
          disabled={!canDrag}
          startIcon={
            isDraggable ? <Edit /> : <DragHandle />
          } variant="outlined" onClick={() => setIsDraggable(!isDraggable)}>

          {
            isDraggable ? t('actions.edit') : t('actions.reorder')
          }
        </Button>
      </span>
    </Tooltip>
    <AddSectionModal setOpen={setAddModalOpen} open={addModalOpen} />
  </Box>
}