import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../../core/store/hooks"
import { ModuleTable } from "../../Table"
import { BOOKING_STATUS, IPlaningAvailability, IPlanning, TBatchBooking } from "@wattsonelements/front-fdk/dist/models/Booking.models";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getPlanningSlotList } from "../../../../../../core/store/actions/Crane.actions";
import dayjs from "dayjs";
import { Box, Button, Checkbox, Chip, FormControl, InputLabel, LinearProgress, ListItemText, MenuItem, Modal, OutlinedInput, Paper, Select } from "@mui/material";
import { getDateObjectFromTime } from "../../../../../../_helpers/dateTime.helper";
import { BookingStatusChip } from "../StatusChip";
import { TableTop } from "../../TableTop";
import { Add, ChevronLeft } from "@mui/icons-material";
import { ReservationForm } from "../reservation/Reservation.form";
import FDK from "@wattsonelements/front-fdk";
import { AlertType, useAppContext } from "../../../../../contexts/AppContext";
import FilterListIcon from "@mui/icons-material/FilterList";
import { BaseSingleInputFieldProps, CalendarIcon, DateValidationError, FieldSection, LocalizationProvider, UseDateFieldProps } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { setPlanningListLoading, setSelectedBooking, updateFilterWithoutSaving } from "../../../../../../core/store/reducers/Crane.reducer";
import { getFilteredPlanning } from "../../../../../../core/store/selectors/Manutention.selector";
import { usePeriodic } from "../../../../../../core/services/Periodic.service";
import i18n from "../../../../../../translations/initI18n";
import { DateType } from "../../../../UX/inputs/DateSelectionWithActions";

interface ButtonFieldProps extends UseDateFieldProps<dayjs.Dayjs>,
  BaseSingleInputFieldProps<
    dayjs.Dayjs | null,
    dayjs.Dayjs,
    FieldSection,
    DateValidationError
  > {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PlanningTable = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation("crane");
  const navigate = useNavigate()
  const { startPeriodic, stopPeriodic } = usePeriodic();
  const { setAlertMessage, setAppLoading } = useAppContext();
  const [showLoader, setShowLoader] = useState(false)

  const port = useAppSelector(state => state.ports.current)
  const { selectedBooking, filters, planningListLoading } = useAppSelector(state => state.crane)
  const planning = useAppSelector(getFilteredPlanning)

  const [selected, setSelected] = useState<IPlaningAvailability[]>([])
  const [openDatePicker, setOpenDatePicker] = useState(false);

  // modal 
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (!port) return
    startPeriodic("slots-list", 15, () => {
      return dispatch(getPlanningSlotList({ port: port.id }))
    });
    return () => {
      stopPeriodic("slots-list");
    };
  }, [port])

  useEffect(() => {
    onChangeDateFilter("date", dayjs())
    setPlanningListLoading(true)
  }, [])

  const onClickRow = (e: React.MouseEvent, row: IPlanning) => {
    if (selectedBooking && selectedBooking?.id === row.id) {
      navigate(``);
      dispatch(setSelectedBooking(null))
      return
    }
    if (row.id) {
      navigate(`${row.id}`);
    } else {
      navigate(``);
      dispatch(setSelectedBooking(null))
    }
  }

  const headers = [
    {
      label: t('table.header.slot'),
      key: "date",
      sort: false,
      compareValue: (data: IPlaningAvailability) => '',
      format: (data: IPlaningAvailability) => {
        return (
          <Box className="flex flex-col items-start	">

            <Chip
              variant="squared"
              color="primary"
              size="small"
              className="mb-1"
              label={<>
                {
                  data.date && `${dayjs(data.date).format(t('dates.day_month', { ns: "common" }))}`
                } &nbsp;
                {getDateObjectFromTime(data.start_time, data.date).format(t('dates.hour', { ns: "common" }))}
                &nbsp;{t('dates.to', { ns: "common" })}&nbsp;
                {getDateObjectFromTime(data.end_time, data.date).format(t('dates.hour', { ns: "common" }))}

              </>
              } />
          </Box>
        )
      }
    },
    {
      label: t('table.header.hardware'),
      key: "hardware",
      format: (data: IPlaningAvailability) => {
        return <>
          {data.hardware &&
            <Chip
              variant="outlined"
              color="primary"
              size="small"
              className="mb-1 "
              label={data.hardware.name} />
          }</>
      },
      sort: true,
      compareValue: (data: IPlaningAvailability) => data?.hardware.name || '',
    },
    {
      label: t('table.header.service'),
      key: "service",
      format: (data: IPlaningAvailability) => {
        return <Box className="flex flex-col items-start">

          {data?.service || ""}
        </Box>
      },
      sort: true,
      compareValue: (data: IPlaningAvailability) => data?.service || '',
    },
    {
      label: t('table.header.boat'),
      key: "boat",
      format: (data: IPlaningAvailability) => {
        const { boat } = data
        return (<>
          <p className="font-bold">{data?.boat?.name}</p>
          {
            boat?.registration_number && <p className="font-bold">{boat?.registration_number}</p>
          }
        </>)
      },
      sort: true,
      compareValue: (data: IPlaningAvailability) => data?.service || '',
    },
    {
      label: t('table.header.boater'),
      key: "boater",
      format: (data: IPlaningAvailability) => {
        return (<>
          <p className="text-center">{data?.boater?.first_name} {data?.boater?.last_name}</p>
          <p className="text-center">{data?.boater?.phone}</p>
        </>)
      },
      sort: true,
      compareValue: (data: IPlaningAvailability) => data?.boater.first_name || '',
    },
    {
      label: t('table.header.status'),
      key: "status",
      format: (data: IPlaningAvailability) => {
        return <BookingStatusChip className="p-3" status={data?.status} />
      },
      sort: true,
      compareValue: (data: IPlaningAvailability) => '' + data.status || '',

    }
  ]

  const markUnavailable = () => {
    const datas = selected.map(slot => {
      return {
        task_id: slot.id,
        planning_id: slot.planning_id,
        status: BOOKING_STATUS.UNAVAILABLE,
        date: slot.date
      } as TBatchBooking
    })
    setAppLoading!(true)
    FDK.Marina.CraneModule.updateBatch(datas).then(res => {
      setAppLoading!(false)
      const message = ""
      setAlertMessage!({ message, ...res.data, type: AlertType.SUCCESS })
      if (!port) return
      dispatch(getPlanningSlotList({ port: port.id }))
    }, err => {
      setAppLoading!(false)
      const message = ""
      setAlertMessage!({ message, type: AlertType.ERROR })
    })

  }

  const markAvailable = () => {
    const datas = selected.filter(s => s.status === BOOKING_STATUS.UNAVAILABLE).map(slot => {
      return {
        task_id: slot.id,
        planning_id: slot.planning_id,
        status: BOOKING_STATUS.AVAILABLE,
        date: slot.date
      } as TBatchBooking
    })
    setAppLoading!(true)
    FDK.Marina.CraneModule.updateBatch(datas).then(res => {
      setAppLoading!(false)
      const message = ""
      setAlertMessage!({ message, ...res.data, type: AlertType.SUCCESS })
      if (!port) return
      dispatch(getPlanningSlotList({ port: port.id }))
    }, err => {
      setAppLoading!(false)
      const message = ""
      setAlertMessage!({ message, type: AlertType.ERROR })
    })
  }

  const onChangeDateFilter = (type: DateType, value: dayjs.Dayjs | null) => {
    setShowLoader(true)
    dispatch(updateFilterWithoutSaving({ date: type, dateStart: value?.toISOString(), dateEnd: null }))
    dispatch(getPlanningSlotList({ port: port!.id })).finally(() => {
      setShowLoader(false)
    })
  }

  const onChangeStatusFilter = (e: any) => {
    let tmpStatus: string[] = e.target.value;
    const newFilters = { ...filters, statusPlanning: { ...filters.statusPlanning } };
    newFilters.statusPlanning = {} as any;
    for (let i in tmpStatus) {
      newFilters.statusPlanning[`${tmpStatus[i]}`] = true;
    }
    dispatch(updateFilterWithoutSaving(newFilters))
    if (!port) return
    dispatch(getPlanningSlotList({ port: port.id })).finally(() => {
      setShowLoader(false)
    })
  }

  return <div>
    <TableTop
      search={false}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
      }}
      buttons={
        <>
          <Box sx={{ minWidth: 110, maxWidth: 200 }}>
            <FormControl size="small" fullWidth={true}>
              {filters.dateStart === "" && (
                <InputLabel id="filter-category-label">
                  {t("table.filters.date")}
                </InputLabel>
              )}
              <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={dayjs(filters.dateStart)}
                  format={t('dates.full', { ns: "common" })}
                  open={openDatePicker}
                  slotProps={{
                    textField: {
                      size: "small"
                    },
                    field: { setOpen: setOpenDatePicker } as any
                  }}
                  onChange={(value: dayjs.Dayjs | null) => onChangeDateFilter("date", value)}
                  label={t("planningSlot.date")}
                  onClose={() => setOpenDatePicker(false)}
                  onOpen={() => setOpenDatePicker(true)}
                  slots={{
                    field: (props: ButtonFieldProps) => {
                      const {
                        setOpen,
                        id,
                        disabled,
                        InputProps: { ref } = {},
                        inputProps: { 'aria-label': ariaLabel } = {},
                      } = props;
                      return (<Button
                        className="h-full"
                        variant="contained"
                        color="light"
                        id={id}
                        disabled={disabled}
                        ref={ref}
                        aria-label={ariaLabel}
                        onClick={() => setOpen?.((prev) => !prev)}
                        endIcon={<CalendarIcon />}
                      >
                        {dayjs(filters.dateStart).format(t('dates.full', { ns: "common" }))}
                      </Button>)
                    }
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 110, maxWidth: 200 }}>
            <FormControl size="small" fullWidth={true}>
              {Object.keys(filters.statusPlanning).length < 1 && (
                <InputLabel id="state-checkbox-label">
                  {t("table.headers.status")}
                </InputLabel>
              )}
              <Select
                labelId="state-checkbox-label"
                id="state-checkbox"
                multiple
                value={Object.keys(filters.statusPlanning)}
                onChange={onChangeStatusFilter}
                input={
                  <OutlinedInput
                  // label={t("table.headers.status")}
                  />
                }
                renderValue={(selected) =>
                  selected.map(
                    (sel) => t(`booking.status.${sel.toLocaleLowerCase()}`) + ", "
                  )
                }
                IconComponent={FilterListIcon}
              >
                {/* Booking Status */}
                {Object.keys(BOOKING_STATUS).map(
                  (s) =>
                  (
                    <MenuItem dense={true} key={"status-" + s} value={s}>
                      <Checkbox
                        size={"small"}
                        checked={filters.statusPlanning[s as BOOKING_STATUS] === true}
                      />
                      <ListItemText
                        primary={t(`booking.status.${s.toLocaleLowerCase()}`)}
                      />
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Box>
          <Button disabled={!selected || selected.length === 0} onClick={(e) => { markUnavailable() }} color="error" variant="contained">
            {t('planningSlot.makeUnavailable')}
          </Button>
          <Button disabled={!selected || selected.length === 0} onClick={(e) => { markAvailable() }} color="secondary" variant="contained">
            {t('planningSlot.makeAvailable')}
          </Button>
          <Button endIcon={<Add />} onClick={(e) => { handleOpen() }} color="secondary" variant="contained">
            {t('booking.action.create')}

          </Button>
        </>
      }
      filters={<>
        <Button onClick={(e) => {
          navigate("/admin/area/all/modules/handling-task/booking");
        }} color="light" variant="text" size="large">
          <ChevronLeft />
          {t("nav.booking")}
        </Button>
      </>}
    />
    {
      (planningListLoading || showLoader) && <LinearProgress color="secondary" />
    }

    <ModuleTable
      headers={headers}
      displayHeader={false}
      rowPadding={true}
      datas={(planning || [])}
      selectedKey={selectedBooking?.planning_id}
      selectionKey='index'
      selectable={true}
      onClickRow={onClickRow}
      isLoading={false}
      selectWithRowIndex={true}
      onChangeSelection={(datas: IPlaningAvailability[]) => {
        setSelected(datas)
      }}
    />
    <Modal
      open={open}
      onClose={handleClose}

    >
      <Paper sx={{
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        boxShadow: 24,
        p: 4,
        zIndex: 10
      }}>
        <ReservationForm onClose={handleClose} />
      </Paper>
    </Modal>
  </div>
}