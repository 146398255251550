import { Alert } from "@mui/material"
import { useTranslation } from "react-i18next";
import { AREA_DATE_ERROR, AreaOpeningDateWarning } from "../../../../../../core/models/portCMS.models";
import { FC } from "react";


type AreaOpeningAlertProps = {
  warning: AreaOpeningDateWarning
}
export const AreaOpeningAlert: FC<AreaOpeningAlertProps> = ({ warning }) => {
  const { t } = useTranslation("portCMS");
  const DateErrors = {
    [AREA_DATE_ERROR.CRITICAL_NO_DATE_RANGE]: t("sections.area.openingForm.noCurrentPeriod"),
    [AREA_DATE_ERROR.RANGE_DISCONTINUITY]: t("sections.area.openingForm.periodDiscontinuity"),
    [AREA_DATE_ERROR.WARNING_NO_FUTURE_DATE_RANGE]: t("sections.area.openingForm.expiringSoon"),
  }

  return <Alert className="" severity={warning.error === AREA_DATE_ERROR.CRITICAL_NO_DATE_RANGE ? "error" : "warning"}>
    {warning.error in DateErrors ?
      t(DateErrors[warning.error]) :
      t("sections.area.openingForm.genericError")
    }
  </Alert>
}