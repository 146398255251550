import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import i18n from "../../../../../../translations/initI18n"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { UPDATABLE_FIELD } from "../../../../../../core/models/technicalArea.models"
import { FC, useEffect, useState } from "react"
import { ReadOnlyField } from "../../../../UX/inputs/ReadOnlyField"
import { Tide, getTide } from "../../../../../../core/services/Tide.service"
import { useAppSelector } from "../../../../../../core/store/hooks"
import { TideHint } from "./Tide.hint"

type EndDateTimeProps = {
  readonly?: boolean
  updatableFields?: UPDATABLE_FIELD[]
}
export const EndDateTime: FC<EndDateTimeProps> = ({ readonly, updatableFields }) => {
  const { t } = useTranslation("technicalArea")
  const { control, getValues, watch } = useFormContext()
  const port = useAppSelector(state => state.ports.current)
  const end_date = watch("end_date")
  const end_time = watch("end_time")

  const [list, setList] = useState<Tide[]>([])

  const getDateTime = () => {
    const time = end_time ? dayjs(end_time) : dayjs().startOf("day")
    const date = dayjs(end_date).hour(time.hour()).minute(time.minute())
    return date
  }

  useEffect(() => {
    if (!port || !end_date) return
    const date = getDateTime()

    getTide(port.id, date).then(res => {
      setList(res.data.tide_forecast)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [end_date, port])
  if (readonly) {
    return <>
      <ReadOnlyField className="col-start-1" label={t("form.dateEnd")} value={dayjs(getValues("end_date")).format(t('dates.full', { ns: "common" }))} />
      <ReadOnlyField label={t("form.hourEnd")} value={dayjs(getValues("end_time")).format(t('dates.hour', { ns: "common" }))} />
      <TideHint date={getDateTime()} className="col-start-1 col-span-full" tides={list} />
    </>
  }
  return <>
    <Controller
      name="end_date"
      control={control}
      rules={{ required: true }}
      render={({ field, fieldState, formState }) => (
        <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs}>
          <DatePicker
            className="col-start-1"
            disabled={!updatableFields?.includes(UPDATABLE_FIELD.END_DATE) || false}
            value={field.value ? dayjs(field.value) : null}
            format={t('dates.full', { ns: "common" })}
            onChange={field.onChange}
            slotProps={
              {
                textField: {
                  ref: field.ref,
                  onBlur: field.onBlur,

                  error: !!fieldState.error
                }
              }
            }
            label={t("form.dateEnd")} />
        </LocalizationProvider>
      )}
    />
    <Controller
      name="end_time"
      control={control}
      rules={{ required: true }}
      render={({ field, fieldState, formState }) => (
        <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs}>
          <TimePicker
            disabled={!updatableFields?.includes(UPDATABLE_FIELD.END_TIME) || false}
            value={field.value ? dayjs(field.value) : null}
            ampm={t('dates.ampm', { ns: "common" })}
            format={t('dates.hour', { ns: "common" })}
            onChange={field.onChange}
            slotProps={
              {
                textField: {
                  ref: field.ref,
                  onBlur: field.onBlur,
                  error: !!fieldState.error
                }
              }
            }
            label={t("form.hourEnd")} />
        </LocalizationProvider>
      )}
    />

    <TideHint className="col-start-1 col-span-full" date={getDateTime()} tides={list} />
  </>
}