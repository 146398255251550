import { createPortal } from 'react-dom';
import { AlertMessage, AlertType, useAppContext } from '../../contexts/AppContext';
import { Alert, Box, Button, Modal, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';

type AlertItemProps = {
  alertMessage: AlertMessage,
}
export const AlertItem: FC<AlertItemProps> = ({ alertMessage }) => {
  return (<Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 shadow-md w-auto md:w-96" sx={{
    bgcolor: "background.paper"
  }}>
    <div className="p-4 gap-y-3">
      {/* <div className="flex items-center w-full pb-5">
        <div className="font-medium text-lg w-full">{t("modals.confirm.title")}</div>
        <CloseIcon className="ml-auto fill-current hover:opacity-70 w-6 h-6 cursor-pointer" onClick={onCancel} />
      </div> */}
      {
        alertMessage?.title && <Typography sx={{ "fontWeight": "800", fontSize: "1.5em" }}>{alertMessage?.title} </Typography>
      }
      <Alert severity={alertMessage.type === AlertType.ERROR ? "error" : "success"}>{alertMessage.message}</Alert>
      {
        alertMessage?.sub_message && <Typography sx={{ marginTop: "10px", fontSize: "0.8em", fontStyle: "italic" }}>{alertMessage?.sub_message} </Typography>
      }
     
    </div>
  </Box>)
}

export const AlertModal = () => {
  const { t } = useTranslation("common");
  const { alertMessage, setAlertMessage } = useAppContext();
  const portalElement = document.getElementById('portal') as HTMLElement;
  const onCancel = () => setAlertMessage!({ type: AlertType.CLEAR, message: "" })
  const component = (

    <Modal
      open={(alertMessage.type === AlertType.SUCCESS || alertMessage.type === AlertType.ERROR) && alertMessage.message !== ""}
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <AlertItem alertMessage={alertMessage} />
        <div className="w-full flex justify-end text-center gap-x-2 pt-5">
          <Button color={alertMessage.type === AlertType.ERROR ? "error" : "primary"} variant="contained" onClick={onCancel} className="apbuttonaction-blue">{t("buttons.close")}</Button>
        </div>
      </>
    </Modal>
  );
  return createPortal(component, portalElement);
}