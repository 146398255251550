import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { addUpdatingTickets } from "../reducers/Ticket.reducer";
import { ITicket, ITicketMessage } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import FDK from "@wattsonelements/front-fdk";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";
import { Nullable, TParams } from "@wattsonelements/front-fdk/dist/models/Misc.models";
import { TicketStatus } from "@wattsonelements/front-fdk/dist/constants/ticket.constants";

// export const generateTicketFileFormat = (ticket: Partial<ITicket>) => {
//   const newTicket = { ...ticket };
//   if (
//     newTicket.ticket_intervention &&
//     newTicket.ticket_intervention.length > 0
//   ) {
//     const steps = newTicket.ticket_intervention[0].intervention_step;
//     newTicket.ticket_intervention[0].intervention_step = steps?.map((step) => {
//       step.intervention_step_attachment = step.intervention_step_attachment.map(
//         (file) => {
//           if (!file.intervention_step && !file.linked_file) {
//             return { linked_file: file.id };
//           }
//           return file;
//         }
//       );
//       return step;
//     });
//   }
//   return ticket;
// };

export const getTicketsList = createAsyncThunk(
  "ticket/list",
  async (
    params: { page?: number; port_area?: UUID },
    { dispatch, getState }
  ) => {
    return FDK.Marina.TicketsModule.getTicketsList(params).then(
      (res) => res.data
    );
  }
);

export const getTicketsClosedList = createAsyncThunk(
  "ticket/listclosed",
  async (
    params: { page?: number; port_area?: UUID },
    { dispatch, getState }
  ) => {
    console.log("getTicketsClosedList");

    return FDK.Marina.TicketsModule.getTicketsClosedList(params).then(
      (res) => res.data
    );
  }
);

export const getTicketDetail = createAsyncThunk(
  "ticket/detail",
  async ({ id }: { id: string }, { dispatch, getState }) => {
    // TO CHECK migth be a bit restrictive but fit current use case
    const selectedID = (getState() as RootState).tickets.selectedID;
    if (selectedID !== id) return Promise.reject();
    return FDK.Marina.TicketsModule.getTicketsById(id).then((res) => res.data);
  }
);

export const getTicketsCategories = createAsyncThunk(
  "ticket/categories",
  async (args, { dispatch, getState }) => {
    return FDK.Marina.TicketsModule.getTicketsCategories().then(
      (res) => res.data
    );
  }
);

// export const createTicket = createAsyncThunk("ticket/create",
//   async (ticket: Partial<Ticket>, { dispatch, getState }) => {
//     return Marina.TicketsModule.createTicket(ticket).then(res => res.data)
//   }
// )

export const createTicket = createAsyncThunk(
  "ticket/create",
  async (ticket: Partial<ITicket>, { dispatch }) => {
    return FDK.Marina.TicketsModule.createTicket(ticket).then((res) => {
      // const newTicket = res.data;
      // dispatch(addTicket(newTicket));
      return res.data as ITicket;
    });
  }
);

export const createTicketMessage = createAsyncThunk(
  "ticketMessage/create",
  async ({ id, message }: { id: UUID; message: Partial<ITicketMessage> }, { dispatch }) => {
    return FDK.Marina.TicketsModule.createTicketMessage(id, message).then((res) => {
      // const newTicket = res.data;
      // dispatch(addTicket(newTicket));
      return res.data as ITicketMessage;
    });
  }
);

export const updateTicket = createAsyncThunk(
  "ticket/update",
  async (
    { id, field }: { id: UUID; field: Partial<Nullable<ITicket>> },
    { dispatch, rejectWithValue }
  ) => {
    dispatch(addUpdatingTickets(id));
    return FDK.Marina.TicketsModule.updateTicket(id, field).then(
      (res) => res.data,
      (err) => rejectWithValue(err)
    );
  }
);


export const exportTicketsList = (filters?: TParams) => {
  return FDK.MainAPI.API.request({
    method: "POST",
    url: "/v1/ticket/export",
    data: filters,
    responseType: 'blob',
  })
}

export const getTicketStatusListParam = (statusFilters: { [key: string]: boolean }, params: TParams = {}) => {
  const statusList = Object.keys(TicketStatus).filter(status => statusFilters[status as TicketStatus] === true).map(
    s => TicketStatus[s as keyof typeof TicketStatus]
  )
  if (statusList.length > 1) {
    params.status__in = statusList.join(',')
  } else if (statusList.length === 1) {
    params.status = statusList[0]
  }
  return params
}