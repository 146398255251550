import { CircularProgress, CssBaseline } from "@mui/material";
import { useEffect, useState } from "react";
import { AppProvider, useAppContext } from "./app/contexts/AppContext";
import { AppRoutes } from "./core/routes";
import "./translations/initI18n";
import { useAuth } from "./core/services/Auth.service";
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import { ConfirmModal } from "./app/components/UX/Confirm.modal";
import { AlertModal } from "./app/components/UX/Alert.modal";
import { useAppSelector } from "./core/store/hooks";

import { Box } from "@mui/system";
import { useQAContext } from "./app/contexts/QAContext";
import { Narwhal } from "./app/components/elements/debug/Narwhal.element";
import { NarwhalModal } from "./app/components/elements/debug/Narwhal.modal";
import FDK from "@wattsonelements/front-fdk";
import { AppLoader } from "./app/components/elements/app/AppLoader.element";
import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";

if (window.location.hostname !== "localhost" && window.location.hostname !== "127.0.0.1") {
  Sentry.init({
    dsn: "https://7dcb7e0e2d68423da0f6122b339fb053@o69095.ingest.sentry.io/6570258",
    integrations: [new BrowserTracing()],
    debug: process.env.REACT_APP_ENV !== "PRODUCTION",
    environment:
      process.env.REACT_APP_ENV === "PRODUCTION" ? "production" : "staging",
    tracesSampleRate: 1.0,
  });
}

dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

const AppInit = (props: any) => {
  const { checkLogin } = useAuth();
  const [initDone, setInitDone] = useState(false);
  const { isLoggedIn } = useAppSelector((state) => state.auth);
  const { init } = useAuth();
  const { API_URL, APP_VERSION } = useQAContext();
  const { logout } = useAuth();

  useEffect(() => {
    if (initDone)
      checkLogin();
  }, [initDone]);
  useEffect(() => {
    if (!initDone) {
      setInitDone(true);
      FDK.Marina.init(
        API_URL,
        APP_VERSION,
        () => {
          return {
            access: localStorage.getItem("access") || "",
            refresh: localStorage.getItem("refresh") || "",
          };
        },
        () => {
          logout();
        }, // logout
        ({ access, refresh }) => {
          localStorage.setItem("access", access);
          localStorage.setItem("refresh", refresh);
        }
      );
    } else
      init();
  }, [initDone, isLoggedIn]);

  if (!initDone)
    return (
      <Box className="absolute w-full h-full flex items-center justify-center">
        <CircularProgress />
      </Box>
    );
  return props.children;
};

function App() {
  const { APP_ENV } = useQAContext();
  const { i18n } = useTranslation()
  return (
    <AppProvider>
      <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs}>
        <AppInit>
          {/* DEBUG MODE */}
          {APP_ENV !== "PRODUCTION" && <Narwhal />}
          <NarwhalModal />
          {/* END DEBUG MODE */}
          <ConfirmModal />
          <AlertModal />
          <CssBaseline />
          <AppRoutes />
        </AppInit>
      </LocalizationProvider>
      <AppLoader />
    </AppProvider>
  );
}

export default App;
