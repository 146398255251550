import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  CircularProgress,
  TextField,
} from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { useDebounce } from "../../../_helpers/debounce.helpers";

interface AsyncAutocompleteProps {
  label: string;
  value: any;
  onSearch: (value: string) => void;
  onChange: (value: any) => void;
  options: any[];
  optionsLabel: (option: any) => string;
  optionsCompareKey?: string;
  keepOptionsWhenTextIsEmpty?: boolean;
  [key: string]: any;
}

export const AsyncAutocomplete: FC<AsyncAutocompleteProps> = ({
  label,
  value,
  onSearch,
  onChange,
  options = [],
  optionsLabel = () => "",
  optionsCompareKey = "id",
  keepOptionsWhenTextIsEmpty = false,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState(value || "");
  const [loading, setLoading] = useState(false);

  const debouncedValue = useDebounce(text, 500);

  const search = useCallback(async () => {
    onSearch(debouncedValue)
  }, [debouncedValue]);

  useEffect(() => {
    setLoading(true);
    search();
    setLoading(false);
  }, [debouncedValue, search]);

  useEffect(() => {
    setLoading(false);
  }, [options, value]);

  useEffect(() => {
    if (!value) setText("");

  }, [value])

  return (
    <>
      <Autocomplete
        {...props}
        open={open}
        inputValue={text}
        value={value}
        onChange={(
          event: React.SyntheticEvent<Element, Event>,
          value: any,
          reason: AutocompleteChangeReason,
          details?: AutocompleteChangeDetails<any> | undefined
        ) => {
          onChange(value);
        }}
        onInputChange={(
          event: React.SyntheticEvent,
          nextValue: string,
          reason: string
        ) => {
          if (reason === "clear") {
            setText("");
            setLoading(false);
            return
          }
          if (nextValue === value && reason !== "input") return;
          if (!nextValue) {
            setText("");
          } else {
            setText(nextValue);
          }

        }}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        freeSolo
        isOptionEqualToValue={(option, value) =>
          option[optionsCompareKey] === value[optionsCompareKey]
        }
        getOptionLabel={optionsLabel || ""}
        options={(text || keepOptionsWhenTextIsEmpty) ? options : []}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
};
