import { Add, Close } from "@mui/icons-material";
import { Box, Button, Checkbox, FormControlLabel, Typography } from "@mui/material"
import { LocalizationProvider, TimeField } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FC, useEffect, useState } from "react";
import { Control, Controller, FieldValues, UseFieldArrayRemove, UseFieldArrayUpdate, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getDateObjectFromTime } from "../../../../../../_helpers/dateTime.helper";
import dayjs from "dayjs";
import { ScheduleDay } from "../../../../../../core/models/portCMS.models";
import i18n from "../../../../../../translations/initI18n";

type OpeningsCalendarProps = {
  index: number
}
export const OpeningsCalendar: FC<OpeningsCalendarProps> = ({ index }) => {
  // TODO ADD DAY I18n
  return (
    <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs}>
      <Box className="flex flex-col gap-3">
        <OpeningDay calendarIndex={index} day={1} />
        <OpeningDay calendarIndex={index} day={2} />
        <OpeningDay calendarIndex={index} day={3} />
        <OpeningDay calendarIndex={index} day={4} />
        <OpeningDay calendarIndex={index} day={5} />
        <OpeningDay calendarIndex={index} day={6} />
        <OpeningDay calendarIndex={index} day={7} />
      </Box>
    </LocalizationProvider >
  )
}

type OpeningDayProps = {
  calendarIndex: number
  day: number,
  onChange?: () => {}
}

export const OpeningDay: FC<OpeningDayProps> = ({ day, calendarIndex = 0, onChange }) => {
  const { t } = useTranslation("portCMS");
  const { control } = useFormContext(); // retrieve all hook methods
  const { fields, append, update, remove, replace } = useFieldArray({
    control,
    name: `area.opening_dates.dates[${calendarIndex}].schedule.${day}`,
  });


  return (
    <Box className="flex justify-between items-start border-slate-300 border py-3 px-6">
      <Box sx={{
        minHeight: "56px",
      }} className="flex gap-3 items-start">
        {/* TODO */}
        <FormControlLabel
          className="min-w-[125px] min-h-[56px]"
          label={t(`dates.days.${day}`, { ns: "common" })}
          onChange={(e, checked) => {
            if (!checked) {
              replace([])
            } else {
              append({ open: '', close: '' })
            }

          }}
          control={<Checkbox
            color="secondary"
            checked={fields.length > 0}
          />}
        />

        <Box className="flex flex-col gap-3 self-center">
          {
            fields.map((field, index) => <OpeningHour
              calendarIndex={calendarIndex}
              key={field.id}
              control={control}
              update={update}
              remove={remove}
              index={index}
              value={field as any as ScheduleDay}
              day={day}
            />)
          }
          {
            fields.length === 0 &&
            <Typography className="text-fcotextgrey">{t('sections.area.openingForm.unavailable')}</Typography>
          }
        </Box>

      </Box>
      <Button
        sx={{
          height: "56px",
        }}
        color={fields.length === 0 ? "secondary" : "primary"}
        endIcon={fields.length === 0 && <Add />}
        variant={fields.length === 0 ? 'outlined' : 'text'}
        onClick={() => {
          append({ open: '', close: '' })
        }}>
        {
          fields.length === 0 ? t('sections.area.openingForm.addHour') : <Add />
        }

      </Button>
    </Box >
  )
}

type OpeningHourProps = {
  control: Control<FieldValues, any>,
  update: UseFieldArrayUpdate<FieldValues, `area.opening_dates.dates[${number}].schedule.${number}`>,
  remove: UseFieldArrayRemove,
  index: number,
  value: ScheduleDay,
  day: number,
  calendarIndex: number
}

export const OpeningHour: FC<OpeningHourProps> = ({ control, index, calendarIndex, day, remove }) => {
  const { t } = useTranslation(["portCMS"]);
  const { getValues, formState: { errors } } = useFormContext()

  return (
    <Box className="flex flex-col gap-3">
      <Box className="flex gap-3 items-center">

        <Controller
          name={`area.opening_dates.dates[${calendarIndex}].schedule.${day}.${index}.open`}
          control={control}
          rules={{
            validate: (value) => {
              const valid = getDateObjectFromTime(getValues(`area.opening_dates.dates[${calendarIndex}].schedule.${day}.${index}.close`)).isAfter(getDateObjectFromTime(value))
              return valid
            },
            // deps: `area.current_schedule.${day}.${index}.close`
          }}
          render={({ field, fieldState, formState }) => (
            <div className="min-w-[100px]">
              <TimeField
                ampm={t('dates.ampm', { ns: "common" })}
                label={t('sections.area.openingForm.startHour')}
                value={field.value ? getDateObjectFromTime(field.value) : ""}
                onChange={(val, context) => {
                  if (!!context.validationError) return
                  field.onChange((val as dayjs.Dayjs).format("HH:mm"))
                }}
                slotProps={
                  {
                    textField: {
                      ref: field.ref,
                      onBlur: field.onBlur,
                      error: !!fieldState.error
                    }
                  }
                }
              />
            </div>
          )}
        />
        <Controller
          name={`area.opening_dates.dates[${calendarIndex}].schedule.${day}.${index}.close`}
          control={control}
          rules={{
            validate: (value) => {
              const valid = getDateObjectFromTime(getValues(`area.opening_dates.dates[${calendarIndex}].schedule.${day}.${index}.open`)).isBefore(getDateObjectFromTime(value))
              return valid
            },
            // deps: `area.current_schedule.${day}.${index}.open`
          }}
          render={({ field, fieldState, formState }) => (
            <div className="min-w-[100px]">
              <TimeField
                ampm={t('dates.ampm', { ns: "common" })}
                label={t('sections.area.openingForm.endHour')}
                value={field.value ? getDateObjectFromTime(field.value) : ""}
                onChange={(val, context) => {
                  if (!!context.validationError) return
                  field.onChange((val as dayjs.Dayjs).format("HH:mm"))
                }}
                slotProps={
                  {
                    textField: {
                      ref: field.ref,
                      onBlur: field.onBlur,
                      error: !!fieldState.error
                    }
                  }
                }
              />
            </div>
          )}
        />

        <Button className="self-stretch" onClick={() => remove(index)}>
          <Close />
        </Button>
      </Box>
    </Box>
  )
}