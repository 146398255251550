import { Box, Checkbox, FormControlLabel, Tooltip, TooltipProps } from "@mui/material";
import { ToolTip } from "../../../core/type";
import { FC } from "react";

interface LegendCheckboxProps {
  label: string | React.ReactElement;
  toolTip?: ToolTip;
  stat?: number | string;
  isLoading?: boolean;
  icon?: any;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  children?: React.ReactElement;
}
export const LegendCheckbox = (props: LegendCheckboxProps) => {
  const {
    isLoading = false,
    label,
    toolTip,
    stat,
    children,
    icon,
    checked = true,
    onChange = () => { },
  } = props;

  // TODO STATS
  // number OR loader <-- this is missing
  // icon
  // label
  return (
    <div className="flex items-center">
      <Tooltip placement={toolTip ? toolTip.placement : "top"} arrow title={(toolTip && toolTip.text) || ""}>
        {<FormControlLabel
          className="flex justify-left items-center w-full"
          control={
            <Checkbox
              sx={{
                padding: "0px 5px 0 0px",
              }}
              checked={checked}
              onChange={(e) => onChange(e.target.checked)}
            />
          }
          label={
            <LabelLegend stat={stat} icon={icon} label={label} />
          }
        />}
      </Tooltip>
      {children}
    </div>
  );
};

type LabelLegendProps = {
  stat?: number | string;
  icon?: any;
  label: string | React.ReactElement;
}

export const LabelLegend: FC<LabelLegendProps> = ({ label, stat, icon }) => {
  return <div className="w-full flex justify-left items-center gap-3 ">
    {typeof stat === "number" && <span className="bold ">{stat}</span>}
    {icon && (
      <img
        alt={"legend-icon-" + label}
        className={
          "w-7 h-7 object-contain " +
          (icon.className ? icon.className : "")
        }
        src={icon.src}
      />
    )}
    <span className="text-sm leading-none">{label}</span>
  </div>
}