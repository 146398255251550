
import { useEffect, useState } from 'react';
import defaultIMG from './../../../assets/img/user-pp.jpg';
import { CircularProgress } from '@mui/material';

type Props = {
  src?: string,
  small?: boolean,
  srcSet?: string,
  alt: string,
  className?: string,
}

export const ImageLoader = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [imgSrc, setImgSrc] = useState<string | undefined>(props.src);
  const newProps = { ...props };
  delete newProps.small;

  useEffect(() => {
    if (props.src !== imgSrc) {
      setImgSrc(props.src)
    }
  }, [props.src])

  const onLoadCB = () => {
    setLoading(false);
  }
  const onErrorCB = () => {
    setImgSrc(defaultIMG);
    setLoading(false);
  }
  return (
    <>
      {loading && <div className={`flex items-center justify-center ${props.small ? "w-10 h-10" : "w-20 h-20"}`}>
        <CircularProgress size={typeof props.small === "boolean" && props.small ? 20 : 40} />
      </div>}
      <img {...newProps}
        srcSet={props.srcSet ? props.srcSet : imgSrc}
        src={imgSrc} onError={onErrorCB} onLoad={onLoadCB} />
    </>
  )

}