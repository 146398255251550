import { Add, Close, CloseRounded, Delete } from "@mui/icons-material";
import { Box, Button, Chip, MenuItem, Select, TextField, Typography } from "@mui/material"
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AreaContact, AreaLayout } from "../../../../../../core/models/portCMS.models";
import { Control, Controller, FieldValue, FieldValues, UseFieldArrayAppend, UseFieldArrayRemove, useFieldArray, useFormContext } from "react-hook-form";
import { getContactsCategories } from "./Area.helpers";



export const ContactListForm: FC = () => {
  const { t } = useTranslation(["portCMS"]);
  const { control, register, unregister, formState: { errors } } = useFormContext<FieldValues, AreaLayout>(); // retrieve all hook methods
  const { fields, append, remove } = useFieldArray({ // 
    control,
    name: 'area.contacts'
  });

  const [tabs, setTabs] = useState<string[]>([])
  const [tab, setTab] = useState('')

  useEffect(() => {
    // console.log('effect', fields.length);

    if (fields.length === 1) {
      register('button', { value: ({ label: "Contacts" } as any) })
      register('button.label', { required: true, value: "Contacts" })
      register('button.action.data', { value: null })
      register('button.action.type', { value: "modal" })
    } else if (fields.length === 0) {
      // unregister('button.label')
      // unregister('button.action.data')
      // unregister('button.action.type')
      unregister('button', { keepValue: true })
    }
    const tmpTabs = getContactsCategories(fields as any as AreaContact[])
    if (tmpTabs.length > 0) {
      setTabs(tmpTabs)
    } else {
      setTabs([t('sections.area.contactForm.tab.default.0'), t('sections.area.contactForm.tab.default.1')])
    }
  }, [fields, register, unregister])

  const addTab = () => {
    // TODO add verif no duplicata
    if (!tab) return
    setTabs([...tabs, tab])
    setTab('')
  }
  const removeTab = (tab: string) => {
    const tmp = tabs.filter(t => t !== tab)
    setTabs(tmp)
  }
  return (
    <Box className="flex flex-col gap-3">
      {
        fields.length > 0 &&
        <>
          <TextField
            error={!!errors?.button}
            label={t("sections.area.contactForm.btnLabel")}
            {...register('button.label', { maxLength: 30, value: "Accéder aux contacts" })} />
        </>
      }
      {fields.length > 0 && <Box>
        <Typography sx={{
          fontWeight: "bold",
          fontSize: "1em"
        }}>{t('sections.area.contactForm.tab.title')}</Typography>

        <Box className="flex gap-2 items-center flex-wrap my-3">
          <TextField disabled={tabs.length >= 2} value={tab} onChange={(e) => {
            if (e.target.value && e.target.value.length > 20) return
            setTab(e.target.value)
          }}></TextField>
          <Button className="h-full" variant="outlined" color="secondary" disabled={tabs.length >= 2} onClick={() => {
            addTab()
          }} startIcon={<Add />}>{t('sections.area.contactForm.tab.add')}</Button>
        </Box>
        <Box className="flex gap-2 flex-wrap mb-3">
          {
            tabs.map(t => <Chip key={`tab-contact-${t}`} label={t} onDelete={() => {
              removeTab(t)
            }} deleteIcon={<Close />} />)
          }
        </Box>
      </Box>}
      <Typography sx={{
        fontWeight: "bold",
        fontSize: "1em"
      }}>{t('sections.area.contactForm.list')}</Typography>
      {
        fields.map((field, index) => <ContractRow
          tabs={tabs}
          key={field.id}
          index={index}
          contact={field as any as AreaContact}
          onAdd={() => append({ label: '', email: '', phone: '', tab_name: '' })}
          onDelete={remove} />)
      }
      {
        fields.length === 0 &&
        <>
          <Typography>
            {t('sections.area.contactListForm.noContact')}
          </Typography>
          <Button onClick={() => {
            setTabs([t('sections.area.contactForm.tab.default.0'), t('sections.area.contactForm.tab.default.1')])
            append({ label: '', email: '', phone: '', tab_name: '' })
          }} className="self-end" variant="outlined" color="secondary" endIcon={<Add />}>
            {t('sections.area.contactListForm.addContact')}
          </Button>
        </>
      }
    </Box >
  )

}
type ContractRowProps = {
  onAdd?: UseFieldArrayAppend<FieldValues, "area.contacts">,
  onDelete?: UseFieldArrayRemove,
  contact?: AreaContact,
  index: number,
  tabs: string[]
}

export const ContractRow: FC<ContractRowProps> = ({ onAdd, onDelete, tabs, index }) => {
  const { t } = useTranslation(["portCMS"]);
  const { register, getValues, getFieldState, control } = useFormContext()

  return (
    <Box className="grid grid-cols-5 gap-3">
      <TextField {...register(`area.contacts.${index}.label`, { required: false, maxLength: 50 })} label={t("sections.area.contactListForm.label")} />
      <TextField
        {...register(`area.contacts.${index}.email`, {
          validate: (value) => {
            if (!value) return !!getValues(`area.contacts.${index}.phone`)
          }
        })}
        error={!!getFieldState(`area.contacts.${index}.email`).error}
        label={t("sections.area.contactListForm.email")} />
      <TextField
        error={!!getFieldState(`area.contacts.${index}.phone`).error}
        {...register(`area.contacts.${index}.phone`, {
          maxLength: 20,
          validate: (value) => {
            if (!value) return !!getValues(`area.contacts.${index}.email`)
          }
        })}
        label={t("sections.area.contactListForm.phone")} />
      {/* {...register(`area.contacts.${index}.tab_name`, { required: true })} */}
      <Controller
        control={control}
        name={`area.contacts.${index}.tab_name`}
        rules={{
          maxLength: 30,
          validate: (value) => {
            return tabs.includes(value)
          }
        }}
        render={({ field, fieldState, formState }) => (
          <Select
            label={t("sections.area.contactListForm.tab")}
            onChange={(e) => {
              field.onChange(e.target.value)
            }}
            error={!!fieldState.error}
            value={field.value}
          >
            {
              tabs.map(t => <MenuItem key={t} value={t}>{t}</MenuItem>)
            }
          </Select>
        )

        }
      />
      {/* <TextField
        {...register(`area.contacts.${index}.tab_name`, { required: true })}
        label={t("sections.area.contactListForm.tab")} /> */}
      <Box className="flex justify-around">
        {
          onDelete && (
            <Button onClick={() => onDelete(index)}>
              <CloseRounded />
            </Button>
          )
        }
        {
          onAdd && (
            <Button onClick={onAdd}>
              <Add />
            </Button>
          )
        }

      </Box>
    </Box>
  )
}