import { TBookingReportItem } from "@wattsonelements/front-fdk/dist/models/Booking.models"
import { AutomaticReport, ReportLayout } from "../../Common/AutomaticReport"
import { TABooking, TABookingEdit, UPDATABLE_FIELD } from "../../../../../../core/models/technicalArea.models"
import { FC, useState } from "react"
import { Badge, Box, Button, Chip, CircularProgress, Modal, Tab, Tabs, TextField } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Controller, useForm } from "react-hook-form"
import { useAppDispatch } from "../../../../../../core/store/hooks"
import { updateTABookingDetail } from "../../../../../../core/store/actions/TechnicalArea.actions"
import { updateItemInList, updateSelectedBooking } from "../../../../../../core/store/reducers/TechnicalArea.reducer"
import { Check, Close, Download } from "@mui/icons-material"


function a11yProps(index: number) {
  return {
    id: `ta-tab-${index}`,
    'aria-controls': `ta-tabpanel-${index}`,
  };
}

type DetailLeftProps = {
  booking: TABooking
}
export const DetailLeft: FC<DetailLeftProps> = ({ booking }) => {
  const { t } = useTranslation("technicalArea")
  const dispatch = useAppDispatch()
  const [value, setValue] = useState(0);
  const { control, handleSubmit, reset, formState: { isDirty } } = useForm({
    defaultValues: {
      marina_comment: booking.marina_comment
    }
  })
  const [error, setError] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)
  const [openPreview, setOpenPreview] = useState(false)
  const [preview, setPreview] = useState("")

  const submit = (data: { marina_comment: string }) => {
    console.log("submit", data);
    setUpdateLoading(true)
    if (error) setError(false)
    updateTABookingDetail(booking.id, {
      marina_comment: data.marina_comment,
    } as any).then(
      res => {
        dispatch(updateItemInList(data))
        dispatch(updateSelectedBooking({
          ...booking,
          ...res.data
        }))
        reset(data)

      }, err => {
        setError(true)
      }
    ).finally(() => {
      setUpdateLoading(false)
    })
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return <ReportLayout
    header={
      <>
        <Tabs variant="scrollable" scrollButtons="auto" textColor="secondary" value={value} onChange={handleChange} aria-label="booking tabs">
          <Tab label={t('detail.tabs.comments')} {...a11yProps(0)} />
          <Tab label={t('detail.tabs.logs')}  {...a11yProps(1)} />
          <Tab label={t('detail.tabs.files')}   {...a11yProps(2)} ></Tab>
          {/* <Tab label="112345678987" {...a11yProps(0)} />
          <Tab label="2" {...a11yProps(1)} />
          <Tab label="3" {...a11yProps(2)} /> */}
        </Tabs></>
    }
  >
    { // COMMENTS
      value === 0 && <Box className="flex flex-col gap-3 ">

        <Chip
          variant="squared"
          size="small"
          color="primary"
          label={t("detail.comment.marina")} />

        <form className="flex flex-col gap-2 w-full" onSubmit={handleSubmit(submit)}>
          <Controller
            name="marina_comment"
            control={control}
            disabled={(booking && !booking.updatable_fields?.includes(UPDATABLE_FIELD.MARINA_COMMENT))}
            render={({ field, fieldState, formState }) => (
              <TextField
                disabled={(booking && !booking.updatable_fields?.includes(UPDATABLE_FIELD.MARINA_COMMENT))}
                className="col-span-2"
                sx={{ marginTop: "10px" }}
                label={t("form.description")}
                multiline
                minRows={4}
                value={field.value}
                ref={field.ref}
                onChange={(e) => {
                  field.onChange(e.target.value)
                }}
                onBlur={field.onBlur}
              />

            )}
          />
          {isDirty && booking && booking.updatable_fields?.includes(UPDATABLE_FIELD.MARINA_COMMENT) && <Box className="flex gap-2 justify-end">

            <Button disabled={updateLoading} onClick={() => {
              reset()
            }} color="error" variant="outlined">
              <Close />
            </Button>
            <Button disabled={updateLoading} type="submit" color="secondary" variant="contained">
              {updateLoading ? <CircularProgress color="info" size={20} /> : <Check />}
            </Button>
          </Box>}
        </form>
        {
          booking.boater_comment && <>
            <Chip
              variant="squared"
              size="small"
              color="primary"
              label={t("detail.comment.boater")} />

            <p className="whitespace-pre-wrap">{booking.boater_comment}</p>
          </>
        }

        {
          booking.agent_comment && <>
            <Chip
              variant="squared"
              size="small"
              color="primary"
              label={t("detail.comment.agent")} />
            <p className="whitespace-pre-wrap">{booking.agent_comment}</p>
          </>}

        {
          booking.closing_comment && <>
            <Chip
              variant="squared"
              size="small"
              color="primary"
              label={t("detail.comment.closing")} />

            <p className="whitespace-pre-wrap"> {booking?.closing_comment}</p>
          </>}

      </Box>
    }
    { // AUTOMATIC REPORT
      value === 1 && <AutomaticReport logs={booking.formatted_logs} />
    }
    { // DOCUMENTS
      value === 2 && <Box className="flex flex-col items-center gap-3 pb-28">
        {
          (!booking.attachments || booking.attachments.length === 0) &&
          <div className="font-bold text-fcomain text-center">
            {t("noFiles")}
          </div>
        }
        {
          booking.attachments && booking.attachments.map(file =>
            <div className="relative max-w-[250px] w-auto " key={file.id}>
              {
                (!file.mime_type || !file.mime_type.includes("image")) &&
                <Button target="_blank" className="w-full" variant="outlined" color="secondary" download href={file.url}>
                  <Download />
                  {t("detail.download")}
                </Button>
              }
              {
                file.mime_type && file.mime_type.includes("image") && <Button onClick={() => {
                  setOpenPreview(true)
                  setPreview(file.url)
                }} variant="text" color="light"><img
                    className="block rounded-md shadow-lg drop-shadow-lg border border-slate-200"
                    alt="booking result"
                    src={file.url} /> </Button>
              }



            </div>)
        }
        <Modal
          className="flex justify-center items-center h-screen w-full"
          onClose={() => {
            setOpenPreview(false)
            setPreview("")
          }} open={openPreview}>
          <div >
            <img
              className="block rounded-md "
              alt="booking result"
              src={preview} />
          </div>
        </Modal>
      </Box>
    }

  </ReportLayout>
}