export const formatAreaId = (areaId: string | undefined): string => {
  return typeof areaId !== "undefined" && areaId !== "all" ? areaId : "";
};
export const generateAreaUrl = (areaId: string): string => {
  return window.location.pathname
    .replace(/area\/([a-z0-9\-]*)/i, `area/${areaId}`)
    .replace(/modules\/([a-z]*)\/([a-z0-9\-]*)/i, `modules/$1`);
};

export const displayNumber = (value?: number | null, replaceWith = "/") => {
  if (value === null || !!!value) {
    return replaceWith
  }
  return value
}