import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useAppSelector } from "../../../../../core/store/hooks"
import { useTranslation } from "react-i18next";
import { FC } from "react";
import { IPortArea } from "@wattsonelements/front-fdk/dist/models/Port.models";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";

type AreaSelectProps = {
  onChange: (area: string | null) => void
  value?: UUID | undefined | null,
  [key: string]: any;
}

export const AreaSelect: FC<AreaSelectProps> = ({
  onChange,
  value
}) => {
  const { t } = useTranslation("ticket");
  const areas = useAppSelector(state => state.ports.current?.areas)
  return (
    <FormControl fullWidth>
      <InputLabel id="area-select-label">{t("form.area")}</InputLabel>
      <Select
        labelId="area-select-label"
        id="area-select"
        value={value}
        label={t("form.area")}
        placeholder={t("form.area")}
        onChange={(event) => {
          console.log(event);

          onChange(event.target.value)
        }}
      >
        {
          areas && areas.map(area => <MenuItem key={area.id} value={area.id}>{area.name}</MenuItem>)
        }
      </Select>
    </FormControl>
  )
}