import { Dispatch, FC, SetStateAction, createContext, useEffect, useState } from "react"
import { LAYOUT_NAME, Layout } from "../../../../../../core/models/portCMS.models"

interface IPortCMSPreview {
  previewSection: Layout | null,
  setPreviewSection: Dispatch<SetStateAction<Layout | null>>
  canDrag: boolean,
  setCanDrag: Dispatch<SetStateAction<boolean>>
  isDraggable: boolean,
  setIsDraggable: Dispatch<SetStateAction<boolean>>
  formsDirty: { [key: string]: boolean }
  setFormsDirty: Dispatch<SetStateAction<{ [key: string]: boolean }>>
}

const defaultState: IPortCMSPreview = {
  previewSection: null,
  canDrag: true,
  isDraggable: true,
  formsDirty: {},
  setPreviewSection: () => { },
  setIsDraggable: () => { },
  setCanDrag: () => { },
  setFormsDirty: () => { },
}

export const PortCMSPreviewContext = createContext(defaultState)

export const PortCMSPreviewProvider: FC = ({ children }) => {
  const [previewSection, setPreviewSection] = useState<Layout | null>(null)
  const [canDrag, setCanDrag] = useState(true)
  const [formsDirty, setFormsDirty] = useState<{ [key: string]: boolean }>({})
  const [isDraggable, setIsDraggable] = useState(false)

  useEffect(() => {
    const isDirty = Object.values(formsDirty).some(isDirty => isDirty)
    setCanDrag(!isDirty)
  }, [formsDirty])

  return (
    <PortCMSPreviewContext.Provider value={{
      previewSection,
      setPreviewSection,
      canDrag, setCanDrag,
      isDraggable, setIsDraggable,
      formsDirty, setFormsDirty
    }}>
      {children}
    </PortCMSPreviewContext.Provider>
  )
}