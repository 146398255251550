import classNames from "classnames"
import { FC } from "react"

type ReadOnlyFieldProps = {
  label: string,
  value: string
  className?: string
}
export const ReadOnlyField: FC<ReadOnlyFieldProps> = ({ className, label, value }) => {
  return <div className={classNames("opacity-70", className)}>
    <p className="font-bold">{label}</p>
    <p className="whitespace-pre-wrap">{value}</p>
  </div>
}
