import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "../../../../../core/store/hooks"

type TicketKindFieldProps = {
  value?: string,
  update?: (value?: string | null) => void
}
export const TicketKindField: FC<TicketKindFieldProps> = ({ value, update = () => { } }) => {
  const { t } = useTranslation("ticket")
  const [localValue, setLocalValue] = useState(value || "")
  const config = useAppSelector(state => state.tickets.ticketConfig)
  const handleChange = (event: SelectChangeEvent) => {
    setLocalValue(event.target.value)
    update(event.target.value || null)
  }

  return <FormControl fullWidth>
    <InputLabel id="select-ticket-kind">{t('form.kind')}</InputLabel>
    <Select
      labelId="select-ticket-kind"
      placeholder={t('form.kind')}
      id="demo-simple-select"
      value={localValue}
      label="Age"
      onChange={handleChange}
    >
      <MenuItem value="">
        <em>{t("form.removeKind")}</em>
      </MenuItem>
      {
        config?.ticket_kinds.map(tk => <MenuItem key={tk.id} value={tk.id}>{tk.name}</MenuItem>)
      }
    </Select>
  </FormControl>
}