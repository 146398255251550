import { Buffer } from 'buffer';
import {
  type Crop
} from 'react-image-crop'
/**
 * Returns promise with loaded image
 *
 * @param {string} url path of the image to load
 * @param {number[]} sizes image sizes w, h
 * @return {Promise} promise with loaded image or error
 */
export const loadImage = (url: any, sizes: number[]) => new Promise((resolve, reject) => {
  const img = new Image(sizes[0], sizes[1]);
  img.addEventListener('load', () => resolve(img));
  img.addEventListener('error', (err) => reject(err));
  img.src = url;
});
export const loadAsBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
  let reader = new FileReader();
  reader.addEventListener('load', (e) => resolve(e.target!.result as string))
  reader.addEventListener('error', (err) => reject(err))
  reader.readAsDataURL(file);
});
/**
 * source : https://github.com/DominicTobias/react-image-crop/issues/529
 * @param completedCrop 
 * @param img 
 * @param onDone 
 * @returns 
 */
export const cropToBlob = (completedCrop: Crop, image: any, onDone: Function) => {
  if (completedCrop) {
    // create a canvas element to draw the cropped image
    const canvas = document.createElement("canvas");
    // const image = document.createElement("img")
    // image.src = img
    // image.setAttribute("style", 'display:hidden')
    // image.crossOrigin = "anonymous"
    const el = document.getElementById('test-canvas')
    el?.replaceChildren()
    // el?.append(image)

    // draw the image on the canvas

    if (image) {
      const crop = completedCrop;
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const ctx = canvas.getContext("2d");
      const pixelRatio = window.devicePixelRatio;
      canvas.width = crop.width * pixelRatio * scaleX;
      canvas.height = crop.height * pixelRatio * scaleY;

      if (ctx) {
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";

        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX,
          crop.height * scaleY
        );
      }
      const base64Image = canvas.toDataURL("image/png"); // can be changed to jpeg/jpg etc    

      if (base64Image) {
        // @ts-ignore
        const fileType = base64Image.split(";")[0].split(":")[1];

        const buffer = Buffer.from(
          base64Image.replace(/^data:image\/\w+;base64,/, ""),
          "base64"
        );
        const file = new File([buffer], "image", { type: fileType });
        onDone(file, base64Image)
      }
    }
  }
}