import FDK from "@wattsonelements/front-fdk";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../core/store/hooks";
import { updateFilter } from "../../../../../core/store/reducers/Berth.reducer";
import { ToolTip } from "../../../../../core/type";
import { Legend } from "../Legend";

export const BerthLegend: FC = () => {
  const { filters, stats } = useAppSelector((state) => state.berth);
  const dispatch = useAppDispatch();
  const { t } = useTranslation("berth");

  const [legend, setLegend] = useState([
    {
      actionGroup: "gestionnaire",
      label: t("legend.marinaAction"),
      legends: [
        // tslint:disable-next-line: max-line-length
        {
          label: t("legend.greenSpot"),
          toolTip: { placement: 'left', text: t("legend.greenSpotToolTip") } as ToolTip,
          key: FDK.Constants.BerthConstants.BERTH_STATUS.AVAILABLE,
          actionGroup: "gestionnaire",
          getStat: (stats: any) => stats[FDK.Constants.BerthConstants.BERTH_STATUS.AVAILABLE][0],
          icon: {
            className: "rotate-90",
            src: require(`../../../../../assets/img/icons/berth/map/v2/${FDK.Constants.BerthConstants.BERTH_STATUS.AVAILABLE}.svg`),
          },
        },
        // tslint:disable-next-line: max-line-length
        {
          label: t("legend.redSpot"),
          toolTip: { placement: 'left', text: t("legend.redSpotToolTip") } as ToolTip,
          key: FDK.Constants.BerthConstants.BERTH_STATUS.AVAILABILITY_TO_CONFIRM,
          actionGroup: "gestionnaire",
          icon: {
            className: "rotate-90",
            src: require(`../../../../../assets/img/icons/berth/map/v2/${FDK.Constants.BerthConstants.BERTH_STATUS.AVAILABILITY_TO_CONFIRM}.svg`),
          },
          getStat: (stats: any) =>
            stats[FDK.Constants.BerthConstants.BERTH_STATUS.AVAILABILITY_TO_CONFIRM][0],
        },
        {
          label: t("legend.greySpot"),
          toolTip: { placement: 'left', text: t("legend.greySpotToolTip") } as ToolTip,
          key: FDK.Constants.BerthConstants.BERTH_STATUS.UNAVAILABLE,
          actionGroup: "gestionnaire",
          icon: {
            className: "rotate-90",
            src: require(`../../../../../assets/img/icons/berth/map/v2/${FDK.Constants.BerthConstants.BERTH_STATUS.UNAVAILABLE}.svg`),
          },
          getStat: (stats: any) => stats[FDK.Constants.BerthConstants.BERTH_STATUS.UNAVAILABLE][0],
        },
        {
          label: t("legend.errorSpot"),
          toolTip: { placement: 'left', text: t("legend.errorSpotToolTip") } as ToolTip,
          key: FDK.Constants.BerthConstants.BERTH_STATUS.ERROR,
          actionGroup: "gestionnaire",
          icon: {
            className: "rotate-90",
            src: require(`../../../../../assets/img/icons/berth/map/v2/${FDK.Constants.BerthConstants.BERTH_STATUS.ERROR}.svg`),
          },
          getStat: (stats: any) => stats[FDK.Constants.BerthConstants.BERTH_STATUS.ERROR][0],
        },
      ],
    },
    {
      actionGroup: "agent",
      label: t("legend.agentAction"),
      legends: [
        // tslint:disable-next-line: max-line-length
        {
          label: t("legend.redBoat"),
          toolTip: { placement: 'left', text: t("legend.redBoatToolTip") } as ToolTip,
          key: FDK.Constants.BerthConstants.BERTH_STATUS.MOORING_TO_CONFIRM,
          actionGroup: "agent",
          icon: {
            className: "rotate-90",
            src: require(`../../../../../assets/img/icons/berth/map/v2/${FDK.Constants.BerthConstants.BERTH_STATUS.MOORING_TO_CONFIRM}.svg`),
          },
          getStat: (stats: any) => stats[FDK.Constants.BerthConstants.BERTH_STATUS.MOORING_TO_CONFIRM][0],
        },
        {
          label: t("legend.greyBoat"),
          toolTip: { placement: 'left', text: t("legend.greyBoatToolTip") } as ToolTip,
          key: FDK.Constants.BerthConstants.BERTH_STATUS.MOORING_IDDLE,
          actionGroup: "agent",
          icon: {
            className: "rotate-90",
            src: require(`../../../../../assets/img/icons/berth/map/v2/${FDK.Constants.BerthConstants.BERTH_STATUS.MOORING_IDDLE}.svg`),
          },
          getStat: (stats: any) => stats[FDK.Constants.BerthConstants.BERTH_STATUS.MOORING_IDDLE][0],
        },
      ],
    },
  ]);

  return (
    <Legend

      legendStatus={filters.status}
      stats={stats?.berth}
      updateLegend={(checked: boolean, key: string) => {
        const newFilters = {
          ...filters,
          status: { ...filters.status, [key]: checked },
        };
        dispatch(updateFilter(newFilters));
      }}
      className="flex items-center"
      legends={legend}
    />
  );
};
