import { SvgIcon, SvgIconProps } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import BerthPNG from './berth.png'

type BerthIconProps = {
  // SvgProps?: SvgIconProps,
  whiteSquare: boolean
}

export const BerthIcon = (props: BerthIconProps) => {

  return !props.whiteSquare ?
    (
      <Box
        component="img"
        sx={{
          width: 14
        }}
        alt="berth icon"
        src={BerthPNG}
      />
    ) :
    (
      <Box component="div" className="flex aspect-square min-w-[24px] rounded-sm bg-white h-6 w-6 items-center justify-center">
        <Box
          component="img"
          sx={{
            width: 14
          }}
          alt="berth icon"
          src={BerthPNG}
        />
      </Box>
    );
}

export default BerthIcon;