import { createSlice } from "@reduxjs/toolkit"
import dayjs from "dayjs"
import { TechnicalAreaState } from "../../models/module.models"
import { TA_BOOKING_STATUS } from "../../models/technicalArea.models"
import { getTABookingDetail, getTABookingList, getTAConfig, getTAServiceslist, getTAStats } from "../actions/TechnicalArea.actions"

const initialState = {
  bookingList: [],
  bookingListLoading: false,
  selectedBooking: null,
  services: [],
  config: null,
  filters: {
    text: "",
    status: {
      REQUESTED: true,
      TO_VALIDATE_BY_BOATER: true,
      BOOKED: true,
      CANCELLED: true,
      ON_HOLD: true,
      REJECTED: true,
      ONGOING: true,
      BOAT_IN_AREA: true,
      DONE: true,
    },
    date: "date",
    dateStart: dayjs().toISOString(),
    dateEnd: dayjs().toISOString(),
  },
} as TechnicalAreaState

export const TechnicalArea = createSlice(
  {
    name: "TechnicalArea",
    initialState,
    reducers: {
      setSelectedBooking: (state, { payload }) => {
        state.selectedBooking = payload
      },
      updateFilterWithoutSavingTA: (state, { payload }) => {
        state.filters = { ...state.filters, ...payload };
      },
      cancelBooking: (state, { payload: id }) => {
        state.bookingList = state.bookingList?.map(b => {
          if (b.id === id) {
            b.status = TA_BOOKING_STATUS.CANCELLED
          }
          return b
        }) || null
        if (state.selectedBooking && state.selectedBooking?.id === id) {
          state.selectedBooking.status = TA_BOOKING_STATUS.CANCELLED
        }
      },
      setBookingListLoading: (state, { payload }) => {
        state.bookingListLoading = payload
      },
      updateItemInList: (state, { payload }) => {
        if (!state.bookingList) return state
        const newlist = [...state.bookingList].map(b => {
          if (payload.id === b.id) {
            return {
              ...b,
              services: payload?.services || b.services,
              agents: payload?.agent || b.agents,
              status: payload?.status || b.status,
              start_date: payload?.start_date || b.start_date,
              end_date: payload?.end_date || b.end_date,
              start_time: payload?.start_time || b.start_time,
              end_time: payload?.end_time || b.end_time
            }
          }
          return b
        })
        state.bookingList = newlist
      },
      updateSelectedBooking: (state, { payload }) => {
        if (state.selectedBooking && state.selectedBooking?.id === payload.id) {
          state.selectedBooking = {
            ...state.selectedBooking,
            ...payload,
          }
        }
      },
      updateSelectedBookinStatus: (state, { payload }) => {
        if (!!!state.bookingList) return state
        state.selectedBooking!.status = payload
      }
    },
    extraReducers: (builder) => {
      builder.addCase(getTABookingList.fulfilled, (state, { payload }) => {
        state.bookingListLoading = false
        state.bookingList = payload
      }).addCase(getTABookingList.rejected, (state, { payload }) => {
        // state.selectedBooking = payload
      }).addCase(getTABookingDetail.fulfilled, (state, { payload }) => {
        state.selectedBooking = payload
      }).addCase(getTAServiceslist.fulfilled, (state, { payload }) => {
        state.services = payload
      }).addCase(getTAConfig.fulfilled, (state, { payload }) => {
        if (payload.results && payload.results.length > 0) {
          state.config = payload.results[0]
        }

      }).addCase(getTAStats.fulfilled, (state, { payload }) => {
        state.stats = payload
      })
    }

  }

)

export default TechnicalArea.reducer;
export const {
  setSelectedBooking,
  setBookingListLoading,
  cancelBooking,
  updateItemInList,
  updateFilterWithoutSavingTA,
  updateSelectedBookinStatus,
  updateSelectedBooking
} = TechnicalArea.actions;