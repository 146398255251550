import { Box, Button, Tab, Tabs, Typography } from "@mui/material"
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { OpeningDate } from "../../../../../../core/models/portCMS.models";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { OpeningsCalendar } from "./Openings.calendar";
import { Add, Close } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useConfirm from "../../../../../../_helpers/useConfirm";
import i18n from "../../../../../../translations/initI18n";

export const CalendarsList = () => {
  const { t } = useTranslation("portCMS");
  const { confirm } = useConfirm();

  const { control, getValues } = useFormContext(); // retrieve all hook methods
  const { fields, append, remove } = useFieldArray({
    control,
    name: `area.opening_dates.dates`
  })

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const removePeriod = async (index: number, period: OpeningDate) => {
    if (!(await confirm(t("sections.area.openingForm.confirmDeletePeriod", {
      from: dayjs(period.start_date).format(t('dates.abbreviation_month_date', { ns: "common" })),
      to: dayjs(period.end_date).format(t('dates.abbreviation_month_date', { ns: "common" }))
    })))) return;
    remove(index)
    setValue(0);
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const addPeriod = () => {
    append({
      start_date: dayjs().format("YYYY-MM-DD"),
      end_date: dayjs().add(7, 'day').format("YYYY-MM-DD"),
      schedule: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: []
      }
    } as OpeningDate)
    setValue(fields.length)
  }
  return <Box>
    {/* HEADER ONGLET */}
    <Box className="flex flew-wrap justify-between items-center">

      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {
          fields.map((f, index: number) => <Tab
            key={f.id}
            {...a11yProps(index)}
            wrapped
            label={<Box className="flex items-center">
              {`${dayjs((f as any as OpeningDate).start_date).format(t('dates.abbreviation_month_date', { ns: "common" }))} - ${dayjs((f as any as OpeningDate).end_date).format(t('dates.abbreviation_month_date', { ns: "common" }))}`}
              {
                value === index && <Button color="error" onClick={(e) => {
                  e.preventDefault()
                  removePeriod(index, f as any as OpeningDate)
                }}><Close /></Button>
              }
            </Box>}
          />)
        }
      </Tabs>
      <Button color="secondary" variant="outlined" onClick={addPeriod} endIcon={<Add />}>
        {t('sections.area.openingForm.addPeriod')}
      </Button>
    </Box>


    {
      fields.map((f, index: number) => <TabPanel key={f.id} value={value} index={index}>
        <Box className="flex gap-3 mb-3 items-center">
          <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs}>
            {t("sections.area.openingForm.periodFrom")}
            <Controller
              name={`area.opening_dates.dates[${index}].start_date`}
              control={control}
              rules={{
                validate: (value) => {
                  const valid = dayjs(getValues(`area.opening_dates.dates[${index}].end_date`)).isSameOrAfter(dayjs(value, "YYYY-MM-DD"), 'day')
                  return valid
                },
                // deps: `area.current_schedule.${day}.${index}.close`
              }}
              render={({ field, fieldState, formState }) => (
                <>
                  <DatePicker
                    label={t('sections.area.openingForm.startPeriod')}
                    value={dayjs(field.value)}
                    onChange={(val, context) => {
                      if (!!context.validationError) return
                      field.onChange(val?.format("YYYY-MM-DD"))
                    }}
                    slotProps={
                      {
                        textField: {
                          ref: field.ref,
                          onBlur: field.onBlur,
                          error: !!fieldState.error
                        }
                      }
                    }
                  />
                </>
              )}
            />
            {t("dates.to", { ns: "common" })}
            <Controller
              name={`area.opening_dates.dates[${index}].end_date`}
              control={control}
              rules={{
                validate: (value) => {
                  const valid = dayjs(getValues(`area.opening_dates.dates[${index}].start_date`)).isSameOrBefore(dayjs(value, "YYYY-MM-DD"), 'day')
                  return valid
                },
                // deps: `area.current_schedule.${day}.${index}.close`
              }}
              render={({ field, fieldState, formState }) => (
                <>
                  <DatePicker
                    label={t('sections.area.openingForm.endPeriod')}
                    value={dayjs(field.value)}
                    onChange={(val, context) => {
                      if (!!context.validationError) return
                      field.onChange(val?.format("YYYY-MM-DD"))
                    }}
                    slotProps={
                      {
                        textField: {
                          ref: field.ref,
                          onBlur: field.onBlur,
                          error: !!fieldState.error
                        }
                      }
                    }
                  />
                </>
              )}
            />
          </LocalizationProvider >
        </Box>
        <OpeningsCalendar index={index} />
      </TabPanel>)
    }
  </Box>
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}