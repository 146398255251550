import dayjs from "dayjs"
import { FC, useState } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Box, Button, CircularProgress, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../../../core/store/hooks"
import { LocalPrintshop } from "@mui/icons-material"
import { createTABooking, getTABookingList } from "../../../../../core/store/actions/TechnicalArea.actions"
import { TABookingCreation, TAService, UPDATABLE_FIELD } from "../../../../../core/models/technicalArea.models"
import { ImageUploader } from "../../../UX/ImageUploader.element"
import { durationDisplay } from "../../../../../_helpers/dateTime.helper"
import { BoatBoaterSelection } from "../../../UX/inputs/BoatBoater.select"
import { StartDateTime } from "./form/StartDatetime"
import { EndDateTime } from "./form/EndDatetime"
import { ServicesSelect } from "./form/Services.select"
import { TADuration } from "./form/Duration"
import { CGUField } from "./form/CGU.field"
import { PrintSignatureBox } from "./form/SignatureBox.print"
import { BoatHint } from "./form/Boat.hint"
import MainAPI from "@wattsonelements/front-fdk/dist/api"

type TACreationFormProps = {
  onClose?: () => any
}

const defaultValue = {
  start_date: dayjs().minute(0).toISOString(),
  start_time: dayjs().minute(0).hour(10).toISOString(),
  end_date: dayjs().add(1, "d").add(2, "h").hour(10).minute(0).toISOString(),
  end_time: dayjs().minute(0).toISOString(),
  services: [],
  boater: undefined,
  boat: undefined,
  terms_and_conditions_accepted: false,
  marina_comment: "",
  terms_and_conditions_upload: null
}

export const TACreationForm: FC<TACreationFormProps> = ({ onClose = () => { } }) => {
  const { t } = useTranslation("technicalArea")
  const dispatch = useAppDispatch()
  const port = useAppSelector(state => state.ports.current)

  const formMethods = useForm({
    defaultValues: defaultValue,
  })
  const { control, handleSubmit, watch, formState: { isValid } } = formMethods
  const [isSending, setIsSending] = useState(false)
  const start_date = watch("start_date")
  const start_time = watch("start_time")
  const end_date = watch("end_date")
  const end_time = watch("end_time")

  const submit = (data: any) => {
    const booking: TABookingCreation = {
      start_date: dayjs(data.start_date).format("YYYY-MM-DD"),
      start_time: dayjs(data.start_time).format("HH:mm"),
      end_date: dayjs(data.end_date).format("YYYY-MM-DD"),
      end_time: dayjs(data.end_date).format("HH:mm"),
      boat: data.boat,
      boater: data.boater,
      port: port!.id,
      marina_comment: data.marina_comment,
      terms_and_conditions_accepted: data.terms_and_conditions_accepted,
      services: data.services.map((s: TAService) => s.id),
      terms_and_conditions_upload: data.terms_and_conditions_upload
    }
    if (!booking.terms_and_conditions_upload) {
      delete booking.terms_and_conditions_upload
    }
    const getFormData = (object: any) => Object.keys(object).reduce((formData, key: string) => {
      if (Array.isArray(object[key])) {
        for (var i = 0; i < object[key].length; i++) {
          formData.append(`${key}`, object[key][i]);
        }
        return formData;
      }
      formData.append(key, object[key]);
      return formData;
    }, new FormData());
    const formData = getFormData(booking)
    setIsSending(true)
    createTABooking(formData).then(() => {
      dispatch(getTABookingList())
      onClose()
    }).finally(() => {
      setIsSending(false)
    })
  }

  return (
    <Box className="print:table-row-group">
      <FormProvider {...formMethods}>
        <form
          id="technical-area-form-creation"
          onSubmit={handleSubmit(submit)}
          className="grid grid-cols-2 gap-x-6 gap-y-4"
        >
          <Box className="col-span-2 text-fcotextgrey text-sm -mb-1">
            {t('form.boaterNote')}
          </Box>
          <BoatBoaterSelection
            boatRequest={(data) => MainAPI.API.request({
              method: "GET",
              url: "/v2/marina/technical-areas/boats",
              params: { ...data, port_id: port?.id }
            })}
            withBoatHint
            readonly={false} />
          <Box className="col-span-2 grid grid-cols-3 gap-x-6 gap-y-4">
            <StartDateTime updatableFields={[UPDATABLE_FIELD.START_DATE, UPDATABLE_FIELD.START_TIME]} />
            <EndDateTime updatableFields={[UPDATABLE_FIELD.END_DATE, UPDATABLE_FIELD.END_TIME]} />
          </Box>


          <TADuration
            className="col-span-2 text-sm  -mt-6 py-3"
            dateEnd={end_date}
            timeEnd={end_time}
            dateStart={start_date}
            timeStart={start_time} />

          <ServicesSelect />

          <Controller
            name="marina_comment"
            control={control}
            render={({ field, fieldState, formState }: any) => (
              <TextField
                className="col-span-2"
                label={t("form.description")}
                multiline
                minRows={4}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value)
                }}
                onBlur={field.onBlur}
              />

            )}
          />
          <CGUField
            disabled={isSending}
            cguAcceptedFieldName="terms_and_conditions_accepted"
            cguUploadFieldName="terms_and_conditions_upload"
          />
          <PrintSignatureBox />

          <Box className="print:hidden col-span-2 pt-3 flex flex-col gap-2">
            <Button disabled={!isValid || isSending} className="w-full" color="secondary" variant="contained" type="submit">
              {!isSending && t('form.actions.save')}
              {
                isSending && <div className="flex gap-2 items-center">
                  {t('form.actions.saving')}
                  <CircularProgress size={20} />
                </div>
              }

            </Button>

          </Box>
        </form>
      </FormProvider>
    </Box>
  )
}