import { TextField, debounce } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { durationFieldToHoursMinutes, readableDuration } from "../../../../../_helpers/dateTime.helper";

type TimeSpentProps = {
  value?: string | null,
  disabled?: boolean,
  update?: (value: string | null) => any,
  onError?: (error: boolean) => any
}
export const TimeSpent: FC<TimeSpentProps> = ({ value, disabled = false,
  update = () => { },
  onError = () => { },
}) => {
  const { t } = useTranslation("ticket")
  const [error, setError] = useState(false)
  const [duration, setDuration] = useState<string>(durationFieldToHoursMinutes(value))

  useEffect(() => {
    if (value) {
      setDuration(durationFieldToHoursMinutes(value))
    }
  }, [value])

  return (
    <>

      <TextField
        defaultValue={duration}
        label={t("form.timeSpent") + (value ? ` : ${readableDuration(value)} (= ${duration.replace(':', t('dates.h', { ns: "common" }))})` : '')}
        className="w-full"
        disabled={disabled}
        inputProps={{
          pattern: '^([0-9]+):([0-9]{2}$)*'
        }}

        helperText={t("form.helper.timeSpent")}
        error={error}
        onBlur={debounce((event: any) => {
          if (/^([0-9]+):([0-9]{2}$)/.test(event.target.value)) {
            onError(false)
            update(event.target.value + ":00")
            setError(false)

          } else {
            setError(true)
            onError(true)
          }
        }, 500)}
      />


    </>
  )

}