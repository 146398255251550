import { DashboardLayout } from "../../layouts/Dashboard.layout";
import { Outlet, useLocation, } from "react-router-dom";
import { useEffect, useState } from "react";

export const CraneModulePage = () => {
    const location = useLocation();
    const [value, setValue] = useState<string | boolean>("planning");

    useEffect(() => {
        if (location.pathname.includes("planning")) {
            setValue("planning");
        } else if (location.pathname.includes("booking")) {
            setValue("booking");
        } else {
            setValue(false);
        }
    }, [location]);
    return (
        <DashboardLayout
        // top={<div className="flex flex-col">
        //     <div>
        //         CraneModulePage
        //     </div>

        // </div>}
        >
            <Outlet></Outlet>
        </DashboardLayout>
    )
}