import { useEffect, useRef, useState } from "react";

export const debounce = (fn: Function, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;

  return function (this: any, ...args: any[]) {
    console.log("debounce before", timeoutId);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      console.log("APPLY DEBOUNCE");

      fn.apply(this, args)
    }, ms);
    console.log("debounce after", timeoutId);

  };
};

export const useDebounce = <T = any>(value: T, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState<typeof value>();
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    timerRef.current = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [value, delay]);

  return debouncedValue;
};