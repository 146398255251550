import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { compareString } from "../../../_helpers/sort.helpers";
import dayjs from "dayjs";
import i18n from "../../../translations/initI18n";
import { getDateObjectFromTime } from "../../../_helpers/dateTime.helper";
import { stringDescendingComparator } from "../../../_helpers/sort.helpers";
import { IBooking, IPlaningAvailability } from "@wattsonelements/front-fdk/dist/models/Booking.models";

export const getFilteredBookingList = createSelector(
    (state: RootState) => state.crane.bookingList,
    (state: RootState) => state.crane.filters,
    (list, filters) => {
        return list?.filter(l => {
            const date = dayjs(l.date).format(i18n.t('dates.day_month', { ns: "common" })) +
                getDateObjectFromTime(l.related_planning.start_time, l.date).format(i18n.t('dates.hour', { ns: "common" })) +
                " " +
                getDateObjectFromTime(l.related_planning.end_time, l.date).format(i18n.t('dates.hour', { ns: "common" }))

            const str = "" + (l.agent?.first_name || "") +
                (l.agent?.last_name || "") +
                (l.boater?.first_name || "") +
                (l.boater?.last_name || "") +
                (l.motive?.label || "") +
                (l.boat?.name || "") + date

            return compareString(str, filters.text) && (Object.keys(filters.statusBooking).length > 0
                ? filters.statusBooking[l.status.toUpperCase()] : 1)
        }).sort((a: IBooking, b: IBooking) => {
            const timeA = getDateObjectFromTime(a.related_planning.start_time, a.date)
            const timeB = getDateObjectFromTime(b.related_planning.start_time, b.date)
            return -stringDescendingComparator(timeA, timeB)
        })
    }
)

export const getFilteredPlanning = createSelector((state: RootState) => state.crane.planning,
    (state: RootState) => state.crane.filters,
    (list, { statusPlanning }) => {
        if (!list) return []
        return list.slice()
            .filter(item => (Object.keys(statusPlanning).length > 0
                ? statusPlanning[item.status.toUpperCase()]
                : 1))
            .sort((a: IPlaningAvailability, b: IPlaningAvailability) => {
                const timeA = getDateObjectFromTime(a.start_time, a.date)
                const timeB = getDateObjectFromTime(b.start_time, b.date)
                return -stringDescendingComparator(timeA, timeB)
            })
    })