import { CircularProgress } from "@mui/material";
import FDK from "@wattsonelements/front-fdk";
import { IPowerUnit } from "@wattsonelements/front-fdk/dist/models/Electricity.models";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Geojson } from "../../../../../core/models/map.models";
import { ElecMarkerGroup, useElectricityService } from "../../../../../core/services/Electricity.service";
import { usePortService } from "../../../../../core/services/Port.service";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../core/store/hooks";
import { setSelectedMarkerId, setSelectedPowerUnit } from "../../../../../core/store/reducers/Electricity.reducer";
import { setSelected as setSelectedPlug } from "../../../../../core/store/reducers/Electricity.reducer";
import { getFilteredPlugList } from "../../../../../core/store/selectors/Module.selector";
import { loadPaginatedDatas } from "../../../../../_helpers/loadPaginatedData.helpers";
import { MapType } from "../../../../../_helpers/map.helper";
import { formatAreaId, generateAreaUrl } from "../../../../../_helpers/misc.helpers";
import { MapBoxLayer } from "../../MapBoxLayer.element";

export const ElecMap = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { areaId } = useParams();
  const mapRef = useRef<any>();

  const [powerunits, setPowerunits] = useState<IPowerUnit[]>();
  const [loading, setLoading] = useState(true);
  const { firstLoading, selectedMarkerId, selected, selectedPowerUnit } = useAppSelector(
    (state) => state.electricity
  );
  const port = useAppSelector((state) => state.ports.current);
  const plugs = useAppSelector(getFilteredPlugList);
  const { generateElectricityGeoJson } = useElectricityService();
  const { generateAreasStatsGeoJson } = usePortService();
  const [geojson, setGeoJson] = useState<Geojson>(generateElectricityGeoJson([]));

  const [selectedMarkers, setSelectedMarkers] = useState<string[]>([])

  useEffect(() => {
    let tmp = []
    if (selected) {
      tmp.push(selected.id)
    }
    if (selectedPowerUnit) {
      tmp.push(selectedPowerUnit.id)
    }
    setSelectedMarkers(tmp)
  }, [selected, selectedPowerUnit])

  useEffect(() => {
    if (loading || firstLoading || !mapRef.current) return
    if (!selected && !selectedPowerUnit) {
      setTimeout(() => mapRef.current?.fitTheMap(), 500);
    } else {

      setTimeout(() =>
        mapRef.current.flyToSelectedMarker(selectedMarkerId || selectedPowerUnit?.id || ""), 500);
    }

  }, [selected, selectedPowerUnit, selectedMarkerId, loading, firstLoading]);

  const handlePlugMarkerClick = (plugId: UUID) => {
    console.log("handlePlugMarkerClick");
    const plug = plugs.find((p) => p.id === plugId);
    if (plug) {
      navigate(`/admin/area/${areaId || "all"}/modules/electricity/${plug.id}`);
    }
  }

  const handlePowerunitMarkerClick = (powerunitId: UUID) => {
    const powerunit = powerunits?.find((p) => p.id === powerunitId);
    if (powerunit) {
      if (!selected) {
        dispatch(setSelectedPowerUnit(powerunit));
      }
      if (selected && powerunit.plugs && powerunit.plugs.length > 0) {
        navigate(`/admin/area/${areaId || "all"}/modules/electricity/${powerunit.plugs[0].id}`);
      }
    }
  }

  const clickOnMarker = (plugOrPowerunitId: UUID, group?: ElecMarkerGroup) => {
    console.log("clickOnMarker", group);
    if (group === "plug") {

    }
    dispatch(setSelectedMarkerId(plugOrPowerunitId));

    switch (group) {
      case ElecMarkerGroup.powerunit:
        handlePowerunitMarkerClick(plugOrPowerunitId)
        break;
      case ElecMarkerGroup.plug:
        handlePlugMarkerClick(plugOrPowerunitId)
        break;
      default:
        break;
    }
  };
  const clickOnArea = (id: UUID) => {
    navigate(generateAreaUrl(id))
  };


  useEffect(() => {
    if (!areaId || areaId === "all") {
      loadPaginatedDatas((page: number = 1) =>
        FDK.Marina.ElectricityModule.getPowerunitList({ page })
      ).then((res) => {
        setPowerunits(res);
      });
      return
    }
    loadPaginatedDatas((page: number = 1) =>
      FDK.Marina.ElectricityModule.getPowerunitList({ page, port_area: areaId })
    ).then((res) => {
      setPowerunits(res);
    });
  }, [areaId]);

  useEffect(() => {
    if (!port) return;
    if (powerunits && plugs) {
      (formatAreaId(areaId) === "" && port.areas.length > 1) ?
        setGeoJson(generateAreasStatsGeoJson(port.areas, MapType.ELECTRICITY))
        : setGeoJson(generateElectricityGeoJson(powerunits))
      setLoading(false);
    }
  }, [powerunits, plugs, port]);

  return (
    <>
      <MapBoxLayer
        ref={mapRef}
        loading={loading || firstLoading}
        selectedMarkerId={selectedMarkerId}
        geojson={geojson}
        mapType={MapType.ELECTRICITY}
        multiMarkerSelection={selectedMarkers}
        clickOnMarker={
          !port ?
            () => { }
            : (formatAreaId(areaId) === "" && port.areas.length > 1) ?
              clickOnArea
              : clickOnMarker}
        zoom={{
          default: 17,
          max: 21,
          min: 17
        }}
      />
    </>
  );
};
