import { Box } from "@mui/material"
import dayjs from "dayjs"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { addTimeToDate, durationDisplay, getDateObjectFromTime } from "../../../../../../_helpers/dateTime.helper"

type DurationProps = {
  dateStart: string | dayjs.Dayjs
  timeStart: string | dayjs.Dayjs | null
  dateEnd: string | dayjs.Dayjs
  timeEnd: string | dayjs.Dayjs | null
  className?: string
}

export const TADuration: FC<DurationProps> = (props) => {
  const { t } = useTranslation("technicalArea")
  let dateStart = dayjs(props.dateStart)
  let dateEnd = dayjs(props.dateEnd)
  if (props.timeStart) {
    const timeStart = typeof props.timeStart === "string" ? getDateObjectFromTime(props.timeStart) : props.timeStart
    dateStart = dateStart.hour(timeStart.get('h')).minute(timeStart.get('minute'))
  }
  if (props.timeEnd) {
    const timeEnd = typeof props.timeEnd === "string" ? getDateObjectFromTime(props.timeEnd) : props.timeEnd

    dateEnd = dateEnd.hour(dayjs(timeEnd).get('h')).minute(dayjs(timeEnd).get('minute'))
  }

  return <Box className={props.className}>
    {t("form.duration")} : {
      durationDisplay(
        dateStart,
        dateEnd,
        true
      )
    }
  </Box>
}