import { Controller, useFormContext } from "react-hook-form"
import { TicketBoatInput } from "../../elements/dashboard/Tickets/TicketBoat.element"
import { BoaterSearchInput } from "./BoaterSearchInput"
import { FC, useState } from "react"
import { IShortUser } from "@wattsonelements/front-fdk/dist/models/Ticket.models"
import { IBoat } from "@wattsonelements/front-fdk/dist/models/Boats.models"
import { ReadOnlyField } from "./ReadOnlyField"
import { useTranslation } from "react-i18next"
import { Box } from "@mui/material"
import { BoatHint } from "../../elements/dashboard/TechnicalArea/form/Boat.hint"
import { BoatSearchInput } from "./BoatSearchInput"
import { MODULES } from "@wattsonelements/front-fdk/dist/models/Port.models"
import { AxiosResponse } from "axios"

type BoatBoaterSelectionProps = {
  readonly: boolean,
  boat?: Partial<IBoat> | null
  boater?: IShortUser | null
  withBoatHint?: boolean
  boatRequest?: (search: { name?: string, owner?: string }) => Promise<AxiosResponse<IBoat[], any>>
}

export const BoatBoaterSelection: FC<BoatBoaterSelectionProps> = ({ readonly, boatRequest, withBoatHint, boat: initialBoat, boater: initialBoater }) => {
  const { t } = useTranslation()
  const { control, setValue } = useFormContext()
  const [boat, setBoat] = useState<Partial<IBoat> | undefined | null>(initialBoat)
  const [boater, setBoater] = useState<IShortUser | undefined | null>(initialBoater)
  if (readonly) {
    return <>
      <ReadOnlyField label={t("form.boater", { ns: "ticket" })} value={boater?.first_name + " " + boater?.last_name || ""} />
      <ReadOnlyField label={t("form.boat", { ns: "ticket" })} value={boat?.name || ""} />
    </>
  }
  return <>
    <Box>
      <Controller
        control={control}
        name="boat"
        rules={{ required: true }}
        render={({ field, fieldState, formState }) => (
          <BoatSearchInput
            beforeLabel="*"
            owner={boater}
            initialValue={boat}
            request={boatRequest}
            onChange={(value) => {
              if (!value) {
                setBoater(null)
                setValue("boater", null)
              }
              field.onChange(value?.id)
              setBoat(value as Partial<IBoat>)
            }} />
        )}
      />
      {
        withBoatHint && boat && <BoatHint boat={boat} />
      }
    </Box>
    <Controller
      name="boater"
      rules={{ required: true }}
      control={control}
      render={({ field, fieldState, formState }) => (
        <BoaterSearchInput
          initialValue={boater}
          beforeLabel="*"
          boat={boat || null}
          onChange={(value) => {
            if (!value) {
              setBoat(null)
              setValue("boat", null)
            }
            field.onChange(value?.id)
            setBoater(value)
          }}
        />

      )}
    /></>
}