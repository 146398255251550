import {
  Button,
  CircularProgress,
  Box,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  OutlinedInput,
  ListItemText,
  Checkbox,
  Chip,
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { usePeriodic } from "../../../../../core/services/Periodic.service";
import { useTicket } from "../../../../../core/services/Ticket.service";
import {
  exportTicketsList,
  getTicketStatusListParam,
  getTicketsClosedList,
  getTicketsList,
  updateTicket,
} from "../../../../../core/store/actions/Ticket.actions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../core/store/hooks";
import {
  changeSort,
  setFirstLoading,
  updateFilter,
  updateFilterWithoutSaving,
} from "../../../../../core/store/reducers/Ticket.reducer";
import { getFilteredTicketList } from "../../../../../core/store/selectors/Module.selector";
import { getCategoryById } from "../../../../../core/store/selectors/Ticket.selector";
import { ModuleTable } from "../Table";
import { TableTop } from "../TableTop";
import { SelectTicketStatus } from "./SelectTicketStatus.element";
import { TicketAssignees } from "./TicketAssignees";
import FilterListIcon from "@mui/icons-material/FilterList";
import { AlertType, useAppContext } from "../../../../contexts/AppContext";
import { IAssignee, IBerth, ITicket, ITicketBoat } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import FDK from "@wattsonelements/front-fdk";
import { formatAreaId } from "../../../../../_helpers/misc.helpers";
import { TicketStatus } from "@wattsonelements/front-fdk/dist/constants/ticket.constants";
import { DlFileButton } from "../../../UX/DlFile.button";
import { TICKET_FIELDS } from "../../../../../core/API/Ticket";
import { IPortArea } from "@wattsonelements/front-fdk/dist/models/Port.models";

export const TicketTable = () => {
  const { t } = useTranslation("ticket");
  const navigate = useNavigate();
  const { areaId } = useParams()
  const datas = useAppSelector(getFilteredTicketList);
  const filters = useAppSelector((state) => state.tickets.filters);
  const order = useAppSelector((state) => state.tickets.sort?.order);
  const orderBy = useAppSelector((state) => state.tickets.sort?.orderBy);
  const categories = useAppSelector((state) =>
    state.tickets.categories.filter((cat: any) => cat.parent_category !== null)
  );
  const selectedTicketId = useAppSelector(({ tickets }) => tickets?.selectedID);
  const port = useAppSelector(state => state.ports.current)
  const { setAlertMessage } = useAppContext();
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState<ITicket[]>([]);
  const { updateAssignee } = useTicket();
  const { stopPeriodic, startPeriodic } = usePeriodic();
  const [editing, setEditing] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [firstInit, setFirstInit] = useState(true);
  const tableRef = useRef<any>();
  const listLoading = useAppSelector((state) => state.tickets.firstLoading);

  const [headers, setHeaders] = useState()

  const disableSelectionRow = (ticket: ITicket) => {
    return ticket.status === FDK.Constants.TicketConstants.TicketStatus.CLOSED;
  };

  useEffect(() => {
    return () => {
      dispatch(updateFilterWithoutSaving({ text: "" }));
    };
  }, []);

  useEffect(() => {
    if (editing) {
      stopPeriodic("tickets-list");
    } else if (!firstInit) {
      dispatch(setFirstLoading(true))
      startPeriodic("tickets-list", 60, () => {
        return dispatch(getTicketsList({ port_area: formatAreaId(areaId) }));
      });
    } else {
      setFirstInit(false);
    }
  }, [editing, areaId]);

  useEffect(() => {
    generateHeaders()
  }, [port])

  const sortHandler = (key: string) => {
    let tmpOrder;
    if (order === "asc")
      tmpOrder = "desc";
    else
      tmpOrder = "asc";
    // if (order === undefined) {
    //   tmpOrder = "asc";
    // } else if (order === "asc") {
    //   tmpOrder = "desc";
    // } else {
    //   tmpOrder = undefined;
    // }
    dispatch(changeSort({ order: tmpOrder, orderBy: key }));
  };

  const categFilterChanged = (e: SelectChangeEvent<string>) => {
    dispatch(updateFilter({ category: e.target.value }));
  };
  const stateFilterChanged = (e: any) => {
    let tmpStatus: string[] = e.target.value;
    const newFilters = { ...filters, status: { ...filters.status } };
    newFilters.status = {};
    for (let i in tmpStatus) {
      newFilters.status[`${tmpStatus[i]}`] = true;
    }
    dispatch(updateFilter(newFilters));
    if (tmpStatus.includes(FDK.Constants.TicketConstants.TicketStatus.CLOSED.toUpperCase())) {
      dispatch(getTicketsClosedList({ port_area: formatAreaId(areaId) }));
    }
    dispatch(updateFilterWithoutSaving(newFilters));
  };

  const assigneesCB = (id: string) => async (ass: Partial<IAssignee>[]) => {
    let assignees = ass.map(as => as.id)
    try {
      await updateAssignee(id, assignees as string[]);
    } catch (e) {
      setAlertMessage!({ type: AlertType.ERROR, message: t('ticketUpdateError') })
    }
  }

  const massClose = async () => {
    setEditing(true);
    setUpdating(true);
    let errors = 0;
    for (let i in selected) {
      try {
        let res: any = await dispatch(
          updateTicket({
            id: selected[i].id,
            field: { status: FDK.Constants.TicketConstants.TicketStatus.CLOSED }
          }))
        if (res.type === "ticket/create/rejected") {
          errors++;
          setAlertMessage!({
            type: AlertType.ERROR,
            message: t("ticketClosedErreur"),
          });
        }
      } catch (e) {
        errors++;
        setAlertMessage!({
          type: AlertType.ERROR,
          message: t("ticketClosedErreur"),
        });
      }
    }
    if (selected.length > errors) {
      if (tableRef.current) tableRef.current.resetSelected();
      setAlertMessage!({
        type: AlertType.SUCCESS,
        message: t("ticketWellClosed"),
      });
    }
    setUpdating(false);
    setEditing(false);
  };

  const customOrderPriority = (tab: ITicket[]) => {
    tab = [
      ...tab.filter((ticket: any) => ticket.priority === FDK.Constants.TicketConstants.TicketPriority.URGENT),
      ...tab.filter(
        (ticket: any) => ticket.priority === FDK.Constants.TicketConstants.TicketPriority.INTERMEDIATE
      ),
      ...tab.filter(
        (ticket: any) =>
          ticket.priority !== FDK.Constants.TicketConstants.TicketPriority.URGENT &&
          ticket.priority !== FDK.Constants.TicketConstants.TicketPriority.INTERMEDIATE
      ),
    ];
    return tab;
  };

  const generateHeaders = () => {
    let tmpHeaders = [
      {
        label: t("table.headers.category"),
        key: "name",
        cellClassName: "cursor-pointer",
        format: (data: ITicket): React.ReactElement => {
          const Icon = () => {
            const category = useAppSelector((state) =>
              getCategoryById(state, data.category)
            );

            const priorityColor = {
              normal: "#92929d",
              urgent: "#ff0000",
              intermediate: "#0eb6cd",
            };
            if (category?.slug) {
              return (
                <>
                  {/* hack to push the cell, because of the absolute next div which doesnt push it */}
                  <div className="whitespace-nowrap">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                  <div
                    className="absolute w-10 h-full flex justify-center items-center top-0 left-0"
                    style={{
                      background: priorityColor[data.priority],
                    }}
                  >
                    <img
                      className="w-5"
                      alt={category?.label}
                      src={category.icon_url}
                    />
                  </div>
                </>
              );
            } else {
              return <CircularProgress />;
            }
          };
          return <Icon />;
        },
        sort: false,
        compareValue: (ticket: ITicket) => ticket.category,
      },
      {
        label: t("table.headers.date"),
        key: "date",
        cellClassName: "cursor-pointer",
        format: (data: ITicket) => (
          <span>
            {dayjs(data.date_created).format(t("dates.full", { ns: "common" }))}
            <br />
            {dayjs(data.date_created).format(t("dates.hour", { ns: "common" }))}
          </span>
        ),
        sort: true,
        compareValue: (data: ITicket) => dayjs(data.date_created).unix(),
      },
      {
        label: t("table.headers.refdesc"),
        key: "reference",
        cellClassName: "cursor-pointer",
        format: (data: ITicket) => (
          <Box className="mx-2 flex flex-col">
            <span className=" font-extrabold">
              {data.reference ? data.reference : "-"}
            </span>{" "}
            {/* {data.description
              ? data.description.length > 25
                ? data.description.substr(0, 22) + "..."
                : data.description
              : "-"} */}
            <span>{data.title}</span>
          </Box>
        ),
        sort: true,
        compareValue: (data: ITicket) =>
          data.reference ? data.reference : data.description,
      },
      {
        label: t("table.headers.boat"),
        key: "boat",
        cellClassName: "cursor-pointer",
        format: (data: ITicket) => {
          return (data.boat as ITicketBoat)?.name || "- -";
        },
        sort: true,
        compareValue: (data: ITicket) => (data.boat as ITicketBoat)?.name || "- -",
      },
      {
        label: t("table.headers.berth"),
        key: "berth",
        cellClassName: "cursor-pointer",
        format: (data: ITicket) => {
          return (data.spot as IBerth)?.name || "- -";
        },
        sort: true,
        compareValue: (data: ITicket) => (data.spot as IBerth)?.name || "- -",
      },
      {
        label: t("table.headers.ref"),
        key: "ref",
        format: (ticket: ITicket) => {
          return (
            <TicketAssignees
              onOpen={(open: boolean) => {
                if (open) {
                  setEditing(true);
                } else {
                  setEditing(false);
                }
              }}
              canEdit={
                !(
                  ticket?.status === FDK.Constants.TicketConstants.TicketStatus.CLOSED ||
                  ticket?.status === FDK.Constants.TicketConstants.TicketStatus.ARCHIVED
                )
              }
              onChange={assigneesCB(ticket.id)}
              ticket={ticket}
            />
          );
        },
        sort: false,
        compareValue: (data: ITicket) => "- -",
      },
      {
        label: t("table.headers.status"),
        key: "status",
        format: (data: ITicket) => {
          return (
            <SelectTicketStatus
              displayLabel={false}
              onOpen={() => setEditing(true)}
              onClose={() => setEditing(false)}
              ticket={data}
            />
          );
        },
        sort: false,
        compareValue: (data: ITicket) => data.status || "- -",
      },
    ];
    if (port && port.config.ticket_displayed_fields.includes(TICKET_FIELDS.DEADLINE)) {
      const deadlineHeader = {
        label: t("form.deadline"),
        key: "deadline",
        format: (data: ITicket) => {
          if (!data.deadline) return <></>

          return <Chip
            color={dayjs(data.deadline).isSame(dayjs(), 'day')
              && (data.status === TicketStatus.ONGOING || data.status === TicketStatus.OPEN) ? 'error' : 'default'}
            label={
              dayjs(data.deadline).format(t("dates.full", { ns: "common" })) + ' \n ' + dayjs(data.deadline).format(t("dates.hour", { ns: "common" }))
            }></Chip >
        },
        sort: true,
        compareValue: (data: ITicket) => data?.deadline || "- -",
      }
      tmpHeaders = [...tmpHeaders.slice(0, 6), deadlineHeader, ...tmpHeaders.slice(6)]
    }
    if (port && port.config.ticket_displayed_fields.includes(TICKET_FIELDS.AREA)) {
      const areaHeader = {
        label: t("form.area"),
        key: "area",
        format: (data: ITicket) => {
          if (!data.area) return <></>

          return <>{(data.area as IPortArea)?.name}</>
        },
        sort: true,
        compareValue: (data: ITicket) => data?.deadline || "- -",
      }
      tmpHeaders = [...tmpHeaders.slice(0, 6), areaHeader, ...tmpHeaders.slice(6)]
    }
    if (port && !port.config.ticket_displayed_fields.includes(TICKET_FIELDS.BOAT)) {
      tmpHeaders = tmpHeaders.filter(h => h.key !== "boat")
    }
    if (port && !port.config.ticket_displayed_fields.includes(TICKET_FIELDS.SPOT)) {
      tmpHeaders = tmpHeaders.filter(h => h.key !== "berth")
    }
    if (port && port.config.ticket_displayed_fields.includes(TICKET_FIELDS.AREA)) {

    }
    setHeaders(tmpHeaders as any)
  }

  return (
    <>
      <TableTop
        datas={datas}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          event.preventDefault();
          dispatch(updateFilterWithoutSaving({ text: event.target.value }));
        }}
        buttons={
          <>
            {/* NOT TODO add link to archived view */}
            {/* <Button>{t("btns.viewArchived")}</Button> */}
            <Box sx={{ minWidth: 110, maxWidth: 200 }}>
              <FormControl size="small" fullWidth={true}>
                {Object.keys(filters.status).length < 1 && (
                  <InputLabel id="state-checkbox-label">
                    {t("table.headers.status")}
                  </InputLabel>
                )}
                <Select
                  labelId="state-checkbox-label"
                  id="state-checkbox"
                  multiple
                  value={Object.keys(filters.status)}
                  onChange={stateFilterChanged}
                  input={
                    <OutlinedInput
                    // label={t("table.headers.status")}
                    />
                  }
                  renderValue={(selected) =>
                    selected.map(
                      (sel) => t(`status.${sel.toLocaleLowerCase()}`) + ", "
                    )
                  }
                  IconComponent={FilterListIcon}
                >
                  {/* TicketStatus */}
                  {Object.keys(FDK.Constants.TicketConstants.TicketStatus).map(
                    (s) =>
                      // s.toLocaleLowerCase() !== FDK.Constants.TicketConstants.TicketStatus.BLOCKED &&
                      s.toLocaleLowerCase() !== FDK.Constants.TicketConstants.TicketStatus.ARCHIVED && (
                        <MenuItem dense={true} key={"status-" + s} value={s}>
                          <Checkbox
                            size={"small"}
                            checked={filters.status[s] === true}
                          />
                          <ListItemText
                            primary={t(`status.${s.toLocaleLowerCase()}`)}
                          />
                        </MenuItem>
                      )
                  )}
                </Select>
              </FormControl>
            </Box>
            <Box className="w-32">
              <FormControl size="small" fullWidth={true}>
                {filters.category === "" && (
                  <InputLabel id="filter-category-label">
                    {t("table.filters.category")}
                  </InputLabel>
                )}
                <Select
                  labelId="filter-category-label"
                  id="filter-category"
                  value={filters.category}
                  // label={t('table.filters.category')}
                  onChange={categFilterChanged}
                  IconComponent={FilterListIcon}
                >
                  <MenuItem value="">
                    {t("table.filters.allCategories")}
                  </MenuItem>
                  {categories.map((cat) => (
                    <MenuItem key={`filter-categ-${cat.id}`} value={cat.id}>
                      {cat.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {
              selected.length === 0 && <DlFileButton
                disabled={selected && selected.length > 0}
                request={() => {
                  let params = {}
                  if (formatAreaId(areaId)) {
                    params = {
                      ...params,
                      port_area: formatAreaId(areaId)
                    }
                  }
                  if (filters.category) {
                    params = {
                      ...params,
                      category: filters.category
                    }
                  }
                  params = getTicketStatusListParam(filters.status, params)
                  return exportTicketsList(params) as any
                }}

              >
                {t("btns.export")}
              </DlFileButton>
            }

            {selected.length > 0 && !updating && (
              <Button color="warning" variant="contained" onClick={massClose}>
                {t("btns.massClose")}
              </Button>
            )}
            {updating && <CircularProgress color={"info"} />}
          </>
        }
      />
      {/* TODO mass close ticket condition on checkbox :O */}
      <ModuleTable
        onClickRow={(event: React.MouseEvent, ticket) => {
          // navigate(`/admin/area/${areaId || "all"}/modules/tickets/${ticket.id}`);
          // no port areas for the moment
          navigate(`/admin/area/all/modules/tickets/${ticket.id}`);
        }}
        onChangeSelection={setSelected}
        datas={datas}
        ref={tableRef}
        headers={headers}
        disableSelection={disableSelectionRow}
        isLoading={listLoading}
        selectedKey={selectedTicketId}
        order={order}
        orderBy={orderBy}
        // TODO discussion about the order to be sure we want that
        // customOrder={customOrderPriority}
        onSort={sortHandler}
      />
    </>
  );
};
