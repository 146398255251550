import { Box, Button, Checkbox, CircularProgress, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import { BOOKING_SERVICE, IBookingCreation, IHardware, IHardwareConfig, IPlaningAvailability } from "@wattsonelements/front-fdk/dist/models/Booking.models"
import { useTranslation } from "react-i18next";

import { TicketBoatInput } from "../../Tickets/TicketBoat.element"
import { BoaterSearchInput } from "../../../../UX/inputs/BoaterSearchInput";
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FC, useEffect, useState } from "react";
import { IBoat } from "@wattsonelements/front-fdk/dist/models/Boats.models";
import { IShortUser } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import FDK from "@wattsonelements/front-fdk";
import { useAppSelector } from "../../../../../../core/store/hooks";
import { getDateObjectFromTime } from "../../../../../../_helpers/dateTime.helper";
import classNames from "classnames";
import { AlertType, useAppContext } from "../../../../../contexts/AppContext";
import i18n from "../../../../../../translations/initI18n";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { BoatBoaterSelection } from "../../../../UX/inputs/BoatBoater.select";
import MainAPI from "@wattsonelements/front-fdk/dist/api";

type ReservationFormProps = {
  onClose: (close: boolean) => any
}

export const ReservationForm: FC<ReservationFormProps> = ({ onClose = () => { } }) => {
  const { t } = useTranslation("crane");
  const port = useAppSelector(state => state.ports.current)

  const [slotsList, setSlotsList] = useState<IPlaningAvailability[]>([])
  const [slotError, setSlotError] = useState(false)
  const [hardwares, setHardwares] = useState<IHardware[]>()
  const [hardwareConfig, setHardwareConfig] = useState<IHardwareConfig>()

  const [boat, setBoat] = useState<Partial<IBoat> | undefined | null>()
  const [boater, setBoater] = useState<IShortUser | undefined | null>()
  const [slot, setSlot] = useState<IPlaningAvailability | null>()

  const [isSending, setIsSending] = useState(false)
  const { setAlertMessage, } = useAppContext();

  const form = useForm<IBookingCreation & { hardware: string }>({
    defaultValues: {
      date: dayjs().toISOString(),
      start_time: "",
      end_time: "",
      hardware: "",
      motive: "",
      service: null,
      boater: undefined,
      boat: undefined,
      terms_and_conditions_accepted: false,
      related_planning: undefined,
      port: port?.id
    },
  })
  const { register, control, watch, reset, handleSubmit, setValue, formState } = form
  const date = watch("date")
  const hardware = watch("hardware")
  const service = watch("service")

  useEffect(() => {
    if (!port) return
    FDK.Marina.CraneModule.getHardwares(port.id).then(res => {
      setHardwares(res.data.results)
    })
  }, [port])

  useEffect(() => {
    if (!hardware || !date) return
    loadDateSlot(dayjs(date))
  }, [date, hardware])

  const loadDateSlot = (date: dayjs.Dayjs) => {
    if (!hardware) return
    FDK.Marina.CraneModule.getAggregatedAvailabilityPlanning(date.format("YYYY-MM-DD"), hardware).then(res => {
      setSlotError(false)
      setSlotsList(res.data)
    }, err => {
      console.log(err);
      setSlotError(true)
    })
  }

  const loadHardwareConfig = (id: string) => {
    if (!id) return
    FDK.Marina.CraneModule.getHardwareConfig(id).then(res => {
      setHardwareConfig(res.data)
    }, err => {

    })
  }

  const send = (data: IBookingCreation & { hardware: string }) => {
    // e.preventDefault();
    console.log(data);

    setIsSending(true)
    const newSlot: IBookingCreation = {
      start_time: data.start_time,
      end_time: data.end_time,
      related_planning: data.related_planning!,
      boater: data.boater,
      boat: data.boat,
      service: data.service as BOOKING_SERVICE,
      motive: data.motive,
      date: dayjs(data.date)!.format("YYYY-MM-DD"),
      terms_and_conditions_accepted: data.terms_and_conditions_accepted,
      hardware: hardware
    }

    FDK.Marina.CraneModule.createBooking(newSlot).then(({ data }) => {
      const message = 'Réservation crée' // TODO default message
      setAlertMessage!({ message, ...data, type: AlertType.SUCCESS })
      setBoat(undefined)
      setBoater(undefined)
      reset()
      onClose(true)
    }, err => {
      const message = 'Une erreur est survenue' // TODO
      setAlertMessage!({ message, ...err, type: AlertType.ERROR })
    }).finally(() => {
      setIsSending(false)
    })

  }

  return (
    <Box className="bg-white p-10" component="form" onSubmit={handleSubmit(send)}>
      <FormProvider {...form} >
        <h2 className="font-bold mb-4 text-xl">{t("booking.form.title")}</h2>
        <Box className="grid grid-cols-2 gap-3">
          {/* HARDWARE SELECTION */}
          <FormControl className="col-span-2" fullWidth>
            <InputLabel id="select-service-label">{t("booking.form.type")}</InputLabel>
            <Controller
              control={control}
              name="hardware"
              rules={{
                required: true
              }}
              render={
                ({ field, fieldState, formState }) => (
                  <Select
                    labelId="select-service-label"
                    id="select-service-label"
                    label={t('booking.form.service')}
                    onChange={(e) => {
                      // setHardware(e.target.value)
                      field.onChange(e.target.value)

                      setValue("motive", "")
                      setValue("start_time", "")
                      setValue("end_time", "")
                      setValue("related_planning", "")
                      // setMotive("")
                      setSlot(null)
                      setSlotsList([])
                      loadHardwareConfig(e.target.value)
                    }}
                    value={field.value}
                    error={!!fieldState.error}
                    renderValue={(value) => {
                      return hardwares?.find(h => h.id === value)?.name
                    }}
                  >
                    {
                      hardwares?.map(h => {
                        return <MenuItem value={h.id}>{h.name}</MenuItem>
                      })
                    }

                  </Select>
                )}
            />
          </FormControl>

          {/* BOAT + BOATER SELECTION */}
          {/* <Box className="col-span-2 grid grid-cols-1 gap-3"> */}
          <BoatBoaterSelection
            boatRequest={(data) => MainAPI.API.request({
              method: "GET",
              url: "/v2/marina/handling-tasks/boats",
              params: { ...data, port_id: port?.id }
            })}
            boater={boater}
            boat={boat}
            readonly={false} />
          {/* </Box> */}


          <Box className="col-span-2 text-fcotextgrey text-sm -mt-1 mb-4">
            {t('booking.form.boaterNote')}
          </Box>

          {/* SERVICE SELECTION */}
          <FormControl className="col-span-2" fullWidth disabled={!hardware}>
            <InputLabel id="select-service-label">{t('booking.form.service')}</InputLabel>
            <Controller
              control={control}
              name="service"
              rules={{
                required: true,
              }}
              render={({ field, fieldState, formState }) => (
                <Select
                  labelId="select-service-label"
                  id="select-service-label"
                  label="Service"
                  onChange={(e) => field.onChange(e.target.value)}
                  error={!!fieldState.error}
                  value={field.value}
                >
                  <MenuItem value={BOOKING_SERVICE.CRANE_TO_LAND}>{t(`service.${BOOKING_SERVICE.CRANE_TO_LAND}`)}</MenuItem>
                  <MenuItem value={BOOKING_SERVICE.CRANE_TO_WATER}>{t(`service.${BOOKING_SERVICE.CRANE_TO_WATER}`)}</MenuItem>
                </Select>
              )}
            />
          </FormControl>



          {/* DATE + SLOT SELECTION */}
          <FormControl fullWidth disabled={!hardware}>
            <InputLabel id="select-slot-label"></InputLabel>
            <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs}>
              <Controller
                control={control}
                name="date"
                rules={{
                  required: true
                }}
                render={({ field, fieldState, formState }) => (
                  <DatePicker
                    disabled={!hardware}
                    value={dayjs(field.value)}
                    // value={dayjs(field)}
                    // format={t('dates.full', { ns: "common" })}
                    // renderInput={(params) =>
                    //   <TextField {...params} />}
                    onChange={(val, context) => {
                      if (!!context.validationError) return
                      field.onChange(val ? val.format("YYYY-MM-DD") : "")
                    }}
                    label={t("booking.form.date")}
                    slotProps={
                      {
                        textField: {
                          ref: field.ref,
                          onBlur: field.onBlur,
                          error: !!fieldState.error
                        }
                      }
                    }
                  />
                )}
              />

            </LocalizationProvider>
          </FormControl>
          <FormControl fullWidth disabled={slotError || !hardware}>
            <InputLabel id="select-slot-label">{t("booking.form.period")}</InputLabel>
            <Select
              disabled={slotError || !hardware || slotsList.filter((s: any) => s.is_available).length === 0}
              labelId="select-slot-label"
              id="select-slot-label"
              value={slot}
              label={t("booking.form.date")}
              renderValue={(value => {
                if (!value || !date || !slot) return <></>
                return <>
                  {getDateObjectFromTime(slot.start_time, date).format(t('dates.hour', { ns: "common" }))}
                  &nbsp;{t('dates.at', { ns: "common" })}&nbsp;
                  {getDateObjectFromTime(slot.end_time, date).format(t('dates.hour', { ns: "common" }))}
                </>
              })}
            >
              {
                slotsList.filter((s: any) => s.is_available).map((slot, index: number) => <MenuItem
                  key={index + slot.planning_id}
                  onClick={() => {
                    setSlot(slot)
                    setValue('start_time', slot.start_time)
                    setValue('end_time', slot.end_time)
                    setValue('related_planning', slot.planning_id)
                  }}
                  value={slot.planning_id}>
                  {getDateObjectFromTime(slot.start_time, date).format(t('dates.hour', { ns: "common" }))}
                  &nbsp;{t('dates.at', { ns: "common" })}&nbsp;
                  {getDateObjectFromTime(slot.end_time, date).format(t('dates.hour', { ns: "common" }))}
                </MenuItem>)
              }
            </Select>

            {
              slotError && <FormHelperText>{t("booking.form.error.slotsLoading")}</FormHelperText>
            }

          </FormControl>
          {
            hardware && slotsList.filter((s: any) => s.is_available).length === 0 &&
            <FormHelperText error={true} className="col-span-full">
              {t('booking.form.hint.noSlotAvailable')} {dayjs(date).format(t('dates.abbreviation_month_date', { ns: "common" }))}
            </FormHelperText>
          }



          {/* MOTIF SELECTION */}
          {
            hardwareConfig && hardwareConfig?.motives[service as BOOKING_SERVICE] &&
            <FormControl
              className={classNames({ "col-span-2": !(hardware && service && service === BOOKING_SERVICE.CRANE_TO_LAND) })}
              disabled={!hardware}
              fullWidth>
              <InputLabel id="select-motive-label">{t("booking.form.motive")}</InputLabel>
              <Select
                labelId="select-motive-label"
                id="select-motive-label"
                label="Motif"
                {...register("motive", {
                  validate: (value) => {
                    if (hardwareConfig && hardwareConfig?.motives
                      && service && hardwareConfig?.motives[service as BOOKING_SERVICE]
                      && hardwareConfig?.motives[service as BOOKING_SERVICE].length > 0) {
                      return !!value
                    }
                    return true
                  }
                })}
                // onChange={(e) => setMotive(e.target.value)}
                // value={motive}
                disabled={!hardwareConfig || !hardwareConfig?.motives || !service || !hardwareConfig?.motives[service as BOOKING_SERVICE]}
                className={classNames({ "opacity-20": !hardwareConfig?.motives || !service || !hardwareConfig?.motives[service as BOOKING_SERVICE] })}
              >
                {
                  hardwareConfig && hardwareConfig?.motives
                  && service && hardwareConfig?.motives[service as BOOKING_SERVICE]
                  && hardwareConfig?.motives[service as BOOKING_SERVICE].length > 0
                  && hardwareConfig.motives[service as BOOKING_SERVICE].map((m) =>
                    <MenuItem value={m?.value}>{m?.label}</MenuItem>
                  )
                }
              </Select>
            </FormControl>
          }

        </Box>
        <h3 className="font-bold my-4 text-lg">{t('booking.form.cguTitle')}</h3>
        <Box className="flex gap-3 items-center mb-4">
          <Checkbox
            {...register('terms_and_conditions_accepted', { required: true })}
          // value={cguChecked} 
          // onChange={(e) => setcguChecked(e.target.checked)} 
          />
          <Typography>{t("booking.form.cgu")}</Typography>
        </Box>
      </FormProvider>
      <Button
        disabled={isSending || !formState.isValid}
        type="submit"
        startIcon={isSending && <CircularProgress size={20} />}
        fullWidth color="secondary" variant="contained">{t('booking.form.actions.save')}</Button>
    </Box>
  )

}