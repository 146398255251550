import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";

export type TBookingReportItem = {
  text: string;
  type: 'normal' | 'alert';
};

// V2
export enum TA_BOOKING_STATUS {
  REQUESTED = "requested",
  TO_VALIDATE_BY_BOATER = "to-validate-by-boater", // ?
  BOOKED = "booked",
  CANCELLED = "cancelled",
  ON_HOLD = "on-hold", // BLOCKED ? ONGOING_ISSUE ?
  REJECTED = "rejected",
  ONGOING = "ongoing",
  BOAT_IN_AREA = "boat-in-area",
  DONE = "done",
}

export enum UPDATABLE_FIELD {
  MARINA_COMMENT = "marina_comment",
  START_DATE = "start_date",
  END_DATE = "end_date",
  START_TIME = "start_time",
  END_TIME = "end_time",
  SERVICES = "services",
  AGENTS = "agents",
  STATUS = "status"
}

export type TAService = {
  id: string
  name: string,
  descritipon?: string
}


export type TABooking = {
  id: string,
  services: Pick<TAService, "descritipon" | "name" | "id">[],
  status: TA_BOOKING_STATUS
  attachments: {
    id: UUID,
    kind: "picture" | "terms-and-conditions" | string
    url: string,
    mime_type: string
  }[]
  marina_comment: string,
  boater_comment: string,
  agent_comment: string
  closing_comment?: string;
  start_date: string, // YYYY-MM-DD 
  start_time: string,
  end_date: string, // YYYY-MM-DD
  end_time: string;
  port: {
    id: UUID,
    name: string
  }
  boat: {
    id: UUID,
    name: string,
    width: number,
    length: number,
    registration_number: string,
    draft: number
  },
  boater: {
    id: UUID,
    first_name: string,
    last_name: string,
    phone: string,
    email: string
  },
  agents: {
    id: UUID,
    first_name: string,
    last_name: string,
    avatar: string
  }[]

  formatted_logs: TBookingReportItem[];

  config: {
    can_cancel: boolean,
    can_update: boolean,
    distance_unit: string
    // status allowed to change to ?
  }
  creator: UUID
  status_changed_at: string //date
  status_changed_by: UUID
  terms_and_conditions_accepted_at: string //date
  services_done: UUID[]
  files: string[],
  updatable_fields?: UPDATABLE_FIELD[]
  booking_services: TABookingServices[]
  update_requests: Pick<Amendment, "id" | "status">[]
}

export type TABookingServices = {
  id: UUID,
  is_done: boolean,
  name: string,
  service_id: UUID
}

export type TABookingCreation = {
  boat: UUID | string,
  boater: UUID | string,
  contract?: UUID | string,
  port: UUID,
  start_date: string, // YYYY-MM-DD 
  start_time: string | null,
  end_date: string, // YYYY-MM-DD
  end_time: string | null;
  services: UUID[],
  terms_and_conditions_accepted: boolean,
  marina_comment?: string
  terms_and_conditions_upload?: any
}
export type TABookingEdit = {
  start_date: string, // YYYY-MM-DD 
  start_time: string,
  end_date: string, // YYYY-MM-DD
  end_time: string;
  services: UUID[],
  marina_comment?: string
  update_request_validated?: boolean,
  update_request_terms_and_conditions_upload?: any
}
export interface TAConfig {
  id: UUID,
  port: {
    id: UUID,
    name: string
  },
  refusal_motives: {
    id: UUID,
    name: string
  }[],
  terms_and_condition_file_1: string
  terms_and_condition_file_2: string
  total_spot_available: number
}


export enum TA_AMENTDMENT_STATUS {
  TO_VALIDATE_BY_BOATER = "to-validate-by-boater",
}
export type Amendment = {
  id: UUID,
  created_at: string
  end_date: string
  end_time: string

  services_to_add: Pick<TAService, "name" | "id">[],
  services_to_remove: Pick<TAService, "name" | "id">[],
  status: TA_AMENTDMENT_STATUS
  status_changed_at: string
}

export type TAStats = {
  chart: {
    backgroundColor: string[],
    data: number[],
    labels: string[],
    percentage: number
  },
  filters: {
    booking_state_on_hold: number,
    booking_state_requested: number
  }
  counters: {
    currently_available_spots: number,
    currently_occupied_spots: number,
    total_spot_available: number,
  },
  port: UUID
}