import dayjs from "dayjs";
import { AreaContact, OpeningDate } from "../../../../../../core/models/portCMS.models";

export const hasCurrentOpeningPeriod = (dates: OpeningDate[]) => {
  const schedule = dates.some(s => {
    if (dayjs().isBetween(dayjs(s.start_date), dayjs(s.end_date), 'day', '[]')) {
      return true
    }
    return false
  })
  return schedule
}

export const overlappingPeriod = (dates: OpeningDate[]) => {

}

export const getCurrentOpeningPerdiod = (dates: OpeningDate[]) => {
  const schedule = dates.find(s => {
    if (dayjs().isBetween(dayjs(s.start_date), dayjs(s.end_date), 'day', '[]')) {
      return true
    }
    return false
  })
  return schedule
}

export const currentDayNumber = () => {
  return dayjs().day() === 0 ? 7 : dayjs().day()
}

export const getContactsCategories = (contacts: AreaContact[]) => {
  return contacts.reduce((acc: string[], c: AreaContact) => { return [...new Set([c.tab_name, ...acc])] }, []).filter(f => f)
}