import { Button, Paper, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { ModuleLayout } from "../../layouts/Module.layout"
import HelpIcon from "../../../../assets/img/menu/HelpIcon"

export const NoModuleFoundPage = () => {
  const { t } = useTranslation("app")
  return <ModuleLayout>
    <Paper className="mt-0 m-4 p-4 flex flex-col justify-center items-center gap-4">
      <Typography sx={{
        fontSize: "30px"
      }} variant="h3"> {t("module.notFound.title")} </Typography>
      {/* <p>{t("module.notFound.explanation")}</p> */}
      <Button
        color="secondary"
        variant="contained"
        href="/admin/help"
        endIcon={<HelpIcon />}
      >
        {t("menu.help")}

      </Button>
    </Paper>
  </ModuleLayout>
}