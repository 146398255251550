import { Box, Button, CircularProgress, MenuItem, Select } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { TA_BOOKING_STATUS } from "../../../../../../core/models/technicalArea.models"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { Controller, useForm } from "react-hook-form"
import { Check, Close } from "@mui/icons-material"
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models"
import { cancelTABooking, getTABookingDetail, setBookingToOnHold, startTABooking, unblockBooking } from "../../../../../../core/store/actions/TechnicalArea.actions"
import { useAppDispatch, useAppSelector } from "../../../../../../core/store/hooks"
import { updateItemInList, updateSelectedBookinStatus, updateSelectedBooking } from "../../../../../../core/store/reducers/TechnicalArea.reducer"
import { store } from "../../../../../../core/store/store"

const colorByStatus = (value: TA_BOOKING_STATUS) => classNames({
  "error": [TA_BOOKING_STATUS.ON_HOLD, TA_BOOKING_STATUS.REJECTED].includes(value),
  "secondary": [TA_BOOKING_STATUS.REQUESTED].includes(value),
  "warning": [TA_BOOKING_STATUS.CANCELLED].includes(value),
}) || "info"

const variantByStatus = (value: TA_BOOKING_STATUS) => classNames({
  "outlined": [TA_BOOKING_STATUS.CANCELLED,
  TA_BOOKING_STATUS.DONE,
  TA_BOOKING_STATUS.BOAT_IN_AREA,
  TA_BOOKING_STATUS.ONGOING,
  TA_BOOKING_STATUS.TO_VALIDATE_BY_BOATER,
  TA_BOOKING_STATUS.REJECTED,
  TA_BOOKING_STATUS.BOOKED,
  ].includes(value),
  "filled": [TA_BOOKING_STATUS.REQUESTED, TA_BOOKING_STATUS.ON_HOLD].includes(value),
}) || "outlined"


const status: {
  [key in TA_BOOKING_STATUS]: {
    allowedWith: TA_BOOKING_STATUS[],
    action?: (id: UUID, currentStatus?: TA_BOOKING_STATUS) => Promise<any>
  }
} = {
  [TA_BOOKING_STATUS.REQUESTED]: {
    allowedWith: [TA_BOOKING_STATUS.REQUESTED]
  },
  [TA_BOOKING_STATUS.TO_VALIDATE_BY_BOATER]: {
    allowedWith: [TA_BOOKING_STATUS.TO_VALIDATE_BY_BOATER, TA_BOOKING_STATUS.REQUESTED]
  },
  [TA_BOOKING_STATUS.BOOKED]: {
    allowedWith: [TA_BOOKING_STATUS.BOOKED]
  },
  [TA_BOOKING_STATUS.ONGOING]: {
    allowedWith: [TA_BOOKING_STATUS.ONGOING, TA_BOOKING_STATUS.BOOKED, TA_BOOKING_STATUS.BOAT_IN_AREA, TA_BOOKING_STATUS.ON_HOLD],
    action: (id: UUID, currentStatus?: TA_BOOKING_STATUS) => currentStatus === TA_BOOKING_STATUS.ON_HOLD ?
      unblockBooking(id).then((res) => store.dispatch(getTABookingDetail(id))) :
      startTABooking(id).then((res) => store.dispatch(getTABookingDetail(id)))
  },
  [TA_BOOKING_STATUS.BOAT_IN_AREA]: {
    allowedWith: [TA_BOOKING_STATUS.BOAT_IN_AREA]
  },
  [TA_BOOKING_STATUS.DONE]: {
    allowedWith: [TA_BOOKING_STATUS.DONE, TA_BOOKING_STATUS.BOAT_IN_AREA],
  },
  [TA_BOOKING_STATUS.CANCELLED]: {
    allowedWith: [TA_BOOKING_STATUS.CANCELLED, TA_BOOKING_STATUS.BOOKED, TA_BOOKING_STATUS.REQUESTED],
    action: (id: UUID) => cancelTABooking(id).then((res) => store.dispatch(getTABookingDetail(id)))
  },
  [TA_BOOKING_STATUS.REJECTED]: {
    allowedWith: [TA_BOOKING_STATUS.REJECTED, TA_BOOKING_STATUS.REQUESTED]
  },
  [TA_BOOKING_STATUS.ON_HOLD]: {
    allowedWith: [TA_BOOKING_STATUS.ON_HOLD, TA_BOOKING_STATUS.ONGOING],
    action: (id: UUID) => setBookingToOnHold(id).then((res) => store.dispatch(getTABookingDetail(id)))
  },
}

type TAStatusSelectProps = {
  value: TA_BOOKING_STATUS,
  bookingId: UUID,
  expand?: boolean
}
export const TAStatusSelect: FC<TAStatusSelectProps> = ({ value, bookingId, expand }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation("technicalArea")
  const [color, setColor] = useState<string>("info")
  const [isSaving, setIsSaving] = useState(false)
  const selectedBooking = useAppSelector(state => state.technicalArea.selectedBooking)
  const { handleSubmit, reset, control, formState: { isDirty } } = useForm({
    defaultValues: {
      status: value
    }
  })

  useEffect(() => {
    setColor(colorByStatus(value))
  }, [value])

  const submit = (data: { status: TA_BOOKING_STATUS }) => {
    if ("action" in status[data.status] && !!status[data.status].action) {
      setIsSaving(true)
      status[data.status].action!(bookingId, value).then(
        res => {
          dispatch(updateItemInList({ id: bookingId, ...data }))
          if (bookingId === selectedBooking?.id) {
            dispatch(updateSelectedBookinStatus(data.status))
          }

          reset(data)
          setColor(colorByStatus(data.status))
        }, err => {
          // setError(true)
          reset({ status: value })
        }
      ).finally(() => {
        setIsSaving(false)
      })
    }

  }

  if ([
    TA_BOOKING_STATUS.DONE,
    TA_BOOKING_STATUS.REQUESTED,
    TA_BOOKING_STATUS.BOOKED,
    TA_BOOKING_STATUS.CANCELLED,
    TA_BOOKING_STATUS.TO_VALIDATE_BY_BOATER,
    TA_BOOKING_STATUS.DONE,
    TA_BOOKING_STATUS.REJECTED].includes(value)) {
    return <TAStatusNoEdit expand={expand} value={value} />
  }


  return (
    <form onSubmit={handleSubmit(submit)} className="flex gap-1">
      <Box className={classNames(
        "w-3 rounded-md",
        {
          "bg-fcosecondary text-white": [TA_BOOKING_STATUS.REQUESTED].includes(value),
          "bg-fcogreyvariant": [TA_BOOKING_STATUS.BOOKED,
          TA_BOOKING_STATUS.BOAT_IN_AREA,
          TA_BOOKING_STATUS.ONGOING,
          TA_BOOKING_STATUS.DONE,
          TA_BOOKING_STATUS.TO_VALIDATE_BY_BOATER].includes(value),
          "bg-fcodanger text-white": [TA_BOOKING_STATUS.ON_HOLD, TA_BOOKING_STATUS.REJECTED].includes(value),
          "bg-fcowarn text-white": [TA_BOOKING_STATUS.CANCELLED].includes(value),
        })
      } ></Box>
      <Controller
        control={control}
        name="status"
        render={({ field }) =>
          <Select
            color={color as any}
            value={field.value}
            onChange={(e) => {
              field.onBlur()
              field.onChange(e.target.value)
            }}
          >

            {
              status[TA_BOOKING_STATUS.REQUESTED].allowedWith.includes(value) &&
              <MenuItem value={TA_BOOKING_STATUS.REQUESTED}> {t("status.requested")}</MenuItem>
            }
            {
              (status[TA_BOOKING_STATUS.TO_VALIDATE_BY_BOATER].allowedWith.includes(value)) &&
              <MenuItem value={TA_BOOKING_STATUS.TO_VALIDATE_BY_BOATER}> {t("status.to_validate_by_boater")}</MenuItem>
            }
            {
              status[TA_BOOKING_STATUS.BOOKED].allowedWith.includes(value) &&
              <MenuItem value={TA_BOOKING_STATUS.BOOKED}> {t("status.booked")}</MenuItem>
            }
            {
              status[TA_BOOKING_STATUS.ONGOING].allowedWith.includes(value) &&
              <MenuItem value={TA_BOOKING_STATUS.ONGOING}> {t("status.ongoing")}</MenuItem>
            }

            {
              (status[TA_BOOKING_STATUS.BOAT_IN_AREA].allowedWith.includes(value)) &&
              <MenuItem value={TA_BOOKING_STATUS.BOAT_IN_AREA}> {t("status.boat_in_area")}</MenuItem>
            }
            {
              (status[TA_BOOKING_STATUS.DONE].allowedWith.includes(value)) &&
              <MenuItem value={TA_BOOKING_STATUS.DONE}> {t("status.done")}</MenuItem>
            }

            {
              (status[TA_BOOKING_STATUS.REJECTED].allowedWith.includes(value)) &&
              <MenuItem value={TA_BOOKING_STATUS.REJECTED}> {t("status.rejected")}</MenuItem>
            }

            {
              (status[TA_BOOKING_STATUS.ON_HOLD].allowedWith.includes(value)) &&
              <MenuItem value={TA_BOOKING_STATUS.ON_HOLD}> {t("status.on_hold")}</MenuItem>
            }
            {
              (status[TA_BOOKING_STATUS.CANCELLED].allowedWith.includes(value)) &&
              <MenuItem value={TA_BOOKING_STATUS.CANCELLED}> {t("status.cancelled")}</MenuItem>
            }
          </Select>}
      />

      {isDirty && <div className="flex flex-col gap-1">
        <Button disabled={isSaving} type="submit" color="secondary" variant="contained">
          {isSaving ? <CircularProgress color="info" size={20} /> : <Check />}
        </Button>
        <Button disabled={isSaving} onClick={() => {
          reset({ status: value })
        }} color="error" variant="outlined">
          <Close />
        </Button>
      </div>}
    </form>
  )
}

type TAStatusNoEditProps = {
  value: TA_BOOKING_STATUS,
  expand?: boolean
}
export const TAStatusNoEdit: FC<TAStatusNoEditProps> = ({ value, expand }) => {
  const { t } = useTranslation("technicalArea")
  const [color, setColor] = useState<string>("info")
  const [variant, setVariant] = useState<string>("outlined")
  useEffect(() => {
    setColor(colorByStatus(value))
    setVariant(variantByStatus(value))
  }, [value])

  return <Box className={classNames(
    "text-center rounded font-bold px-4 py-2 flex justify-center items-center",
    {
      "max-w-[200px]": !expand
    },
    variant === "filled" ? {
      "bg-fcosecondary text-white": [TA_BOOKING_STATUS.REQUESTED].includes(value),
      "bg-fcogreyvariant": [TA_BOOKING_STATUS.BOOKED,
      TA_BOOKING_STATUS.BOAT_IN_AREA,
      TA_BOOKING_STATUS.ONGOING,
      TA_BOOKING_STATUS.DONE,
      TA_BOOKING_STATUS.TO_VALIDATE_BY_BOATER].includes(value),
      "bg-fcodanger text-white": [TA_BOOKING_STATUS.ON_HOLD, TA_BOOKING_STATUS.REJECTED].includes(value),
      "bg-fcowarn text-white": [TA_BOOKING_STATUS.CANCELLED].includes(value),
    } : {
      "border-fcosecondary border text-white": [TA_BOOKING_STATUS.REQUESTED].includes(value),
      "border-fcogreyvariant border": [TA_BOOKING_STATUS.BOOKED,
      TA_BOOKING_STATUS.BOAT_IN_AREA,
      TA_BOOKING_STATUS.ONGOING,
      TA_BOOKING_STATUS.DONE,
      TA_BOOKING_STATUS.TO_VALIDATE_BY_BOATER].includes(value),
      "border border-fcodanger text-fcodanger": [TA_BOOKING_STATUS.ON_HOLD, TA_BOOKING_STATUS.REJECTED].includes(value),
      "border border-fcowarn text-fcowarn": [TA_BOOKING_STATUS.CANCELLED].includes(value),
    }
  )}>
    {t(`status.${value}`)}
  </Box>
}