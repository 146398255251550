import { createSlice } from "@reduxjs/toolkit";
import {
  applyFiltersFromStorage,
  applyFiltersToStorage,
  FilterType,
} from "../../../_helpers/sort.helpers";
import { TicketState } from "../../models/module.models";
import {
  createTicket,
  createTicketMessage,
  // generateTicketFileFormat,
  getTicketDetail,
  getTicketsCategories,
  getTicketsClosedList,
  getTicketsList,
  updateTicket,
} from "../actions/Ticket.actions";
import FDK from "@wattsonelements/front-fdk";
import { ticketApi } from "../../API/Ticket";

const initialState = applyFiltersFromStorage(
  {
    list: [],
    closed: [],
    loading: false,
    sort: {
      order: "desc",
      orderBy: "date",
    },
    filters: {
      text: "",
      category: "",
      status: { ONGOING: true, OPEN: true },
    },
    selected: null,
    selectedID: undefined,
    categories: [],
    listStatus: "",
    legend: [],
    firstLoading: true,
    selectedStatus: "",
    config: {
      status: {
        available: true,
        last_update: new Date().toISOString(),
        error: {
          message: "",
          code: 200000,
        },
      },
      config: {
        enable: true,
        refresh_delay: 10, // secondes
        reporting: true,
      },
    },
    updating: [],
  },
  FilterType.ticket
) as TicketState;

// never let the closed be true, for optimization purpose
initialState.filters.status = { ONGOING: true, OPEN: true };
applyFiltersToStorage<TicketState>(initialState, FilterType.ticket);

export const Tickets = createSlice({
  name: "Tickets",
  initialState,
  reducers: {
    setFirstLoading: (state, { payload }) => {
      state.firstLoading = payload;
    },
    changeSort: (state, { payload }) => {
      state.sort = { ...state.sort, ...payload };
      applyFiltersToStorage<TicketState>(state, FilterType.ticket);
    },
    updateFilterWithoutSaving: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload };
    },
    updateFilter: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload };
      applyFiltersToStorage<TicketState>(state, FilterType.ticket);
    },
    setSelected: (state, { payload }) => {
      state.selected = payload;
    },
    // addAttachment: (state, { payload }) => {
    //   if (state.selected !== null) {
    //     let tmpTicket = { ...state.selected };
    //     let tmpAttachments;
    //     if (tmpTicket.ticket_intervention[0].intervention_step.length < 1) {
    //       tmpAttachments = [
    //         ...payload.map((file: any) => {
    //           return { linked_file: file.id };
    //         }),
    //       ];
    //       tmpTicket.ticket_intervention[0].intervention_step.push({
    //         intervention_step_attachment: [...tmpAttachments],
    //       });
    //     } else {
    //       tmpAttachments = [
    //         ...tmpTicket.ticket_intervention[0].intervention_step[0]
    //           .intervention_step_attachment,
    //         ...payload.map((file: any) => {
    //           return { linked_file: file.id };
    //         }),
    //       ];
    //       tmpTicket.ticket_intervention[0].intervention_step[0].intervention_step_attachment =
    //         [...tmpAttachments];
    //     }
    //     state.selected = tmpTicket;
    //     // state = { ...state, selected: tmpTicket }
    //   }
    // },
    setSelectedID: (state, { payload }) => {
      state.selectedID = payload;
    },
    addUpdatingTickets: (state, { payload }) => {
      state.updating = [...state.updating, payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTicketsList.pending, (state, { payload }) => {
        state.listStatus = "loading";
      })
      .addCase(getTicketsList.fulfilled, (state, { payload }) => {
        state.list = payload;
        state.firstLoading = false;
      })
      .addCase(getTicketsList.rejected, (state, { payload }) => { })
      .addCase(getTicketsClosedList.pending, (state, { payload }) => {
        state.listStatus = "loading";
      })
      .addCase(getTicketsClosedList.fulfilled, (state, { payload }) => {
        state.closed = payload;
      })
      .addCase(getTicketsClosedList.rejected, (state, { payload }) => { })
      .addCase(getTicketDetail.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getTicketDetail.fulfilled, (state, { payload }) => {
        if (payload.date_modified !== state.selected?.date_modified) {
          state.selected = payload;
        }
        state.loading = false;
      })
      .addCase(getTicketDetail.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(getTicketsCategories.pending, (state, { payload }) => { })
      .addCase(getTicketsCategories.fulfilled, (state, { payload }) => {
        state.categories = payload;
      })
      .addCase(getTicketsCategories.rejected, (state, { payload }) => { })
      .addCase(updateTicket.rejected, (state, { payload }) => {
        if (state.updating.length > 0) state.updating.pop();
        else state.updating = [];
      })
      .addCase(updateTicket.fulfilled, (state, { payload }) => {
        state.list = state.list.filter((t) => t.id !== payload.id);
        state.closed = state.closed.filter((t) => t.id !== payload.id);
        state.updating = state.updating.filter((u) => u !== payload.id);
        if (
          payload.status === FDK.Constants.TicketConstants.TicketStatus.CLOSED
        ) {
          state.closed = [...state.closed, payload];
        } else {
          state.list = [...state.list, payload];
        }
        if (state?.selected?.id === payload.id) {
          state.selected = payload;
        }
      })
      .addCase(createTicketMessage.fulfilled, (state, { payload }) => {
        let key = state.list.findIndex((t) => t.id === payload.ticket);
        if (state.list[key].nb_messages) state.list[key].nb_messages! += 1;
        else state.list[key].nb_messages = 1;
        if (state.selected?.id === payload.ticket) {
          state.selected!.messages!.push(payload);
        }
      })
      .addCase(createTicket.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(createTicket.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.list = [payload, ...state.list];
      })
      .addCase(createTicket.rejected, (state, { payload }) => {
        state.loading = false;
      }).addMatcher(ticketApi.endpoints.getConfig.matchFulfilled, (state, { payload }) => {
        state.ticketConfig = payload
      })
  },
});

export default Tickets.reducer;
export const {
  addUpdatingTickets,
  updateFilter,
  setSelected,
  // addAttachment,
  setSelectedID,
  setFirstLoading,
  updateFilterWithoutSaving,
  changeSort,
} = Tickets.actions;
