import { createAsyncThunk } from "@reduxjs/toolkit"
import { RootState } from "../store"
import { IGenericResponse, IPaginatedRequest, TParams } from "@wattsonelements/front-fdk/dist/models/Misc.models"
import { loadPaginatedDatas } from "../../../_helpers/loadPaginatedData.helpers"
import FDK from "@wattsonelements/front-fdk"
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models"
import { Amendment, TABooking, TABookingCreation, TAConfig, TAService, TAStats, TA_BOOKING_STATUS } from "../../models/technicalArea.models"
import dayjs from "dayjs"
import { IModuleListPaginated } from "@wattsonelements/front-fdk/dist/models/Module.models"

export const getStatusListParam = (statusFilters: { [key: string]: boolean }, params: TParams = {}) => {
  const statusList = Object.keys(TA_BOOKING_STATUS).filter(status => statusFilters[status as TA_BOOKING_STATUS] === true).map(
    s => TA_BOOKING_STATUS[s as keyof typeof TA_BOOKING_STATUS]
  )
  if (statusList.length > 1) {
    params.status__in = statusList.join(',')
  } else if (statusList.length === 1) {
    params.status = statusList[0]
  }
  return params
}

export const addBookingDateParam = (date: string, dateStart: string, params: TParams) => {
  switch (date) {
    case "all":
      break;
    case "week":
      params.start_date__gte = dayjs().startOf("week").format("YYYY-MM-DD")
      params.end_date__lte = dayjs().endOf("week").format("YYYY-MM-DD")
      break;
    case "month":
      params.start_date__gte = dayjs().startOf("month").format("YYYY-MM-DD")
      params.end_date__lte = dayjs().endOf("month").format("YYYY-MM-DD")
      break
    default:
      params.start_date = dayjs(dateStart).format("YYYY-MM-DD")
      break;
  }
  return params
}
export const getTABookingList = createAsyncThunk(
  'technicalArea/booking',
  async (params: TParams | undefined = {}, { getState }) => {
    const { date, dateStart, status } = (getState() as RootState).technicalArea.filters
    params = addBookingDateParam(date, dateStart, params)
    params = getStatusListParam(status, params)

    return loadPaginatedDatas((page: number = 1) => FDK.MainAPI.API.request<IPaginatedRequest<TABooking>>({
      method: "GET",
      url: "/v2/marina/technical-areas/bookings",
      params
    }))
  }
)

export const getTABookingDetail = createAsyncThunk(
  'technicalArea/booking/detail',
  async (id: UUID | string, { getState }) => {
    return FDK.MainAPI.API.request<TABooking>({
      method: "GET",
      url: `/v2/marina/technical-areas/bookings/${id}`
    }).then(res => res.data)
  }
)

export const updateTABookingDetail = async (id: UUID | string, data: Partial<TABooking> | FormData) => {
  return FDK.MainAPI.API.request<TABooking>({
    method: "PATCH",
    url: `/v2/marina/technical-areas/bookings/${id}`,
    data
  })
}

export const acceptTABookingDetail = async (id: UUID | string, data: Partial<TABooking> | FormData) => {
  return FDK.MainAPI.API.request<IGenericResponse>({
    method: "POST",
    url: `/v2/marina/technical-areas/bookings/${id}/action/accept-request`,
    data
  })
}

export const createTABooking = (booking: TABookingCreation | FormData) => FDK.MainAPI.API.request<TABooking>({
  method: "POST",
  url: `/v2/marina/technical-areas/bookings`,
  data: booking,
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})

export const getTAServiceslist = createAsyncThunk(
  'technicalArea/services',
  async (port: UUID, { getState }) => {
    return loadPaginatedDatas((page: number = 1) => FDK.MainAPI.API.request<TAService[]>({
      method: "GET",
      url: `/v2/marina/technical-areas/services`,
      params: {
        port_id: port
      }
    }))

  }
)
export const getTAStats = createAsyncThunk(
  'technicalArea/stats',
  async (port: UUID, { getState }) => {
    return FDK.MainAPI.API.request<TAStats>({
      method: "GET",
      url: `/v2/marina/technical-areas/stats`,
      params: {
        port_id: port
      }
    }).then(res => res.data)

  }
)

export const getTAConfig = createAsyncThunk(
  'technicalArea/configs',
  async (port: UUID, { getState }) => {
    return FDK.MainAPI.API.request<IModuleListPaginated<TAConfig>>({
      method: "GET",
      url: `/v2/marina/technical-areas/configs`,
      params: {
        port_id: port
      }
    }).then(res => res.data)
  }
)

export const setBookingToDone = async (id: UUID | string) => {
  return FDK.MainAPI.API.request({
    method: "POST",
    url: `/v2/marina/technical-areas/bookings/${id}/action/done`,

  })
}
export const setBookingToOnHold = async (id: UUID | string) => {
  return FDK.MainAPI.API.request({
    method: "POST",
    url: `/v2/marina/technical-areas/bookings/${id}/action/block`,

  })
}

export const unblockBooking = async (id: UUID | string) => {
  return FDK.MainAPI.API.request({
    method: "POST",
    url: `/v2/marina/technical-areas/bookings/${id}/action/unblock`,

  })
}

export const startTABooking = async (id: UUID | string) => {
  return FDK.MainAPI.API.request<TABooking>({
    method: "POST",
    url: `/v2/agent/technical-areas/bookings/${id}/action/start`
  })
}
export const cancelTABooking = async (id: UUID | string, data?: { refusal_motive: string }) => {
  return FDK.MainAPI.API.request<TABooking>({
    method: "POST",
    url: `/v2/agent/technical-areas/bookings/${id}/action/cancel`,
    data
  })
}
export const rejectTABooking = async (id: UUID | string, data: { refusal_motive: string }) => {
  return FDK.MainAPI.API.request<IGenericResponse>({
    method: "POST",
    url: `/v2/agent/technical-areas/bookings/${id}/action/refuse`,
    data
  })
}

export const getAmendments = (bookingId: UUID | string, amendmentId: UUID | string) => {
  // update-requests
  return FDK.MainAPI.API.request<Amendment>({
    method: "GET",
    url: `/v2/agent/technical-areas/bookings/${bookingId}/update-requests/${amendmentId}`
  })
}
export const exportBookings = (filters: TParams) => {
  return FDK.MainAPI.API.request<Amendment>({
    method: "GET",
    responseType: 'blob',
    url: `/v2/agent/technical-areas/bookings/export`,
    params: filters
  })

}
