import { Box, Stack, TooltipProps } from "@mui/material";
import React from "react";
import { ToolTip } from "../../../../core/type";
import { LabelLegend, LegendCheckbox } from "../../UX/LegendCheckbox";
import classNames from "classnames";

export interface LegendGrouped {
  actionGroup?: string;
  label?: string; //i18n label
  legends: ILegend[];
}
export interface ILegend {
  label: string | React.ReactElement;
  key: string;
  toolTip?: ToolTip;
  actionGroup?: string;
  children?: ILegend[];
  icon?: any;
  isFilter?: boolean,
  getStat?: (stats: any) => number | string;
}
interface LegendProps {
  className?: string;
  legends: LegendGrouped[];
  legendStatus: { [key: string]: boolean };
  inline?: boolean;
  stats?: any;
  updateLegend: (checked: boolean, key: string) => void;
}

export const Legend = (props: LegendProps) => {
  const {
    className = "",
    legends = [],
    updateLegend,
    legendStatus,
    inline = false,
    stats,
  } = props;
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        padding: "10px 40px",
      }}
      className={`legend justify-items-center ${className} `}
    >
      <div
        className={`grid grid-cols-1 md:grid-cols-${Math.min(
          legends.length, // max 2 col
          2
        )}`}
      >
        {legends.map((group, i) => {
          return (
            <div
              key={`group-${group.actionGroup}-${i}`}
              className={classNames(
                `grid grid-cols-1 gap-2`, {
                "md:grid-cols-2": !!group.label
              }
              )}
            >
              {group.label && (
                <span className="text-right text-sm mr-4">{group.label}</span>
              )}

              <Stack
                direction={inline ? "row" : "column"}
                spacing={0.5}
                className="item-container"
                justifyContent={inline ? "space-evenly" : "flex-start"}
              >
                {group.legends.map((l) => (
                  <React.Fragment key={l.key}>
                    {
                      "isFilter" in l && !l.isFilter ?
                        <LabelLegend
                          stat={stats && l?.getStat ? l?.getStat(stats) : ""}
                          icon={l.icon}
                          label={l.label} /> :
                        <LegendCheckbox
                          onChange={(checked: any) => {
                            if (l.children && l.children.length) {
                              l.children.map((lc) => {
                                updateLegend(checked, lc.key);
                              });
                            } else {
                              updateLegend(checked, l.key);
                            }
                          }}
                          stat={stats && l?.getStat ? l?.getStat(stats) : ""}
                          label={l.label}
                          toolTip={l.toolTip}
                          checked={
                            l.children && l.children.length
                              ? l.children.some((lc) => legendStatus[lc.key])
                              : legendStatus[l.key]
                          }
                          icon={l.icon}
                        />
                    }
                    <div className="relative">
                      {l.children &&
                        l.children.map((lc, i) => (
                          <Box
                            key={`leg2-${i}`}
                            className="flex items-center justify-left gap-3"
                          >
                            <Box className="-mt-4 text-fcomain text-opacity-60">
                              L
                            </Box>
                            {
                              "isFilter" in l && !lc.isFilter ?
                                <LabelLegend
                                  stat={stats && l?.getStat ? l?.getStat(stats) : ""}
                                  icon={l.icon}
                                  label={l.label} /> :
                                <LegendCheckbox
                                  key={lc.key}
                                  onChange={(checked) =>
                                    updateLegend(checked, lc.key)
                                  }
                                  label={lc.label}
                                  checked={legendStatus[lc.key]}
                                  icon={lc.icon}
                                />}
                          </Box>
                        ))}
                    </div>
                  </React.Fragment>
                ))}
              </Stack>
            </div>
          );
        })}
      </div>
    </Box>
  );
};
