import { Box, FormControl, TextField } from "@mui/material"
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import { FC, useEffect, useState } from "react"
import { defaultRow } from "./Planning.form"
import { getDateObjectFromTime } from "../../../../../../_helpers/dateTime.helper"
import i18n from "../../../../../../translations/initI18n"
import { useTranslation } from "react-i18next"
import { useDebounce } from "../../../../../../_helpers/debounce.helpers"


export type PlanningRow = {
  maximum_tasks_number: number,
  start_time: string | null,
  end_time: string | null,
  isValid?: boolean
}


type TimePlanningRowProps = {
  value?: PlanningRow,
  onChange?: (row: PlanningRow) => any;
}


export const TimePlanningRow: FC<TimePlanningRowProps> = ({
  onChange = () => { },
  value = defaultRow
}) => {
  const { t } = useTranslation("common");

  const [timeStart, setTimeStart] = useState<dayjs.Dayjs | null>(value.start_time ? getDateObjectFromTime(value.start_time) : null)
  const [timeEnd, setTimeEnd] = useState<dayjs.Dayjs | null>(value.end_time ? getDateObjectFromTime(value.end_time) : null)
  const [quantity, setQuantity] = useState(1)

  const timeStartDebounced = useDebounce<dayjs.Dayjs | null>(timeStart, 300);

  useEffect(() => {
    let isValid = true
    if (!timeEnd || !timeStart || quantity <= 0 || !timeEnd?.isValid() || !timeStart?.isValid()) {
      isValid = false
    }
    if (timeEnd && timeStart && timeEnd.isBefore(timeStart)) {
      isValid = false
    }
    onChange(
      {
        end_time: timeEnd && timeEnd.isValid() ? timeEnd?.format("HH:mm") : null,
        start_time: timeStart && timeStart.isValid() ? timeStart?.format("HH:mm") : null,
        maximum_tasks_number: quantity,
        isValid
      }
    )
  }, [timeStart, timeEnd, quantity])

  useEffect(() => {
    if (timeStartDebounced && timeStartDebounced.isValid() && (!timeEnd || ((timeEnd && timeEnd?.isBefore(timeStart))))) {
      setTimeEnd(timeStartDebounced.add(1, "hours"))
    }
  }, [timeStartDebounced])


  return <Box className="grid grid-cols-3 gap-3 ">
    <FormControl fullWidth>
      <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs}>
        <TimePicker
          value={timeStart}
          ampm={t('dates.ampm')}
          format={t('dates.hour', { ns: "common" })}
          onChange={(value) => {
            console.log(value);

            setTimeStart(value)


          }}
          label="Début" />
      </LocalizationProvider>
    </FormControl>
    <FormControl fullWidth>
      <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs}>
        <TimePicker
          ampm={t('dates.ampm')}
          format={t('dates.hour', { ns: "common" })}
          value={timeEnd}
          onChange={(value) => {
            setTimeEnd(value)
          }}
          label="Fin" />
      </LocalizationProvider>
    </FormControl>
    <FormControl fullWidth>
      {/* <InputLabel id="select-motive-label">Nombre de créneaux dispo</InputLabel> */}
      <TextField value={quantity} onChange={(e) => setQuantity(parseInt(e.target.value))} inputProps={{ inputMode: 'numeric', min: 1, pattern: '[0-9]*' }} type="number" label="Nombre de créneaux dispo" />
    </FormControl>
  </Box>

}