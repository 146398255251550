import { Box, Button, CircularProgress, Paper, Stack } from "@mui/material"
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../core/store/hooks";
import { useTranslation } from "react-i18next";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";
import { useEffect, useState } from "react";
import { BoaterInfo } from "../Common/BoaterInfos";
import { TAStatusSelect } from "./detail/TAStatus.select";
import { AgentSelector } from "../Crane/reservation/Agent.elelement";
import { Controller, useForm } from "react-hook-form";
import { Amendment as TAAmendment, TABooking, TA_AMENTDMENT_STATUS, TA_BOOKING_STATUS, UPDATABLE_FIELD } from "../../../../../core/models/technicalArea.models";
import { getTABookingDetail, updateTABookingDetail } from "../../../../../core/store/actions/TechnicalArea.actions";
import { durationDisplay, getDateObjectFromTime } from "../../../../../_helpers/dateTime.helper";
import { updateItemInList, updateSelectedBooking } from "../../../../../core/store/reducers/TechnicalArea.reducer";
import { FormModal } from "./form/form.modal";
import { TAEditForm } from "./TAEdit.Form";
import { Edit } from "@mui/icons-material";
import { DetailLeft } from "./detail/DetailLeft";
import { ServicesList } from "./detail/Services.list";
import { Amendment } from "./detail/Amendments";
import { TARejectForm } from "./TAReject.Form";
import { findSelectedInList } from "../../../../../core/store/selectors/TechanicalArea.selector";
import { displayNumber } from "../../../../../_helpers/misc.helpers";

export const TADetail = () => {
  const { t } = useTranslation("technicalArea");
  const { reservationId } = useParams<{ reservationId: UUID }>();
  const dispatch = useAppDispatch()
  const bookingFromList = useAppSelector(state => findSelectedInList(state, reservationId))
  const { selectedBooking } = useAppSelector(state => state.technicalArea)
  const port = useAppSelector(state => state.ports.current)

  const [openEditModal, setOpenEditModal] = useState(false)
  const [openRejectModal, setOpenRejectModal] = useState(false)
  const { reset, control, watch, formState: { isDirty } } = useForm<TABooking>()
  const [error, setError] = useState(false)
  const [errorLoading, setErrorLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [updateLoading, setUpdateLoading] = useState(false)
  const [amendment, setAmendment] = useState<Pick<TAAmendment, "status" | "id"> | null>()
  const [modalRejectMode, setModalRejectMode] = useState<"reject" | "cancel">(selectedBooking?.status === TA_BOOKING_STATUS.REQUESTED ? "reject" : "cancel")

  const agentsField = watch("agents")

  useEffect(() => {
    if (selectedBooking && selectedBooking.id === reservationId) {
      reset(selectedBooking)
      if (selectedBooking.update_requests?.length > 0) {
        const ongoingUpdateRequest = selectedBooking.update_requests.find(ur => ur.status === TA_AMENTDMENT_STATUS.TO_VALIDATE_BY_BOATER)
        if (!ongoingUpdateRequest) {
          setAmendment(null)
        } else {
          setAmendment(ongoingUpdateRequest)
        }
      }
    } else {
      setAmendment(null)
    }
  }, [selectedBooking, reservationId])

  useEffect(() => {
    setAmendment(null)
    if (reservationId) {
      setLoading(true)
      setErrorLoading(false)
      dispatch(getTABookingDetail(reservationId)).then(res => {
        if (res.type.includes("rejected")) {
          setErrorLoading(true)
        } else {
          setErrorLoading(false)
          dispatch(updateItemInList(res.payload))
        }
      }).finally(() => {
        setLoading(false)
      })
    }
  }, [reservationId])

  const submit = () => {
    if (!selectedBooking) return
    console.log("submit", agentsField);
    if (updateLoading) return
    setUpdateLoading(true)

    if (error) setError(false)
    updateTABookingDetail(selectedBooking.id, {
      agents: agentsField,
    } as any).then(
      res => {
        // dispatch(getTABookingList())
        dispatch(updateItemInList(res.data))
        dispatch(updateSelectedBooking(res.data))
        // reset(res.data)

      }, err => {
        setError(true)
      }
    ).finally(() => {
      reset()
      setUpdateLoading(false)
    })
  }

  if ((!selectedBooking || loading) && !errorLoading) {
    return <Paper className="p-5 min-h-max px-10 flex flex-col gap-3 items-center">
      <CircularProgress size={40} />
    </Paper>
  }
  if (errorLoading) {
    return <Paper className="p-5 min-h-max px-10 flex flex-col gap-3 items-center">
      <Box className="text-center font-normal">
        {
          bookingFromList && <>
            <p>{getDateObjectFromTime(
              bookingFromList?.start_time,
              bookingFromList?.start_date)
              .format(t('dates.abbreviation_month_date_hour', { ns: "common" }))} - {getDateObjectFromTime(
                bookingFromList?.end_time,
                bookingFromList?.end_date)
                .format(t('dates.abbreviation_month_date_hour', { ns: "common" }))} (
              {durationDisplay(
                getDateObjectFromTime(bookingFromList?.start_time, bookingFromList?.start_date),
                getDateObjectFromTime(bookingFromList?.end_time, bookingFromList?.end_date))
              }) </p>
            <p>{bookingFromList?.boater.first_name} {bookingFromList?.boater.last_name} - {bookingFromList?.boat.name}</p>
          </>
        }

      </Box>
      <Box className="py-4 px-16 border border-fcodanger rounded-lg text-fcomain font-bold">

        {t("errorLoading")}

      </Box>

    </Paper>
  }
  return (
    <>

      {!!selectedBooking &&
        <Paper className="p-5 px-10 flex flex-col gap-3">
          {
            amendment &&
            <Amendment amendment={amendment.id} booking={selectedBooking} />
          }

          <header className="flex justify-between mb-1">
            <Box>
              <h2 className="text-2xl font-bold" >
                {selectedBooking.boat.name} - {selectedBooking.boat.registration_number}
              </h2>
              <p className="text-lg font-bold flex items-center" >
                {/* {selectedBooking.boat.length || "/"} - {selectedBooking.boat.width || "/"}  - {selectedBooking.boat.draft || "/"} */}
                {displayNumber(selectedBooking.boat.width)} - {displayNumber(selectedBooking.boat.length)} - {displayNumber(selectedBooking.boat.draft)}
                <span className="pl-2 text-xs text-fcotextgrey opacity-70">({t("dimensions")})</span>
              </p>

            </Box>
            <Box className="flex flex-col gap-2 items-end">
              {!amendment && selectedBooking?.updatable_fields && selectedBooking.updatable_fields?.length > 0 &&
                <Button color="secondary"
                  disabled={!selectedBooking}
                  onClick={() => setOpenEditModal(true)}>
                  <Edit />
                </Button>
              }
              <TAStatusSelect expand bookingId={selectedBooking.id} value={selectedBooking.status} />
            </Box>
          </header>

          <Box className="grid grid-cols-2 mt-2 gap-5">
            <Stack className="mt-2" spacing={2}>
              <Box className="flex gap-2 items-center">
                <h2 className="text-lg font-bold">{t("form.agentsTitle")} </h2>
                <Controller
                  control={control}
                  name="agents"
                  render={({ field }) => (
                    <AgentSelector
                      canEdit={selectedBooking && selectedBooking.updatable_fields?.includes(UPDATABLE_FIELD.AGENTS)}
                      onChange={(values) => {
                        field.onChange(values)
                        field.onBlur()
                      }}
                      onOpen={(isOpen) => {
                        console.log("open / close state agent selector", isOpen, isDirty, "=", !isOpen && isDirty);

                        if (!isOpen && isDirty) {
                          submit()
                        }
                      }}
                      agents={field.value}
                      multiple />

                  )}
                />
              </Box>
              <h2 className="text-lg font-bold">{t("form.details")} </h2>
              {
                selectedBooking && selectedBooking?.booking_services &&
                <ServicesList booking={selectedBooking} />
              }
              {
                selectedBooking?.boater &&
                <BoaterInfo boater={selectedBooking?.boater} title={t('boater_infos')} />
              }

              {
                selectedBooking.status === TA_BOOKING_STATUS.REQUESTED && <>
                  <Button onClick={() => { setOpenEditModal(true) }} variant="contained" color="secondary">{t("accept")}</Button>
                  <Button onClick={() => {
                    setOpenRejectModal(true)
                    setModalRejectMode("reject")
                  }} variant="outlined" color="error">{t("reject")}</Button>
                </>
              }
              {
                [TA_BOOKING_STATUS.TO_VALIDATE_BY_BOATER, TA_BOOKING_STATUS.BOOKED].includes(selectedBooking.status) && <>
                  <Button onClick={() => {
                    setOpenRejectModal(true)
                    setModalRejectMode("cancel")
                  }} variant="outlined" color="error">{t("cancel")}</Button>
                </>
              }

            </Stack>
            <DetailLeft booking={selectedBooking} />

          </Box>
          {
            selectedBooking && <FormModal open={openEditModal} setOpen={setOpenEditModal}
              title={selectedBooking.status === TA_BOOKING_STATUS.BOOKED ? t("form.update.requestedtitle") : t("form.update.title")}
              printTitle={t("form.titleBoater")} >
              <TAEditForm onClose={() =>
                setOpenEditModal(false)
              } booking={selectedBooking} />
            </FormModal>
          }
          {
            selectedBooking && <FormModal open={openRejectModal} setOpen={setOpenRejectModal}
              title={modalRejectMode === "reject" ? t("form.reject.title") : t("form.cancel.title")}
              printTitle={t("form.titleBoater")} >
              <TARejectForm modalMode={modalRejectMode} onClose={() => setOpenRejectModal(false)} booking={selectedBooking} />
            </FormModal>
          }
        </Paper>
      }
    </>)
}